import NewsletterSampleAuthor from "@pages/Newsletter/Archive/Thrive/SampleIssues/components/NewsletterSampleAuthor";
import NewsletterSampleContent from "@pages/Newsletter/Archive/Thrive/SampleIssues/components/NewsletterSampleContent";
import NewsletterSampleFooter from "@pages/Newsletter/Archive/Thrive/SampleIssues/components/NewsletterSampleFooter";
import NewsletterSampleHeader from "@pages/Newsletter/Archive/Thrive/SampleIssues/components/NewsletterSampleHeader";
import NewsletterSamplePoll from "@pages/Newsletter/Archive/Thrive/SampleIssues/components/NewsletterSamplePoll";
import { cn } from "@util/helpers";

export type ThriveSampleIssueProps = {
  divRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  firstChildClassName?: string;
};

export default function ThriveSampleIssue({
  divRef,
  className,
  firstChildClassName,
}: ThriveSampleIssueProps): React.ReactElement {
  return (
    <>
      <h2 className="mb-3 text-center font-poppins text-base font-medium">
        Sample issue
      </h2>

      <div
        data-testid="thrive-sample-issue-component"
        ref={divRef}
        className={cn(className, "rounded-2xl border border-gray-850")}
      >
        <div
          className={cn(
            "mt-0 h-[525px] overflow-y-scroll",
            firstChildClassName
          )}
        >
          <div className="mx-auto mb-9 mt-3 w-full max-w-[600px] border-collapse">
            <NewsletterSampleHeader />

            <div className="px-8">
              <NewsletterSampleAuthor />

              <NewsletterSampleContent />

              <NewsletterSamplePoll />
            </div>

            <NewsletterSampleFooter />
          </div>
        </div>
      </div>
    </>
  );
}
