import { EmbedProps } from "@app/types/Embed";
import { Name, Type } from "@app/types/enums";
import { getPodcastStyle } from "@util/helpers";
import cx from "classnames";
import { stringify } from "querystringify";

export default function PodcastWrapper({
  className,
  fields,
}: EmbedProps): React.ReactElement {
  const podcast =
    fields.find((field) => field.name === Name.PodcastWrapperSource)?.value ||
    "";
  const podcastParse = JSON.parse(podcast);
  const podcastSrc = podcastParse.source;
  const podcastStyle = getPodcastStyle(podcastParse.size);
  const podcastURL = `${podcastSrc}/embed?${stringify(podcastStyle)}`;
  const iframeAttribtues = {
    className: cx("border-none", {
      "aspect-square": podcastStyle.size === "square",
    }),
    src: podcastURL,
    width: "100%",
  };

  return (
    <div
      className={className}
      data-story-element={Type.PodcastWrapper}
      data-testid="podcast-embed-component"
    >
      <iframe {...iframeAttribtues} title="podcast-embed-title" />
    </div>
  );
}
