import { Profile } from "@app/types/Cue";
import { isEmpty, maxBy } from "lodash-es";

/**
 * The latest active profile.
 * @param profiles List of profile of Author
 * @returns The latest active profile.
 */
function getAuthorProfile(profiles?: Profile[]) {
  const currentAuthorProfile = maxBy(profiles, (profile) => {
    if (isEmpty(profile.content.fields.startdate)) return 0;
    return new Date(profile.content.fields.startdate).getTime();
  });

  return { currentAuthorProfile };
}

export { getAuthorProfile };
