import { PropsWithChildren } from "react";
import { cn } from "@util/helpers";

export type ColumnProps = {
  rootClassName?: string;
  id?: string;
} & PropsWithChildren;

export default function Column(props: ColumnProps): React.ReactElement {
  const { rootClassName, children, ...rest } = props;

  return (
    <div
      data-component="component-column"
      className={cn("px-3", rootClassName)}
      {...rest}
    >
      {children}
    </div>
  );
}
