import * as React from "react";

import { iconPaths } from "./constant";

export interface IIcon extends React.SVGProps<SVGSVGElement> {
  type?:
    | "bar"
    | "search"
    | "close"
    | "chevronRight"
    | "chevronLeft"
    | "chevronDown"
    | "arrowLeftCircle"
    | "arrowRightCircle"
    | "playCircle"
    | "user"
    | "plus"
    | "plusCircle"
    | "checkCircle"
    | "xMark"
    | undefined;
  rootClassName?: string;
  viewbox?: number;
  size?: number;
  onClick?: (e: React.SyntheticEvent) => void;
}

/**
 * @see https://heroicons.com/
 * @description default fill white color; override with rootClassName
 */
export default function Icon({
  type,
  rootClassName = "",
  onClick,
  viewbox = 20,
  size = 20,
  ...rest
}: IIcon): React.ReactElement | null {
  const handleClick = (e: React.SyntheticEvent) => {
    if (onClick) onClick(e);
  };

  return type && iconPaths[type] ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${viewbox} ${viewbox}`}
      className={rootClassName}
      onClick={handleClick}
      fill="white"
      style={{ width: size, height: size }}
      {...rest}
    >
      {iconPaths[type]}
    </svg>
  ) : null;
}
