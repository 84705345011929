import { useLocation } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { TestIdClassNameProps } from "@app/types/Common";
import { MyBTKeywordsDataList } from "@app/types/Page";
import myBTLogo from "@assets/logo-mybt-light.svg";
import { BlockTitle } from "@blocks/Blocks";
import { useWindowSize } from "@hooks/useWindowSize";
import { Tag } from "@src/app/types/Cue";
import cx from "classnames";

import MyBTCarouselArrow from "./MyBTCarouselArrow";
import MyBTCarouselSlide from "./MyBTCarouselSlide";
import MyBTCarouselTooltip from "./MyBTCarouselTooltip";
import SliderArrow from "../Slider/components/SliderArrow";

type MyBTCarouselProps = TestIdClassNameProps & {
  results: MyBTKeywordsDataList;
  keywords: Tag[];
  title: string;
  page: string;
  showTooltip: boolean;
  disablePaywall?: boolean;
};

export default function MyBTCarousel({
  testId,
  rootClassName,
  results,
  keywords,
  title,
  page,
  showTooltip,
  disablePaywall = false,
}: MyBTCarouselProps): React.ReactElement {
  const SliderComponent =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    typeof window === "undefined" ? Slider.default : Slider;
  const location = useLocation();
  const { isScreenLG } = useWindowSize();
  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4,
    className: "mybt-homepage-carousel -mx-3",
    arrows: isScreenLG,
    prevArrow:
      location.pathname === "/mybt/onboard" ? (
        <SliderArrow
          direction="left"
          rootClassName="absolute bottom-[calc(100%+.6rem)] right-[4.5rem]"
          opacityWhenDisabled="opacity-30"
          prevArrowClass=""
        />
      ) : (
        <MyBTCarouselArrow direction="left" />
      ),
    nextArrow:
      location.pathname === "/mybt/onboard" ? (
        <SliderArrow
          direction="right"
          rootClassName="absolute bottom-[calc(100%+.6rem)] right-6"
          opacityWhenDisabled="opacity-30"
          nextArrowClass=""
        />
      ) : (
        <MyBTCarouselArrow direction="right" />
      ),
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.5,
        },
      },
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 2.5,
        },
      },
    ],
  };

  return (
    <div className={cx(rootClassName)} data-testid={testId}>
      {page === "home" ? (
        <BlockTitle text={title} rootClassName="flex gap-3">
          <img src={myBTLogo} width={72} height={72} />
        </BlockTitle>
      ) : (
        <h2 className="mb-3 mt-5 text-lg font-bold uppercase text-gray-850 lg:text-3xl">
          {title}
        </h2>
      )}

      {showTooltip ? <MyBTCarouselTooltip rootClassName="mt-2 mb-6" /> : null}

      <SliderComponent {...settings}>
        {keywords.map((keyword) => {
          const keywordData = keyword.urlPath
            ? results[keyword.urlPath]
            : undefined;

          return keywordData?.body ? (
            <MyBTCarouselSlide
              key={keyword.urlPath}
              articles={keywordData.body}
              tag={keyword}
              rootClassName="m-3"
              disablePaywall={disablePaywall}
            />
          ) : null;
        })}
      </SliderComponent>
    </div>
  );
}
