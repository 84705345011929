import cx from "classnames";

export type KeywordFilterProps = {
  toolTipClassName?: string;
  toolTipArrowClassName?: string;
};

export default function KeywordFilterTooltip({
  toolTipClassName = "",
  toolTipArrowClassName = "",
}: KeywordFilterProps): React.ReactElement {
  return (
    <>
      <div
        className={cx(
          "tooltip absolute z-20 w-[250px] animate-modalAppear text-center text-white",
          "left-0 top-full",
          "min-[425px]:left-full min-[425px]:top-1/2 min-[425px]:flex min-[425px]:-translate-y-1/2 min-[425px]:items-center",
          "xl:left-1/2 xl:top-full xl:block xl:-translate-x-1/2 xl:translate-y-0",
          toolTipClassName
        )}
      >
        <div
          className={cx(
            "my-0 h-0 w-0 flex-shrink-0 flex-grow-0 border-[6px] border-solid",
            "ml-6 border-b-black",
            "xl:mx-auto xl:border-b-[8px] xl:border-l-[6px] xl:border-r-[6px] xl:border-x-transparent xl:border-b-black xl:border-t-transparent",
            "min-[425px]:ml-0 min-[425px]:border-b-transparent min-[425px]:border-r-black",
            toolTipArrowClassName
          )}
        ></div>
        <div className="rounded-md bg-black px-3 py-2 font-poppins text-xs">
          You can follow keywords now
        </div>
      </div>
    </>
  );
}
