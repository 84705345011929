import {
  FetchSearchDataProps,
  FilteringSortEnum,
  SearchSelectedFacetedObj,
} from "@pages/Search/types";

export const getFacetedKeyAndValue = (
  selectedFacetedData: SearchSelectedFacetedObj[]
) => {
  const facetedkey = selectedFacetedData
    .map((facetedObj) => facetedObj.facetedKey)
    .filter((key) => key !== undefined)
    .join("|");

  const facetedvalue = selectedFacetedData
    .map((facetedObj) => facetedObj.facetedValue)
    .filter((key) => key !== "" && key !== undefined)
    .join("|");

  return { facetedkey, facetedvalue };
};

export const getQuerylyParmsList = ({
  query,
  sortBy,
  selectedFacetedAllData,
  endindex,
}: FetchSearchDataProps) => {
  const batchsize = 10;

  const { facetedkey, facetedvalue } = getFacetedKeyAndValue(
    selectedFacetedAllData || []
  );

  const list = {
    query,
    endindex: endindex || 0,
    batchsize: batchsize,
    sort: sortBy || FilteringSortEnum.Relevance,
  };

  if (facetedkey && facetedvalue) {
    const facetedList = {
      facetedkey,
      facetedvalue,
    };

    const newList = Object.assign(list, facetedList);
    return newList;
  }

  return list;
};
