import { PROPERTY_INSIGHT_OTHER_READS } from "@components/Newsletter/constants";
import NewsletterSampleFooter from "@pages/Newsletter/Archive/components/NewsletterSampleFooter";
import PropertyInsightNewsletterSampleAuthor from "@pages/Newsletter/Archive/PropertyInsight/SampleIssues/components/PropertyInsightNewsletterSampleAuthor";
import PropertyInsightNewsletterSampleContent from "@pages/Newsletter/Archive/PropertyInsight/SampleIssues/components/PropertyInsightNewsletterSampleContent";
import PropertyInsightNewsletterSampleHeader from "@pages/Newsletter/Archive/PropertyInsight/SampleIssues/components/PropertyInsightNewsletterSampleHeader";
import PropertyInsightNewsletterSampleOtherReads from "@pages/Newsletter/Archive/PropertyInsight/SampleIssues/components/PropertyInsightNewsletterSampleOtherReads";
import PropertyInsightNewsletterSampleTopReads from "@pages/Newsletter/Archive/PropertyInsight/SampleIssues/components/PropertyInsightNewsletterSampleTopReads";
import { cn } from "@util/helpers";

export type PropertyInsightLatestIssueProps = {
  divRef?: React.RefObject<HTMLDivElement>;
  rootClassName?: string;
  firstChildClassName?: string;
};

export default function PropertyInsightLatestIssue({
  divRef,
  rootClassName = "",
  firstChildClassName = "",
}: PropertyInsightLatestIssueProps): React.ReactElement {
  return (
    <>
      <h2 className="mb-3 text-center font-poppins text-base font-medium">
        Sample issue
      </h2>

      <div
        data-testid="property-iframe"
        ref={divRef}
        className={cn("rounded-2xl border-2 border-gray-850", rootClassName)}
      >
        <div
          className={cn(
            "mt-2 h-[525px] overflow-y-scroll",
            firstChildClassName
          )}
        >
          <div className="mx-auto mb-9 mt-3 w-full max-w-[600px] border-collapse">
            <PropertyInsightNewsletterSampleHeader />

            <PropertyInsightNewsletterSampleAuthor />

            <PropertyInsightNewsletterSampleContent />

            <PropertyInsightNewsletterSampleTopReads />

            <PropertyInsightNewsletterSampleOtherReads
              newsletters={PROPERTY_INSIGHT_OTHER_READS}
            />

            <NewsletterSampleFooter editionId={"61043"} />
          </div>
        </div>
      </div>
    </>
  );
}
