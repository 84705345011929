import { useState } from "react";
import { Newsletter } from "@components/Newsletter/types/Newsletter";

export default function useHandleSelectedNewsletter(): [
  Newsletter[],
  (action: "remove" | "add" | "reset", newsletter?: Newsletter) => void,
] {
  const [selectedNewsletters, setSelectedNewsletters] = useState<Newsletter[]>(
    []
  );

  const handleSelectedNewsletter = (
    action: "remove" | "add" | "reset",
    newsletter?: Newsletter
  ) => {
    if (action === "add" && newsletter) {
      setSelectedNewsletters((selectedNewsletters) => [
        ...selectedNewsletters,
        newsletter,
      ]);
    }

    if (action === "remove" && newsletter) {
      setSelectedNewsletters((selectedNewsletters) =>
        selectedNewsletters.filter(
          (selectedNewsletter) => selectedNewsletter.type !== newsletter.type
        )
      );
    }

    if (action === "reset") {
      setSelectedNewsletters([]);
    }
  };

  return [selectedNewsletters, handleSelectedNewsletter];
}
