import { RouteFactory } from "@app/routePaths";
import CirculationBanner from "@assets/newsletter/circulation_banner.gif";
import { renderGreenLink } from "@pages/Newsletter/Archive/util/helpers";
import { cn } from "@util/helpers";

const links = [
  {
    href: RouteFactory.newsletterSignup,
    text: "Sign up here",
  },
];

export default function EsgInsightsNewsletterSampleTopReads(): React.ReactElement {
  const bannerLink =
    "https://subscribe.sph.com.sg/promotions/bt-personal/?utm_campaign=btevgreenbanner1&utm_medium=banner&utm_source=newsletter&utm_content=ESGnewsletter-Evergreen1-2024";
  return (
    <>
      <div className="mx-5 pb-6 pl-0">
        <h5 className="box-border border-gray-250 pt-1 font-pullQuote text-4xl font-bold leading-6 text-verticals-esg">
          Other ESG reads
        </h5>
      </div>

      <div>
        {stories.map((story) => (
          <div
            key={story.id}
            data-id={`PropertyStory${story.id}`}
            className={cn("px-2 py-5")}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={story.link}
              className="flex text-black no-underline"
            >
              <div className="story-counter my-auto min-w-16 text-center font-pullQuote text-12xl font-normal text-verticals-esg">
                {story.counter}
              </div>

              <div className="story-title pl-3 pr-3 font-pullQuote text-xl font-bold text-black-200">
                {story.title}
              </div>
            </a>
          </div>
        ))}
      </div>
      <div className="border-t-[3px] border-t-gray-250 p-5">
        <p className="mb-2.5 font-times text-sm leading-6">
          Did you receive this newsletter from someone?
        </p>
        <p className="mb-2.5 font-times text-sm leading-6">
          {renderGreenLink("Sign up here", links[0].href)} to get the latest
          updates sent right to your inbox.
        </p>
      </div>
      <div className="front-copy mx-auto max-w-[800px] border-collapse p-0 p-5">
        <div className="border-t-[3px] border-gray-250 px-5 pb-6" />

        <div className="mx-auto flex max-w-[300px] justify-center p-0">
          <a
            href={bannerLink}
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline"
          >
            <img
              src={CirculationBanner}
              alt="Circulation Banner"
              width="300"
              height="180"
              className="block h-auto max-w-full"
            />
          </a>
        </div>
      </div>
    </>
  );
}

const stories = [
  {
    id: 0,
    title:
      "Asia-Pacific’s race to net zero is transforming the global investment landscape",
    link: "https://www.businesstimes.com.sg/esg/asia-pacifics-race-net-zero-transforming-global-investment-landscape",
    counter: 1,
  },
  {
    id: 1,
    title:
      "Accountants can help companies with sustainability strategies: global accounting body",
    link: "https://www.businesstimes.com.sg/esg/accountants-can-help-companies-sustainability-strategies-global-accounting-body",
    counter: 2,
  },
  {
    id: 2,
    title:
      "Government to price gas on cost-plus basis amid concerns over less competitive pricing with centralised procurement",
    link: "https://www.businesstimes.com.sg/singapore/economy-policy/government-price-gas-cost-plus-basis-amid-concerns-over-less-competitive-pricing-centralised",
    imgSrc: null,
    counter: 3,
  },
  {
    id: 3,
    title:
      "Thailand’s EV makers seek to renegotiate government incentives as sales slow",
    link: "https://www.businesstimes.com.sg/international/asean/thailands-ev-makers-seek-renegotiate-government-incentives-sales-slow",
    imgSrc: null,
    counter: 4,
  },
  {
    id: 4,
    title:
      "China plans to include steel, cement and aluminium in its carbon market in 2024",
    link: "https://www.businesstimes.com.sg/esg/china-plans-include-steel-cement-and-aluminium-its-carbon-market-2024",
    imgSrc: null,
    counter: 5,
  },
  {
    id: 5,
    title:
      "Trillions of dollars of fossil fuel finance routed via tax havens: study",
    link: "https://www.businesstimes.com.sg/esg/trillions-dollars-fossil-fuel-finance-routed-tax-havens-study",
    imgSrc: null,
    counter: 6,
  },
];
