import { CaaSImageFilters } from "@app/types/OptimisedImage";
import { stringify } from "querystringify";

export const getFormattedCaaSImageUrl = (
  url?: string,
  filters?: CaaSImageFilters
) => {
  if (!url) return "";
  if (!filters) return url;
  // https://d32cmb05ummg49.cloudfront.net/image/businesstimes/d01aab779738beb9b5e5bf88d33a972d4276019ad81c7f9632b9c879a9db3563.jpg
  return `${url}?${stringify(filters)}`;
};

/**
 * Preloads an image by creating a new Image object and setting its source to the specified URL.
 *
 * @param src - The URL of the image to preload.
 * @returns A promise that resolves when the image has finished loading, or rejects if an error occurs.
 */
export const preloadImage = (src: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
    img.onerror = reject;
  });
};

/**
 * Preloads an array of images by creating new Image objects and setting their sources to the specified URLs.
 *
 * @param imagesArray - An array of URLs of the images to preload.
 * @returns A promise that resolves when all the images have finished loading, or rejects if an error occurs.
 */
export const preloadImages = (imagesArray: string[] = []) => {
  const promises = imagesArray.map((src) => preloadImage(src));
  return Promise.all(promises);
};
