import {
  FilterByDateType,
  FilteringfacetedObjectEnum,
  FilteringSortEnum,
} from "./types";

export const FilteringSort = [
  { key: FilteringSortEnum.Relevance, value: "Relevance" },
  { key: FilteringSortEnum.Newest, value: "Newest" },
];

export const FilteringAllfaceted = [
  { key: FilteringfacetedObjectEnum.pubDate, value: "Filter by date" },
  { key: FilteringfacetedObjectEnum.category, value: "Filter by category" },
  { key: FilteringfacetedObjectEnum.creator, value: "Filter by author" },
];

export const FilteringByDate: FilterByDateType = {
  "24": "Within one day",
  "168": "Within one week",
  "720": "Within one month",
  "4320": "Within 6 months",
  "8760": "Within one year",
  "26280": "Within 3 years",
};
