import { StoryThreadLayout } from "./utilities/helpers";
import { StoryThreadContext } from "./utilities/types";

type StoryThreadContentProps = Pick<StoryThreadContext, "data" | "entity">;

export default function StoryThreadPage({
  data,
  entity,
}: StoryThreadContentProps): React.ReactElement {
  const Layout = StoryThreadLayout(entity.urlPath);

  return <Layout {...{ data, entity }} />;
}
