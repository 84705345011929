import aseanSampleImg from "@assets/newsletter/aseanSampleImg.png";
import SpotlightIcon from "@assets/newsletter/show_spotlight_theather_icon.png";
import { renderBlueLink } from "@pages/Newsletter/Archive/util/helpers";

const links = [
  {
    href: "https://www.businesstimes.com.sg/international/asean/99-speed-mart-jumps-14-market-debut-following-malaysias-largest-ipo-7-years",
    text: "biggest IPO",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/thai-household-debt-record-high-amid-sluggish-economy-survey-shows",
    text: "record high",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/vietnam-tech-unicorn-vng-appoints-new-acting-general-manager-after-police-raid",
    text: "police raid",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/indonesias-planned-push-retire-13-coal-fired-plants-could-face-social-funding-challenges",
    text: "retire coal-fired power plants",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/hanoi-flooded-swollen-river-typhoon-yagi-leaves-179-dead",
    text: "at least 179 people",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/tourism-factories-reeling-typhoon-yagi-wreaks-havoc-northern-vietnam",
    text: "Read our Vietnam correspondent Jamille Tran’s story to find out more",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/asia-funds-create-alliance-help-funnel-billions-vietnam",
    text: "Vietnam Private Capital Agency,",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/can-south-east-asia-keep-lights-and-taps-running-amid-data-centre-boom",
    text: "Read this piece",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/thai-consumer-confidence-drops-13-month-low-august",
    text: "13-month low",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/thailand-distribute-first-145-billion-baht-handout-scheme-starting-month",
    text: "accelerating the rollout of a 145 billion baht “digital wallet”",
  },
  {
    href: "https://www.businesstimes.com.sg/international/asean/energy-giants-exits-deflate-vietnams-offshore-wind-ambitions",
    text: "comprehensive legal framework",
  },
  {
    href: "https://www.businesstimes.com.sg/companies-markets/oxley-holdings-expects-net-loss-h2-fy2024-earnings",
    text: "Oxley Holdings",
  },
  {
    href: "https://www.businesstimes.com.sg/property/us-new-home-sales-rise-more-expected-july",
    text: "US single-family homes",
  },
  {
    href: "https://www.businesstimes.com.sg/property/relevance-real-estate-agent-table",
    text: "not hiring a real estate agent",
  },
  {
    href: "mailto:lyee@sph.com.sg",
    text: "lyee@sph.com.sg",
  },
];

export default function AseanBusinessPropertyInsightNewsletterSampleContent(): React.ReactElement {
  return (
    <>
      <div className="today-highlights-wrapper px-5 pb-6">
        <h4 className="mb-2.5 font-arial text-base font-bold leading-6 text-gray-850">
          This week in Asean:
        </h4>

        <ul className="mb-2.5 list-disc pl-7 font-arial text-base font-normal leading-6 text-gray-850">
          <li className="pb-2">
            <strong>Malaysia:</strong> 99 Speed Mart shines in the country’s{" "}
            {renderBlueLink("biggest IPO", links[0].href)} in 7 years
            <br />
            <strong>Thailand:</strong> Household debt hits{" "}
            {renderBlueLink("record high", links[0].href)} at 90.8 per cent of
            GDP
            <br />
            <strong>Vietnam:</strong> VNG appoints new acting general manager
            after {renderBlueLink("police raid", links[0].href)}
            <br />
            <strong>Indonesia:</strong> Government efforts to{" "}
            {renderBlueLink("retire coal-fired power plants", links[0].href)}{" "}
            encounter resistance
          </li>
        </ul>
      </div>

      <div className="mx-5 mt-6 border-t-[3px] border-gray-250 pb-6" />

      <div className="front-copy w-full max-w-2xl px-5 text-left font-arial font-normal">
        <div className="greeting-text-wrapper ">
          <div>
            <img
              src={aseanSampleImg}
              alt="READ MORE"
              className="my-6 block h-auto max-w-full"
            />
          </div>
          <p className="mb-2.5 font-arial text-sm font-normal leading-6 text-gray-850">
            People wade through flood waters on a boat in Hanoi as heavy rains
            in the aftermath of Typhoon Yagi brought flooding to northern
            Vietnam. PHOTO: AFP
          </p>

          <p>&nbsp;</p>
          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            Dear BT reader,
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            This week, northern Vietnam was severely impacted by Typhoon Yagi,
            Asia’s most powerful storm this year, with the death toll rising to{" "}
            {renderBlueLink("at least 179 people", links[0].href)} after it tore
            through China’s island of Hainan and the Philippines.
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            In Hanoi, the Red River reached its highest water level in 20 years,
            flooding streets and forcing thousands of nearby residents to
            evacuate. Travel agencies have halted operations and there is no
            clear timeline for recovery.{" "}
            {renderBlueLink(
              "Read our Vietnam correspondent Jamille Tran’s story to find out more",
              links[0].href
            )}
            .
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            The recent disaster has not diminished Vietnam’s appeal to foreign
            investors. In response, several private equity firms in the country
            have established the{" "}
            {renderBlueLink("Vietnam Private Capital Agency,", links[0].href)}{" "}
            aiming to facilitate US$35 billion of investment into Vietnam over
            the next decade.
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            While the target is higher than previous years’ annual investments,
            market observers remain optimistic that Vietnam will continue to
            attract foreign investment as global companies consider relocating
            their operations amid US-China tensions.
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            This trend is particularly evident in the South-east Asia data
            centre industry, where Malaysia, Indonesia, Singapore, Vietnam and
            Thailand are leading a boom. However, despite the influx of billions
            in investments, the strain on power grids and water supplies is
            raising concerns for governments.
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            Each country is developing its own solutions for sustainable
            development, including renewable energy initiatives.{" "}
            {renderBlueLink("Read this piece", links[0].href)} – a collaborative
            effort by several BT journalists – to find out what the various
            nations are doing.
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            In Thailand, consumer confidence fell for the sixth consecutive
            month in August, reaching a{" "}
            {renderBlueLink("13-month low", links[0].href)} due to worries about
            sluggish economic growth, record-high household debts, and rising
            living costs amid uncertainty over new government policies.
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            To stimulate the economy, the Thai government proposed{" "}
            {renderBlueLink(
              "accelerating the rollout of a 145 billion baht “digital wallet”",
              links[0].href
            )}{" "}
            programme, which was initially scheduled for the last quarter of the
            year, to address urgent economic needs.
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            This measure is seen as a cornerstone of Thailand’s strategy to
            revitalise its economy, which grew by only 2.3 per cent in the
            second quarter.
          </p>

          <p className="mb-2.5 font-arial text-base font-normal leading-6 text-gray-850">
            I hope you have enjoyed this week’s reads. Be sure to explore more
            stories in the sections below, and see you next Friday!
          </p>
        </div>

        <h4 className="mb-2.5 pt-12 font-arial text-base font-bold leading-6 text-verticals-asean">
          TRENDING
        </h4>
        <div className="greeting-text-wrapper bg-[#D6F0FF]">
          <div className="flex gap-4 p-5">
            <div className="m-auto min-w-16">
              <img
                width="56"
                height="57"
                src={SpotlightIcon}
                alt="READ MORE"
                className="my-6 block h-auto max-w-full"
              />
            </div>
            <div>
              <p className="mb-5 font-arial text-base leading-6">
                <strong>What’s going on?</strong> Norwegian energy company
                Equinor has scrapped its offshore wind plans in Vietnam due to
                growing regulatory and economic uncertainties.
              </p>
              <p className="mb-2.5 font-arial text-base leading-6">
                <strong>Why it matters:</strong> Despite the country’s vast
                offshore wind potential, the absence of a{" "}
                {renderBlueLink("comprehensive legal framework", links[0].href)}{" "}
                has caused several investors to withdraw from their deals,
                reports our Vietnam correspondent Jamille Tran
              </p>
            </div>
          </div>
        </div>

        <div className="mt-12 border-t-[6px] border-gray-250 pb-6" />
      </div>
    </>
  );
}
