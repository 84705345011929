import React, { memo } from "react";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import chevronRight from "@assets/icon-chevron-right-white.svg";
import { SanitizedPulseData } from "@pages/Pulse/utils/types";
import { getFormattedCaaSImageUrl } from "@src/app/components/OptimisedImage/helper";
import { cn } from "@util/helpers";

export type PulseIndividualArticleProps = {
  article: SanitizedPulseData;
  cardWidth?: number;
  smallCardWidth?: number;
  testId?: string;
  activeSlide: number;
  index: number;
};

function PulseIndividualArticle({
  article,
  smallCardWidth,
  cardWidth,
  activeSlide,
  index,
  testId = "pulse-desktop-individual-article-card-component",
}: PulseIndividualArticleProps): React.ReactElement {
  const defaultFilters: CaaSImageFilters[] = [{ w: 800, dpr: 1, f: "webp" }];

  const imgSrcSets = defaultFilters.map(({ breakpoint, ...imageFilters }) => {
    return getFormattedCaaSImageUrl(article.media, { ...imageFilters });
  });

  return (
    <div
      data-testid={testId}
      className={cn(
        "relative h-[360px] overflow-hidden rounded-md bg-gradient-10 from-[#373737] to-[#2E2E2E] p-[1px]",
        "transition-size duration-300 group-[[data-is-active='true']]:h-full group-[[data-is-prev='true']]:opacity-0 group-[[data-is-active='true']]:shadow-pulseActive"
      )}
      style={{
        width: `${activeSlide === index ? cardWidth : smallCardWidth}px`,
      }}
    >
      <div className="h-full bg-[#212121]">
        <div
          className={cn(
            "relative h-[128px] rounded-t-md bg-cover bg-center bg-no-repeat transition-all duration-300",
            "group-[[data-is-active='true']]:h-[240px]"
          )}
          style={{
            backgroundImage: `url(${imgSrcSets[0]})`,
          }}
        >
          <div className="absolute h-full w-full bg-gradient-to-t from-[#212121]"></div>

          <div className="relative flex h-full flex-col justify-end px-6 py-3">
            <div>
              <p
                className={cn(
                  "mb-0 pb-2 font-poppins text-10xs font-medium uppercase tracking-widest text-white transition-all duration-300 text-shadow-pulse-headlines",
                  "group-[[data-is-active='true']]:text-xs"
                )}
                data-testid="individual-article-card-section"
              >
                {article.sections}
              </p>

              <p
                className={cn(
                  "font-lct text-4xs font-bold leading-tight -tracking-2% text-white transition-all duration-300 text-shadow-pulse-headlines",
                  "group-[[data-is-active='true']]:text-4xl"
                )}
                data-testid="individual-article-card-title"
              >
                {article.title}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-4 ">
          <ul
            className={cn(
              "list-outside list-disc space-y-2 overflow-hidden px-9 font-lucida text-9xs font-medium leading-normal -tracking-5% text-gray-250 transition-all duration-300",
              "group-[[data-is-active='true']]:text-base"
            )}
          >
            {article.summaries.map((summary, index) => {
              return <li key={`summary-${article.id}-${index}`}>{summary}</li>;
            })}
          </ul>
        </div>

        <div className="absolute bottom-0 w-full">
          <div
            className={cn(
              "absolute bottom-0 flex h-[80px] w-full items-end justify-end bg-gradient-to-t from-[#212121] from-30% to-100% transition-all duration-300",
              "group-[[data-is-active='true']]:h-[100px] "
            )}
          ></div>

          <div
            className={cn(
              "relative mx-4 flex h-max items-end justify-end border-t border-gray-750 p-1 transition-all duration-300",
              "group-[[data-is-active='true']]:p-4"
            )}
          >
            <a
              href={`${article.url}?ref=pulse`}
              target="_blank"
              className={cn(
                "tracking-tight pointer-events-none inline-flex items-center gap-0 rounded-[60px] border border-solid border-transparent bg-gray-850 px-1 py-1 font-poppins text-10xs font-semibold leading-tight text-gray-250 transition-all duration-300 hover:bg-[#555555] active:scale-95",
                "group-[[data-is-active='true']]:pointer-events-auto group-[[data-is-active='true']]:gap-2 group-[[data-is-active='true']]:px-8 group-[[data-is-active='true']]:py-4 group-[[data-is-active='true']]:text-sm"
              )}
              rel="noreferrer"
              aria-label={`link-${article.id}`}
            >
              Read full story
              <img
                src={chevronRight}
                className={cn(
                  "h-3 w-3 transition-all duration-300",
                  "group-[[data-is-active='true']]:h-6 group-[[data-is-active='true']]:w-6"
                )}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(PulseIndividualArticle);
