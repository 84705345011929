import cx from "classnames";

interface SideMenuWrapperProps {
  children: React.ReactElement;
  isOpen: boolean;
}

export function ThriveSideMenuWrapper({
  children,
  isOpen,
}: SideMenuWrapperProps): React.ReactElement {
  return (
    <>
      <div
        data-testid="side-menu-wrapper"
        className={cx("thrive-side-menu-wrapper absolute w-full", {
          open: isOpen,
        })}
      >
        {children}
      </div>
    </>
  );
}
