import { TestIdClassNameProps } from "@app/types/Common";
import { BlockTitle } from "@blocks/Blocks";
import { useHomepageVideos } from "@hooks/useHomepageVideos";
import { sectionNavigationItems } from "@util/constant";

import VideosStories from "./VideoStories";

const section = sectionNavigationItems.videos;

export default function Video({
  testId,
  rootClassName,
}: TestIdClassNameProps): React.ReactElement {
  const { videos } = useHomepageVideos();

  return (
    <section className={rootClassName} data-testid={testId}>
      <BlockTitle link={section?.link} text={section?.label?.toUpperCase()} />

      <div className="videos">
        {videos.map((video, index) => {
          return <VideosStories key={video.id} index={index} {...video} />;
        })}
      </div>
    </section>
  );
}
