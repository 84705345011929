import { Section, Tag } from "@app/types/Cue";
import {
  BT_NEWSLETTERS,
  Newsletter,
} from "@components/Newsletter/types/Newsletter";
import { isEmpty } from "lodash-es";

/**
 * Helper function to get the newsletter object based on sections
 * @param sections Array of section
 * @returns {(Newsletter[] | null)}
 */
export const getNewsletterBySections = (
  sections: Section[]
): Newsletter[] | null => {
  const newsletters = BT_NEWSLETTERS.filter((newsletter) =>
    newsletter.sections?.some((newsletterSection) =>
      sections.some(
        (articleSection) =>
          articleSection.uniqueName === newsletterSection.uniqueName
      )
    )
  ).slice(0, 1);

  return !isEmpty(newsletters) ? newsletters : null;
};

/**
 * Helper function to get the newsletter object based on tags
 * @param tags Array of tags
 * @returns {(Newsletter[] | null)}
 */
export const getNewsletterByTags = (tags: Tag[] = []): Newsletter[] | null => {
  const newsletters = BT_NEWSLETTERS.filter((newsletter) =>
    newsletter.tags?.some((newsletterTag) =>
      tags.some((articleTag) => articleTag.urlPath === newsletterTag.urlPath)
    )
  ).slice(0, 1);

  return !isEmpty(newsletters) ? newsletters : null;
};

/**
 * Helper function to format the given title to a URL-friendly format.
 * @param title The title to be formatted.
 * @returns The formatted title.
 */
export function formatTitle(title: string): string {
  return title
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .replace(/\s+/g, "-");
}
