import { useSearchParams } from "react-router-dom";
import { useWindowSize } from "@hooks/useWindowSize";
import { IS_PLAYWRIGHT } from "@util/constant";
import { useDetectAdBlock } from "adblock-detect-react";
import Cookies from "js-cookie";

import { AdTargetingType } from "./AdUnit";
import PrestitialAd from "./PrestitialAd";

export type PrestitialAdWrapperProps = {
  isPrestitialEnabled: boolean;
  slot: string;
  topOverlayImpressions: number;
  topOverlayValidity: number;
  slotTargettings?: AdTargetingType[];
};

export default function PrestitialAdWrapper({
  isPrestitialEnabled,
  slot,
  topOverlayImpressions, // Maximum total number of impressions
  topOverlayValidity, // Validity of topoverlay cookie in hours
  slotTargettings = [],
}: PrestitialAdWrapperProps): React.ReactElement {
  const adBlockDetected = useDetectAdBlock();
  const { isScreenMD } = useWindowSize();
  const [searchParams] = useSearchParams();

  const topOverlayDisplayedCookie = Cookies.get("topoverlayDisplayed");
  const topOverlayImpressionsServedCookie = Cookies.get(
    "topOverlayImpressionsServed"
  );

  const totalImpressionsServed =
    parseInt(topOverlayImpressionsServedCookie || "0") || 0;

  const displayPrestitial =
    isPrestitialEnabled && // Variable coming from prebid.js, controlled by ads team.
    !adBlockDetected && // Display if there is no adblocker
    totalImpressionsServed <= topOverlayImpressions && // Display until maximum impressions reached
    typeof topOverlayDisplayedCookie === "undefined" && // Display if cookie is NOT present
    !IS_PLAYWRIGHT; // Display if not in playwright

  const hasDfpPreview = typeof searchParams.get("dfp_preview") === "string"; // Override for prestitial

  return (
    <>
      {(displayPrestitial || hasDfpPreview) && isScreenMD ? (
        <PrestitialAd
          {...{
            slot,
            topOverlayImpressions,
            totalImpressionsServed,
            topOverlayValidity,
            adBlockDetected,
            slotTargettings,
          }}
        />
      ) : null}
    </>
  );
}
