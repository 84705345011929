import Container from "@components/Container/Container";
import { linksInHomeNewsletterOther } from "@pages/Thrive/constants";

export default function ThriveSampleNewsletterOthers(): React.ReactElement {
  return (
    <Container
      rootClassName="px-0 text-gray-850"
      data-testid="thrive-home-sample-newsletter-others-component"
    >
      <p className="m-0 py-[10px] font-interBold text-2xl">In other news…</p>

      <div className="pb-2">
        <p className="m-0 mb-2 p-0 font-interBold">⚖️ Economy</p>

        <ul className="list-disc pl-6">
          <li>
            That{" "}
            <a
              href={linksInHomeNewsletterOther.economyPortCongestion.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              port congestion
            </a>
            {
              "issue we wrote about last week? Singapore's transport minister has revealed that"
            }
            <a
              href={linksInHomeNewsletterOther.economy90Percent.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              about 90 per cent of
            </a>{" "}
            containerships are off-schedule, compared with an average of 77 per
            cent in 2023.
          </li>
        </ul>
      </div>

      <div className="pb-2">
        <p className="m-0 my-2 p-0 font-interBold">💰 Money</p>

        <ul className="list-disc pl-6">
          <li className="mb-2">
            {
              "HDB coffee shops aren't the only places where you can spend your CDC vouchers. Around 180 coffee shops run by private chains – including "
            }
            <a
              href="https://www.straitstimes.com/singapore/180-coffee-shops-to-join-budget-meal-scheme-to-accept-cdc-vouchers-from-july-1"
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              Chang Cheng and Koufu
            </a>{" "}
            – have started accepting these vouchers
          </li>

          <li className="mb-2">
            <a
              href={linksInHomeNewsletterOther.moneyScoot.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              Scoot and AirAsia
            </a>{" "}
            are offering discounted flight tickets from now till Jul 7 to
            destinations such as Athens, Osaka, Bali and Phuket.
          </li>

          <li className="mb-2">
            Four years since digital retail banks made a splash in Singapore,
            none of the five banks have{" "}
            <a
              href={linksInHomeNewsletterOther.moneyProfit.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              made a profit.
            </a>
          </li>

          <li className="mb-2">
            <a
              href={linksInHomeNewsletterOther.moneyHospitalisation.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              Hospitalisation insurance
            </a>{" "}
            plans can vary by more than S$85,000 over your lifetime, depending
            on which insurance company you choose. For an overview on what these
            are and whether you need one, check out our post on
            <a
              href={linksInHomeNewsletterOther.moneyShieldPlans.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              Integrated Shield Plans.
            </a>
          </li>
        </ul>
      </div>

      <div className="pb-2">
        <p className="m-0 my-2 p-0 font-interBold">📈 Stocks</p>

        <ul className="list-disc pl-6">
          <li className="mb-2">
            {
              "Should GIC, Singapore's sovereign wealth fund, invest to help revive the Singapore Exchange? Doing so is\""
            }
            <a
              href={linksInHomeNewsletterOther.stocksNotTheSolution.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              not the solution
            </a>
            {'" '}says the government.
          </li>

          <li className="mb-2">
            {"Singapore's"} <s>benchmark</s> banking index?: For every dollar
            you put in the
            <a
              href={linksInHomeNewsletterOther.stockSTI.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              Straits Times Index
            </a>{" "}
            around 45 cents now goes into shares of the three local banks.
          </li>

          <li className="mb-2">
            {
              "Here's a recent trend in the world of exchange-traded funds (ETFs): Stock ETFs that"
            }
            <a
              href={linksInHomeNewsletterOther.stocksETF.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              {"guarantee you can't lose money."}
            </a>
          </li>
        </ul>
      </div>

      <div className="pb-2">
        <p className="m-0 my-2 p-0 font-interBold">💼 Career</p>

        <ul className="list-disc pl-6">
          <li className="mb-2">
            <a
              href={linksInHomeNewsletterOther.careerNinjaVan.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              Ninja Van
            </a>{" "}
            is conducting a second round of layoffs, cutting the jobs of 5 per
            cent of junior tech roles in Singapore.
          </li>

          <li className="mb-2">
            Private equity firms are on the hunt for skilled operators of{" "}
            <a
              href={linksInHomeNewsletterOther.careerDataCentre.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              data centres
            </a>{" "}
            as money is pouring into the region in anticipation of AI computing
            needs.
          </li>
        </ul>
      </div>

      <div className="pb-2">
        <p className="m-0 my-2 p-0 font-interBold">🧬 Life</p>

        <ul className="list-disc pl-6">
          <li className="mb-2">
            In the Thai hit movie{" "}
            <i>How To Make Millions Before Grandma Dies,</i> grandmother Amah
            occasionally speaks Teochew, a language her grandson M struggles to
            understand. This{" "}
            <a
              href={linksInHomeNewsletterOther.lifeLanguageBarrier.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              language barrier
            </a>{" "}
            is also a problem in Singapore.
          </li>
        </ul>
      </div>

      <div className="pb-2">
        <p className="m-0 my-2 p-0 font-interBold">🏠 Property</p>

        <ul className="list-disc pl-6">
          <li className="mb-2">
            HDB resale prices went up{" "}
            <a
              href={linksInHomeNewsletterOther.propertyRise.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              2.1 per cent
            </a>{" "}
            in the second quarter of this year, the fastest three-month rise
            since 2022.
          </li>
        </ul>
      </div>

      <div className="pb-2">
        <p className="m-0 my-2 p-0 font-interBold">📜 Politics</p>

        <ul className="list-disc pl-6">
          <li className="mb-2">
            Why are members of parliament allowed to hold full-time jobs? Prime
            Minister{" "}
            <a
              href={linksInHomeNewsletterOther.politicsPm.to}
              target="_blank"
              className="border-b-2 border-green-400"
              rel="noreferrer"
            >
              Lawrence Wong
            </a>{" "}
            responds.
          </li>
        </ul>
      </div>
    </Container>
  );
}
