import { Link } from "react-router-dom";
import { isExternalLink } from "@util/helpers";
import cx from "classnames";

interface NavigationItemProps {
  to: string;
  children: React.ReactNode;
  className?: string;
}

export default function NavigationItem({
  to,
  children,
  className,
}: NavigationItemProps): React.ReactElement {
  return (
    <li className="items-center font-inter text-lg font-normal text-gray-850">
      <Link
        to={to}
        className={cx("leading-[22.4px]", className)}
        {...(isExternalLink(to) ? { target: "_blank" } : undefined)}
        reloadDocument
      >
        {children}
      </Link>
    </li>
  );
}
