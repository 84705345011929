import { useEffect, useRef } from "react";
import Zoom from "react-medium-image-zoom";
import { TestIdClassNameProps } from "@app/types/Common";
import { Media } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import chevronLeft from "@assets/icon-chevron-left.svg";
import chevronRight from "@assets/icon-chevron-right.svg";
import ArticleThumbnail from "@components/ArticleThumbnail/ArticleThumbnail";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { useOnLoadImages } from "@hooks/useOnLoadImages";
import { BrightcoveVideo } from "@pages/Article/components/StoryElements/BrightcoveVideo";
import YouTubeVideo from "@pages/Article/components/StoryElements/YouTubeVideo";
import {
  cn,
  getDimensionsForFilter,
  getImageCaptionCreditFromSummary,
} from "@util/helpers";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@greglaisph/react-splide/css";

type ArticleMainImageProps = TestIdClassNameProps & {
  media: Media[];
  captionRootClassname?: string;
  imgRootClassName?: string;
  noCaption?: boolean;
  thumbnailRootClassName?: string;
  landscapeThumbnailRootClassName?: string;
  landscapeImgRootClassName?: string;
  invisibleImgRootClass?: string;
  thumbnailArrowRootClassNameLeft?: string;
  thumbnailArrowRootClassNameRight?: string;
  displayBlurBg?: boolean;
  forceDisplayArrow?: boolean;
  withPagination?: boolean;
  thumbnailArrowHoverState?: string;
  withZoom?: boolean;
};
export default function ArticleMainImage({
  rootClassName,
  media,
  captionRootClassname,
  imgRootClassName,
  thumbnailRootClassName = "bg-gray-250",
  noCaption = false,
  landscapeThumbnailRootClassName = "",
  landscapeImgRootClassName = "",
  invisibleImgRootClass = "",
  thumbnailArrowRootClassNameLeft = "",
  thumbnailArrowRootClassNameRight = "",
  displayBlurBg = false,
  forceDisplayArrow = true,
  withPagination = false,
  withZoom = true,
}: ArticleMainImageProps): React.ReactElement {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  const options: Options = {
    perMove: 1,
    pagination: media?.length > 1 ? withPagination : false,
    arrows: media?.length > 1,
    snap: true,
    focus: 0,
    type: "loop",
    speed: 500,
    drag: media?.length > 1,
    classes: {
      // Add classes for pagination.
      page: `splide__pagination__page aria-selected:scale-[1.25] aria-selected:opacity-100 aria-selected: bg-gray-650 m-[0.25rem]`, // each button
    },
  };

  const optionsForCaption: Options = {
    perMove: 1,
    pagination: false,
    arrows: false,
    snap: true,
    focus: 0,
    type: "loop",
    drag: false,
    speed: 500,
  };

  const thumbnailsRef = useRef<Splide>(null);
  const captionRef = useRef<Splide>(null);

  useEffect(() => {
    if (
      thumbnailsRef.current &&
      captionRef.current &&
      captionRef.current.splide &&
      thumbnailsRef.current.splide
    ) {
      captionRef.current.sync(thumbnailsRef.current.splide);
    }
  }, [thumbnailsRef, captionRef]);

  return (
    <>
      {media.length > 0 ? (
        <div
          className={rootClassName}
          data-testid="article-main-image-component"
        >
          <Splide options={options} hasTrack={false} ref={thumbnailsRef}>
            <ul
              className={cn("splide__pagination", {
                "flex lg:hidden": withPagination,
              })}
            ></ul>
            <div
              className={cn(
                "splide__arrows absolute bottom-1/2 hidden w-full gap-2 lg:flex",
                { "flex lg:flex": forceDisplayArrow }
              )}
            >
              <button
                className={cn(
                  "splide__arrow splide__arrow--prev left-5 bg-gray-250 focus-within:outline-none focus:outline-none",
                  thumbnailArrowRootClassNameLeft
                )}
                data-size={"sm"}
              >
                <div
                  className={cn(
                    "flex h-full w-full items-center justify-center"
                  )}
                >
                  <img src={chevronLeft} width={18} height={18} />
                </div>
              </button>
              <button
                className={cn(
                  "splide__arrow splide__arrow--next right-5 bg-gray-250 focus-within:outline-none focus:outline-none ",
                  thumbnailArrowRootClassNameRight
                )}
                data-size={"sm"}
              >
                <div
                  className={cn(
                    "flex h-full w-full items-center justify-center"
                  )}
                >
                  <img src={chevronRight} width={18} height={18} />
                </div>
              </button>
            </div>
            <SplideTrack>
              {media.map(({ content, ...rest }) => {
                const thumbnail = content
                  ? { ...content, alt: "" }
                  : { ...rest, alt: "" };
                // If Article Main Image Has YoutubeVideo.
                const isYoutubeVideo =
                  content?.type === Type.ArticleYoutubeVideo;
                const videoFields = isYoutubeVideo
                  ? [
                      {
                        name: "title",
                        annotations: [],
                        value: content?.fields?.title,
                      },
                      {
                        name: "description",
                        annotations: [],
                        value: content?.fields?.description,
                      },
                      {
                        name: "youtubeId",
                        annotations: [],
                        value: content?.fields?.youtubeId,
                      },
                      {
                        name: "display",
                        annotations: [],
                        value: "card",
                      },
                    ]
                  : null;

                // If Article Main Image Has BrightcoveVideo.
                const isBrightcoveVideo =
                  content?.type === Type.ArticleBrightcoveVideo;
                const brightcoveVideoFields = isBrightcoveVideo
                  ? [
                      {
                        name: "title",
                        value: content?.fields?.title,
                        annotations: [],
                      },
                      {
                        name: "description",
                        value: content?.fields?.description,
                        annotations: [],
                      },
                      {
                        name: "brightcoveId",
                        value: content?.fields?.brightcoveId,
                        annotations: [],
                      },
                    ]
                  : null;
                const metadata = content?.fields?.com_escenic_defaultmetadata;

                const filters: CaaSImageFilters[] = [
                  {
                    breakpoint: "(min-width: 992px)",
                    ...getDimensionsForFilter(metadata, { w: 1520, h: 1281 }),
                    f: "webp",
                  },
                  {
                    ...getDimensionsForFilter(metadata, { w: 1050, h: 600 }),
                    f: "webp",
                  },
                ];

                return (
                  <SplideSlide key={`main-media-container-${content?.id}`}>
                    <div
                      className="relative h-full"
                      key={content?.id}
                      ref={wrapperRef}
                    >
                      {!imagesLoaded ? (
                        <div className="mx-auto aspect-3x2 w-full max-w-[1400px] bg-gray-250"></div>
                      ) : (
                        <>
                          {!isYoutubeVideo && !isBrightcoveVideo ? (
                            <>
                              {withZoom ? (
                                <Zoom zoomMargin={32} classDialog="medium-zoom">
                                  <ArticleThumbnail
                                    thumbnail={thumbnail}
                                    filters={filters}
                                    rootClassName={thumbnailRootClassName}
                                    imgRootClassName={imgRootClassName}
                                    landscapeThumbnailRootClassName={
                                      landscapeThumbnailRootClassName
                                    }
                                    landscapeImgRootClassName={
                                      landscapeImgRootClassName
                                    }
                                    invisibleImgRootClass={
                                      invisibleImgRootClass
                                    }
                                    displayBlurBg={displayBlurBg}
                                  />
                                </Zoom>
                              ) : (
                                <ArticleThumbnail
                                  thumbnail={thumbnail}
                                  filters={filters}
                                  rootClassName={thumbnailRootClassName}
                                  imgRootClassName={imgRootClassName}
                                  landscapeThumbnailRootClassName={
                                    landscapeThumbnailRootClassName
                                  }
                                  landscapeImgRootClassName={
                                    landscapeImgRootClassName
                                  }
                                  invisibleImgRootClass={invisibleImgRootClass}
                                  displayBlurBg={displayBlurBg}
                                />
                              )}
                            </>
                          ) : null}
                          {isYoutubeVideo && videoFields ? (
                            <YouTubeVideo
                              key={content.id}
                              fields={videoFields}
                              className="mb-6 flex h-full items-center justify-center  bg-gray-250"
                            />
                          ) : null}
                          {isBrightcoveVideo && brightcoveVideoFields ? (
                            <BrightcoveVideo
                              key={content.id}
                              fields={brightcoveVideoFields}
                              className="mb-6 flex h-full items-center justify-center bg-gray-250"
                            />
                          ) : null}
                        </>
                      )}
                    </div>
                  </SplideSlide>
                );
              })}
            </SplideTrack>
          </Splide>
          {!noCaption ? (
            <Splide
              options={optionsForCaption}
              hasTrack={false}
              ref={captionRef}
            >
              <SplideTrack>
                {media.map(({ content, ...rest }) => {
                  // If Article Main Image Has YoutubeVideo.
                  const isYoutubeVideo =
                    content?.type === Type.ArticleYoutubeVideo;
                  // If Article Main Image Has BrightcoveVideo.
                  const isBrightcoveVideo =
                    content?.type === Type.ArticleBrightcoveVideo;

                  return (
                    <SplideSlide key={`captio-media-container-${content?.id}`}>
                      <div>
                        {!isYoutubeVideo && !isBrightcoveVideo ? (
                          <div className={captionRootClassname}>
                            {getImageCaptionCreditFromSummary(rest.summary)}
                          </div>
                        ) : null}
                      </div>
                    </SplideSlide>
                  );
                })}
              </SplideTrack>
            </Splide>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
