import { cn } from "@util/helpers";

export type ContainerProps = {
  rootClassName?: string;
  children?: React.ReactElement | React.ReactElement[];
};

export default function Container(props: ContainerProps): React.ReactElement {
  const { rootClassName, children, ...rest } = props;

  return (
    <div
      data-component="component-container"
      className={cn("container px-3", rootClassName)}
      {...rest}
    >
      {children}
    </div>
  );
}
