import { Link } from "react-router-dom";
import type { NavigationItem } from "@app/types/Navigation";
import { cn } from "@util/helpers";

interface CategoryLinkProps {
  childCategoryData: NavigationItem;
  isLinkActive?: boolean;
  navItemStyle?: string;
  parentCategoryData: NavigationItem;
  linkClassName?: string;
}

export default function IndividualCategoryLink({
  childCategoryData,
  isLinkActive,
  navItemStyle,
  parentCategoryData,
  linkClassName,
}: CategoryLinkProps): React.ReactElement {
  return (
    <Link
      key={childCategoryData.key}
      className={cn(
        "block",
        { "!font-bold": isLinkActive },
        linkClassName,
        navItemStyle
      )}
      to={`${childCategoryData.link}?ref=listing-menubar`}
      reloadDocument
    >
      {parentCategoryData.key === childCategoryData.key
        ? "Top Stories"
        : childCategoryData.label}
    </Link>
  );
}
