import { Link } from "react-router-dom";
import type { SanitizedSectionArticle, Tag, ValueOf } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import { PodcastKeywordData } from "@pages/Section/layouts/PodcastsPage/util/constants";
import cx from "classnames";

import PodcastsKeywordsStories from "./PodcastsKeywordsStories";

export type PodcastsKeywordsProps = {
  rootClassName?: string;
  blockTitle: string;
  description: string;
  spotify_link: string;
  apple_link: string;
  youtube_link: string;
  articles: SanitizedSectionArticle[];
  tag: Tag;
  thumbnailSrc: string;
  cardName: ValueOf<Pick<PodcastKeywordData, "id">>;
};

export default function PodcastsKeywords({
  rootClassName,
  blockTitle,
  description,
  spotify_link,
  apple_link,
  youtube_link,
  articles,
  tag,
  cardName,
  thumbnailSrc,
}: PodcastsKeywordsProps): React.ReactElement {
  const blockTitleLink = `${tag.urlPath}?card_name=${cardName}`;

  return (
    <>
      {articles.length > 0 ? (
        <section
          className={rootClassName}
          data-testid="podcasts-keyword-component"
        >
          <div className="mb-6 flex w-auto flex-col border-t border-gray-850 pt-2 md:flex-row">
            <img
              loading="lazy"
              src={thumbnailSrc}
              width={128}
              height={128}
              alt={cardName}
              className="mb-4 mr-4"
            />

            <div className="w-auto">
              <BlockTitle
                rootClassName="mb-3 text-black tracking-[1px]"
                link={blockTitleLink}
                text={blockTitle?.toUpperCase()}
              />

              <p
                className={cx(
                  "mb-4 max-w-full font-public-sans text-base !leading-normal text-gray-850 lg:w-[700px]",
                  "lg:text-lg"
                )}
              >
                {description}
              </p>
            </div>
          </div>

          <div className="mb-7">
            <span
              className={cx(
                "mb-2 mr-2 block font-public-sans text-base font-medium text-gray-850",
                "lg:inline lg:text-lg"
              )}
            >
              Subscribe:
            </span>

            <Link
              className={cx(
                "mr-2 border-r border-gray-650 pr-2 font-poppins text-base font-semibold text-black hover:underline",
                "lg:text-lg"
              )}
              to={spotify_link}
              rel="noreferrer"
              target="_blank"
            >
              Spotify
            </Link>

            <Link
              className={cx(
                "mr-2 border-r border-gray-650 pr-2 font-poppins text-base font-semibold text-black hover:underline",
                "lg:text-lg"
              )}
              to={apple_link}
              rel="noreferrer"
              target="_blank"
            >
              Apple Podcasts
            </Link>

            <Link
              className={cx(
                "mr-2 font-poppins text-base font-semibold text-black hover:underline",
                "lg:text-lg"
              )}
              to={youtube_link}
              rel="noreferrer"
              target="_blank"
            >
              YouTube
            </Link>
          </div>

          <div className="w-full">
            {articles.map((article, index) => {
              return (
                <PodcastsKeywordsStories
                  key={article.id}
                  article={article}
                  cardName={cardName}
                  index={index + 1}
                />
              );
            })}
          </div>

          <Link
            className="block w-fit py-3 font-poppins text-base font-semibold text-black hover:underline md:text-lg"
            to={blockTitleLink}
            rel="noreferrer"
            target="_blank"
          >
            View All Episodes
          </Link>
        </section>
      ) : null}
    </>
  );
}
