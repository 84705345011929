import { useEffect, useState } from "react";
import Container from "@components/Container/Container";
import { useWindowSize } from "@hooks/useWindowSize";
import { thriversCards } from "@pages/Thrive/AboutUs/components/constants";

import ThriveAboutUsIndividualCard from "./ThriveAboutUsIndividualCard";

export default function ThriveAboutUsTeamCards(): React.ReactElement {
  const [isFlipped, setIsFlipped] = useState<string>("");
  const { isScreenLG } = useWindowSize();

  useEffect(() => {
    setIsFlipped("");
  }, [isScreenLG]);

  return (
    <Container
      rootClassName="px-7 py-10 lg:py-20 font-inter text-gray-850"
      data-testid="thrive-about-us-team-card-component"
    >
      <p className="mb-16 text-center text-7xl lg:text-9xl">
        Our team<span className="text-green-400">.</span>
      </p>

      <div className="mx-auto grid gap-16 md:grid-cols-2 lg:grid-cols-3">
        {thriversCards.map((thrivers) => {
          return (
            <ThriveAboutUsIndividualCard
              isFlipped={thrivers.name === isFlipped}
              key={thrivers.name}
              thrivers={thrivers}
              handleOnClick={setIsFlipped}
            />
          );
        })}
      </div>
    </Container>
  );
}
