import { BlockTitle } from "@blocks/Blocks";
import { ArticleStockItem } from "@pages/Article/components/ArticleStockSidebar/ArticleStockItem";

export interface ArticleStockSidebarProps {
  codes: string[];
}

export default function ArticleStockSidebar({
  codes,
}: ArticleStockSidebarProps) {
  const filteredCodes = codes.filter((code) => code);

  return (
    <>
      {filteredCodes.length > 0 ? (
        <section
          className="mx-auto mb-6 max-w-[300px]"
          data-component="stocks-component"
          data-testid="article-stocks"
        >
          <BlockTitle text="STOCKS" rootClassName="mb-4" />

          {filteredCodes.map((code) => (
            <div className="mt-[2px]" key={code}>
              <ArticleStockItem code={code} />
            </div>
          ))}
        </section>
      ) : null}
    </>
  );
}
