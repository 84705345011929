import { MouseEventHandler } from "react";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TestIdClassNameProps } from "@src/app/types/Common";
import { gaEventTracker } from "@util/helpers";
import cx from "classnames";

export type MyBTCarouselArrowProps = TestIdClassNameProps & {
  direction: "left" | "right";
  onClick?: MouseEventHandler<SVGSVGElement> | undefined;
};

export default function MyBTCarouselArrow({
  testId,
  rootClassName,
  direction = "left",
  onClick,
}: MyBTCarouselArrowProps): React.ReactElement {
  const handleArrowClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as SVGSVGElement;
    target.dataset.icon === "chevron-right"
      ? gaEventTracker("mybt", "click", "right arrow")
      : gaEventTracker("mybt", "click", "left arrow");
  };

  return (
    <div
      className={cx(
        rootClassName,
        "absolute top-1/2 z-10 -translate-y-1/2 bg-white transition-opacity",
        {
          "right-6 xl:left-full xl:right-[unset]": direction === "right",
          "left-6 xl:left-[unset] xl:right-full": direction === "left",
          "opacity-70 hover:opacity-100": typeof onClick === "function",
          "pointer-events-none opacity-0": typeof onClick === "object",
        }
      )}
      data-testid={testId}
      onClick={handleArrowClick}
    >
      <FontAwesomeIcon
        icon={direction === "left" ? faChevronLeft : faChevronRight}
        size="lg"
        className="block h-4 w-4 cursor-pointer rounded-full border border-black fill-gray-650 p-2 hover:opacity-100"
        onClick={onClick}
      />
    </div>
  );
}
