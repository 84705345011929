import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import iconForwardArrowBlack from "@assets/icon-forward-arrow-black.svg";
import { useWindowSize } from "@hooks/useWindowSize";
import cx from "classnames";

import styles from "./Accordion.module.scss";

export type AccordionProps = {
  id?: string;
  label?: string;
  link?: string;
  children?: React.ReactNode;
  rootClassName?: string;
  rootChildrenClassName?: string;
  isExpanded?: boolean;
  isMatchUrl?: boolean;
  isChildMatchUrl?: boolean;
  renderHeader?: () => React.ReactElement;
  onToggle?: (
    e: React.SyntheticEvent,
    accordionId: string,
    newIsExpand: boolean
  ) => void;
  icon?: React.ReactElement;
};

export default function Accordion({
  label = "",
  link = "#",
  children = "",
  rootClassName,
  rootChildrenClassName,
  isExpanded = false,
  isMatchUrl = false,
  isChildMatchUrl = false,
  renderHeader,
  onToggle,
  icon,
  id = "",
}: AccordionProps): React.ReactElement {
  const [childrenHeight, setChildrenHeight] = useState(0);
  const childrenRef = useRef(null);
  const uid = `side-drawer-navigation-${id}`;
  const windowSize = useWindowSize();

  const handleToggleAccordion = (e: React.SyntheticEvent) => {
    if (onToggle && children) {
      onToggle(e, id, !isExpanded);
    }
  };

  useEffect(() => {
    if (childrenRef.current) {
      setChildrenHeight((childrenRef.current as HTMLDivElement).scrollHeight);
    }
  }, [windowSize]);

  return (
    <div
      className={cx(
        styles.componentAccordion,
        rootClassName,
        "border-gray-175",
        "hover:cursor-pointer"
      )}
      id={uid}
      data-testid={uid}
    >
      {renderHeader ? (
        renderHeader()
      ) : (
        <h3
          className={cx(
            "color-gray-850 flex flex-row items-center justify-between truncate px-5 py-4 font-poppins text-lg",
            {
              "font-semibold": isMatchUrl || isChildMatchUrl,
            }
          )}
          onClick={handleToggleAccordion}
        >
          <Link to={link} reloadDocument>
            {icon} {label}
          </Link>
          {children ? (
            <img
              src={iconForwardArrowBlack}
              width="12"
              height="12"
              className={cx(isExpanded && "rotate-90")}
            />
          ) : null}
        </h3>
      )}
      <div
        ref={childrenRef}
        className={cx(
          styles.content,
          "box-border overflow-hidden px-[24px]",
          rootChildrenClassName
        )}
        style={{ height: isExpanded && children ? childrenHeight : 0 }}
      >
        {children}
      </div>
    </div>
  );
}
