import { TestIdClassNameProps } from "@app/types/Common";
import { Tag } from "@app/types/Cue";
import { MyBTKeywordsDataList } from "@app/types/Page";
import { BlockTitle } from "@blocks/Blocks";
import SplideArrows from "@components/Slider/components/SplideArrows";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { gaEventTracker } from "@util/helpers";

import MyBTCarouselSlide from "./MyBTCarouselSlide";

type MyBTCarouselProps = TestIdClassNameProps & {
  results: MyBTKeywordsDataList;
  keywords: Tag[];
  title: string;
  page: string;
  showTooltip: boolean;
  disablePaywall?: boolean;
  seeAlsoLink: string;
};

export default function MyBTHomeCarousel({
  testId,
  rootClassName,
  results,
  keywords,
  disablePaywall = false,
  seeAlsoLink,
}: MyBTCarouselProps): React.ReactElement {
  const options: Options = {
    pagination: false,
    type: "slide",
    autoplay: false,
    interval: 10000,
    perMove: 1,
    gap: "1.5rem",
    focus: 0,
    omitEnd: true,
    mediaQuery: "min",
    arrows: false,
    fixedWidth: "65%",
    breakpoints: {
      768: { fixedWidth: "38%", arrows: true },
      992: { perPage: 4, fixedWidth: undefined },
    },
  };

  const handleSeeMore = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.target as HTMLAnchorElement;
    const label = target.href ? `see more - ${target.href}` : "see more";
    gaEventTracker("mybt", "click", label);
  };

  return (
    <div className={rootClassName} data-testid={testId}>
      <BlockTitle text="MYBT" link={seeAlsoLink} onClick={handleSeeMore} />

      <Splide options={options} tag="div" hasTrack={false}>
        <SplideArrows className="absolute -top-8 right-0 flex flex-row items-center justify-center gap-2" />

        <SplideTrack>
          {keywords.map((keyword) => {
            const keywordData = results[keyword.urlPath];

            return keywordData?.body ? (
              <SplideSlide key={keyword.urlPath}>
                <MyBTCarouselSlide
                  articles={keywordData.body}
                  tag={keyword}
                  rootClassName="my-3"
                  disablePaywall={disablePaywall}
                />
              </SplideSlide>
            ) : null;
          })}
        </SplideTrack>
      </Splide>
    </div>
  );
}
