import { Type } from "@app/types/enums";
import useCerosScript from "@hooks/useCerosScript";
import cx from "classnames";

export type DynamicIframeProps = {
  source: string;
  className?: string;
};

export default function DynamicIframe({
  className,
  source,
}: DynamicIframeProps) {
  useCerosScript();

  const sourceData = JSON.parse(source);
  const paddingBottom = sourceData ? sourceData.data[6]?.value : "";

  return (
    <>
      {sourceData ? (
        <div
          className={cx(
            className,
            "relative bottom-0 left-0 top-0",
            "m-0 h-0 border-0"
          )}
          style={{
            paddingBottom: paddingBottom,
          }}
          data-story-element={Type.DynamicIframeWrapper}
          data-aspectRatio={sourceData.data[3]?.value}
          data-tablet-aspectRatio={sourceData.data[4]?.value}
          data-mobile-aspectRatio={sourceData.data[5]?.value}
          data-testid="dynamic-iframe-wrapper-component"
        >
          <iframe
            className={cx(
              "ceros-experience absolute bottom-0 left-0 top-0 overflow-hidden",
              "m-0 min-h-full min-w-full border-0"
            )}
            title={sourceData.data[2]?.value}
            src={sourceData.data[0]?.value}
          ></iframe>
        </div>
      ) : null}
    </>
  );
}
