import { useEffect, useState } from "react";
import { QuerylyTrendingSearchResponse } from "@pages/Search/types";
import axios from "axios";
import dayjs from "dayjs";

export const useQuerylyTrendingSearch = () => {
  const [trendingSearch, setTrendingSearch] = useState<
    QuerylyTrendingSearchResponse[]
  >([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fromDate = dayjs().subtract(3, "day").format("YYYYMMDDHH");
        const response = await axios.get<QuerylyTrendingSearchResponse[]>(
          "/_plat/api/v1/trending-search",
          {
            params: { fromdate: fromDate },
            timeout: 100000,
          }
        );

        const searchData = response.data || [];
        setTrendingSearch(searchData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching trending list", error);
        return [];
      }
    };

    fetchData();
  }, []);

  return trendingSearch;
};
