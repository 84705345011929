import { useState } from "react";
import type { ButtonProps as AriaButtonProps } from "react-aria-components";
import Button from "@components/AccessibleComponents/Button";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copy from "copy-to-clipboard";

export type CopyLinkProps = AriaButtonProps & {
  link?: string;
};

export default function CopyLink(props: CopyLinkProps): React.ReactElement {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const { link } = props;

  const handleCopyLink = () => {
    if (!link) return;

    copy(link);
    setIsLinkCopied(true);
  };

  return (
    <>
      {isLinkCopied ? (
        <div className="flex items-center gap-2 py-3">
          <FontAwesomeIcon icon={faCircleCheck} size="xl" />
          <span className="font-poppins text-base font-semibold">
            LINK COPIED
          </span>
        </div>
      ) : (
        <Button
          {...props}
          onPress={handleCopyLink}
          className="w-full rounded-full bg-black px-9 py-3 font-poppins text-base font-semibold text-white outline-none"
        >
          COPY LINK
        </Button>
      )}
    </>
  );
}
