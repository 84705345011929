import { RouteFactory } from "@app/routePaths";
import GAData from "@components/GAData/GAData";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import ThriveFooter from "@pages/Thrive/components/ThriveFooter";
import ThriveHeader from "@pages/Thrive/components/ThriveHeader";

import ThriveBanner from "./components/ThriveBanner";
import ThrivePastEvents from "./components/ThrivePastEvents";

export default function ThriveEvents(): React.ReactElement {
  return (
    <div className="w-full bg-verticals-thrive" id="thrive-events-page">
      <MetaTags
        title={renderPageTitleV2({
          kind: "static",
          title: "Thrive",
          sectionName: "Events",
        })}
        description="Read more at The Business Times."
        slug={RouteFactory.thriveEvents}
        shortLink={RouteFactory.thriveEvents}
      />

      <GAData title="Thrive_Events" level2="miscellaneous" />

      <ThriveHeader />

      <ThriveBanner />

      <ThrivePastEvents />

      <div className="flex justify-center bg-gray-850 font-poppins">
        <ThriveFooter />
      </div>
    </div>
  );
}
