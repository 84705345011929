import { Link } from "react-router-dom";
import thrivePlane from "@assets/thrive_images/thrive-plane.svg";
import Container from "@components/Container/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { socialLinks } from "@pages/Thrive/constants";
import { cn } from "@util/helpers";

export default function ThriveGetSocial(): React.ReactElement {
  return (
    <Container
      rootClassName="p-20 px-10 pb-20 font-inter text-gray-850"
      data-testid="thrive-get-social-component"
    >
      <div
        className={cn(
          "mt-4 border border-black bg-white shadow-thriveButton transition-all duration-150 hover:shadow-thriveNewsletterHover",
          "relative w-full items-center justify-between p-4 lg:flex lg:p-20"
        )}
      >
        <p className="absolute -top-10 right-0 hidden font-inter text-7xl uppercase lg:left-full lg:top-0 lg:block lg:-rotate-180 lg:[writing-mode:tb-rl]">
          Get Social <span className="text-green-400">.</span>
        </p>

        <div className="max-w-2/5 -top-28 left-0 z-10 mx-auto -mt-36 lg:absolute lg:mt-auto lg:w-2/5">
          <img src={thrivePlane} className="w-full" alt="get social" />
        </div>

        <div className="ml-auto text-center lg:text-right">
          <p>Be a part of the Thrive community.</p>

          <div className="flex flex-col gap-4 lg:flex-row">
            {socialLinks.map((links) => {
              return (
                <Link
                  key={links.to}
                  to={links.to}
                  reloadDocument
                  className="group flex items-center justify-center gap-2 border-2 border-orange-400 px-6 py-4 text-center hover:bg-orange-400"
                >
                  <FontAwesomeIcon
                    icon={links.icon}
                    className="group-hover:text-white"
                  />
                  <span className="font-interSemibold uppercase group-hover:text-white">
                    {links.label}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
}
