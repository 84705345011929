import { ReactElement } from "react";
import type { TooltipProps as AriaTooltipProps } from "react-aria-components";
import {
  OverlayArrow as AriaOverlayArrow,
  Tooltip as AriaTooltip,
} from "react-aria-components";
import cx from "classnames";

import styles from "./Tooltip.module.scss";

/**
 *
 * @param props AriaTooltipProps
 * @see https://react-spectrum.adobe.com/react-aria/Tooltip.html
 * @returns ReactElement<AriaTooltipProps>
 */
export default function Tooltip(props: AriaTooltipProps) {
  const { className = "", children, ...rest } = props;

  return (
    <AriaTooltip
      className={cx(styles.componentTooltip, className as string)}
      offset={6}
      placement="bottom"
      {...rest}
    >
      <AriaOverlayArrow
        className={({ placement }) => {
          return cx({
            "!rotate-90": placement === "right",
            "!rotate-180": placement === "bottom",
            "!-rotate-90": placement === "left",
          });
        }}
      >
        <svg
          className="fill-[var(--digital-black)]"
          width="10"
          height="10"
          viewBox="0 0 10 10"
        >
          <polygon points="0,0 5,5 10,0" />
        </svg>
      </AriaOverlayArrow>
      {children as ReactElement}
    </AriaTooltip>
  );
}
