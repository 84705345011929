import { useState } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import axios from "axios";

export const useLoadMorePodcastStories = (
  initialList: SanitizedSectionArticle[],
  pageSize: number
) => {
  const [articleList, setMoreArticlesList] = useState(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length >= pageSize
  );

  const handleLoadMore = async () => {
    const lastArticle = articleList?.[articleList.length - 1];
    const sort = lastArticle?.sort;

    if (articleList.length === 0) return;
    if (typeof lastArticle === "undefined") return;
    if (typeof sort === "undefined") return;

    const newMoreArticles = await fetchStories(pageSize, sort);

    if (newMoreArticles.length < pageSize) setHasMoreArticles(false);

    setMoreArticlesList((prev) => [...prev, ...newMoreArticles]);
  };

  return { articleList, handleLoadMore, hasMoreArticles };
};

const fetchStories = async (size: number, sort: (string | number)[]) => {
  try {
    const response = await axios.post<SanitizedSectionArticle[]>(
      "/_plat/api/v1/more-podcast-stories",
      { sort, size }
    );

    return response?.data || [];
  } catch (error) {
    console.log("error on fetchStories"); // eslint-disable-line no-console
    return [];
  }
};
