import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { NewsletterArchiveKeywordsEnums } from "@app/types/enums";
import { NEWSLETTER_TYPE } from "@components/Newsletter/types/Newsletter";
import AseanBusinessLatestIssue from "@pages/Newsletter/Archive/AseanBusiness/SampleIssues/AseanBusinessLatestIssue";
import BigMoneySampleIssue from "@pages/Newsletter/Archive/BigMoney/SampleIssues/BigMoneySampleIssue";
import EsgInsightsLatestIssue from "@pages/Newsletter/Archive/EsgInsight/SampleIssues/EsgInsightsLatestIssue";
import GarageSampleIssue from "@pages/Newsletter/Archive/Garage/SampleIssues/GarageSampleIssue";
import PropertyInsightLatestIssue from "@pages/Newsletter/Archive/PropertyInsight/SampleIssues/PropertyInsightLatestIssue";
import ThriveSampleIssue from "@pages/Newsletter/Archive/Thrive/SampleIssues/ThriveSampleIssue";

export type NewsletterSampleIssueType = {
  newsletter?: NEWSLETTER_TYPE;
  divtRef?: React.RefObject<HTMLDivElement>;
  rootClassName?: string;
};

export const NewsletterSampleIssueFactory = ({
  newsletter,
  divtRef,
  rootClassName,
}: NewsletterSampleIssueType) => {
  switch (newsletter) {
    case NEWSLETTER_TYPE.THRIVE:
      return <ThriveSampleIssue divRef={divtRef} className={rootClassName} />;

    case NEWSLETTER_TYPE.BigMoney:
      return (
        <BigMoneySampleIssue divRef={divtRef} rootClassName={rootClassName} />
      );

    case NEWSLETTER_TYPE.GARAGE:
      return (
        <GarageSampleIssue divRef={divtRef} rootClassName={rootClassName} />
      );

    case NEWSLETTER_TYPE.PROPERTY:
      return (
        <PropertyInsightLatestIssue
          divRef={divtRef}
          rootClassName={rootClassName}
        />
      );

    case NEWSLETTER_TYPE.ESG:
      return (
        <EsgInsightsLatestIssue divRef={divtRef} ClassName={rootClassName} />
      );

    case NEWSLETTER_TYPE.ASEAN:
      return (
        <AseanBusinessLatestIssue divRef={divtRef} ClassName={rootClassName} />
      );
    default:
      return null;
  }
};

export const renderLink = (text: string, href: string) => (
  <a href={href} className="text-green underline">
    {text}
  </a>
);

/**
 * Helper function to render newsletter orange text link.
 * @param text anchor text.
 * @param href anchor href link.
 * @returns {string[]}
 */
export const renderOrangeLink = (text: string, href: string) => (
  <a href={href} className="font-bold text-orange-500 underline">
    {text}
  </a>
);

/**
 * Helper function to render newsletter asean text link.
 * @param text anchor text.
 * @param href anchor href link.
 * @returns {string[]}
 */
export const renderBlueLink = (text: string, href: string) => (
  <Link
    to={href}
    className="font-bold text-verticals-asean underline"
    reloadDocument
  >
    {text}
  </Link>
);

/**
 * Helper function to render newsletter asean text link.
 * @param text anchor text.
 * @param href anchor href link.
 * @returns {string[]}
 */
export const renderGreenLink = (text: string, href: string) => (
  <Link
    to={href}
    className="font-bold text-verticals-esg underline"
    reloadDocument
  >
    {text}
  </Link>
);

/**
 * Extracts a keyword from the URL path based on predefined routes.
 *
 * @param {string} urlPath - URL component containing the path.
 * @returns {string} The extracted keyword.
 */
export const getKeywordFromUrlPath = (urlPath: string) => {
  switch (urlPath) {
    case RouteFactory.newsletterThriveArchivePage:
      return NewsletterArchiveKeywordsEnums.Thrive;

    case RouteFactory.newsletterGarageArchivePage:
      return NewsletterArchiveKeywordsEnums.Garage;

    case RouteFactory.newsletterBigMoneyArchivePage:
      return NewsletterArchiveKeywordsEnums.BigMoney;

    case RouteFactory.newsletterPropertyInsightArchivePage:
      return NewsletterArchiveKeywordsEnums.PropertyInsight;

    case RouteFactory.newsletterAseanBusinessArchivePage:
      return NewsletterArchiveKeywordsEnums.AseanBusiness;

    case RouteFactory.newsletterEsgInsightsArchivePage:
      return NewsletterArchiveKeywordsEnums.EsgInsight;

    default:
      return "";
  }
};

/**
 * Helper function to render newsletter lime green text link.
 * @param text anchor text.
 * @param href anchor href link.
 * @returns {string[]}
 */
export const renderLimeGreenLink = (text: string, href: string) => (
  <a href={href} className="font-normal text-green-500 underline">
    {text}
  </a>
);
