import { ReactElement, useState } from "react";
import { Block } from "@app/blocks/Blocks";
import Button from "@src/app/components/AccessibleComponents/Button";
import cx from "classnames";

export type BannerContent = string;
export type BannerRootClass = string;

export interface BannerProps {
  rootClassName?: string;
  dismissable?: boolean;
  children?: React.ReactElement;
}

export default function Banner({
  rootClassName,
  dismissable = false,
  children,
}: BannerProps): ReactElement | null {
  const [visible, setVisible] = useState(true);

  const removeComponent = () => {
    setVisible((prev) => !prev);
  };

  return visible ? (
    <Block rootClassName={rootClassName}>
      <div className="container relative px-3">
        {children}

        {dismissable ? (
          <Button
            aria-label="Closes"
            className={cx(
              "absolute end-0 top-0 z-[2] box-content h-[1em] w-[1em] rounded-md border-0 bg-transparent bg-close bg-1em bg-center bg-no-repeat px-2 py-2.5 text-black opacity-50",
              "hover:no-underline hover:opacity-75"
            )}
            type="button"
            onPress={removeComponent}
          />
        ) : null}
      </div>
    </Block>
  ) : null;
}
