import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import useOKTAUserStore from "@store/useOKTAUserStore";

import { getPermutiveVisitorCat, getVisitorCat } from "./helper";

type GADataProps = {
  articleid?: string;
  cue_articleid?: string;
  at?: string;
  author?: string;
  chapter1?: string;
  contentcat?: 1 | 2;
  contenttype?: "" | "1" | "2" | "3" | undefined;
  keyword?: string;
  level2?: string;
  pagination?: number;
  // permutive_id?: string;
  pubdate?: string;
  story_threads?: string;
  title?: string;
  visitorcat?: 1 | 2 | undefined;
  termid?: string;
  product_flag?: string;
  user_keyword?: string;
  adblocker?: 0 | 1;
  cd_version?: "" | "bt2" | undefined;
  gsChannels?: string;
};

/**
 * GAData
 * Description: This component renders the _data layer_ for GA to consume.
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GAData(props: GADataProps): ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const {
    articleid = "",
    at = OKTAUserInfo?.mysphw || "", // mysphw cookie
    author = "", // Comma separated author name
    chapter1 = "", // Section child category name.
    contentcat = 1, // Paid (2) or free (1)
    contenttype = "", // Index/listing = "", article = 1 photo = 2 video = 3
    keyword = "", // Comma separated keyword names
    // if in article, level2 = main category parent or main category, main category is the first category
    // if breakingnews, level2 = breaking news
    level2 = "",
    pagination = 1,
    // permutive_id = "", // window.localStorage.getItem("permutive-id") || "",
    pubdate = "", // Post date of article otherwise ""
    story_threads = "", // Comma separated story thread name
    title = "", // ie I_am_the_article_title, Section_name_Index, Breaking_News_Index
    visitorcat = getVisitorCat(
      OKTAUserInfo?.usertype,
      OKTAUserInfo?.reguserstatus,
      OKTAUserInfo?.service_type
    ),
    termid = "", // Section term id
    product_flag = "(not set)",
    user_keyword = "(not set)",
    adblocker = 0,
    cd_version = "bt2", // to determine if from pv is from bt1 or bt2, no value is bt1
    gsChannels, // grapeshot keywords
  } = props;

  return (
    <Helmet>
      <script id={`ga_data_layer_${articleid}`}>
        {`
var _data = {};
_data = {
  "articleid"      : "${articleid}",
  "cue_articleid"  : "${articleid}",
  "at"             : "${at}",
  "author"         : "${author}",
  "chapter1"       : "${chapter1}",
  "contentcat"     : "${contentcat}",
  "contenttype"    : "${contenttype}",
  "keyword"        : "${keyword}",
  "level2"         : "${level2}",
  "pagination"     : "${pagination}",
  "permutive_id"   : window.localStorage.getItem("permutive-id") || "",
  "pubdate"        : "${pubdate}",
  "story_threads"  : "${story_threads}",
  "title"          : "${title}",
  "visitorcat"     : "${visitorcat}",
  "termid"         : "${termid}",
  "product_flag"   : "${product_flag}",
  "user_keyword"   : "${user_keyword}",
  "adblocker"      : ${adblocker},
  "cd_version"     : "${cd_version}",
  "gs_channels"    : "${gsChannels}",
};

var isArticlePage = false;
if (typeof _data != "undefined" && typeof _data.articleid != "undefined" && _data.articleid != "") {
  isArticlePage = true;
}
          `}
      </script>

      <script id="permutive_script">
        {`
var permutive_keys = {
  articleid: "${articleid}",
  chapter1: "${chapter1}",
  contentcat: "${contentcat}",
  contenttype: "${contenttype}",
  gsKeywords: ${JSON.stringify(gsChannels?.toLowerCase().split(",") || [])},
  keywords: ${JSON.stringify(keyword.toLowerCase().split(",") || [])},
  sph_seg: [],
  story_threads: "${story_threads}",
  title: "${title}",
  visitorcat: "${getPermutiveVisitorCat(visitorcat)}",
};

per_userid = "";
var per_ptid = "";


if (typeof _data != 'undefined') {
  if (typeof _data.at != 'undefined' && _data.at != "") { per_userid = _data.at; }
  if (
    typeof per_getcookie("mysph_lr") != "undefined" &&
    per_getcookie("mysph_lr") != ""
  ) {
    per_ptid = per_getcookie("mysph_lr");
  }
  if (typeof _data.pagination != 'undefined' && _data.pagination != "") { permutive_keys.pagination = parseInt(_data.pagination); }
  if (typeof _data.printcat != 'undefined' && _data.printcat != "") { permutive_keys.printcat = _data.printcat; }
}

function per_getcookie(cname) {
  if (document.cookie.length > 0) {
    c_start = document.cookie.indexOf(cname + "=");
    if (c_start != -1) {
      c_start = c_start + cname.length + 1;
      c_end   = document.cookie.indexOf(";", c_start);
      if (c_end == -1) { c_end = document.cookie.length }
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ""
}

var per_suid = per_getcookie('suid');
var sph_seg  = per_getcookie('sph_seg');
if (typeof sph_seg != 'undefined' && sph_seg != "") { permutive_keys.sph_seg = sph_seg.toLowerCase().split(','); }

!function (e, o, n, i) { if (!e) { e = e || {}, window.permutive = e, e.q = []; var t = function () { return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (e) { return (e ^ (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(1))[0] & 15 >> e / 4).toString(16) }) }; e.config = i || {}, e.config.apiKey = o, e.config.workspaceId = n, e.config.environment = e.config.environment || "production", (window.crypto || window.msCrypto) && (e.config.viewId = t()); for (var g = ["addon", "identify", "track", "trigger", "query", "segment", "segments", "ready", "on", "once", "user", "consent"], r = 0; r < g.length; r++) { var w = g[r]; e[w] = function (o) { return function () { var n = Array.prototype.slice.call(arguments, 0); e.q.push({ functionName: o, arguments: n }) } }(w) } } }(window.permutive, "ab403253-b305-47fa-a31b-3efb2473166f", "5f876161-9740-4cc8-9b64-4585990b2690", {});
window.googletag = window.googletag || {}, window.googletag.cmd = window.googletag.cmd || [], window.googletag.cmd.push(function () { if (0 === window.googletag.pubads().getTargeting("permutive").length) { var e = window.localStorage.getItem("_pdfps"); window.googletag.pubads().setTargeting("permutive", e ? JSON.parse(e) : []); var o = window.localStorage.getItem("permutive-id"); window.googletag.pubads().setTargeting("puid", o ? o : ""); window.googletag.pubads().setTargeting("ptime", Date.now().toString()); window.permutive.config.viewId && window.googletag.pubads().setTargeting("prmtvvid", window.permutive.config.viewId); window.permutive.config.workspaceId && window.googletag.pubads().setTargeting("prmtvwid", window.permutive.config.workspaceId); } });

permutive.identify([
  {
    id: per_userid,
    tag: "userid",
    priority: 0,
  },
  {
    id: per_ptid,
    tag: "ptid",
    priority: 1,
  },
  {
    id: per_suid,
    tag: "suid",
    priority: 2,
  },
]);

permutive.addon('web', {
  page: permutive_keys
});

// Permutive Ready check with Timeout function definition
window.permutive.readyWithTimeout = function (e, i, t) { var u = !1, n = function () { u || (e(), u = !0) }; (t = t || 1 / 0) !== 1 / 0 && window.setTimeout(n, t), permutive.ready(n, i) };
        `}
      </script>
    </Helmet>
  );
}
