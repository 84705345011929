import { useEffect } from "react";

export type Props = {
  id: string;
  rel: string;
  href: string;
};

export default function useLink({ id, rel, href }: Props) {
  useEffect(() => {
    if (document.getElementById(id)) {
      return;
    }
    const link = document.createElement("link");

    link.id = id;
    link.rel = rel;
    link.href = href;

    document.head.appendChild(link);
  }, [id, rel, href]);
}
