import { MAX_GIFTS_PER_MONTH } from "@pages/Article/components/ArticleSubshare/constants";

export type GiftThisArticleProps = {
  giftCount?: number;
};

export default function GiftThisArticle({
  giftCount = 0,
}: GiftThisArticleProps): React.ReactElement {
  const giftLeft = MAX_GIFTS_PER_MONTH - giftCount;
  const articleText = giftLeft > 1 ? "articles" : "article";

  return (
    <>
      <p className="font-poppins text-base">
        Share this link and remind your recipient to redeem the gifted story
        within 14 days.
      </p>

      <p className="font-poppins text-base">
        {`You currently have ${giftLeft} ${articleText} left to gift for this month.`}
      </p>
    </>
  );
}
