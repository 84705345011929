"use client";
import { ComponentType, Fragment, ReactElement, ReactNode } from "react";
import type { FallbackProps } from "react-error-boundary";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";
import { isLiveEnvironment } from "@util/constant";

export type ErrorBoundaryProps = {
  fallback: Sentry.ErrorBoundaryProps["fallback"] &
    ComponentType<FallbackProps>;
  children?: ReactNode & Sentry.ErrorBoundaryProps["children"];
};

/**
 * Company: SPHMedia
 * @description: ErrorBoundary component, which is used to catch errors in the children tree.
 * when an error is caught, it will also log the error to Sentry, if environment is not local. (local is defined as !import.meta.env.PROD)
 * Otherwise, it will use react-error-boundary to catch errors and display a fallback UI instead of the component tree that crashed.
 * @see https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
 * @see https://www.npmjs.com/package/react-error-boundary
 * @returns {ReactElement<ErrorBoundaryProps>}
 */

export default function ErrorBoundary({
  children,
  fallback,
}: ErrorBoundaryProps): ReactElement<ErrorBoundaryProps> {
  return (
    <Fragment>
      {isLiveEnvironment ? (
        <Sentry.ErrorBoundary fallback={fallback}>
          {children}
        </Sentry.ErrorBoundary>
      ) : (
        <ReactErrorBoundary FallbackComponent={fallback}>
          {children}
        </ReactErrorBoundary>
      )}
    </Fragment>
  );
}
