import { Link } from "react-router-dom";
import thriveEventBannerImage from "@assets/thrive-events-banner.png";
import Container from "@components/Container/Container";

import { ThriveSlider } from "./ThriveSlider";

export default function ThriveBanner() {
  return (
    <div className="w-full overflow-hidden bg-no-repeat pt-5 md:bg-thriveBG md:pt-24">
      <Container rootClassName="relative px-5 font-inter md:px-8 !mb-36 lg:!mb-52">
        <div className="flex w-full flex-col items-start border-2 border-gray-850 bg-white px-5 pb-20 pt-8 shadow-thrive md:w-[95%] lg:px-8">
          <div>
            <div className="flex flex-col items-start space-y-3 md:w-8/10 lg:w-1/2">
              <h1 className="thrive-past-event-title stroke-gray-850 stroke-1 font-interBold text-11xl font-bold leading-[2.5rem] text-white text-shadow-thrive-2 md:text-13xl md:!leading-[4.5rem] lg:text-shadow-thrive-4">
                Events to help you thrive
              </h1>
              <p className="text-lg font-normal leading-8 text-gray-850">
                Want to learn how to navigate money, career and life as a young
                adult? We provide opportunities for Gen Zs to network and learn
                from industry professionals and the Thrive community.
              </p>
              <Link
                to="#past-events"
                className="flex w-full justify-center border border-gray-850 bg-orange-400 px-12 py-4 font-inter text-base font-semibold text-white shadow-thriveButton shadow-gray-850 sm:w-13/20"
              >
                ALL EVENTS
              </Link>
            </div>
            <img
              width="446"
              height="377"
              className="absolute -top-[9%] right-[4%] hidden border-2 border-gray-850 shadow-thrive shadow-gray-850 lg:block"
              src={thriveEventBannerImage}
            />
          </div>
        </div>
        <div className="absolute -right-5 top-9/10 block md:-left-[2%] lg:left-3/10">
          <ThriveSlider />
        </div>
      </Container>
    </div>
  );
}
