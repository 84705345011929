import { Section, Tag } from "@app/types/Cue";
import aseanThumbnail from "@assets/newsletter_thumbnails/newsletter_asean.png";
import bigMoneyThumbnail from "@assets/newsletter_thumbnails/newsletter_big_money.png";
import morningThumbnail from "@assets/newsletter_thumbnails/newsletter_breakfast_brief.png";
import eveningThumbnail from "@assets/newsletter_thumbnails/newsletter_daily_debrief.png";
import esgThumbnail from "@assets/newsletter_thumbnails/newsletter_esg.png";
import lifestyleThumbnail from "@assets/newsletter_thumbnails/newsletter_lifestyle.png";
import propertyThumbnail from "@assets/newsletter_thumbnails/newsletter_property.png";
import sgsmeThumbnail from "@assets/newsletter_thumbnails/newsletter_sgsme.png";
import thriveThumbnail from "@assets/newsletter_thumbnails/newsletter_thrive.png";

import { EmarsysResponse } from "./Emarsys";

export enum NEWSLETTER_TYPE {
  MORNING = "breakfast-brief",
  EVENING = "daily-debrief",
  PROPERTY = "property-insights",
  ESG = "esg-insights",
  THRIVE = "thrive",
  GARAGE = "garage",
  SGSME = "sgsme",
  ASEAN = "asean-business",
  LIFESTYLE = "lifestyle",
  BigMoney = "big-money",
}

export const VERTICAL_NEWSLETTER_TYPE: Record<string, NEWSLETTER_TYPE> = {
  asean: NEWSLETTER_TYPE.ASEAN,
  sme: NEWSLETTER_TYPE.SGSME,
  ge: NEWSLETTER_TYPE.ASEAN,
  garage: NEWSLETTER_TYPE.GARAGE,
  esg: NEWSLETTER_TYPE.ESG,
};

export type Newsletter = {
  type: NEWSLETTER_TYPE;
  emarsysFieldId: string;
  active?: boolean;
  label: string;
  description?: string;
  updateTime: string;
  thumbnail: string;
  link?: string;
  tags?: Partial<Tag>[];
  sections?: Section[];
  premiumOnly?: boolean;
};

export type NewsletterSectionProps = {
  title: string;
  newsletters: Newsletter[];
  selectedNewsletters: Newsletter[];
  handleSelectedNewsletter: (
    action: "remove" | "add" | "reset",
    newsletter?: Newsletter
  ) => void;
  sectionRef: React.RefObject<HTMLDivElement>;
  rootClassname?: string;
};

export const BT_NEWSLETTERS: Newsletter[] = [
  {
    type: NEWSLETTER_TYPE.MORNING,
    emarsysFieldId: "11594",
    active: true,
    label: "Breakfast Brief",
    updateTime: "Mon-Sat, 7.20 am",
    description:
      "All the latest news you need to know to start your day, right in your inbox.",
    thumbnail: morningThumbnail,
  },
  {
    type: NEWSLETTER_TYPE.EVENING,
    emarsysFieldId: "11596",
    active: true,
    label: "Daily Debrief",
    updateTime: "Mon-Sat, 7.20 pm",
    description: "A round-up of all the important things that happened today.",
    thumbnail: eveningThumbnail,
  },
  {
    type: NEWSLETTER_TYPE.ASEAN,
    emarsysFieldId: "53484",
    active: true,
    updateTime: "Friday, 8.30 am",
    label: "Asean Business",
    description:
      "Business insights centering on South-east Asia's fast-growing economies.",
    link: "/newsletter/asean",
    thumbnail: aseanThumbnail,
    tags: [
      {
        name: "Singapore",
        urlPath: "/keywords/singapore",
      },
      {
        name: "Malaysia",
        urlPath: "/keywords/malaysia",
      },
      {
        name: "Cambodia",
        urlPath: "/keywords/cambodia",
      },
      {
        name: "Vietnam",
        urlPath: "/keywords/vietnam",
      },
      {
        name: "Laos",
        urlPath: "/keywords/laos",
      },
      {
        name: "Myanmar",
        urlPath: "/keywords/myanmar",
      },
      {
        name: "Brunei",
        urlPath: "/keywords/brunei",
      },
      {
        name: "Indonesia",
        urlPath: "/keywords/indonesia",
      },
      {
        name: "Philippines",
        urlPath: "/keywords/philippines",
      },
      {
        name: "Thailand",
        urlPath: "/keywords/thailand",
      },
      {
        name: "Asean",
        urlPath: "/keywords/asean",
      },
      {
        name: "Asean Business",
        urlPath: "/keywords/asean-business",
      },
      {
        name: "Asean Summit",
        urlPath: "/keywords/asean-summit",
      },
      {
        name: "South-East Asia",
        urlPath: "/keywords/south-east-asia",
      },
    ],
    sections: [
      {
        name: "Asean",
        uniqueName: "international_asean",
        directoryName: "asean",
        parent: {
          name: "International",
          uniqueName: "international",
          directoryName: "international",
        },
        href: "https://businesstimes.cue-staging.sphnet.com.sg/international/asean/",
        parameters: [],
      },
    ],
  },
  {
    type: NEWSLETTER_TYPE.PROPERTY,
    emarsysFieldId: "61043",
    active: true,
    label: "Property Insights",
    updateTime: "Tuesday, 12 pm",
    description:
      "Get an exclusive analysis of real estate and property news in Singapore and beyond.",
    link: "/newsletter/property",
    thumbnail: propertyThumbnail,
    tags: [
      {
        name: "frasers property",
        urlPath: "/keywords/frasers-property",
      },
      {
        name: "Hdb",
        urlPath: "/keywords/hdb",
      },
      {
        name: "Property investment",
        urlPath: "/keywords/property-investment",
      },
      {
        name: "CapitaLand",
        urlPath: "/keywords/capitaland",
      },
      {
        name: "Guocoland",
        urlPath: "/keywords/guocoland",
      },
      {
        name: "bukit sembawang estates",
        urlPath: "/keywords/bukit-sembawang-estates",
      },
      {
        name: "Far East Organization",
        urlPath: "/keywords/far-east-organization",
      },
      {
        name: "Property",
        urlPath: "/keywords/property",
      },
      {
        name: "Singapore Property",
        urlPath: "/keywords/singapore-property",
      },
      {
        name: "Asia Property",
        urlPath: "/keywords/asia-property",
      },
      {
        name: "Asian Property",
        urlPath: "/keywords/asian-property",
      },
      {
        name: "Asian Real Estate",
        urlPath: "/keywords/asian-real-estate",
      },
      {
        name: "Asia Property Market",
        urlPath: "/keywords/asia-property-market",
      },
      {
        name: "Asia-Pacific Property",
        urlPath: "/keywords/asia-pacific-property",
      },
      {
        name: "Asia-Pacific Real Estate",
        urlPath: "/keywords/asia-pacific-real-estate",
      },
      {
        name: "Commercial Property",
        urlPath: "/keywords/commercial-property",
      },
      {
        name: "European Property",
        urlPath: "/keywords/european-property",
      },
      {
        name: "Freehold Property",
        urlPath: "/keywords/freehold-property",
      },
      {
        name: "Global Property",
        urlPath: "/keywords/global-property",
      },
      {
        name: "BTO",
        urlPath: "/keywords/bto",
      },
      {
        name: "Rental Market",
        urlPath: "/keywords/rental-market",
      },
      {
        name: "HDB Rents",
        urlPath: "/keywords/hdb-rents",
      },
      {
        name: "High-End Property",
        urlPath: "/keywords/high-end-property",
      },
      {
        name: "Hong Kong Property",
        urlPath: "/keywords/hong-kong-property",
      },
      {
        name: "Hong Kong Commercial Property",
        urlPath: "/keywords/hong-kong-commercial-property",
      },
      {
        name: "Landed Property",
        urlPath: "/keywords/landed-property",
      },
      {
        name: "Office Property",
        urlPath: "/keywords/office-property",
      },
      {
        name: "Property Regulations",
        urlPath: "/keywords/property-regulations",
      },
      {
        name: "PropNex",
        urlPath: "/keywords/propnex",
      },
      {
        name: "City Developments",
        urlPath: "/keywords/city-developments",
      },
      {
        name: "Allgreen Properties",
        urlPath: "/keywords/allgreen-properties",
      },
      {
        name: "Singapore REITs",
        urlPath: "/keywords/singapore-reits",
      },
      {
        name: "Asia Reits",
        urlPath: "/keywords/asia-reits",
      },
    ],
    sections: [
      {
        name: "Property",
        uniqueName: "property",
        directoryName: "property",
        href: "https://businesstimes.cue-staging.sphnet.com.sg/property/",
        parent: {
          name: "Home",
          uniqueName: "ece_frontpage",
          directoryName: "frontpage",
        },
        parameters: [],
      },
    ],
  },
  {
    type: NEWSLETTER_TYPE.ESG,
    emarsysFieldId: "59770",
    active: true,
    label: "ESG Insights",
    updateTime: "Friday, 12.30 pm",
    description:
      "An exclusive weekly report on the latest environmental, social and governance issues.",
    link: "/newsletter/esg",
    thumbnail: esgThumbnail,
    tags: [
      {
        name: "esg",
        urlPath: "/keywords/esg",
      },
      {
        name: "Sustainability",
        urlPath: "/keywords/sustainability",
      },
      {
        name: "Green finance",
        urlPath: "/keywords/green-finance",
      },
      {
        name: "ESG trends",
        urlPath: "/keywords/esg-trends",
      },
      {
        name: "Environmental Policy",
        urlPath: "/keywords/environmental-policy",
      },
      {
        name: "Environmental Regulations",
        urlPath: "/keywords/environmental-regulations",
      },
      {
        name: "Carbon Trade",
        urlPath: "/keywords/carbon-trade",
      },
      {
        name: "Carbon Trading",
        urlPath: "/keywords/carbon-trading",
      },
      {
        name: "Green Bonds",
        urlPath: "/keywords/green-bonds",
      },
      {
        name: "Green Energy",
        urlPath: "/keywords/green-energy",
      },
      {
        name: "Green Fuel",
        urlPath: "/keywords/green-fuel",
      },
      {
        name: "Green Loan",
        urlPath: "/keywords/green-loan",
      },
      {
        name: "Green Loans",
        urlPath: "/keywords/green-loans",
      },
      {
        name: "Net Zero",
        urlPath: "/keywords/net-zero",
      },
      {
        name: "Singapore Environment",
        urlPath: "/keywords/singapore-environment",
      },
    ],
    sections: [
      {
        name: "ESG",
        uniqueName: "esg",
        directoryName: "esg",
        href: "https://businesstimes.cue-staging.sphnet.com.sg/esg/",
        parent: {
          name: "Home",
          uniqueName: "ece_frontpage",
          directoryName: "frontpage",
        },
        parameters: [],
      },
    ],
  },
  {
    type: NEWSLETTER_TYPE.THRIVE,
    emarsysFieldId: "60845",
    active: true,
    updateTime: "Friday, 3 pm",
    label: "Thrive",
    description:
      "Money, career and life hacks to help young adults stay ahead of the curve.",
    link: "/newsletter/thrive",
    thumbnail: thriveThumbnail,
    tags: [
      {
        name: "Gen Z",
        urlPath: "/keywords/gen-z",
      },
      {
        name: "Millennials",
        urlPath: "/keywords/millennials",
      },
      {
        name: "Career",
        urlPath: "/keywords/career",
      },
      {
        name: "Mental health",
        urlPath: "/keywords/mental-health",
      },
      {
        name: "Millennial",
        urlPath: "/keywords/millennial",
      },
      {
        name: "Financial Literacy",
        urlPath: "/keywords/financial-literacy",
      },
      {
        name: "Financial Education",
        urlPath: "/keywords/financial-education",
      },
      {
        name: "Investor Education",
        urlPath: "/keywords/investor-education",
      },
      {
        name: "Careers",
        urlPath: "/keywords/careers",
      },
      {
        name: "Career Advice",
        urlPath: "/keywords/career-advice",
      },
      {
        name: "Career Development",
        urlPath: "/keywords/career-development",
      },
      {
        name: "Work Culture",
        urlPath: "/keywords/work-culture",
      },
      {
        name: "Work-Life Balance",
        urlPath: "/keywords/work-life-balance",
      },
      {
        name: "Youth",
        urlPath: "/keywords/youth",
      },
      {
        name: "Student",
        urlPath: "/keywords/student",
      },
      {
        name: "Students",
        urlPath: "/keywords/students",
      },
      {
        name: "NTU",
        urlPath: "/keywords/ntu",
      },
      {
        name: "SMU",
        urlPath: "/keywords/smu",
      },
      {
        name: "Money",
        urlPath: "/keywords/money",
      },
      {
        name: "Tertiary Students",
        urlPath: "/keywords/tertiary-students",
      },
      {
        name: "Jobs",
        urlPath: "/keywords/jobs",
      },
      {
        name: "Young Adults",
        urlPath: "/keywords/young-adults",
      },
      {
        name: "Hustle Culture",
        urlPath: "/keywords/hustle-culture",
      },
    ],
  },
  {
    type: NEWSLETTER_TYPE.SGSME,
    emarsysFieldId: "56548",
    active: true,
    updateTime: "Friday, 8.30 am",
    label: "SGSME",
    description:
      "Get updates on Singapore's SME community, along with profiles, news and tips.",
    thumbnail: sgsmeThumbnail,
    tags: [
      {
        name: "SMEs",
        urlPath: "/keywords/smes",
      },
      {
        name: "Singapore SMEs",
        urlPath: "/keywords/singapore-smes",
      },
      {
        name: "Small and Medium Enterprises",
        urlPath: "/keywords/small-and-medium-enterprises",
      },
      {
        name: "SME",
        urlPath: "/keywords/sme",
      },
      {
        name: "Singapore SME",
        urlPath: "/keywords/singapore-sme",
      },
      {
        name: "Small Business",
        urlPath: "/keywords/small-business",
      },
      {
        name: "Small Businesses",
        urlPath: "/keywords/small-businesses",
      },
    ],
    sections: [
      {
        name: "SMEs",
        uniqueName: "singapore_smes",
        directoryName: "smes",
        href: "https://businesstimes.cue-staging.sphnet.com.sg/singapore/smes/",
        parent: {
          name: "Singapore",
          uniqueName: "singapore",
          directoryName: "singapore",
        },
        parameters: [],
      },
    ],
  },
  {
    type: NEWSLETTER_TYPE.GARAGE,
    active: false,
    emarsysFieldId: "",
    label: "Garage",
    updateTime: "MONDAY, 3.30 PM",
    description:
      "The hottest news on all things startup and tech to kickstart your week. [This newsletter is no longer in production.]",
    thumbnail: "",
  },
  {
    type: NEWSLETTER_TYPE.LIFESTYLE,
    emarsysFieldId: "52275",
    active: true,
    updateTime: "Friday, 2 pm",
    label: "Lifestyle",
    description:
      "Our picks of the latest dining, travel and leisure options to treat yourself.",
    thumbnail: lifestyleThumbnail,
    sections: [
      {
        name: "Lifestyle",
        uniqueName: "lifestyle",
        directoryName: "lifestyle",
        href: "https://businesstimes.cue-staging.sphnet.com.sg/lifestyle/",
        parent: {
          name: "Home",
          uniqueName: "ece_frontpage",
          directoryName: "frontpage",
        },
        parameters: [],
      },
    ],
    tags: [
      {
        name: "Style",
        urlPath: "/keywords/style",
      },
      {
        name: "Travel",
        urlPath: "/keywords/travel",
      },
      {
        name: "Dining",
        urlPath: "/keywords/dining",
      },
      {
        name: "Beauty and Wellness",
        urlPath: "/keywords/beauty-and-wellness",
      },
      {
        name: "Health and Wellness",
        urlPath: "/keywords/health-and-wellness",
      },
      {
        name: "Luxury",
        urlPath: "/keywords/luxury",
      },
      {
        name: "Luxury Goods",
        urlPath: "/keywords/luxury-goods",
      },
      {
        name: "Beauty",
        urlPath: "/keywords/beauty",
      },
      {
        name: "Fashion",
        urlPath: "/keywords/fashion",
      },
      {
        name: "Music",
        urlPath: "/keywords/music",
      },
      {
        name: "Lifestyle",
        urlPath: "/keywords/lifestyle",
      },
    ],
  },
  {
    type: NEWSLETTER_TYPE.BigMoney,
    emarsysFieldId: "63311",
    active: true,
    label: "Big Money",
    link: "/newsletter/big-money",
    updateTime: "Monday, 7 AM",
    description:
      "A weekly breakdown of the latest developments affecting financial markets.",
    thumbnail: bigMoneyThumbnail,
    premiumOnly: true,
    tags: [
      {
        urlPath: "/keywords/big-money-newsletter",
        name: "Big Money Newsletter",
      },
    ],
  },
];

export const NEWSLETTER_REQUESTS_RESPONSES: {
  [k in
    | "SUCCESS"
    | "EMAILEXIST"
    | "ERROR"
    | "EMAILISREQUIRED"
    | "INVALIDEMAIL"]: EmarsysResponse;
} = {
  SUCCESS: {
    emarsysCode: 1,
    status: "success",
    message: "Thank you for signing up.",
  },
  EMAILEXIST: {
    emarsysCode: 2,
    status: "error",
    message: "E-mail address already exists.",
  },
  ERROR: {
    emarsysCode: 3,
    status: "error",
    message: "Something went wrong!",
  },
  EMAILISREQUIRED: {
    emarsysCode: 4,
    status: "error",
    message: "E-mail field is mandatory!",
  },
  INVALIDEMAIL: {
    emarsysCode: 5,
    status: "error",
    message: "Invalid e-mail address.",
  },
};
