import { useRef } from "react";
import TextInput from "@components/AccessibleComponents/TextInput";

type SearchInputProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  placeholder?: string;
};

export default function OnboardSearchInput({
  value,
  onChange,
  onKeyDown,
  onClick,
  placeholder = "Find more keywords",
}: SearchInputProps): React.ReactElement {
  const inputWrapperRef = useRef<HTMLDivElement>(null);

  const toggleWrapperClass = (addClass: boolean) => {
    const wrapper = inputWrapperRef.current;
    if (!wrapper) return;

    if (addClass) {
      wrapper.classList.add("w-full");
    } else {
      const input = wrapper.querySelector("input");
      if (input && input.value.length !== 0) return;
      wrapper.classList.remove("w-full");
    }
  };

  const handleFocus = () => {
    toggleWrapperClass(true);
  };
  const handleBlur = () => {
    toggleWrapperClass(false);
  };

  return (
    <div className="flex w-full justify-center text-black">
      <div
        className="relative z-20 w-7/10 transition-all"
        ref={inputWrapperRef}
      >
        <TextInput
          type="text"
          className="w-full rounded-md px-4 py-1 shadow-sm ring-1 ring-gray-400 placeholder:text-sm focus:outline-none focus:ring-1 focus:ring-black lg:placeholder:text-lg"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        {value.length === 0 ? (
          <button type="submit" className="absolute right-0 top-0 mr-4 mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentcolor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5.0 105.196 5.196a7.5 7.5.0 0010.607 10.607z"
              ></path>
            </svg>
          </button>
        ) : (
          <button
            type="submit"
            className="absolute right-0 top-0 mr-4 mt-2"
            onClick={onClick}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" strokeWidth="2">
              <path
                d="M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z"
                stroke="currentColor"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}
