import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { PodcastPageContext } from "@app/types/Page";
import { BlockTitle } from "@blocks/Blocks";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useCategoriesFromPath } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import PodcastsTopFeaturedCarousel from "@pages/Section/layouts/PodcastsKeywordPage/components/PodcastsTopFeaturedCarousel";
import { sectionNavigationItems } from "@util/constant";
import { cn, GoogleAdsSlotFactory } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import MorePodcasts from "./components/MorePodcasts/MorePodcasts";
import PodcastsKeywords from "./components/PodcastsKeywords";
import { PODCAST_KEYWORDS_DATA } from "./util/constants";

export type PodcastPageContentProps = Pick<PodcastPageContext, "data">;

export default function PodcastPageContent({
  data: {
    title,
    podcastsFeaturedArticles,
    marketFocusArticles,
    moneyHacksArticles,
    lensOnArticles,
    btCorrespondentsArticles,
    brandedContentArticles,
    morePodcastsArticles,
  },
}: PodcastPageContentProps): React.ReactElement {
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });
  const adBlockDetected = useDetectAdBlock();

  const slotUniqueName = RouteFactory.podcasts.replace("/", "");
  const termName = "Podcasts";
  const articleMapping = [
    { name: "lens_on", articles: lensOnArticles },
    { name: "money_hacks", articles: moneyHacksArticles },
    { name: "market_focus", articles: marketFocusArticles },
    { name: "bt_correspondents", articles: btCorrespondentsArticles },
    { name: "branded_podcasts", articles: brandedContentArticles },
  ];

  const { parentCategory } = useCategoriesFromPath();

  return (
    <>
      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${termName} - Find ${termName} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="article"
        slug={RouteFactory.podcasts}
      />

      <GAData
        level2={RouteFactory.podcasts.replace("/", "")}
        title="Podcasts_Index"
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Advertisement
        rootClassName="min-h-[50px] lg:min-h-[250px] md:py-3 border-b py-1 w-full bg-gray-125 border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(slotUniqueName),
          adsClassName:
            "min-h-[50px] lg:min-h-[250px] flex flex-col justify-center items-center",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(slotUniqueName)}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header />

      {parentCategory && sectionNavigationItems?.[parentCategory] ? (
        <div className="w-full flex-none">
          <Container>
            <h1
              className={cn(
                "pb-3 pt-6 font-playfair text-8xl uppercase",
                "border-b border-gray-850"
              )}
            >
              PODCASTS
            </h1>
          </Container>
        </div>
      ) : null}

      <Container>
        <Row>
          <Column rootClassName={cn("w-full mt-9 md:pr-6", "lg:w-8/12")}>
            <PodcastsTopFeaturedCarousel data={podcastsFeaturedArticles} />
          </Column>

          <Column rootClassName={cn("w-full mt-9 px-0 lg:px-3 lg:w-4/12")}>
            <Advertisement
              rootClassName="lg:sticky lg:top-[120px] py-4 bg-gray-125 border-y border-gray-175"
              adUnitProps={{
                type: AdvertisementTypeEnum.IMU1,
                slot: GoogleAdsSlotFactory.imu1(slotUniqueName),
              }}
            />
          </Column>
        </Row>

        <Row>
          {PODCAST_KEYWORDS_DATA.map(
            ({
              apple_link,
              description,
              id,
              spotify_link,
              tag,
              youtube_link,
              thumbnailSrc,
            }) => {
              // Gets the appropriate article list based on mapping.
              const articles = articleMapping
                .filter(({ name: _name }) => id === _name)
                .flatMap(({ articles }) => articles);
              const blockTitle = tag.name.toUpperCase();
              return (
                <Column
                  rootClassName={cn("w-full py-8", {
                    "py-0": articles.length <= 0,
                  })}
                  key={id}
                >
                  <PodcastsKeywords
                    {...{
                      blockTitle,
                      articles,
                      description,
                      spotify_link,
                      apple_link,
                      youtube_link,
                      tag,
                      cardName: id,
                      thumbnailSrc,
                    }}
                  />
                </Column>
              );
            }
          )}
        </Row>

        <Row>
          <Column rootClassName="w-full lg:w-8/12 py-8">
            <BlockTitle
              text="MORE PODCASTS"
              rootClassName="border-t border-black pt-3"
            />

            <MorePodcasts articles={morePodcastsArticles} />
          </Column>

          <Column rootClassName="w-full py-8 px-0 lg:px-3 lg:w-4/12">
            <Advertisement
              rootClassName="lg:sticky lg:top-[60px] py-4 bg-gray-125 border-y border-gray-175"
              adUnitProps={{
                type: AdvertisementTypeEnum.IMU2,
                slot: GoogleAdsSlotFactory.imu2(slotUniqueName),
              }}
            />
          </Column>
        </Row>
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(slotUniqueName),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </>
  );
}
