import thumbnail from "@assets/image-gifted-newspaper.jpg";
import Button from "@components/AccessibleComponents/Button";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";

export type ArticleSubshareGiftingUpdateContentProps = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleGenerateGiftURLModal: () => void;
};

export default function ArticleSubshareGiftingUpdateContent({
  setIsModalOpen,
  handleGenerateGiftURLModal,
}: ArticleSubshareGiftingUpdateContentProps): React.ReactElement {
  Cookies.set("giftAnArticleNotified", "yes");

  const handleClick = () => {
    handleGenerateGiftURLModal();
    setIsModalOpen(false);
  };

  return (
    <div>
      <Button
        className="absolute right-4 top-4 outline-none"
        onPress={() => {
          setIsModalOpen(false);
        }}
      >
        <FontAwesomeIcon icon={faXmark} size="xl" />
      </Button>

      <img
        className="mb-6 w-full"
        src={thumbnail}
        width={400}
        height={213}
        alt=""
      />

      <div className="px-4 text-center font-poppins">
        <h2 className="mb-4 font-lct text-lg font-bold">New gifting update</h2>
        <p>
          <b>For recipients:</b> Free access to the gifted story for 14 days, no
          account needed
        </p>
        <p>
          <b>For you:</b> Gift up to 5 premium stories a month, this remains
          free
        </p>
        <p>
          Start gifting friends and family today, and help them stay in the
          loop!
        </p>

        <Button
          className="mb-6 w-full rounded-full bg-black px-9 py-3 font-poppins text-base font-bold text-white outline-none"
          onPress={handleClick}
        >
          START GIFTING
        </Button>
      </div>
    </div>
  );
}
