import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import useAlacrity from "@hooks/useAlacrity";
import { ARTICLE_ERROR_GENERIC } from "@pages/Article/components/ArticleSubshare/constants";
import {
  LAMBDA_RESPONSE_TYPES,
  LambdaResponsePayload,
} from "@pages/Article/types/Alacrity";

import { DRUPAL_API_ENDPOINT } from "../util/constant";

export default function useArticleGiftReceived() {
  const { search, pathname } = useLocation();
  const giftToken =
    new URLSearchParams(search).get("gift") ??
    new URLSearchParams(search).get("token");
  const articleUrl = `${DRUPAL_API_ENDPOINT}${pathname}`;

  const [cookies] = useCookies(["neuronId"]);
  const neuronId: string | undefined = cookies.neuronId;

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [alacrityRes, setAlacrityRes] = useState<LambdaResponsePayload | null>(
    null
  );
  const { verifyGiftToken } = useAlacrity();
  const isGiftReceived =
    alacrityRes?.data.responseType ===
    LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN;

  useEffect(() => {
    if (!giftToken) return;

    const checkGiftReceived = async () => {
      console.log("[subshare] GiftReceived giftToken", giftToken); // eslint-disable-line no-console
      console.log("[subshare] GiftReceived neuronId", neuronId); // eslint-disable-line no-console
      if (!neuronId) {
        console.log("[subshare] GiftReceived neuronId not defined returning"); // eslint-disable-line no-console
        return;
      }

      try {
        console.log("[subshare] GiftReceived calling verifyGiftToken"); // eslint-disable-line no-console

        const response = await verifyGiftToken(articleUrl, giftToken, neuronId);

        console.log("[subshare] GiftReceived response", response); // eslint-disable-line no-console

        setAlacrityRes(response);
      } catch (err) {
        console.log("[subshare] GiftReceived err", err); // eslint-disable-line no-console
        setAlacrityRes(ARTICLE_ERROR_GENERIC);
      } finally {
        setIsModalOpen(true);
      }
    };

    checkGiftReceived();
  }, [giftToken, neuronId, verifyGiftToken, articleUrl]);

  console.log("[subshare] GiftReceived isGiftReceived", isGiftReceived); // eslint-disable-line no-console

  return { isModalOpen, setIsModalOpen, alacrityRes, isGiftReceived };
}
