import Button from "@components/AccessibleComponents/Button";
import cx from "classnames";

export type ThriveToggleSideMenuButtonProps = {
  isOpen: boolean;
  onClick: () => void;
};

export default function ThriveToggleSideMenuButton({
  isOpen,
  onClick,
}: ThriveToggleSideMenuButtonProps): React.ReactElement {
  return (
    <Button
      aria-label="Toggle side menu"
      className={cx(
        "thrive-toggler absolute right-0 z-10 block border border-black bg-white shadow-thriveToggle outline-none lg:hidden",
        { open: isOpen }
      )}
      onPress={onClick}
    >
      <svg
        className="hamburger"
        fill="#2b2b2b"
        viewBox="0 0 100 100"
        width="36"
      >
        <rect className="line top" height="2" width="60" x="20" y="40">
          {" "}
        </rect>
        <rect className="line bottom" height="2" width="60" x="20" y="60">
          {" "}
        </rect>
      </svg>
    </Button>
  );
}
