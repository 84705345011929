import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";

export type UserInfoType = {
  allow: string;
  aud: string;
  auth_time: number;
  cid: string;
  exp: number;
  firstname: string;
  hashid: string;
  iat: number;
  iss: string;
  jti: string;
  lastname: string;
  loginId: string;
  scp: Array<string>;
  sessionid: string;
  sub: string;
  svc: Array<string>;
  uid: string;
  ver: number;
} | null;

const useUserInfo = () => {
  const [userInfo, setUserInfo] = useState<UserInfoType>(null);
  const [userError, setUserError] = useState<unknown>(null);

  const handleLoadUserInfo = () => {
    try {
      const token = JSON.parse(
        window.localStorage.getItem("okta-token-storage") as string
      )?.accessToken;

      setUserInfo(jwt_decode(token?.accessToken));
    } catch (error) {
      setUserError(error);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleLoadUserInfo();
      window.addEventListener("storage", handleLoadUserInfo);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("storage", handleLoadUserInfo);
      }
    };
  }, []);

  return {
    userInfo,
    userError,
  };
};

export { useUserInfo };
