import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import {
  faFacebookF,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@util/helpers";

export type SocialShareProps = {
  shareUrl: string;
  rootClassName?: string;
};

export default function SocialShare({
  shareUrl,
  rootClassName,
}: SocialShareProps): React.ReactElement {
  const socialClasses =
    "px-[7px] py-1 rounded-full border border-black bg-white text-center text-gray-850";
  return (
    <div data-testid="social-share" className={cn(rootClassName)}>
      <div className="flex flex-col items-center border-t border-gray-200 pt-2 lg:flex-row">
        <p className="mb-5 font-public-sans text-base font-normal lg:mb-0 lg:mr-2">
          Share this Newsletter
        </p>

        <div className="flex items-center space-x-[6px]">
          <div
            className={cn(socialClasses, "px-[10px]")}
            data-testid="fb-share"
          >
            <FacebookShareButton url={shareUrl}>
              <FontAwesomeIcon icon={faFacebookF} />
            </FacebookShareButton>
          </div>

          <div
            className={cn(socialClasses, "px-[9px]")}
            data-testid="linkedin-share"
          >
            <LinkedinShareButton url={shareUrl}>
              <FontAwesomeIcon icon={faLinkedinIn} />
            </LinkedinShareButton>
          </div>

          <div className={cn(socialClasses)} data-testid="twitterx-share">
            <TwitterShareButton url={shareUrl}>
              <FontAwesomeIcon icon={faXTwitter} />
            </TwitterShareButton>
          </div>

          <div className={cn(socialClasses)} data-testid="telegram-share">
            <TelegramShareButton url={shareUrl}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </TelegramShareButton>
          </div>
        </div>
      </div>
    </div>
  );
}
