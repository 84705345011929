import { useState } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import thriveLogo from "@assets/thrive-logo.svg";
import Container from "@components/Container/Container";
import { useWindowSize } from "@hooks/useWindowSize";

import ThriveNavigation from "./ThriveNavigation";
import ThriveSideMenu from "./ThriveSideMenu";
import ThriveToggleSideMenuButton from "./ThriveToggleSideMenuButton";

export default function ThriveHeader() {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const { isScreenLG } = useWindowSize();

  const toggleSideMenu = () => {
    setIsSideMenuOpen((prev) => !prev);
    document.body.style.overflowY = isSideMenuOpen ? "scroll" : "hidden";
  };

  return (
    <header className="px-5" data-testid="thrive-header-component">
      {/* uppper */}
      <Container rootClassName="max-w-[1300px] px-0">
        <div className="relative z-10 flex w-full items-center py-5 lg:py-8">
          <div className="flex h-10 w-full justify-between">
            <Link to={RouteFactory.thrive} reloadDocument>
              <img src={thriveLogo} alt="Thrive" width="150" height="56" />
            </Link>

            <div className="hidden items-center lg:flex lg:justify-end">
              <ThriveNavigation />
            </div>
          </div>

          {!isScreenLG ? (
            <>
              <ThriveToggleSideMenuButton
                isOpen={isSideMenuOpen}
                onClick={toggleSideMenu}
              />
              <ThriveSideMenu isSideMenuOpen={isSideMenuOpen} />
            </>
          ) : null}
        </div>
      </Container>
    </header>
  );
}
