import iconChevronRightDark from "@assets/icon-chevron-right-dark.svg";
import iconChevronRightLight from "@assets/icon-chevron-right-light.svg";
import { cn } from "@util/helpers";

export default function SplideNextArrow(): React.ReactElement {
  return (
    <button
      className={cn(
        "splide__arrow splide__arrow--next",
        "relative left-[unset] right-0 top-[unset] translate-x-0 transition-colors",
        "!h-9 !w-9 rounded-full !border !border-solid border-gray-175 bg-white text-gray-850 !opacity-100 hover:border-gray-850",
        "group cursor-pointer disabled:cursor-auto disabled:border-gray-175"
      )}
    >
      <img
        src={iconChevronRightDark}
        width={24}
        height={24}
        alt="Next slide"
        className="block flex-shrink-0 group-disabled:hidden"
      />

      <img
        src={iconChevronRightLight}
        width={24}
        height={24}
        alt="Next slide"
        className="hidden flex-shrink-0 group-disabled:block"
      />
    </button>
  );
}
