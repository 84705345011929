import { useState } from "react";
import chevronRight from "@assets/chevron-right.svg";
import { getPercentageChangeInfo } from "@components/StockPickerWidget/helpers";
import StockPickerWidget from "@components/StockPickerWidget/StockPickerWidget";
import { StockData } from "@components/StockPickerWidget/types";

export interface ArticleStockDataProps {
  stockData: StockData;
}

export function ArticleStockData({
  stockData,
}: ArticleStockDataProps): React.ReactElement {
  const [isStockPickerVisible, setIsStockPickerVisible] = useState(false);

  const {
    percentageChange,
    percentageChangeColor,
    percentageChangeSign,
    percentageChangeIcon,
  } = getPercentageChangeInfo(stockData.CHANGES, stockData.PREVIOUS);

  const priceChangeStyle = `font-poppins text-base font-medium tracking-wide ${percentageChangeColor}`;

  return (
    <>
      <StockPickerWidget
        code={stockData.STOCK_CODE}
        stockData={stockData}
        setIsModalOpen={setIsStockPickerVisible}
        isModalOpen={isStockPickerVisible}
      />

      <div
        className="cursor-pointer bg-gray-850 py-4 pl-4 text-white"
        onClick={() => {
          setIsStockPickerVisible(true);
        }}
        data-testid={`article-stock-${stockData.STOCK_CODE}`}
      >
        <div className="relative">
          {stockData.FULL_NAME ? (
            <span className="pr-8 font-poppins text-base font-bold capitalize">
              {stockData.FULL_NAME.toLowerCase()}
            </span>
          ) : null}

          <span className="absolute right-0 top-0 block pr-3">
            <img
              src={chevronRight}
              width={14}
              height={28}
              alt="stock details"
            />
          </span>
        </div>

        <div className="font-poppins text-xs font-medium">
          {stockData.STOCK_NAME}: {stockData.STOCK_CODE}
        </div>

        <div className="text-4xl">
          <span>{stockData.LAST}</span>

          <span className="text-4xs"> {stockData.CURRENCY}</span>
        </div>

        {stockData.CHANGES !== 0 ? (
          <div
            className="flex space-x-1"
            data-testid={`stock-price-change-${stockData.STOCK_CODE}`}
          >
            <img
              src={percentageChangeIcon}
              width="14"
              height="22"
              alt="Percentage Change Icon"
            />

            <span className={priceChangeStyle}>
              {percentageChangeSign}
              {percentageChange}%
            </span>

            <div className="ml-3 pl-3">
              <span className={priceChangeStyle}>
                {percentageChangeSign}
                {stockData.CHANGES}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
