import cx from "classnames";

interface NewsletterTitleProps {
  title: string;
  formattedTitle: string;
  sectionRef: React.RefObject<HTMLDivElement>;
  rootClassname?: string;
}

export default function NewsletterTitle({
  title,
  formattedTitle,
  sectionRef,
  rootClassname,
}: NewsletterTitleProps): React.ReactElement {
  return (
    <h5
      className={cx(
        rootClassname,
        "mb-5 pt-3 font-poppins text-lg uppercase text-gray-850 "
      )}
      id={formattedTitle}
      ref={sectionRef}
    >
      {title}
    </h5>
  );
}
