import { TestIdClassNameProps } from "@app/types/Common";
import { ArticleDataObject, Tag } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import { getKeywordLink } from "@components/FollowKeyword/helper";
import Column from "@components/Grid/Column";
import SeeMore from "@components/SeeMore/SeeMore";
import { gaEventTracker } from "@util/helpers";

import MainArticle from "./MainArticle";
import OtherArticles from "./OtherArticles";

export type ListOfArticlesProps = TestIdClassNameProps & {
  tag: Tag;
  articles: ArticleDataObject[];
};

export default function ListOfArticles({
  tag,
  articles,
  testId,
}: ListOfArticlesProps): React.ReactElement {
  const handleSeeMore: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    const target = e.target as HTMLAnchorElement;
    const label = target.href ? `see more - ${target.href}` : "see more";
    gaEventTracker("mybt", "click", label);
  };

  return (
    <Column rootClassName="w-full lg:w-8/12" data-testid={testId}>
      <BlockTitle text={tag.name.toUpperCase()} rootClassName="mb-5" />

      <MainArticle articles={articles.slice(0, 1)} />

      <OtherArticles articles={articles.slice(1, 5)} />

      <SeeMore
        link={getKeywordLink(tag)}
        text="SEE MORE >"
        rootClassName="mt-4"
        onClick={handleSeeMore}
      />
    </Column>
  );
}
