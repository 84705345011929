import cx from "classnames";

type ArticleKickerProps = {
  className?: string;
  kicker?: string;
};
export default function ArticleKicker({
  className,
  kicker,
}: ArticleKickerProps): React.ReactElement {
  return (
    <>
      {kicker ? (
        <div
          className={cx(
            className,
            "font-poppins text-4xs tracking-[1px] text-gray-650"
          )}
          data-testid="article-kicker-component"
        >
          {kicker}
        </div>
      ) : null}
    </>
  );
}
