import ArticleSubshareGiftingUpdateContent from "./ArticleSubshareGiftingUpdateContent";
import ArticleSubshareModal from "./ArticleSubshareModal";

type ArticleSubshareGiftingUpdateProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleGenerateGiftURLModal: () => void;
};

export default function ArticleSubshareGiftingUpdate({
  isModalOpen,
  setIsModalOpen,
  handleGenerateGiftURLModal,
}: ArticleSubshareGiftingUpdateProps): React.ReactElement {
  return (
    <div data-testid="article-subshare-gifting-update-component">
      <ArticleSubshareModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isContentCentered={false}
      >
        <ArticleSubshareGiftingUpdateContent
          {...{
            setIsModalOpen,
            handleGenerateGiftURLModal,
          }}
        />
      </ArticleSubshareModal>
    </div>
  );
}
