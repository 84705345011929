import trendingIcon from "@assets/icon-trending-search.svg";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import { TrendingKeywordsProps } from "@pages/Search/types";
import { cn } from "@src/app/util/helpers";

export default function SearchTrendingKeywords({
  keywords,
  setSelectedKeyword,
}: TrendingKeywordsProps): React.ReactElement {
  return (
    <div
      data-testid="search-trending-keywords-component"
      className="mt-6 md:mt-18"
    >
      <div className="mb-6 flex border-b border-gray-850 py-2">
        <img
          src={trendingIcon}
          width={24}
          height={24}
          alt="trending keywords"
          className="mr-2"
        />

        <p className="font-poppins font-semibold uppercase md:text-lg">
          Trending searches
        </p>
      </div>

      <div className={cn("flex w-full flex-wrap")}>
        {keywords.map((keyword) => {
          return (
            <button
              key={keyword.query}
              onClick={() => {
                setSelectedKeyword(keyword.query || "");
              }}
              className={cn(
                "mb-4 mr-4 rounded-[4px] border border-gray-175 px-4 py-3 font-poppins font-medium transition-all duration-300 hover:border-gray-850 md:text-lg",
                REMOVE_FOCUS_CSS
              )}
            >
              {keyword.query}
            </button>
          );
        })}
      </div>
    </div>
  );
}
