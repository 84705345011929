import { Link } from "react-router-dom";
import imgQRCode from "@assets/img-whatsapp-qr-black.svg";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { whatsappChannelUrl } from "@util/constant";
import { cn, gaEventTracker } from "@util/helpers";

export default function WhatsappQRBreakingNews(): React.ReactElement {
  const handleClick = () => {
    gaEventTracker("bt on whatsapp", "click", whatsappChannelUrl);
  };

  return (
    <div
      className={cn(
        "relative flex w-[calc(100%+5rem)] -translate-x-20 items-center border-b border-t border-gray-175 bg-white py-6",
        "lg:w-auto lg:translate-x-0"
      )}
      data-testid="whatsapp-qr-breaking-news-component"
    >
      <div className="p-2 lg:p-0">
        <FontAwesomeIcon icon={faWhatsapp} size="3x" className="lg:hidden" />

        <img
          src={imgQRCode}
          alt="QR Code"
          width={60}
          height={60}
          className="hidden aspect-square lg:block"
        />
      </div>

      <div className="pl-2">
        <h2 className="mb-1 font-poppins text-base font-semibold text-gray-850 underline">
          BT is now on WhatsApp
        </h2>

        <p className="font-public-sans text-xs text-gray-850">
          <span className="hidden lg:inline">
            Scan the QR code for breaking news and our top stories, right to
            your phone.
          </span>

          <span className="lg:hidden">
            Breaking news and our top stories, right to your phone.
          </span>
        </p>
      </div>

      <Link
        to={whatsappChannelUrl}
        className="absolute inset-0"
        target="_blank"
        onClick={handleClick}
      />
    </div>
  );
}
