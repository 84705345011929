import { useState } from "react";
import { getQuerylyParmsList } from "@pages/Search/helpers";
import {
  FetchSearchDataparams,
  FetchSearchDataProps,
  QuerylySearchFacetedDataResponse,
  QuerylySearchResultsResponse,
  SearchResultsItems,
} from "@pages/Search/types";
import axios from "axios";

const fetchQuerylySearchData = async (paramList: FetchSearchDataparams) => {
  try {
    if (!paramList) return;

    const response = await axios.get<QuerylySearchResultsResponse>(
      `/_plat/api/v1/queryly-search`,
      {
        params: paramList,
        timeout: 10000,
      }
    );

    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching queryly list", error);
    return;
  }
};

export const useQuerylySearchFetchData = () => {
  const [searchData, setSearchResults] = useState<SearchResultsItems[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [endindex, setEndIndex] = useState(0);
  const [facetedData, setFacetedData] =
    useState<QuerylySearchFacetedDataResponse>();
  const [displayLoadMore, setDisplayLoadMore] = useState(true);

  const fetchSearchData = async ({
    query,
    sortBy,
    isHandleLoadMore,
    isDefaultEndIndex,
    selectedFacetedAllData,
  }: FetchSearchDataProps) => {
    setIsLoading(true);

    const parameterList = getQuerylyParmsList({
      query,
      sortBy,
      endindex: isDefaultEndIndex ? 0 : endindex,
      selectedFacetedAllData,
    });

    const data = await fetchQuerylySearchData(parameterList);
    const searchResults = data?.items || [];

    const prevSearchData = searchData || [];
    const allSearchResults = isHandleLoadMore
      ? [...prevSearchData, ...searchResults]
      : searchResults;

    setSearchResults(allSearchResults);

    setEndIndex(data?.metadata?.endindex || 0);

    const displayLoadMore =
      (data?.metadata?.total || 0) <= allSearchResults.length;
    setDisplayLoadMore(!displayLoadMore);

    setFacetedData(data?.faceted);
    setIsLoading(false);
  };

  const handleClearSearchData = () => {
    setSearchResults(undefined);
    setEndIndex(0);
    setFacetedData(undefined);
    setDisplayLoadMore(false);
  };

  return {
    searchData,
    isLoading,
    displayLoadMore,
    facetedData,
    handleClearSearchData,
    fetchSearchData,
  };
};
