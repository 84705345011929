import { Tag } from "@src/app/types/Cue";

/**
 * Get the href of keyword in keywords/uri format.
 * @param tag The tag object.
 * @returns {string} uri
 *
 */
export const getKeywordLink = (tag: Tag): string => {
  // Return the URL path if it exists, keep the other conditions for backward compatibility
  if (tag.urlPath) return tag.urlPath;

  if (tag.uri === "") return "";
  if (tag.uri === null) return "";
  if (tag.uri.includes("/keywords/")) return tag.uri;
  if (tag.uri.includes("keywords/")) return `/${tag.uri}`;
  if (tag.uri.includes("main/"))
    return `/${tag.uri.replace("main/", "keywords/")}`;
  return `/keywords/${tag.uri}`;
};
