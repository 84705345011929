import ThriveProfileLogo from "@assets/newsletter/Thrive/daryl20choo20headshot.jpg";

export default function NewsletterSampleAuthor(): React.ReactElement {
  return (
    <>
      <div className="box-border py-2 font-inter">
        <h2 className="mb-2 mt-2.5 py-1.5 font-inter text-2xl font-bold text-gray-850">
          🧍 We, the investors of Singapore
        </h2>

        <p className="mb-2 text-base leading-7 text-gray-850">
          While filming the third episode of
          <a
            href="https://www.instagram.com/p/C-raWq7SBVd/"
            target="_blank"
            className="border-b-2 border-green-400 text-gray-850 no-underline"
            rel="noreferrer"
          >
            <em>Investing with Choo</em>
          </a>
          &nbsp; on passive investing, I asked investment advisor Katherine
          Chua: Is it a good idea to buy and hold a Straits Times Index (STI)
          fund?
        </p>

        <p className="mb-2 text-base leading-7 text-gray-850">
          After all, the Singapore economy seems to have held up fairly well
          through the uncertainty over the past years. A recent
          <a
            href="https://www.businesstimes.com.sg/international/asean/two-thirds-singapore-consumers-optimistic-about-local-economy-over-half-lack-financial-preparedness"
            target="_blank"
            className="border-b-2 border-green-400 text-gray-850 no-underline"
            rel="noreferrer"
          >
            survey by UOB
          </a>
          even found that more than two-thirds of respondents were optimistic
          about the local economy.
        </p>

        <p className="mb-2 text-base leading-7 text-gray-850">
          So if you’re confident about the Singapore economy, doesn’t it make
          sense to invest in an index fund that tracks the biggest companies
          here? Well, the answer is not that simple.
        </p>

        <p className="mb-2 text-base leading-7 text-gray-850">
          <em>
            P.S. We’ve started using gifted stories for links to BT stories in
            the “In other news” section of our newsletter. thrive readers who
            redeem the premium articles within 14 days will be able to read them
            without a subscription. Enjoy 🎁!
          </em>
        </p>
      </div>

      <div className="m-0 box-border flex items-center p-0">
        <img
          className="relative m-0 box-border h-auto max-w-full rounded-full p-0"
          src={ThriveProfileLogo}
          width="80"
        />
        <div className="m-0 box-border px-2 py-4">
          <span className="m-0 box-border block p-0 text-base font-bold leading-6 tracking-[-0.3px] text-gray-850">
            Daryl Choo
          </span>
          <span className="m-0 box-border block p-0 text-base font-normal leading-6 tracking-[-0.3px] text-gray-850">
            Young Audience Journalist
          </span>
        </div>
      </div>
      <h2 className="m-0 mb-1.5 mt-2 box-border p-0 pb-1.5 pt-1.5 font-inter text-2xl font-bold text-gray-850">
        In this week &apos;s issue
      </h2>

      <ul className="m-0 box-border list-disc p-0 pl-6">
        <li className="m-0 box-border px-5 py-2 font-inter text-base leading-7 text-gray-850">
          SkillsFuture credits can now be used for Coursera, Udemy Business
          courses
        </li>
        <li className="m-0 box-border px-5 py-2 font-inter text-base leading-7 text-gray-850">
          Healthy food is too expensive for lower-income families 🥫
        </li>
        <li className="m-0 box-border px-5 py-2 font-inter text-base leading-7 text-gray-850">
          Should new investors invest in the STI?
        </li>
      </ul>
    </>
  );
}
