import { FormEvent, useState } from "react";
import { TestIdClassNameProps } from "@app/types/Common";
import Button from "@components/AccessibleComponents/Button";
import TextInput from "@components/AccessibleComponents/TextInput";
import { EmarsysResponse } from "@components/Newsletter/types/Emarsys";
import { Newsletter } from "@components/Newsletter/types/Newsletter";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition, TransitionChild } from "@headlessui/react";
import { newsletterRequests } from "@pages/Newsletter/SignUp/Newsletter.server";
import useOKTAUserStore from "@store/useOKTAUserStore";
import { BigMoneyNewsletter } from "@util/constant";
import cn from "classnames";

export type ArticleNewsletterFormProps = TestIdClassNameProps & {
  newsletters: Newsletter[];
  showForm: boolean;
  emarsysResponse?: EmarsysResponse;
  setEmarsysResponse: (response: EmarsysResponse) => void;
};

export const ArticleNewsletterForm = ({
  testId,
  rootClassName,
  newsletters,
  showForm,
  emarsysResponse,
  setEmarsysResponse,
}: ArticleNewsletterFormProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isBigMoney, setIsBigMoney] = useState(false);
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const target = event.target as typeof event.target & {
      email: { value: string };
    };

    const request = async () => {
      // Check if any of the newsletters have premiumOnly set to true
      const isPremiumOnly = newsletters.some(
        (newsletter) => newsletter.premiumOnly
      );

      if (isPremiumOnly && target.email.value !== OktaUserInfo?.loginid) {
        setErrorMessage(BigMoneyNewsletter.premiumEmailValidation);
        setIsBigMoney(true);
        setLoading(false);
      } else {
        await newsletterRequests
          .processSubscription(target.email.value, newsletters)
          .then((response) => {
            if (response.status === "error") setErrorMessage(response.message);
            setEmarsysResponse(response);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    request();
  };

  return (
    <Transition
      show={showForm}
      as="form"
      onSubmit={handleSubmit}
      className={cn(rootClassName, "grid grid-cols-6 gap-x-3")}
      data-testid={testId}
    >
      <TransitionChild
        enter="transition ease-in-out duration-300 transform"
        enterFrom="translate-x-full opacity-0"
        enterTo="translate-x-0 opacity-100"
      >
        <div className="col-span-3 sm:col-span-4">
          <TextInput
            className={cn(
              "h-full w-full py-1.5 font-public-sans text-base font-normal placeholder-gray-600 outline-none"
            )}
            name="email"
            type="email"
            placeholder="Your email address"
            required
          />
        </div>
      </TransitionChild>

      <TransitionChild
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full opacity-0"
        enterTo="translate-x-0 opacity-100"
      >
        <div className="col-span-3 sm:col-span-2">
          <Button
            type="submit"
            className={cn(
              "w-full cursor-pointer whitespace-nowrap rounded-full bg-gray-850 px-6 py-2 text-center text-base text-white md:w-auto",
              "focus-within:outline-none hover:bg-gray-675 focus:outline-none"
            )}
          >
            {loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spin={loading}
                size="lg"
                color="text-gray-850"
              />
            ) : (
              "Sign up"
            )}
          </Button>
        </div>
      </TransitionChild>

      <div
        role="alert"
        className={cn(
          "relative col-span-6 mt-1 border-t font-poppins text-xs font-semibold text-red",
          {
            "border-red pt-1": errorMessage,
            "border-gray-850": !errorMessage,
          }
        )}
      >
        {errorMessage ? (
          <>
            {errorMessage}
            {isBigMoney ? (
              <a
                className="underline"
                href="https://subscribe.sph.com.sg/promotions/bt-personal/?utm_campaign=bigmoneynewsletter&utm_medium=sph_publication&utm_source=bt&utm_content=newsletterarchivesubscribelink"
              >
                here.
              </a>
            ) : null}
          </>
        ) : (
          emarsysResponse?.message
        )}
      </div>
    </Transition>
  );
};
