import { useEffect, useState } from "react";
import { TrimmedBreakingNewsArticle } from "@app/caas/types";
import axios from "axios";

const fetchTrimmedBreakingNews = async (): Promise<
  TrimmedBreakingNewsArticle[]
> => {
  try {
    const response = await axios.get("/_plat/api/v1/trimmed-breaking-news");
    return response.data;
  } catch (error) {
    return [];
  }
};

/**
 * Description: It returns trimmed breaking news list.
 * @returns data - {TrimmedBreakingNewsArticle[]}
 */
export const useTrimmedBreakingNews = (): TrimmedBreakingNewsArticle[] => {
  const [data, setData] = useState<TrimmedBreakingNewsArticle[]>([]);
  useEffect(() => {
    const callFetch = async () => {
      setData(await fetchTrimmedBreakingNews());
    };
    callFetch();
  }, []);

  return data;
};
