import Container from "@components/Container/Container";

import LogoMasthead from "./LogoMasthead/LogoMasthead";
import type { NavigationA } from "./types";

export type MastheadProps = {
  prefix?: string;
  mastheadTitle?: string;
  mastheadCustomElement?: React.ReactElement;
  userDisplayName?: boolean;
  mastheadLinks?: NavigationA;
};

export default function HomepageMasthead() {
  return (
    <Container rootClassName="">
      <div className="relative hidden w-full flex-none py-5 lg:block">
        <LogoMasthead rootClassName={"flex justify-center"} imgWidth={575} />
      </div>
    </Container>
  );
}
