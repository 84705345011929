import { RouteFactory } from "@app/routePaths";
import btLogo from "@assets/newsletter/big-money-bt-logo-with-white-bg.png";
import PropertyInsightLogo from "@assets/newsletter/newsletter-property-insight-logo.png";

export default function PropertyInsightNewsletterSampleHeader(): React.ReactElement {
  return (
    <>
      <div
        className="m-0 mx-auto box-border w-full max-w-4xl p-0 px-5"
        data-testid="property-insight-newsletter-sample-header-component"
      >
        <div className="m-0 box-border">
          <a
            href={RouteFactory.section("startups-tech/startups")}
            target="_blank"
            rel="noopener noreferrer"
            className="block border-t-[5px] border-orange-500 text-center no-underline"
          >
            <img
              className="banner-logo relative mx-auto block h-auto"
              src={btLogo}
              height="40"
              width="210"
              alt="BT Logo"
            />
            <img
              className="banner-logo relative mx-auto block h-auto py-6"
              src={PropertyInsightLogo}
              height="78"
              width="255"
              alt="Newsletter Logo"
            />
          </a>
        </div>

        <div className="m-0 box-border text-center">
          <span className="font-arial text-base font-bold leading-4 tracking-[1px] text-gray-850">
            FRI, AUG 30, 2024
          </span>
        </div>
      </div>
    </>
  );
}
