import { Suspense } from "react";
import { Await, useLocation } from "react-router-dom";
import { TermContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import GAData from "@components/GAData/GAData";
import Spinner from "@components/Spinner/Spinner";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useRouteContext } from "@sphtech/web2-core/ssr";
import { toTitleCase } from "@util/helpers";

import { LayoutFactory } from "./helpers";

export default function SectionPage({
  testId = null,
}: {
  testId?: string | null;
}): React.ReactElement {
  const { context } = useRouteContext<TermContext>();

  if (Object.keys(context).length === 0) {
    throw new Error();
  }

  const { pathname } = useLocation();
  const [parentCategory, childCategory, variant] = pathname
    .replace("/", "")
    .split("/");

  const AwaitError = () => {
    return (
      <div className="relative flex flex-grow flex-col items-center justify-center">
        <ExclamationCircleIcon className="text-red-500 h-16 w-16" />
        <ExclamationCircleIcon className="text-red-500 h-16 w-16" />
        <h3 className="text-md mt-2 font-semibold text-gray-900">
          Error With Section Page!
        </h3>
        <p className="mt-1 text-sm text-gray-500">Try again later.</p>
      </div>
    );
  };

  const Layout = LayoutFactory({
    parentCategory,
    childCategory,
    variant,
  });

  const gaTitle =
    variant !== "undefined" && variant === `latest`
      ? " Latest Index"
      : " Index";

  const title = toTitleCase(
    (childCategory || parentCategory).replace("-", " ") + gaTitle
  );

  return (
    <Suspense fallback={<Spinner />}>
      <Await resolve={context} errorElement={<AwaitError />}>
        {(context) => {
          return !["section", "keyword"].includes(context?.kind) ||
            context?.error ? (
            <CustomError />
          ) : (
            <>
              <GAData
                chapter1={childCategory?.replaceAll("-", " ") || ""}
                level2={parentCategory?.replaceAll("-", " ") || ""}
                title={title}
                adblocker={typeof window !== undefined ? 0 : window.canRunAds}
              />

              <Layout
                data-testid={testId}
                context={context}
                parentCategory={parentCategory}
                childCategory={childCategory}
              />
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}
