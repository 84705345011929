/**
 * Company: SPHMedia
 * Description: LifestyleNews to display the news in a grid style
 */
import { Fragment, ReactElement } from "react";
import { TestIdClassNameProps } from "@app/types/Common";
import { ArticleDataObject } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import TopStoryCard from "@components/ArticleCard/TopStoryCard";
import { GoogleAdsSlotFactory } from "@util/helpers";

export type LifestyleNewsProps = TestIdClassNameProps & {
  data?: ArticleDataObject[];
  parentCategory: string;
  childCategory: string;
};

/**
 * LifestyleNews
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function LifestyleNews({
  testId = "lifestyle-news",
  data = [],
  parentCategory,
  childCategory,
}: LifestyleNewsProps): ReactElement {
  return (
    <div
      className="mb-12 grid grid-cols-1 gap-x-6 gap-y-[72px] lg:grid-cols-3"
      data-testid={testId}
    >
      {parentCategory === "lifestyle" &&
      childCategory === undefined &&
      data.length ? (
        <Advertisement
          rootClassName="bg-stripes mb-5 py-5 h-auto"
          adUnitProps={{
            type: AdvertisementTypeEnum.IMU2,
            slot: GoogleAdsSlotFactory.imu2(parentCategory),
          }}
        />
      ) : null}

      {data?.map((article: ArticleDataObject, index: number) => {
        const {
          id,
          title,
          media,
          sections,
          paywall,
          slug,
          kicker,
          blurb,
          displaySetting,
          tags,
        } = article;
        const uniqueName = sections?.[0]?.uniqueName ?? "";

        return (
          <Fragment key={id}>
            {parentCategory === "lifestyle" &&
            childCategory === "bt-luxe" &&
            index == 2 ? (
              <div>
                <Advertisement
                  rootClassName="lg:sticky lg:top-[120px] bg-stripes mb-6 py-5 lg:mb-0"
                  adUnitProps={{
                    type: AdvertisementTypeEnum.IMU1,
                    slot: GoogleAdsSlotFactory.imu1(
                      parentCategory + "/" + childCategory
                    ),
                  }}
                />
              </div>
            ) : null}

            {media || uniqueName ? (
              <TopStoryCard
                id={id}
                title={displaySetting?.displayHeadline || title}
                media={media}
                slug={slug}
                kicker={kicker?.fields?.[0]?.value}
                tag={tags?.[0]}
                paywall={paywall?.contentAccess === "1"}
                blurb={blurb}
                defaultImage={{
                  directoryName: uniqueName,
                }}
              />
            ) : null}
          </Fragment>
        );
      })}
    </div>
  );
}
