import cx from "classnames";

export default function GlobalEnterpriseNewsletterDescription(): React.ReactElement {
  return (
    <div
      className={cx(
        "mb-4 flex flex-col",
        "md:mb-2 md:flex-row md:items-center md:justify-center",
        "lg:mb-0 lg:flex-col lg:items-start",
        "xl:flex-row xl:items-center"
      )}
    >
      <h4 className="text-center font-poppins text-4xl font-bold md:mr-4 lg:mb-0 lg:mr-3 lg:text-left">
        STAY UPDATED
      </h4>
      <h5 className="px-3 text-center font-poppins text-xl font-light md:px-0 lg:mb-0 lg:text-left">
        Get the latest on ASEAN and business news
      </h5>
    </div>
  );
}
