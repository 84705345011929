import { useState } from "react";
import { TestIdClassNameProps } from "@app/types/Common";
import Button from "@components/AccessibleComponents/Button";
import cx from "classnames";

export default function MyBTCarouselTooltip({
  testId,
  rootClassName,
}: TestIdClassNameProps): React.ReactElement {
  const [showTooltip, setShowTooltip] = useState(true);

  return (
    <>
      {showTooltip ? (
        <div className={cx(rootClassName, "relative")} data-testid={testId}>
          <div className="ml-6 h-0 w-0 border-4 border-transparent border-b-black"></div>

          <div className="bg-black p-4">
            <p className="mb-3 text-xs font-medium text-white">
              You can now personalise your BT experience with keywords.
            </p>

            <Button
              className="text-xs font-bold text-white"
              onPress={() => {
                setShowTooltip(false);
              }}
            >
              GOT IT.
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
}
