import type { ArticleDataObject } from "@app/types/Cue";
import HomePageTopStories from "@pages/Home/Overview/HomepageTopStories";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import cx from "classnames";

export type OverviewProps = {
  testId?: string | null;
  rootClassName?: string;
  data?: ArticleDataObject[];
};

export default function Overview({
  testId = null,
  rootClassName,
  data,
}: OverviewProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  return (
    <section
      id="homepage-topstories-section"
      data-testid={testId}
      className={cx(
        rootClassName,
        "stories flex list-none flex-col",
        "lg:grid lg:grid-flow-col lg:grid-cols-3 lg:grid-rows-4"
      )}
    >
      {data?.map((article: ArticleDataObject, index: number) => {
        return article ? (
          <HomePageTopStories
            article={article}
            index={index}
            key={index}
            disablePaywall={
              OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
            }
          />
        ) : null;
      })}
    </section>
  );
}
