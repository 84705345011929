import Container from "@components/Container/Container";
import { linksInHomeNewsletterPoll } from "@pages/Thrive/constants";

export default function ThriveSampleNewsletterPoll(): React.ReactElement {
  return (
    <Container
      rootClassName="p-6 text-gray-850 border border-black"
      data-testid="thrive-home-sample-newsletter-poll-component"
    >
      <p className="m-0 mb-2 p-0 font-interBold text-2xl">Poll of the week</p>

      <p className="m-0 mb-2 p-0">Have you started investing?</p>

      <ul className="list-disc pl-6">
        <li className="mb-4">
          <a
            href={linksInHomeNewsletterPoll.pollLink.to}
            target="_blank"
            className="border-b-2 border-green-400"
            rel="noreferrer"
          >
            {
              "I haven't started working, still saving up my emergency fund or paying off my debt"
            }
          </a>
        </li>

        <li className="mb-4">
          <a
            href={linksInHomeNewsletterPoll.pollLink.to}
            target="_blank"
            className="border-b-2 border-green-400"
            rel="noreferrer"
          >
            {"It's too risky 😨"}
          </a>
        </li>

        <li className="mb-4">
          <a
            href={linksInHomeNewsletterPoll.pollLink.to}
            target="_blank"
            className="border-b-2 border-green-400"
            rel="noreferrer"
          >
            Yes!
          </a>
        </li>
      </ul>
    </Container>
  );
}
