import { Tag } from "@app/types/Cue";

export enum KeywordType {
  listing = "listing",
  withTag = "with_tag",
  default = "default",
}

export interface KeywordTypeFactoryProps extends IndividualKeywordType {
  keywordType?: KeywordType;
}

export type IndividualKeywordType = {
  tagDisplayName: string;
  tagClassName?: string;
  handleClick?: () => void;
  disableBorder?: boolean;
  tag: Tag;
};
