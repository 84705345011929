import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { getIconSrc } from "@components/Button/helpers";
import { IconName } from "@components/Button/types";
import { cn } from "@src/app/util/helpers";

export default function SearchTrigger() {
  return (
    <Link
      reloadDocument
      className={cn(
        "flex cursor-pointer items-center justify-center opacity-100 duration-300",
        "border-0 !outline-none focus-within:outline-0 focus:outline-0 lg:hover:rounded-full lg:hover:bg-gray-250"
      )}
      aria-label="Search"
      to={RouteFactory.search()}
    >
      <img
        src={getIconSrc(IconName.search)}
        className="h-full w-[36px]"
        width={36}
        height={36}
        alt="close"
      />

      <p className="m-0 block font-poppins text-xs font-normal tracking-2% text-gray-850 lg:hidden">
        Search
      </p>
    </Link>
  );
}
