import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import facebookIcon from "@assets/thrive_images/thrive-facebook.svg";
import linkedinIcon from "@assets/thrive_images/thrive-linkedin.svg";
import telegramIcon from "@assets/thrive_images/thrive-telegram.svg";
import Container from "@components/Container/Container";
import { linksInHomeNewsletterStory } from "@pages/Thrive/constants";

export default function ThriveSampleNewsletter(): React.ReactElement {
  const shareUrl =
    "https://www.businesstimes.com.sg/companies-markets/how-much-do-you-need-start-investing";
  return (
    <Container
      rootClassName="px-0 text-gray-850 "
      data-testid="thrive-home-sample-newsletter-story-component"
    >
      <p className="m-0 pb-4 font-interBold text-2xl">
        💼 The case for investing
      </p>

      <p className="m-0 mb-2 p-0">
        <i>It takes money to make money.</i>
      </p>

      <p className="m-0 mb-2 p-0">
        I never quite understood that saying when I was younger – until I learnt
        about investing.
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "If you invested S$10,000 for a year, you'd get back S$700 based on a very realistic 7 per cent annual return."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "But suppose your rich parents gave you S$1,000,000 as a graduation gift, and you invested that whole sum. You'd make S$70,000 in a year and earn more than I do without ever stepping foot in an office 🕶️."
        }
      </p>

      <div className="mb-2">
        <img
          className="w-full"
          src={linksInHomeNewsletterStory.storyImgWokeSalaryMan.src}
          width={300}
          height={300}
        />

        <p className="m-0 mb-2 p-0 text-3xs italic">
          {linksInHomeNewsletterStory.storyImgWokeSalaryMan.source}
        </p>
      </div>

      <p className="m-0 mb-2 p-0">
        Okay, so investing makes rich people richer. Why should regular people
        bother?
      </p>

      <p className="m-0 mb-2 p-0 font-interBold">✨ Inflation ✨.</p>

      <p className="m-0 mb-2 p-0">
        {
          "What it means is that whatever money you have saved up that's sitting idle in a bank or in a Milo tin will always be worth less as time passes."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "Let's say you figure that you need S$3,000 a month when you retire and save accordingly, forgetting inflation."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "In 50 years' time, that S$3,000 would be worth S$890.22 in today's dollars, going by Singapore's past inflation rate over the same time period. Could you survive on that?"
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "Investing (responsibly) won't get you rich quickly. But even if you don't have much to invest with, the small sum of money you get in return should at least protect your savings from being eroded away by the rising cost of living."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          '"But my parents didn\'t invest, and they did well for themselves," you say.'
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "Well, even if they didn't buy any stocks or bonds, they probably did make investment gains if they owned any sort of housing 🏘️."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "Our parents lived in an age when property prices rose so quickly that they likely made more from buying and selling an HDB flat than if they put that money into local stocks."
        }
      </p>

      <div className="mb-2">
        <img
          className="w-full"
          src={linksInHomeNewsletterStory.storyImgTikTok.src}
          width={300}
          height={300}
        />

        <p className="m-0 mb-2 p-0 text-3xs italic">
          {linksInHomeNewsletterStory.storyImgTikTok.source}
        </p>
      </div>

      <p className="m-0 mb-2 p-0">
        {
          "Here's what He Ruiming, co-founder of the hugely popular financial comic he Woke Salaryman, has to say about this during our interview:"
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          '"Nowadays, as a young person, you will find that property can be quite out of reach, or maybe the property market might not boom so  much in the future…'
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          '"So I would say, for most people, you need to invest in some way. It could be the stock market, it could be the property market if you have enough money, it could be something like your CPF Special Account."'
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "The only people who can probably get by without investing these days are high-income earners, he says."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          '"If you\'re a median earner and you spend a median amount, you probably need to invest."'
        }
      </p>

      <p className="m-0 p-0 py-4 font-interBold text-2xl">🖐️ But wait…</p>

      <p className="m-0 mb-2 p-0">
        Of course, all of what has been said above assumes that your investments
        make money. However, as we know, investments can lose money, too 📉.
      </p>

      <p className="m-0 mb-2 p-0">
        Generally speaking, the stock market makes money more often than not.
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "The US stock market accounts for the majority of the world's stocks. When we look at the S&P 500 index, the de facto benchmark for the US market's performance, it has made around 10 per cent in returns every year over the long term."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "The US stock market accounts for the majority of the world's stocks. When we look at the S&P 500 index, the de facto benchmark for the US market's performance, it has made around 10 per cent in returns every year over the long term."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          'The emphasis here is on "long term". In reality, your investments could go up 10 per cent in one year, down 20 per cent the next, and so on.'
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "So it's important that you're not investing money you'll need in the near future because you don't want to be in a situation where you're forced to sell off your investments in a downturn only for the market to recover when you're ready to invest again."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "There are generally two things you need to settle before you're ready to invest, He says."
        }
      </p>

      <ol className="list-decimal py-2 pl-6">
        <li className="mb-2">
          <span className="font-interBold">Do you have an </span>
          <a
            href={linksInHomeNewsletterStory.storyEmergercyFunds.to}
            target="_blank"
            className="border-b-2 border-green-400 font-interBold"
            rel="noreferrer"
          >
            emergency fund
          </a>{" "}
          set aside in an account that you can readily access 🚑?
        </li>

        <li>
          <span className="font-interBold">Do you have any </span>
          <a
            href={linksInHomeNewsletterStory.storyInterestRate.to}
            target="_blank"
            className="border-b-2 border-green-400 font-interBold"
            rel="noreferrer"
          >
            high-interest loans
          </a>
          {
            ", like credit card debt, that'll cost more in interest fees than you can make with investments 💳?"
          }
        </li>
      </ol>

      <p className="m-0 mb-2 p-0">
        {
          'If you answered "yes" to the first question and "no" to the second, then you\'re pretty much set.'
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          '"Some people say you need like hundreds or thousands of dollars to start investing. I think that\'s not true," says He.'
        }
      </p>

      <p className="m-0 p-0 py-4 font-interBold text-2xl">
        🕝 The magic of starting early
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "There's this powerful chart that really drives home the magic of compound interest and why it's important to start investing early."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "There are countless variations of the same idea but here's one showing how someone who starts investing a regular sum every year for 10 years at age 25 ends up saving more than someone else who invests the same amount every year for 30 years."
        }
      </p>

      <div className="mb-2">
        <img
          className="w-full"
          src={linksInHomeNewsletterStory.storyImgFederal.src}
          width={300}
          height={300}
        />

        <p className="m-0 mb-2 p-0 text-3xs italic">
          {linksInHomeNewsletterStory.storyImgFederal.source}
        </p>
      </div>

      <p className="m-0 mb-2 p-0">
        A good way to start is to use the famous{" "}
        <a
          href={linksInHomeNewsletterStory.storySavingRules.to}
          target="_blank"
          className="border-b-2 border-green-400 font-interBold"
          rel="noreferrer"
        >
          50-30-20 rule
        </a>
        {
          ", which involves setting aside 20 per cent of your salary for investments once you've saved up enough for your emergency fund."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          '"Sometimes, while investing, you also learn what it\'s like to actually put money in the stock market. Sometimes the money goes up, sometimes the money goes down. It trains you so that you get used to how the market behaves," he says.'
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "\"This is, as opposed to, let's say, you wait until you're 40 years old and start investing, then your first market crash, you get scared, and then you sell at a loss.\""
        }
      </p>

      <p className="m-0 mb-2 p-0">
        It takes time, he adds, to build the temperament, mindset and philosophy
        required of a good investor 🧠.
      </p>

      <p className="m-0 p-0 py-4 font-interBold">TL;DR</p>

      <ul className="list-disc pl-6">
        <li className="pb-2">
          {
            "Every year you leave your money idle, you're effectively losing money because of inflation"
          }
        </li>

        <li className="pb-2">
          {
            "Unless you're earning a fat salary, it can be hard to build your finances without investing"
          }
        </li>

        <li className="pb-2">
          {
            "You don't need thousands of dollars to start investing; even S$500 a  month is enough"
          }
        </li>

        <li className="pb-2">
          By starting early, your investments will grow larger than someone who
          starts later with a larger capital
        </li>
      </ul>

      <div className="flex items-center justify-end gap-1 py-4">
        <p className="m-0 p-0">{"Share this story"} </p>

        <TwitterShareButton url={shareUrl}>
          <img
            src={telegramIcon}
            width={36}
            height={36}
            alt="share on twitter"
          />
        </TwitterShareButton>

        <LinkedinShareButton url={shareUrl}>
          <img
            src={linkedinIcon}
            width={36}
            height={36}
            alt="share on linkedin"
          />
        </LinkedinShareButton>

        <FacebookShareButton url={shareUrl}>
          <img
            src={facebookIcon}
            width={36}
            height={36}
            alt="share on facebook"
          />
        </FacebookShareButton>
      </div>
    </Container>
  );
}
