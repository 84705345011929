/**
 * Company: SPHMedia
 * Description: Lifestyle Section Layout
 */

import { ReactElement } from "react";
import { Await } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { LifestyleContext } from "@app/types/Page";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import { CardVariations } from "@components/ArticleCard/types";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import CustomError from "@components/Error/CustomError";
import GAData from "@components/GAData/GAData";
// Components import
import Header from "@components/Header/Header";
import {
  useBreadCrumbState,
  useCategoriesFromPath,
} from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import BtLuxeCarousel from "@components/Slider/BtLuxeSlider";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import LifestyleHero from "@pages/Section/components/Lifestyle/LifestyleHero";
import LifestyleNews from "@pages/Section/components/Lifestyle/LifestyleNews";
import SectionDivider from "@pages/Section/components/Lifestyle/SectionDivider";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { useContext } from "@util/getContext";
import { GoogleAdsSlotFactory } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

/**
 * Lifestyle
 * Description: Lifestyle Section Layout
 * @param {SectionProps} props
 * @returns {SectionContext}
 */
export type SectionProps = {
  context: LifestyleContext;
};

export default function LifestylePage({
  testId,
}: {
  testId?: string;
}): ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const context: LifestyleContext = useContext();
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });
  const { parentCategory, childCategory } = useCategoriesFromPath();

  const variant: CardVariations = {
    image: {
      position: "left",
      width: "w-full lg:w-1/2",
      extraClass: "object-cover",
      filters: [
        { breakpoint: "(min-width: 992px)", w: 588, h: 392, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 696, h: 464, dpr: 1, f: "webp" },
        { w: 401, h: 267, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: "w-full lg:w-1/2",
      extraClass: "p-4 bg-gray-850 text-white",
    },
    title: {
      size: "text-xl lg:text-6xl",
      color: "text-white",
      extraClass: "text-left font-pt-serif",
    },
    kicker: {
      color: "text-gray-515",
      extraClass: "uppercase",
    },
  };

  const adBlockDetected = useDetectAdBlock();
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  return (
    <Await resolve={context} errorElement={<CustomError />}>
      {({ kind, error, data: { title, lifestyle, lifestyleCarousel } }) => {
        return kind !== "section" || error ? (
          <h1>Error</h1>
        ) : (
          <div className="w-full" data-testid={testId}>
            <MetaTags
              title={title}
              description="THE BUSINESS TIMES Lifestyle - Find Lifestyle News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
              ogType="article"
              slug={RouteFactory.lifestyle}
            />

            <GAData
              chapter1={childCategory?.replaceAll("-", " ") || ""}
              level2={parentCategory?.replaceAll("-", " ") || ""}
              title={title}
              adblocker={adBlockDetected ? 1 : 0}
            />

            <Advertisement
              rootClassName="min-h-[50px] lg:min-h-[250px] bg-gray-125 py-1 md:py-3 border-b border-gray-175"
              adUnitProps={{
                type: AdvertisementTypeEnum.LB1,
                slot: GoogleAdsSlotFactory.lb1(parentCategory),
                adsClassName:
                  "min-h-[50px] lg:min-h-[250px] flex flex-col justify-center items-center",
              }}
            />

            <PrestitialAdWrapper
              slot={GoogleAdsSlotFactory.prestitial(parentCategory)}
              {...{
                isPrestitialEnabled,
                topOverlayImpressions,
                topOverlayValidity,
              }}
            />

            <Header
              displayBreadcrumb={
                parentCategory &&
                sectionNavigationItems?.[parentCategory] &&
                isShowBreadCrumb
                  ? true
                  : false
              }
              parentCategory={parentCategory}
              childCategory={childCategory}
              categoryDataMapping={sectionNavigationItems}
            />

            {parentCategory && sectionNavigationItems?.[parentCategory] ? (
              <div className="flex-none">
                <Container>
                  <h1 className="pb-3 pt-6 font-playfair text-6xl font-semibold uppercase text-gray-850 md:text-8xl">
                    Lifestyle
                  </h1>
                  <div ref={breadcrumbsRef}>
                    <Breadcrumb
                      className="container m-auto"
                      parentCategory={parentCategory}
                      childCategory={childCategory}
                      categoryDataMapping={sectionNavigationItems}
                    />
                  </div>
                </Container>
              </div>
            ) : null}

            {lifestyle[0] ? (
              <LifestyleHero
                article={lifestyle[0]}
                defaultImage={{
                  directoryName: lifestyle[0].sections?.[0]?.uniqueName,
                }}
              />
            ) : null}

            {lifestyle.length ? <SectionDivider /> : null}

            <div className="container px-4">
              <LifestyleNews
                data={lifestyle.slice(1)}
                parentCategory={parentCategory}
                childCategory={childCategory}
              />

              <div className="flex-1 overflow-hidden">
                <BtLuxeCarousel
                  data={lifestyleCarousel}
                  testid="lifestyle-carousel-list"
                  variations={variant}
                  dotsCustomClass="lifestyle-carousel-dots"
                  btLuxeClass="self-center lg:self-start"
                  disablePaywall={
                    OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
                  }
                />
              </div>
            </div>

            <Advertisement
              adUnitProps={{
                type: AdvertisementTypeEnum.CATFISH,
                slot: GoogleAdsSlotFactory.catfish(parentCategory),
              }}
            />

            <Advertisement
              adUnitProps={{
                type: AdvertisementTypeEnum.ABM,
                slot: GoogleAdsSlotFactory.abm(),
              }}
            />
          </div>
        );
      }}
    </Await>
  );
}
