import { FormEvent, useState } from "react";
import { TestIdClassNameProps } from "@app/types/Common";
import Button from "@components/AccessibleComponents/Button";
import TextInput from "@components/AccessibleComponents/TextInput";
import NewsletterSuccessPanel from "@components/Newsletter/NewsletterSuccessPanel";
import { EmarsysResponse } from "@components/Newsletter/types/Emarsys";
import { Newsletter } from "@components/Newsletter/types/Newsletter";
import { faClose, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newsletterRequests } from "@pages/Newsletter/SignUp/Newsletter.server";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";
import { BigMoneyNewsletter } from "@src/app/util/constant";
import cx from "classnames";

export type NewsletterSubscriptionPanelProps = TestIdClassNameProps & {
  selectedNewsletters: Newsletter[];
  handleSelectedNewsletter: (
    action: "add" | "remove" | "reset",
    newsletters?: Newsletter
  ) => void;
  email: string;
  setEmail: (email: string) => void;
};

export default function NewsletterSubscriptionPanel({
  rootClassName,
  selectedNewsletters,
  handleSelectedNewsletter,
  email,
  setEmail,
}: NewsletterSubscriptionPanelProps): React.ReactElement | null {
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();
  const [errorMessage, setErrorMessage] = useState("");
  const [subscriptionUrl, setSubscriptionUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);
    const target = event.target as typeof event.target & {
      email: { value: string };
    };

    const request = async () => {
      setEmail(target.email.value);
      // For Premium Newsletter subscriber check if user.
      // Enter BT Account ID.
      if (
        selectedNewsletters?.[0]?.emarsysFieldId ==
          BigMoneyNewsletter.emarsysId &&
        target.email.value !== OktaUserInfo?.loginid
      ) {
        setErrorMessage(BigMoneyNewsletter.premiumEmailValidation);
        setSubscriptionUrl(BigMoneyNewsletter.subscriptionURl);
        setLoading(false);
      } else {
        setSubscriptionUrl("");
        await newsletterRequests
          .processSubscription(target.email.value, selectedNewsletters)
          .then((response) => {
            if (response.status === "error") setErrorMessage(response.message);
            setEmarsysResponse(response);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    };

    request();
  };

  return (
    <div
      className={cx(
        rootClassName,
        "fixed bottom-0 left-0 right-0 z-50 border-t-[3px] border-black bg-white"
      )}
      data-testid="newsletter-subscription-panel"
    >
      {emarsysResponse?.status !== "success" ? (
        <div
          className={cx(
            "container flex flex-col gap-6 p-6",
            "md:flex-row",
            "lg:px-3"
          )}
        >
          <div
            className={cx(
              "flex w-full flex-wrap items-center gap-2",
              "md:w-1/2"
            )}
          >
            <h3 className="mr-4 font-poppins text-lg font-semibold tracking-normal">
              YOUR SELECTION
            </h3>

            {selectedNewsletters.map((newsletter) => {
              const { type, label } = newsletter;

              return (
                <div
                  key={type}
                  className={cx(
                    "flex items-center gap-3 bg-gray-850 px-2 py-1 font-poppins text-xs font-normal text-white"
                  )}
                >
                  {label}
                  <Button
                    className="text-white opacity-50 transition-opacity hover:opacity-100"
                    onPress={() => {
                      handleSelectedNewsletter("remove", newsletter);
                    }}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                </div>
              );
            })}
          </div>

          <div className={cx("w-full md:w-1/2")}>
            <form
              className={cx(
                "ml-auto flex w-full flex-row items-center justify-between border-b pb-1 lg:w-10/12",
                errorMessage ? "border-red" : "border-gray-850"
              )}
              onSubmit={handleSubmit}
            >
              <TextInput
                className={cx(
                  "w-7/12 font-poppins outline-none placeholder:text-gray-600 lg:w-9/12",
                  errorMessage ? "text-red" : "text-gray-850"
                )}
                name="email"
                type="email"
                inputMode="email"
                placeholder="Your email address"
                defaultValue={email}
                required
              />

              <Button
                className="rounded-pill w-5/12 bg-gray-850 px-4 py-2 font-poppins text-base font-normal text-white lg:w-3/12"
                type="submit"
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Sign Up"
                )}
              </Button>
            </form>

            {errorMessage ? (
              <div className="mt-1 w-10/12 ps-0 font-poppins text-xs font-semibold text-red lg:ml-auto">
                {errorMessage}
                {subscriptionUrl ? (
                  <a
                    className="underline"
                    target="_blank"
                    href={subscriptionUrl}
                    rel="noreferrer"
                  >
                    here.
                  </a>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <NewsletterSuccessPanel
          message={emarsysResponse.message}
          handleSelectedNewsletter={handleSelectedNewsletter}
          setEmarsysResponse={setEmarsysResponse}
        />
      )}
    </div>
  );
}
