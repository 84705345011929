import { Tag } from "@app/types/Cue";
import brandedPodcastThumbnail from "@assets/podcasts_banner/branded-podcasts.svg";
import btCorrespondentsThumbnail from "@assets/podcasts_banner/bt-correspondents.svg";
import lensOnThumbnail from "@assets/podcasts_banner/lens-on.svg";
import marketFocusThumbnail from "@assets/podcasts_banner/market-focus.svg";
import moneyHacksThumbnail from "@assets/podcasts_banner/money-hacks.svg";

export type PodcastKeywordData = {
  id:
    | "market_focus"
    | "money_hacks"
    | "lens_on"
    | "wealthbt"
    | "propertybt"
    | "mark_to_market"
    | "branded_podcasts"
    | "bt_correspondents";
  label: string;
  spotify_link: string;
  apple_link: string;
  channel_link: string;
  youtube_link: string;
  tag: Tag;
  description: string;
  thumbnailSrc: string;
};

export const PODCAST_KEYWORDS_DATA: PodcastKeywordData[] = [
  {
    id: "lens_on",
    label: "Lens On",
    spotify_link: "https://bt.sg/lenssp",
    apple_link: "https://bt.sg/lensap",
    channel_link: "https://bt.sg/btlenson",
    youtube_link: "https://bt.sg/lensyt",
    tag: { name: "Lens On", urlPath: "/keywords/lens-on" } as Tag,
    description:
      "Catch up on the biggest news from around the world at the start of every business day with Lens on Daily. In Lens on Singapore, we take a monthly big picture look at current affairs, societal issues and government policies.",
    thumbnailSrc: lensOnThumbnail,
  },
  {
    id: "money_hacks",
    label: "Money Hacks",
    spotify_link: "https://bt.sg/oeGN",
    apple_link: "https://bt.sg/oeXe",
    channel_link: "https://bt.sg/btmoneyhacks",
    youtube_link: "https://bt.sg/mhyt",
    tag: { name: "Money Hacks", urlPath: "/keywords/money-hacks" } as Tag,
    description:
      "Every Monday, host Howie Lim breaks down actionable financial tips and expert opinions on the latest investment opportunities.",
    thumbnailSrc: moneyHacksThumbnail,
  },
  {
    id: "market_focus",
    label: "Market Focus",
    spotify_link: "https://bt.sg/mfsp",
    apple_link: "https://bt.sg/mfap",
    channel_link: "https://bt.sg/btmktfocus",
    youtube_link: "https://bt.sg/mfyt",
    tag: { name: "Market Focus", urlPath: "/keywords/market-focus" } as Tag,
    description:
      "Market Focus is a daily closing bell look at the markets and the news that drove its movements.",
    thumbnailSrc: marketFocusThumbnail,
  },

  {
    id: "bt_correspondents",
    label: "BT Correspondents",
    spotify_link: "https://bt.sg/btcosp",
    apple_link: "https://bt.sg/btcoap",
    channel_link: "https://bt.sg/ggYh",
    youtube_link: "https://bt.sg/btcoyt",
    tag: {
      name: "BT Correspondents",
      urlPath: "/keywords/bt-correspondents",
    } as Tag,
    description:
      "Every Tuesday, insights and analysis in market trends, property, wealth and mobility. Powered by The Business Times journalism of Genevieve Cua, Leslie Yee, Ben Paul and Derryn Wong.",
    thumbnailSrc: btCorrespondentsThumbnail,
  },
  {
    id: "branded_podcasts",
    label: "Branded Podcasts",
    spotify_link: "https://bt.sg/bpsp",
    apple_link: "https://bt.sg/bpap",
    channel_link: "https://bt.sg/btbrpod",
    youtube_link: "https://bt.sg/bryt",
    tag: {
      name: "Branded Podcasts",
      urlPath: "/keywords/branded-podcasts",
    } as Tag,
    description:
      "Finely curated, intelligent, thought-provoking branded content for decision-makers. Backed by journalism from The Business Times.",
    thumbnailSrc: brandedPodcastThumbnail,
  },
];
