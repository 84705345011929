import { Link } from "react-router-dom";
import thriveMeetTheThrivers from "@assets/thrive_images/thrive-meet-the-thrivers.png";
import Container from "@components/Container/Container";
import { linksInHomeAboutUs } from "@pages/Thrive/constants";
import { cn } from "@util/helpers";

export default function ThriveMiniAboutUs(): React.ReactElement {
  return (
    <div
      className="bg-white font-inter text-gray-850"
      data-testid="thrive-mini-about-us-component"
    >
      <Container rootClassName="lg:p-20 lg:px-10 py-10 px-6 pb-12 font-inter">
        <div className={cn("gap-10 lg:flex")}>
          <div className="lg:w-2/5">
            <img
              src={thriveMeetTheThrivers}
              alt="meet the thrivers"
              width={450}
              height={397}
            />
          </div>

          <div className="lg:w-3/5">
            <h2 className="mb-2 mt-6 font-inter">
              About us<span className="text-green-400">.</span>
            </h2>

            <p className="text-lg">
              {
                "Hello there! We are a bunch of (relatively) young people creating content for other young people on career, money and life. We aim to build a community where we can discuss issues that really matter. Adulting's tough, but you don't have to do it alone. Thrive is owned by The Business Times, part of SPH Media."
              }
            </p>

            <div className="h-full">
              <Link
                to={linksInHomeAboutUs.aboutUs.to}
                reloadDocument
                className="border border-orange-400 px-4 py-3 font-interSemibold uppercase hover:bg-orange-400 hover:text-white"
              >
                {linksInHomeAboutUs.aboutUs.label}
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
