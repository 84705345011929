import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { cn } from "@util/helpers";

export type ArticleSubshareModalProps = {
  children: React.ReactNode;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isContentCentered?: boolean;
};

export default function ArticleSubshareModal({
  children,
  isModalOpen,
  setIsModalOpen,
  isContentCentered = true,
}: ArticleSubshareModalProps) {
  return (
    <Transition show={isModalOpen}>
      <Dialog
        className="relative z-30"
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <TransitionChild
          enter="transition duration-150 linear"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-150 linear delay-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        </TransitionChild>

        {/* Full-screen container to center the panel */}
        <div
          className={cn("fixed inset-0 mt-8", {
            "flex w-screen items-end justify-center md:items-center":
              isContentCentered,
          })}
        >
          <TransitionChild
            enter="transition duration-300 ease-out delay-150"
            enterFrom="opacity-0 -translate-y-12"
            enterTo="opacity-100 translate-y-0"
            leave="transition duration-300 ease-out"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-12"
          >
            <DialogPanel
              className={cn(
                "relative w-full rounded-t-3xl bg-white text-center",
                "md:w-[25rem] md:rounded-none",
                {
                  "fixed bottom-0 left-0 right-0 overflow-hidden rounded-t-3xl bg-white md:bottom-8 md:left-[unset] md:right-8 md:rounded-none":
                    !isContentCentered,
                }
              )}
            >
              {children}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
