import { Field } from "@app/types/Cue";
import { Name, Type } from "@app/types/enums";
import BrightcoveVideoPlayer from "@components/Player/BrightcoveVideoPlayer";
import useBrightcovePlayerId from "@hooks/useBrightcovePlayerId";
import { BT_BRIGHTCOVE_ACCOUNT_ID } from "@util/constant";
import { getFieldValue } from "@util/helpers";

export type BrightcoveVideoProps = {
  fields: Field[];
  className?: string;
};

export function BrightcoveVideo({
  className,
  fields,
}: BrightcoveVideoProps): React.ReactElement {
  const id = getFieldValue(fields, Name.BrightcoveId);
  const playerId = useBrightcovePlayerId();

  return (
    <>
      {typeof id == "string" ? (
        <div
          className={className}
          data-story-element={Type.BrightcoveVideo}
          data-testid="brightcove-video-component"
        >
          {typeof playerId !== "undefined" ? (
            <BrightcoveVideoPlayer
              videoId={id}
              accountId={BT_BRIGHTCOVE_ACCOUNT_ID}
              playerId={playerId}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
}
