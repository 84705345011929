import { MessageFactory } from "@pages/Article/components/ArticleSubshare/helpers";
import { LambdaResponsePayload } from "@pages/Article/types/Alacrity";

export type MessagesProps = LambdaResponsePayload;

export default function Messages({
  data: { responseType, giftCount },
}: MessagesProps): React.ReactElement {
  const Message = MessageFactory(responseType);

  return <>{Message ? <Message {...{ giftCount }} /> : null}</>;
}
