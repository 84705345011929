import { Await } from "react-router-dom";
import { MyBTOnboardContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import MyBTOnboardPageContent from "./MyBTOnboardPageContent";

export default function MyBTOnboardPage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<MyBTOnboardContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }: MyBTOnboardContext) => {
        if (kind === "static") {
          return <MyBTOnboardPageContent data={data} />;
        }

        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}
