import { cardAssets } from "@pages/Thrive/helpers";
import { cn } from "@util/helpers";

import { converstionCardDetails } from "./constants";

export default function ThriveIndividualConversationCard(): React.ReactElement {
  return (
    <div
      className="grid-cols-3 gap-16 text-gray-850 lg:grid"
      data-testid="thrive-home-conversation-card-component"
    >
      {converstionCardDetails.map((details, index) => {
        return (
          <div
            key={`card-${details.imgName}`}
            className={cn("group relative my-16 lg:z-10 lg:m-0", {
              "z-10": index === converstionCardDetails.length - 1,
            })}
          >
            <p className="absolute -top-10 right-0 text-7xl lg:left-full lg:top-0 lg:-rotate-180 lg:[writing-mode:tb-rl]">
              {details.title}
              <span className="text-green-400">.</span>
            </p>

            <div className="h-full border border-black bg-white px-6 py-4 group-hover:shadow-thriveNewsletterHover">
              <img
                src={cardAssets(details.imgName)}
                className="mx-auto"
                alt={details.imgName}
              />

              <p className="text-lg">{details.text}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
