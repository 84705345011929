export const converstionCardDetails = [
  {
    imgName: "career",
    text: "Navigate working life and get first dibs on exclusive networking events.",
    title: "CAREER",
  },
  {
    imgName: "money",
    text: "No-frills content on personal finance and investing.",
    title: "MONEY",
  },
  {
    imgName: "self",
    text: "Join in conversations on growing, living, and adulting.",
    title: "LIFE",
  },
];
