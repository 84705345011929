/**
 * Company: SPHMedia
 * Description: Component for displaying See Also section in a article page
 */

import { ReactElement } from "react";
import { TestIdClassNameProps } from "@app/types/Common";
import { seeAlso } from "@app/types/Cue";
import ArticleTitleLink from "@components/ArticleMeta/TitleLink";

export type ArticleSeeAlsoProps = TestIdClassNameProps & seeAlso;

/**
 * ArticleSeeAlso
 * Description: Component for displaying See Also section in a article
 * @param props - The props required by the component
 * @returns The rendered React element
 */
export default function ArticleSeeAlso({
  title,
  articleId,
  slug,
}: ArticleSeeAlsoProps): ReactElement {
  return (
    <div
      className="border-t border-gray-250 p-4"
      data-testid="article-see-also-component"
    >
      <h5 className="mb-1 font-public-sans text-3xs font-light">SEE ALSO</h5>

      <ArticleTitleLink
        rootClassName="text-base"
        linkRootClassName="!text-gray-850"
        title={title}
        articleId={articleId}
        slug={slug}
      />
    </div>
  );
}
