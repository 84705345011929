import {
  defer,
  LoaderFunction,
  useLoaderData,
  useRouteLoaderData,
} from "react-router-dom";
import { SearchResult } from "@src/app/types/Search";
import { isEmpty } from "lodash-es";

import { Context } from "../types/Page";

type CustomContext = Record<string, unknown> & {
  context?: Promise<Context>;
  defaultSearchResults: Promise<Array<SearchResult>>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isCustomContext = (x: any): x is CustomContext =>
  "context" in x || "defaultSearchResults" in x;

export const useContext = () => {
  // Client-side loader data (eg, context.json)
  let loaderData = useLoaderData();
  const customLoaderDataToInject = useRouteLoaderData("1");

  if (loaderData && !isEmpty(loaderData)) {
    // If loader data has somehow not been parsed, parse it
    // This can happen if there's no on-page context and the data fetcher
    // falls back to context.json
    if (typeof loaderData === "string") {
      loaderData = JSON.parse(loaderData) as CustomContext;
    }

    if (isCustomContext(loaderData)) {
      if ("context" in loaderData) {
        loaderData = loaderData.context;
      }
    }

    return loaderData;
  }

  // If no client-side loader data, try getting loader data injected into page.
  // This data should be escaped manually with encodeURIComponent before insertion to the page.
  try {
    return JSON.parse(decodeURIComponent(customLoaderDataToInject as string));
  } catch (err) {
    throw new Error("Failed to get context from page");
  }
};

export const fetchContext: LoaderFunction = async (args) => {
  // remove query and hash in case they are brought within the request url e.g. ?xxx=xxx #xxx
  const formattedUrl = args.request.url.split(/[?#]/)[0];

  const contextUrl = formattedUrl.endsWith("/")
    ? `${formattedUrl}context.json`
    : `${formattedUrl}/context.json`;

  return defer({
    //TODO: To disable cache via CDN in the next Sprint.
    context: await fetch(contextUrl, { cache: "no-store" }).then(
      async (response: Response) => {
        return response.json();
      }
    ),
  });
};
