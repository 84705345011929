import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NewsletterSampleFooter(): React.ReactElement {
  return (
    <div className="mx-auto mb-20 w-full bg-gray-850 py-12 text-center">
      <div className="px-5 py-12">
        <a
          href="https://www.tiktok.com/@thrivebt"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-7 inline-block border-2 border-orange-400 bg-white px-5 py-4 font-semibold text-gray-850 no-underline sm:mr-0"
        >
          <FontAwesomeIcon icon={faTiktok} />
          WE&apos;RE HERE
        </a>
        <a
          href="https://www.instagram.com/thrive_bt/"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-2 inline-block border-2 border-orange-400 bg-white px-5 py-4 font-semibold text-gray-850 no-underline"
        >
          <FontAwesomeIcon icon={faInstagram} />
          AND HERE
        </a>
      </div>
      <div className="px-20 py-2">
        <p className="mb-2 text-xs leading-5 text-white">
          Everything in this newsletter is for your general information only.
          Consult a qualified financial professional or expert for independent
          advice and verification.
        </p>
      </div>
      <div className="px-6 py-2">
        <p className="mb-2 text-xs leading-5 text-white">
          Was this email forwarded to you?{" "}
          <a
            href="https://www.businesstimes.com.sg/thrive"
            className="text-white underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sign up here.
          </a>
        </p>
      </div>
      <div className="px-5 py-2">
        <p className="mb-2 text-xs leading-5 text-white">
          <a
            href="https://www.businesstimes.com.sg/bt_newsletter/unsubscribe?edition=60845"
            className="text-white underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Unsubscribe
          </a>{" "}
          from Thrive publications.
        </p>
      </div>
      <div className="py-5">
        <p className="mb-2 text-base font-bold leading-7 text-white">
          THRIVE by THE BUSINESS TIMES.
        </p>
        <p className="font-dm-sans mb-0 text-xs leading-5 text-white">
          Copyright © 2023 SPH Media Limited. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
