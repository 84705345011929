import { PropsWithChildren, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type DelayedComponentsProps = PropsWithChildren & {
  waitBeforeShow?: number;
  urlPath?: string;
};

const DelayedComponentsLocationChange = ({
  children,
  waitBeforeShow = 500,
  urlPath,
}: DelayedComponentsProps) => {
  const [isShown, setIsShown] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === urlPath) {
      const timer = setTimeout(() => {
        setIsShown(true);
      }, waitBeforeShow);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [location, waitBeforeShow, urlPath]);

  return isShown ? children : null;
};

export default DelayedComponentsLocationChange;
