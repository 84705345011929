import { useEffect, useState } from "react";
import { getTimeSince } from "@src/app/util/helpers";
import cx from "classnames";
import dayjs from "dayjs";

export type UpdateTimeProps = {
  rootClassName?: string;
  updated?: string;
  edited?: string;
};

/**
 * UpdateTime
 * Description: The update time of the article
 * @param {UpdateTimeProps} props
 * @returns {ReactElement}
 */
export default function UpdateTime({
  rootClassName = "",
  updated = "",
  edited = "",
}: UpdateTimeProps): React.ReactElement {
  const [timeSinceUpdate, setTimeSinceUpdate] = useState("");
  const [displayUpdateTime, setDisplayUpdateTime] = useState(false);
  const hasArticleBeenUpdated = updated < edited;

  useEffect(() => {
    const isLessThanOneDay = dayjs().diff(edited, "hours") <= 23;
    setDisplayUpdateTime(hasArticleBeenUpdated && isLessThanOneDay);
    setTimeSinceUpdate(getTimeSince(edited));
  }, [edited, updated, hasArticleBeenUpdated]);

  return (
    <>
      {displayUpdateTime ? (
        <div
          data-testid="update-time-component"
          className={cx(
            "truncate font-poppins text-7xs uppercase",
            rootClassName
          )}
        >
          UPDATED {timeSinceUpdate}
        </div>
      ) : null}
    </>
  );
}
