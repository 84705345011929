import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { TestIdClassNameProps } from "@app/types/Common";
import Button from "@components/AccessibleComponents/Button";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@util/helpers";

export type NewsletterFormTriggerProps = TestIdClassNameProps & {
  setShowForm: (show: boolean) => void;
  showViewAll?: boolean;
  displayNewVersionNewsletter?: boolean;
  commonButtonCss?: string;
};

export const NewsletterFormTrigger = ({
  testId,
  setShowForm,
  showViewAll = false,
  displayNewVersionNewsletter = false,
  commonButtonCss,
}: NewsletterFormTriggerProps) => {
  return (
    <div className="relative" data-testid={testId}>
      <Button
        className={cn(
          "rounded-full bg-gray-850 px-8 py-1.5 text-center text-base text-white hover:bg-gray-675",
          commonButtonCss,
          {
            "w-full min-w-[155px] bg-transparent px-0 py-0 hover:bg-transparent md:max-w-[155px]":
              displayNewVersionNewsletter,
          }
        )}
        onPress={() => {
          setShowForm(true);
        }}
        data-size="md"
      >
        Sign Up
      </Button>

      {showViewAll ? (
        <Link
          to={RouteFactory.newsletterSignup}
          className="whitespace-nowrap pl-4 text-base font-semibold !text-gray-850 hover:!no-underline"
          target="_blank"
          reloadDocument
        >
          VIEW ALL
          <FontAwesomeIcon icon={faChevronRight} size="xs" className="ml-1" />
        </Link>
      ) : null}
    </div>
  );
};
