import { Link } from "react-router-dom";
import telegramIcon from "@assets/icon-telegram.svg";

export default function TelegramBanner(): React.ReactElement {
  return (
    <div
      className="border-t border-solid border-gray-850 py-6 font-poppins"
      data-testid="telegram-banner-component"
    >
      <div className="flex items-start">
        <div className="w-1/6 text-center">
          <Link
            to="https://t.me/BizTimes"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="mr-1 inline-block align-middle md:mr-2"
              aria-hidden="true"
              data-prefix="fas"
              data-icon="envelope"
              role="img"
              width="51"
              height="51"
              src={telegramIcon}
            />
          </Link>
        </div>
        <div className="ml-1 w-5/6 flex-1">
          <p className="mb-0 font-bold">BT is now on Telegram!</p>
          <p className="mb-0">
            For daily updates on weekdays and specially selected content for the
            weekend. Subscribe to &nbsp;
            <Link
              to="https://t.me/BizTimes"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              t.me/BizTimes
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
