import iconBigPdf from "@assets/icon-big-pdf.svg";
import logoBT from "@assets/logo-bt-mobile-black.svg";

export default function TodaysMarketData(): React.ReactElement {
  return (
    <div className="mb-5 bg-gray-250 p-4">
      <div className="flex items-start gap-x-6">
        <img
          src={logoBT}
          alt="The Business Times"
          width={40}
          height={28}
          className="mt-1"
        />

        <h3 className="mb-3 font-poppins text-4xl font-normal tracking-[1px]">
          <a
            className="text-black hover:no-underline"
            href="https://static1.businesstimes.com.sg/s3fs-public/wealth/2023/07/13/bt_markets_data_130723_combined.pdf"
          >
            {"DOWNLOAD TODAY'S MARKET DATA"}
          </a>
        </h3>
      </div>
      <img
        src={iconBigPdf}
        alt="Download Today's Market Data"
        width={56}
        height={56}
        className="mx-auto block"
      />
    </div>
  );
}
