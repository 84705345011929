import Button from "@components/AccessibleComponents/Button";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LambdaResponsePayload } from "@pages/Article/types/Alacrity";

import ArticleSubshareGifterActions from "./Modal/Actions/Actions";
import ArticleSubshareGifterHeader from "./Modal/Header";
import ArticleSubshareGifterIcon from "./Modal/Icon";
import ArticleSubshareGifterMessage from "./Modal/Messages/Messages";
import ArticleSubshareGifterSocials from "./Modal/Socials";

export type ArticleSubshareSentContentProps = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  alacrityRes: LambdaResponsePayload;
};

export default function ArticleSubshareSentContent({
  setIsModalOpen,
  alacrityRes,
}: ArticleSubshareSentContentProps) {
  return (
    <>
      <Button
        className="absolute right-4 top-4 outline-none"
        onPress={() => {
          setIsModalOpen(false);
        }}
      >
        <FontAwesomeIcon icon={faXmark} size="xl" />
      </Button>

      <div className="flex flex-col items-center p-8">
        <ArticleSubshareGifterIcon {...alacrityRes} />

        <ArticleSubshareGifterHeader {...alacrityRes} />

        <ArticleSubshareGifterMessage {...alacrityRes} />

        <ArticleSubshareGifterSocials {...alacrityRes} />

        <ArticleSubshareGifterActions
          {...alacrityRes}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
    </>
  );
}
