import { Helmet } from "react-helmet-async";
import btLogo from "@assets/bt-logo-white-mobile.svg";
import { dayjsSingaporeTimezone } from "@util/constant";
import dayjs from "dayjs";

import {
  ANDRIOD_APP_NAME,
  ANDRIOD_PACKAGE,
  FB_ADMINS,
  FB_APP_ID,
  FB_PAGES,
  FORMAT_DETECTION,
  GOOGLE_SITE_VERIFICATION,
  IOS_APP_NAME,
  IOS_APP_STORE_ID,
  OG_IMAGE_HEIGHT,
  OG_IMAGE_WIDTH,
  OG_SITE_NAME,
  READ_MORE_AT_BT,
  TWITTER_APP_COUNTRY,
  TWITTER_APP_ID_GOOGLEPLAY,
  TWITTER_APP_ID_IPAD,
  TWITTER_APP_ID_IPHONE,
  TWITTER_CARD,
  TWITTER_SITE,
  URL_ORIGIN,
  URL_ORIGIN_ALTERNATE,
  URL_ORIGIN_TWITTER,
} from "./constants";

export type MetaTagsProps = {
  title: string;
  author?: string;
  description: string;
  keywords?: string[];
  slug: string;
  shortLink?: string;
  canonicalUrl?: string;
  ogType?: string;
  articlePageclass?: string;
  imageUrl?: string;
  imageAlt?: string;
  robots?: string;
  jsonLd?: object;
  ogTitle?: string;
  gsChannels?: string;
  articleId?: string;
  articleCategory?: string;
  accessFlag?: string;
  publishedDate?: string;
  updatedDate?: string;
  created?: string;
  edited?: string;
};

export default function MetaTags({
  title,
  author,
  description,
  keywords = [],
  slug = "",
  shortLink,
  canonicalUrl,
  ogType = "article",
  articlePageclass = "article",
  imageUrl = `${URL_ORIGIN}${btLogo}`,
  imageAlt = "",
  robots,
  jsonLd,
  ogTitle,
  gsChannels = "no_analysis",
  articleId,
  articleCategory,
  accessFlag,
  publishedDate,
  updatedDate,
}: MetaTagsProps) {
  const alternateUrl: string = `${URL_ORIGIN_ALTERNATE}${slug}`;
  const twitterAppUrl: string = `${URL_ORIGIN_TWITTER}${slug}`;
  const mobileUrl: string = `${URL_ORIGIN}${slug}`;
  canonicalUrl = canonicalUrl ? canonicalUrl : `${URL_ORIGIN}${slug}`;
  shortLink = shortLink ? `${URL_ORIGIN}${shortLink}` : "";
  const articleDescription = description.trim();

  return (
    <Helmet>
      <title>{title}</title>
      {articleDescription ? (
        <meta name="description" content={articleDescription} />
      ) : null}

      {/* Search Engine tags */}
      {robots ? <meta name="robots" content={robots} /> : null}
      <link rel="alternate" href={alternateUrl} />
      <link rel="canonical" href={canonicalUrl} />
      {shortLink ? <link rel="shortlink" href={shortLink} /> : null}
      {articleId ? <meta name="article:title" content={title} /> : null}
      {articleId && articleDescription ? (
        <meta name="article:description" content={articleDescription} />
      ) : null}

      {articleId ? (
        <meta
          name="article:published_time"
          content={dayjs(publishedDate).tz(dayjsSingaporeTimezone).format()}
        />
      ) : null}
      {articleId ? (
        <meta name="created" content={dayjs(publishedDate).format("X")} />
      ) : null}
      {articleId ? (
        <meta
          name="article:modified_time"
          content={dayjs(updatedDate).tz(dayjsSingaporeTimezone).format()}
        />
      ) : null}
      {articleId ? (
        <meta name="updated" content={dayjs(updatedDate).format("X")} />
      ) : null}
      {articleId ? <meta name="article:articleid" content={articleId} /> : null}
      {articleId ? <meta name="articleid" content={articleId} /> : null}
      {articleId ? <meta name="article_id" content={articleId} /> : null}
      {articleId && keywords.length > 0 && (
        <meta name="article_tags" content={keywords.join(",").toLowerCase()} />
      )}
      {articleId && keywords.length > 0 && (
        <meta name="article:sph-keywords" content={keywords.join(",")} />
      )}
      {articleId && author ? (
        <meta name="article:author" content={author} />
      ) : null}
      {articleId && author ? <meta name="author" content={author} /> : null}
      {articleId ? (
        <meta name="article:sph-accessflag" content={accessFlag}></meta>
      ) : null}
      {articleId ? (
        <meta name="article:pageclass" content={articlePageclass} />
      ) : null}
      <meta
        name="google-site-verification"
        content={GOOGLE_SITE_VERIFICATION}
      />
      <meta name="format-detection" content={FORMAT_DETECTION} />
      {articleId ? <meta name="article:category_id" content="" /> : null}
      {articleId && articleCategory ? (
        <meta name="article:category" content={articleCategory} />
      ) : null}

      {/* Open Graph tags */}
      <meta property="og:site_name" content={OG_SITE_NAME} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={ogTitle ? ogTitle : title} />
      {articleId ? (
        <meta
          property="og:description"
          content={`${articleDescription} ${READ_MORE_AT_BT}`}
        />
      ) : null}

      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={OG_IMAGE_WIDTH} />
      <meta property="og:image:height" content={OG_IMAGE_HEIGHT} />
      <meta property="og:image:alt" content={imageAlt} />

      {/* Facebook tags */}
      <meta property="fb:admins" content={FB_ADMINS} />
      <meta property="fb:pages" content={FB_PAGES} />
      <meta property="fb:app_id" content={FB_APP_ID} />

      {/* Twitter tags */}
      <meta property="twitter:card" content={TWITTER_CARD} />
      <meta property="twitter:site" content={TWITTER_SITE} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:url" content={canonicalUrl} />
      {description ? (
        <meta property="twitter:description" content={description} />
      ) : null}
      {imageUrl ? <meta property="twitter:image" content={imageUrl} /> : null}
      {imageAlt ? (
        <meta property="twitter:image:alt" content={imageAlt} />
      ) : null}
      <meta property="twitter:app:country" content={TWITTER_APP_COUNTRY} />
      <meta property="twitter:app:id:iphone" content={TWITTER_APP_ID_IPHONE} />
      <meta property="twitter:app:url:iphone" content={twitterAppUrl} />
      <meta property="twitter:app:id:ipad" content={TWITTER_APP_ID_IPAD} />
      <meta property="twitter:app:url:ipad" content={twitterAppUrl} />
      <meta
        property="twitter:app:id:googleplay"
        content={TWITTER_APP_ID_GOOGLEPLAY}
      />
      <meta property="twitter:app:url:googleplay" content={twitterAppUrl} />

      {/* Mobile tags */}
      <meta property="al:ios:url" content={mobileUrl} />
      <meta property="al:ios:app_store_id" content={IOS_APP_STORE_ID} />
      <meta property="al:ios:app_name" content={IOS_APP_NAME} />
      <meta property="al:android:url" content={mobileUrl} />
      <meta property="al:android:package" content={ANDRIOD_PACKAGE} />
      <meta property="al:android:app_name" content={ANDRIOD_APP_NAME} />
      <meta property="al:web:url" content={mobileUrl} />

      {/* Grapeshot tags */}
      <meta
        name="grapeshot_tag"
        id="grapeshot"
        data-testid="grapeshot"
        content={gsChannels}
      />
      <script>{`var gs_channels = ["${gsChannels}"];`}</script>

      {jsonLd ? (
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      ) : null}
    </Helmet>
  );
}
