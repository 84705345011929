export default function LoadingAnimation(): React.ReactElement {
  return (
    <div
      data-testid="loading-animation-component"
      className="flex h-screen w-screen items-center justify-center gap-4"
    >
      <div className="h-3 w-3 animate-[loading_2.5s_ease-in-out_infinite_100ms] rounded-full bg-white" />
      <div className="h-3 w-3 animate-[loading_2.5s_ease-in-out_infinite_200ms] rounded-full bg-white" />
      <div className="h-3 w-3 animate-[loading_2.5s_ease-in-out_infinite_300ms] rounded-full bg-white" />
      <div className="h-3 w-3 animate-[loading_2.5s_ease-in-out_infinite_400ms] rounded-full bg-white" />
      <div className="h-3 w-3 animate-[loading_2.5s_ease-in-out_infinite_500ms] rounded-full bg-white" />
    </div>
  );
}
