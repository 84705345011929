import { Link } from "react-router-dom";
import { getKeywordLink } from "@components/FollowKeyword/helper";
import type { Tag } from "@src/app/types/Cue";
import cx from "classnames";

export default function KeywordLinks({
  tags = [],
  rootClassName = "",
  tagRootClassName = "",
}: {
  tags: Tag[];
  rootClassName?: string;
  tagRootClassName?: string;
}) {
  return (
    <>
      {tags.length > 0 ? (
        <div
          className={cx(
            "mt-2 flex gap-2 text-3xs text-gray-675",
            rootClassName
          )}
          data-testid="keyword-links"
        >
          {tags.slice(0, 2).map((tag) => (
            <Link
              key={tag.name}
              to={getKeywordLink(tag)}
              className={cx(
                "border border-gray-675 px-2 text-center font-poppins hover:underline",
                tagRootClassName
              )}
              target="_blank"
              rel="noopener"
            >
              {tag.name}
            </Link>
          ))}
        </div>
      ) : null}
    </>
  );
}
