import { VWO_SCRIPT_CONTENT } from "@util/customScripts/vwo";

import useScriptFunction from "./useScriptFunction";

// Custom hook for VWO integration
export default function useVWO() {
  useScriptFunction({
    id: "vwoCode",
    value: VWO_SCRIPT_CONTENT,
  });
}
