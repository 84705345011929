import { useState } from "react";
import { BlockTitle } from "@blocks/Blocks";
import { NEWSLETTER_PAGE_LINK } from "@components/Newsletter/constants";
import HomepageCarouselNewsletterSlide from "@components/Newsletter/HomepageCarouselNewsletter/HomepageCarouselNewsletterSlide";
import NewsletterSubscriptionPanel from "@components/Newsletter/NewsletterSubscriptionPanel";
import {
  BT_NEWSLETTERS,
  Newsletter,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import SplideArrows from "@components/Slider/components/SplideArrows";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@util/helpers";

export type HomepageCarouselNewsletterProps = {
  testId?: string;
  rootClassName?: string;
  blockTitle?: string;
  showSeeMore?: boolean;
  newsletterToSkip?: NEWSLETTER_TYPE;
};

export default function HomepageCarouselNewsletter({
  testId,
  rootClassName,
  blockTitle = "OUR NEWSLETTERS",
  newsletterToSkip,
}: HomepageCarouselNewsletterProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();

  const [email, setEmail] = useState("");
  const [selectedNewsletters, setSelectedNewsletters] = useState<Newsletter[]>(
    []
  );

  const newsletters = isScreenLG ? BT_NEWSLETTERS : BT_NEWSLETTERS.slice(0, 4);

  const options: Options = {
    pagination: false,
    type: "slide",
    interval: 10000,
    gap: "1.5rem",
    focus: 0,
    omitEnd: true,
    mediaQuery: "min",
    arrows: false,
    autoplay: false,
    destroy: true,
    perPage: 2,
    breakpoints: {
      992: { destroy: false, perPage: 4, perMove: 1, arrows: true },
    },
  };

  const handleSelectedNewsletter = (
    action: "remove" | "add" | "reset",
    newsletter?: Newsletter
  ) => {
    // Add newsletter to the selected list.
    if (action === "add" && newsletter) {
      setSelectedNewsletters((selectedNewsletters) => [
        ...selectedNewsletters,
        newsletter,
      ]);
    }

    // Remove newsletter to the selected list.
    if (action === "remove" && newsletter) {
      setSelectedNewsletters((selectedNewsletters) =>
        selectedNewsletters.filter(
          (selectedNewsletter) => selectedNewsletter.type !== newsletter.type
        )
      );
    }

    // Remove newsletter to the selected list.
    if (action === "reset") {
      setSelectedNewsletters([]);
    }
  };

  return (
    <div className={rootClassName} data-testid={testId}>
      <BlockTitle
        link={NEWSLETTER_PAGE_LINK}
        text={blockTitle}
        rootClassName="mb-4"
      />

      <Splide options={options} tag="div" hasTrack={false}>
        <SplideArrows className="absolute -top-12 right-0 flex flex-row items-center justify-center gap-2" />

        <SplideTrack
          className={cn(
            "[&>ul]:relative [&>ul]:!grid [&>ul]:gap-6 [&>ul]:sm:grid-cols-2 [&>ul]:lg:!flex [&>ul]:lg:gap-0",
            "[&>ul>li]:after:absolute [&>ul>li]:after:-bottom-3 [&>ul>li]:after:h-[1px] [&>ul>li]:after:w-full [&>ul>li]:after:bg-gray-250 [&>ul>li]:md:after:hidden"
          )}
        >
          {newsletters
            .filter(
              (newsletter) =>
                newsletter.type !== newsletterToSkip && newsletter.active
            )
            .map((newsletter) => (
              <SplideSlide key={newsletter.type}>
                <HomepageCarouselNewsletterSlide
                  newsletter={newsletter}
                  rootClassName="text-gray-850"
                  handleSelectedNewsletter={handleSelectedNewsletter}
                  subscribed={selectedNewsletters.some(
                    (selectedNewsletter) =>
                      selectedNewsletter.type === newsletter.type
                  )}
                  premiumOnly={newsletter?.premiumOnly}
                />
              </SplideSlide>
            ))}
        </SplideTrack>
      </Splide>

      <>
        {selectedNewsletters.length > 0 ? (
          <NewsletterSubscriptionPanel
            selectedNewsletters={selectedNewsletters}
            handleSelectedNewsletter={handleSelectedNewsletter}
            email={email}
            setEmail={setEmail}
          />
        ) : null}
      </>
    </div>
  );
}
