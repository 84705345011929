import { MouseEventHandler } from "react";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TestIdClassNameProps } from "@src/app/types/Common";
import { gaEventTracker } from "@util/helpers";
import cx from "classnames";

export type SliderArrowProps = TestIdClassNameProps & {
  direction: "left" | "right";
  prevArrowClass?: string;
  nextArrowClass?: string;
  prevArrowIcon?: IconProp;
  nextArrowIcon?: IconProp;
  iconPadding?: number;
  iconSize?: SizeProp;
  opacityWhenDisabled?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
};

export default function SliderArrow({
  testId,
  rootClassName = "absolute top-1/2 z-20 -translate-y-1/2 bg-white transition-opacity",
  prevArrowClass = "left-4",
  nextArrowClass = "right-10",
  prevArrowIcon = faChevronLeft,
  nextArrowIcon = faChevronRight,
  direction = "left",
  iconPadding = 2,
  iconSize = "lg",
  opacityWhenDisabled = "opacity-0",
  onClick,
}: SliderArrowProps): React.ReactElement {
  const handleArrowClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as SVGSVGElement;
    target.dataset.icon === "chevron-right"
      ? gaEventTracker("mybt", "click", "right arrow")
      : gaEventTracker("mybt", "click", "left arrow");
  };

  return (
    <div
      className={cx(rootClassName, {
        [nextArrowClass]: direction === "right",
        [prevArrowClass]: direction === "left",
        "opacity-70 hover:opacity-100": typeof onClick === "function",
        [`pointer-events-none ${opacityWhenDisabled}`]:
          typeof onClick === "object",
      })}
      data-testid={testId}
      onClick={handleArrowClick}
    >
      <FontAwesomeIcon
        icon={direction === "left" ? prevArrowIcon : nextArrowIcon}
        size={iconSize}
        className={cx(
          "block h-4 w-4 cursor-pointer rounded-full border border-black fill-gray-650 hover:opacity-100",
          `p-${iconPadding}`
        )}
        onClick={onClick}
      />
    </div>
  );
}
