import { LAMBDA_RESPONSE_TYPES } from "@api/endpoint-handlers/bff-functions/Alacrity/types";
import GiftedAlready from "@pages/Article/components/ArticleSubshare/Modal/Messages/GiftedAlready";
import GiftingLimitReached from "@pages/Article/components/ArticleSubshare/Modal/Messages/GiftingLimitReached";
import GiftThisArticle from "@pages/Article/components/ArticleSubshare/Modal/Messages/GiftThisArticle";

import CopyLink from "./Modal/Actions/CopyLink";
import Ok from "./Modal/Actions/Ok";
import StartReading from "./Modal/Actions/StartReading";
import Subscribe from "./Modal/Actions/Subscribe";
import GiftLinkExpired from "./Modal/Messages/GiftLinkExpired";
import GiftRedeemedSuccessfully from "./Modal/Messages/GiftRedeemedSuccessfully";

/**
 * Helper function to get the header text for gifter modal.
 * @param responseType The type of response from lambda.
 * @returns {string}
 */
export const getHeaderText = (responseType: LAMBDA_RESPONSE_TYPES): string => {
  switch (responseType) {
    case LAMBDA_RESPONSE_TYPES.SUCCESS_SHORTNER:
    case LAMBDA_RESPONSE_TYPES.SUCCESS_GIFT:
      return "Gift this article";

    case LAMBDA_RESPONSE_TYPES.SUCCESS_ALREADY_GIFTED:
      return "You've already gifted this premium story";

    case LAMBDA_RESPONSE_TYPES.ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED:
      return "Gifting limit reached";

    case LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN:
      return "You've successfully redeemed your gift!";

    case LAMBDA_RESPONSE_TYPES.ERROR_INVALID_GIFT_TOKEN:
      return "The gift link has expired";

    default:
      return "An error has occurred, please try again";
  }
};

/**
 * Helper function to get the gifter message.
 * @param responseType The response type.
 * @returns
 */
export const MessageFactory = (responseType: LAMBDA_RESPONSE_TYPES) => {
  switch (responseType) {
    case LAMBDA_RESPONSE_TYPES.SUCCESS_SHORTNER:
    case LAMBDA_RESPONSE_TYPES.SUCCESS_GIFT:
      return GiftThisArticle;

    case LAMBDA_RESPONSE_TYPES.SUCCESS_ALREADY_GIFTED:
      return GiftedAlready;

    case LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN:
      return GiftRedeemedSuccessfully;

    case LAMBDA_RESPONSE_TYPES.ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED:
      return GiftingLimitReached;

    case LAMBDA_RESPONSE_TYPES.ERROR_INVALID_GIFT_TOKEN:
      return GiftLinkExpired;
  }
};

/**
 * Helper function to get the CTA button of subshare modal.
 * @param responseType Alacrity response.
 * @returns
 */
export const ActionsFactory = (responseType: LAMBDA_RESPONSE_TYPES) => {
  switch (responseType) {
    case LAMBDA_RESPONSE_TYPES.SUCCESS_SHORTNER:
    case LAMBDA_RESPONSE_TYPES.SUCCESS_GIFT:
      return CopyLink;

    case LAMBDA_RESPONSE_TYPES.SUCCESS_ALREADY_GIFTED:
      return CopyLink;

    case LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN:
      return StartReading;

    case LAMBDA_RESPONSE_TYPES.ERROR_INVALID_GIFT_TOKEN:
      return Subscribe;

    case LAMBDA_RESPONSE_TYPES.ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED:
    default:
      return Ok;
  }
};
