import { useRef } from "react";
import { Link } from "react-router-dom";
import { BreakingNewsAllCaughtUpProps } from "@app/types/BreakingNews";
import IconEnvelope from "@assets/footer/icon-envelope.svg";
import IconHouse from "@assets/footer/icon-house.svg";
import IconPaperPlan from "@assets/footer/icon-paper-plane.svg";
import useOnScreen from "@hooks/useOnScreen";
import cx from "classnames";

export default function BreakingNewsAllCaughtUp({
  testId,
  rootClassName,
}: BreakingNewsAllCaughtUpProps): React.ReactElement {
  const blockRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(blockRef);

  return (
    <div
      className={cx(rootClassName, {
        "in-view": isOnScreen,
      })}
      data-testid={testId}
      ref={blockRef}
    >
      <div className="my-24 overflow-hidden py-2 text-center font-poppins font-normal">
        <div
          className={cx(
            "border-top scale-x-0 border-black transition-transform delay-300 duration-300",
            {
              "!scale-x-100": isOnScreen,
            }
          )}
        ></div>
        <div
          className={cx("opacity-0 transition-opacity delay-700 duration-700", {
            "!opacity-100": isOnScreen,
          })}
        >
          <h2 className="mb-2 font-playfair text-11xl font-medium">
            <span className="text-[3rem]">A</span>LL{" "}
            <span className="text-[3rem]">C</span>AUGHT{" "}
            <span className="text-[3rem]">U</span>P
          </h2>
          <p className="fa-1x mb-16 font-public-sans">
            You&apos;ve seen the latest posts from the past 72 hours
          </p>
        </div>
        <div
          className={cx(
            "translate-y-11/10 transition-transform delay-1000 duration-300",
            {
              "!translate-y-0": isOnScreen,
            }
          )}
        >
          <p className="fa-1x mb-3 font-public-sans">
            Don&apos;t sleep on the latest updates
          </p>
          <div className="mx-auto flex flex-col flex-wrap lg:w-1/2">
            <Link
              className="border-visited:text-white rounded-pill mb-3 cursor-pointer border border-gray-850 bg-gray-850 px-8 py-2 font-medium text-white opacity-100 transition-opacity hover:opacity-80"
              to="https://t.me/s/BizTimes"
              target="_blank"
            >
              <img className="inline-block h-5" alt="" src={IconPaperPlan} />
              <span className="ms-2 text-inherit">Join BT on Telegram</span>
            </Link>

            <Link
              className="fw-500 rounded-pill text-decoration-none mb-3 text-nowrap border border-gray-850 px-6 py-2 font-medium"
              to="/newsletter/sign-up"
              target="_blank"
            >
              <img
                className="inline-block"
                alt=""
                height={20}
                src={IconEnvelope}
                width={20}
              />
              <span className="ms-2">Browse our Newsletters</span>
            </Link>

            <Link
              className="fw-500 rounded-pill text-decoration-none mb-3 text-nowrap border border-gray-850 px-6 py-2 font-medium"
              to="/"
              target="_blank"
            >
              <img
                className="inline-block"
                alt=""
                height={20}
                src={IconHouse}
                width={20}
              />
              <span className="ms-2">Go to Homepage</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
