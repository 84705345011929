import { useState } from "react";
import { ArticleDataObject, SanitizedSectionArticle } from "@app/types/Cue";
import { NewsletterArchiveKeywordsEnums } from "@app/types/enums";
import CreateTime from "@components/ArticleMeta/CreateTime";
import LoadMore from "@components/LoadMore/LoadMore";
import { fetchMoreStoriesData } from "@pages/Section/Section.server";
import { STORIES_PAGE_SIZE } from "@util/constant";
import { cn } from "@util/helpers";

export type NewsletterArchiveArticlesListProps = {
  articles: SanitizedSectionArticle[];
  newsletterKeyword: NewsletterArchiveKeywordsEnums;
};

export default function ThrivePastIssueArticles({
  articles = [],
  newsletterKeyword,
}: NewsletterArchiveArticlesListProps): React.ReactElement {
  const [moreArticles, setMoreArticles] =
    useState<ArticleDataObject[]>(articles);
  const [hasMoreStories, setHasMoreStories] = useState(true);

  const handleLoadMore = async () => {
    const page = moreArticles.length / STORIES_PAGE_SIZE + 1;

    const newArticles = await fetchMoreStoriesData(
      newsletterKeyword,
      "keywords",
      page
    );

    setMoreArticles([...moreArticles, ...newArticles]);

    if (newArticles.length < STORIES_PAGE_SIZE) {
      setHasMoreStories(false);
    }
  };

  return (
    <div
      className="lg:w-7/12"
      data-testid="thrive-past-issue-articles-component"
    >
      <h2 className="mt-16 flex justify-center pb-2 font-inter text-7xl font-medium text-gray-850 lg:text-9xl">
        Past Issues<span className="text-green-400">.</span>
      </h2>
      <div
        className={cn(
          "mt-4 border border-black bg-white p-4 shadow-thriveButton transition-all duration-150 hover:shadow-thriveNewsletterHover lg:w-fit lg:px-6 lg:py-8"
        )}
      >
        {moreArticles.map((article) => (
          <div key={article.id} className="lg:pb-3">
            <div className="flex flex-row justify-between border-b border-gray-300">
              <div className="mb-4 flex w-full flex-col lg:w-2/3 lg:flex-row">
                <div>
                  {article.updated ? (
                    <CreateTime
                      rootClassName="block font-inter py-1 text-4xs font-normal text-gray-850 lg:hidden"
                      dateFormat="MMM DD, YYYY"
                      created={article.updated}
                    />
                  ) : null}

                  <h2 className="mb-0 pr-2 font-inter text-base font-medium lg:text-lg">
                    <a
                      href={article.urlPath}
                      target="_blank"
                      className="relative block w-fit after:absolute after:-bottom-0.5 after:block after:h-[2px] after:w-full after:origin-center after:scale-x-0 after:bg-green-400 after:transition after:duration-300 after:hover:scale-x-100"
                      rel="noreferrer"
                    >
                      {article.title}
                    </a>
                  </h2>
                </div>
              </div>

              {article.updated ? (
                <CreateTime
                  rootClassName="hidden font-inter py-1 text-4xs font-normal text-gray-850 lg:block"
                  dateFormat="MMM DD, YYYY"
                  created={article.updated}
                />
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <div className="mb-4 mt-10 flex justify-center">
        {hasMoreStories ? (
          <LoadMore
            rootClassName="group flex items-center justify-center gap-2 border-2 border-orange-400 px-20 py-4 text-center hover:bg-gray-250"
            onLoadMore={handleLoadMore}
            loadText="Show More"
            hasMore={hasMoreStories}
          />
        ) : null}
      </div>
    </div>
  );
}
