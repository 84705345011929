import { YouTubeEmbed } from "react-social-media-embed";
import FacebookEmbedCustom from "@components/Embeds/FacebookEmbedCustom";
import FlourishEmbedCustom from "@components/Embeds/FlourishEmbedCustom";
import InstagramEmbedCustom from "@components/Embeds/InstagramEmbedCustom";
import TiktokEmbedCustom from "@components/Embeds/TiktokEmbedCustom";
import XEmbedCustom from "@components/Embeds/XEmbedCustom";
import { isThisUrlFrom } from "@util/helpers";

export const SocialEmbedFactory = (source: string) => {
  if (isThisUrlFrom(source, "tiktok.com")) return TiktokEmbedCustom;

  if (isThisUrlFrom(source, "youtube.com")) return YouTubeEmbed;
  if (isThisUrlFrom(source, "youtu.be")) return YouTubeEmbed;

  if (isThisUrlFrom(source, "facebook.com")) return FacebookEmbedCustom;

  if (isThisUrlFrom(source, "twitter.com")) return XEmbedCustom;
  if (isThisUrlFrom(source, "x.com")) return XEmbedCustom;

  if (isThisUrlFrom(source, "instagram.com")) return InstagramEmbedCustom;

  if (isThisUrlFrom(source, "public.flourish.studio"))
    return FlourishEmbedCustom;
};

/**
 * Helper function to get the flourish data source.
 * @param src The flourish SRC added in CUE.
 * @returns {string|undefined} The flourish data source.
 */
export const getFlourishDataSrc = (src: string): string | undefined => {
  const FLOURISH_PATTERN =
    /https:\/\/public\.flourish\.studio\/(visualisation|story)\/(\d+)/;

  const match = src.match(FLOURISH_PATTERN);

  if (!match) return;

  if (match[1] && match[2]) {
    return `${match[1]}/${match[2]}`;
  }

  return;
};
