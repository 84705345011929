/**
 * Company: SPHMedia
 * Description: LifestyleLuxe Section Layout
 */

import { Await } from "react-router-dom";
import { LifestyleLuxeContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import LifestyleLuxeContent from "./components/LifestyleLuxeContent";

export default function LifestyleLuxePage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<LifestyleLuxeContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }: LifestyleLuxeContext) => {
        if (kind === "section") {
          return <LifestyleLuxeContent data={data} />;
        }

        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}
