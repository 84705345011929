export const defaultSourceForListing = [
  "data.context.id",
  "data.context.title",
  "data.context.created",
  "data.context.updated",
  "data.context.edited",
  "data.context.media",
  "data.context.paywall",
  "data.context.elements",
  "data.context.displaySetting",
  "data.context.sections",
  "data.context.urlPath",
  "data.context.tags",
  "data.context.others.storyThread",
  "data.context.kicker",
  "data.context.storyContent",
];
