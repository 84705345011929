import { getErrorMessage } from "@util/helpers";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface UserStore {
  userInfo: OKTAUserType;
  setUserInfo: (userInfo: OKTAUserType) => void;
  userError: unknown;
  setUserError: (error: ReturnType<typeof getErrorMessage>) => void;
}

export type OKTAUserType = {
  display_name?: string;
  loginid?: string;
  mySPHSessionId?: string;
  mysphw?: string;
  reguserstatus?: OKTARegistrationStatus;
  seamless_login_from_app?: string;
  service_type?: string;
  service_type_value?: 1 | 0 | null;
  usertype?: OKTAUserTypeEnum;
  svc?: string;
} | null;

export enum OKTAUserTypeEnum {
  SUBSCRIBER = "y-sub",
  REGISTERED = "y-reg",
  ANONYMOUS = "y-anoy",
  GUEST = "y-guest",
}

export enum OKTARegistrationStatus {
  VERIFIED = "verified",
  UNVERIFIED = "unverified",
}

const useOKTAUserStore = create(
  persist<UserStore>(
    (set) => ({
      userInfo: {
        service_type: "",
        display_name: "",
        mysphw: "",
        reguserstatus: undefined,
        mySPHSessionId: "",
        usertype: OKTAUserTypeEnum.ANONYMOUS,
      },
      setUserInfo: (userInfo: OKTAUserType) => {
        set({ userInfo });
      },
      userError: "",
      setUserError: (error: ReturnType<typeof getErrorMessage>) => {
        set({ userError: error });
      },
    }),
    {
      name: "bt-user", // unique name
      getStorage: () => localStorage, // Specify localStorage as the storage option
    }
  )
);

export default useOKTAUserStore;
