/**
 * New ENUMS to match with bt-web2-bff
 * https://github.com/SPHTech/bt-web2-bff/blob/8626ffbd7686fe5de53b11aa30a8181e847825ec/src/services/Alacrity/types.ts
 */

// Payload format expected from bt-web repo when tried to access Alacriy API.
export type LambdaRequestPayload = {
  action: LAMBDA_REQUEST_ACTION_TYPES;
  data: {
    articleURL?: string;
    aovisitorID?: string;
    giftToken?: string;
  };
};

// Payload format expected to send to bt-web repo after getting response from alacrity.
export type LambdaResponsePayload = {
  status: LAMBDA_RESPONSE_STATUS;
  message: LAMBDA_RESPONSE_MESSAGES;
  data: {
    responseType: LAMBDA_RESPONSE_TYPES;
    shortURL?: string;
    giftCount?: number;
    viewGiftStatus?: boolean;
  };
};

export enum LAMBDA_REQUEST_ACTION_TYPES {
  GENERATE_SHORTEN_URL = "generate-shortner-url",
  GENERATE_GIFT_URL = "generate-gift-url",
  VERIFY_GIFT_TOKEN = "verify-gift-token",
}

export enum LAMBDA_RESPONSE_STATUS {
  SUCCESS = "success", // Represents a successful response.
  ERROR = "error", // Represents an error response.
}

export enum LAMBDA_RESPONSE_TYPES {
  SUCCESS_SHORTNER = 1, // Successful shortener response.
  SUCCESS_GIFT = 2, // Successful gift response.
  ERROR_GIFT = 3, // Error response for exceeding quota.
  SUCCESS_VALID_GIFT_TOKEN = 4, // Successful response for a valid gift token.
  ERROR_INVALID_GIFT_TOKEN = 5, // Error response for an invalid gift token.
  SUCCESS_ALREADY_GIFTED = 6, // Successful response for an already gifted article.
  ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED = 7, // Error response to indicate monthly quota is reached.
  ERROR_UNSUPPORTED_ACTION = 8, // Error response to indicate payload action is not correct.
  ERROR_GENERIC = 9, // Generic error response.
}

export enum LAMBDA_RESPONSE_MESSAGES {
  SUCCESS_SHORTNER = "Shortner URL has been generated successfully", // Success message for generating a short URL.
  SUCCESS_GIFT = "Gift an article URL has been generated successfully", // Success message for generating a gift URL.
  ERROR_GIFT = "Quota has been exceeded!", // Error message for exceeding quota.
  SUCCESS_VALID_GIFT_TOKEN = "Gift token is valid", // Success message for a valid gift token.
  ERROR_INVALID_GIFT_TOKEN = "Gift token is invalid!", // Error message for an invalid gift token.
  SUCCESS_ALREADY_GIFTED = "Article has already been gifted", // Success message for an already gifted article.
  ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED = "Your monthly quota for sharing articles as gifts has been reached!", // Error message to indicate monthly quota is completed.
  ERROR_UNSUPPORTED_ACTION = "Unsupported action!", // Indicate that the payload action sent to lambda is not correct.
  ERROR_GENERIC = "Something went wrong!", // Generic error message.
}

export type AlacrityPayload = {
  service: "alacrity";
  payload: LambdaRequestPayload;
};
