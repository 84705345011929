import { Link } from "react-router-dom";
import thriveScrollTopIcon from "@assets/icon-scroll-top.svg";
import Container from "@components/Container/Container";
import { footerLinks, socialMediaLinks } from "@pages/Thrive/constants";
import dayjs from "dayjs";

import ThriveSubscriptionForm from "./ThriveSubscriptionForm";

const ThriveFooter = (): React.ReactElement => {
  const copyrightText = `©${dayjs().format("YYYY")} The Business Times`;

  return (
    <Container
      rootClassName="w-full px-14 pb-20"
      data-testid="thrive-footer-component"
    >
      <Link
        className="flex items-center justify-center text-white lg:hidden"
        to="#thrive-events-page"
      >
        <img
          src={thriveScrollTopIcon}
          alt="Thrive Scroll top"
          width="48"
          height="48"
        />
      </Link>

      <div className="flex flex-col justify-center py-36 font-inter text-white lg:items-center lg:text-center">
        <h2 className="mb-2 font-inter text-7xl font-normal lg:text-10xl">
          Join us<span className="text-green-400">.</span>
        </h2>

        <ThriveSubscriptionForm />

        <span className="mt-2 self-stretch text-center text-lg font-normal">
          We respect your privacy.
        </span>
      </div>

      <div className="flex flex-col flex-wrap items-center justify-center gap-6 border-t-2 border-gray-950 pt-12 font-inter lg:flex-row lg:justify-start lg:gap-8">
        {socialMediaLinks.slice(0, 1).map((link) => (
          <Link key={link.alt} to={link.to} reloadDocument>
            <img height="60px" width="60px" src={link.icon} alt={link.alt} />
          </Link>
        ))}

        {footerLinks.map(({ label, to }) => (
          <p className="flex-basis m-0 p-0" key={to}>
            <Link
              className="text-white hover:underline"
              to={to}
              target="_blank"
              reloadDocument
              rel="noopener noreferrer"
            >
              {label}
            </Link>
          </p>
        ))}

        <p className="flex-basis mb-0 text-white">{copyrightText}</p>

        <div className="ml-auto hidden justify-end gap-6 lg:flex">
          {socialMediaLinks.slice(1).map((link, index) => (
            <Link
              key={link.alt}
              to={link.to}
              {...(index !== 0 ? { target: "_blank" } : undefined)}
              reloadDocument
            >
              <img height="60px" width="60px" src={link.icon} alt={link.alt} />
            </Link>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ThriveFooter;
