import { InstagramEmbed, InstagramEmbedProps } from "react-social-media-embed";
import { useWindowSize } from "@hooks/useWindowSize";

import { SOCIAL_EMBED_WIDTH } from "./constants";

export default function InstagramEmbedCustom(
  props: InstagramEmbedProps
): React.ReactElement {
  const { isScreenMD } = useWindowSize();

  return (
    <div className="flex justify-center">
      <InstagramEmbed
        {...props}
        width={isScreenMD ? SOCIAL_EMBED_WIDTH : "100%"}
      />
    </div>
  );
}
