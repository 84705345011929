import { ReactElement } from "react";
import { Vertical } from "@app/types/enums";
import { BlockTitle } from "@blocks/Blocks";
import VerticalMostRead from "@components/ArticleCard/VerticalMostRead";
import { ChartbeatArticleDataObject } from "@src/app/types/Chartbeat";
import { VerticalName } from "@util/constant";
import cx from "classnames";

export type MostReadStoriesProps = {
  testId?: string;
  articles: ChartbeatArticleDataObject[];
  verticalName: VerticalName;
};

export default function MostReadStories({
  testId,
  articles,
  verticalName,
}: MostReadStoriesProps): ReactElement {
  const blockTitle = verticalName === Vertical.Sme ? "Most Read" : "MOST READ";

  return (
    <>
      {articles?.length > 0 ? (
        <div data-testid={testId}>
          <BlockTitle
            text={blockTitle}
            rootClassName="vertical-block-title mb-5 !font-poppins font-semibold !text-xl"
          />

          <div className="bg-gray-250 p-4">
            {articles?.map(
              (article: ChartbeatArticleDataObject, index: number) => {
                const spacer =
                  index !== articles.length - 1
                    ? "mb-4 pb-4 border-b border-gray-175"
                    : "";

                return (
                  <div className={cx(spacer)} key={index}>
                    <VerticalMostRead
                      articleData={article}
                      verticalName={verticalName}
                    />
                  </div>
                );
              }
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
