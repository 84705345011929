export const profileURIMapping: {
  uri: string;
  urlPath: string;
}[] = [
  {
    uri: "ng-wei-kai1",
    urlPath: "/authors/ng-wei-kai",
  },
  {
    uri: "kalpana-rashiwala1",
    urlPath: "/authors/kalpana-rashiwala",
  },
  {
    uri: "angela-tan4",
    urlPath: "/authors/angela-tan",
  },
  {
    uri: "wong-pei-ting1",
    urlPath: "/authors/wong-pei-ting",
  },
  {
    uri: "simon-ang1",
    urlPath: "/authors/simon-ang",
  },
  {
    uri: "elisa-valenta1",
    urlPath: "/authors/elisa-valenta",
  },
  {
    uri: "dennis-chan1",
    urlPath: "/authors/dennis-chan",
  },
  {
    uri: "jessie-lim1",
    urlPath: "/authors/jessie-lim",
  },
  {
    uri: "vivien-ang1",
    urlPath: "/authors/vivien-ang",
  },
  {
    uri: "varun-karthik1",
    urlPath: "/authors/varun-karthik",
  },
  {
    uri: "genevieve-cua1",
    urlPath: "/authors/genevieve-cua",
  },
  {
    uri: "anita-gabriel1",
    urlPath: "/authors/anita-gabriel",
  },
  {
    uri: "michelle-quah1",
    urlPath: "/authors/michelle-quah",
  },
  {
    uri: "annabeth-leow1",
    urlPath: "/authors/annabeth-leow",
  },
  {
    uri: "nisha-ramchandani1",
    urlPath: "/authors/nisha-ramchandani",
  },
  {
    uri: "kok-ping-soon1",
    urlPath: "/authors/kok-ping-soon",
  },
  {
    uri: "samar-khanna1",
    urlPath: "/authors/samar-khanna",
  },
  {
    uri: "beth-kowitt2",
    urlPath: "/authors/beth-kowitt",
  },
  {
    uri: "david-streitfeld1",
    urlPath: "/authors/david-streitfeld",
  },
];
