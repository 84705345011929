import * as React from "react";
import { Link } from "react-router-dom";
import { TestIdClassNameProps } from "@app/types/Common";
import { VERTICAL_COLOUR_VARIANTS, VerticalName } from "@src/app/util/constant";
import cx from "classnames";

export type SeeMoreProps = TestIdClassNameProps & {
  text?: string;
  link?: string;
  verticalName?: VerticalName;
  onClick?: React.MouseEventHandler;
};

export default function SeeMore({
  rootClassName,
  text = "See More",
  link = "#",
  verticalName,
  onClick,
}: SeeMoreProps): React.ReactElement {
  const color = verticalName
    ? VERTICAL_COLOUR_VARIANTS[verticalName].textStyle
    : "text-black";

  return (
    <div
      className={cx(
        rootClassName,
        color,
        "text-end font-poppins text-base font-semibold"
      )}
    >
      <Link
        to={link}
        className="hover:underline"
        rel="noopener"
        target="_blank"
        reloadDocument
        onClick={onClick}
      >
        {text}
      </Link>
    </div>
  );
}
