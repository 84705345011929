import Container from "@components/Container/Container";
import { linksInHomeNewsletterIntroduction } from "@pages/Thrive/constants";

export default function ThriveSampleNewsletterIntroduction(): React.ReactElement {
  return (
    <Container
      rootClassName="px-0 text-gray-850"
      data-testid="thrive-home-sample-newsletter-introduction-component"
    >
      <p className="m-0 py-[10px] pt-3 font-interBold text-2xl">
        🧓 How to make millions before you die
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "The investing world can seem impenetrable to a newbie learning the ropes . From the lingo to the complex terminology, there's just so much to know when starting out that we sometimes just think, \"I'm not ready for this\" 🤯."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        {
          "But the truth is that investing doesn't have to be hard. Over the next few months, I'll be hosting a series of videos diving into the complex world of investing. With the help of experts, we'll be looking at how to get started on your investment journey – from building your first portfolio to different investing strategies."
        }
      </p>

      <p className="m-0 mb-2 p-0">
        Join us on Investing with Choo on our{" "}
        <a
          href={linksInHomeNewsletterIntroduction.instagram.to}
          target="_blank"
          className="border-b-2 border-green-400"
          rel="noreferrer"
        >
          Instagram
        </a>
        {
          " 🌱! And if you're a beginner investor with any questions, drop us a DM or e-mail me at darylchoo@sph.com.sg and we may feature them in one of our videos."
        }
      </p>

      <div className="flex items-center gap-4 py-1">
        <img
          src={linksInHomeNewsletterIntroduction.authorImg.to}
          width={80}
          height={80}
          alt="author"
          className="rounded-full"
        />

        <div>
          <p className="m-0 p-0 font-interBold">Daryl Choo</p>

          <p className="m-0 p-0">Young Audience Journalist</p>
        </div>
      </div>

      <div className="pt-10">
        <p className="m-0 p-0 pb-4 font-interBold text-2xl">
          {"In this week's issue"}
        </p>

        <ul className="list-disc pl-6">
          <li className="pb-2">
            Privately-run coffee shops start accepting CDC vouchers
          </li>

          <li className="pb-2">Ninja Van lays off more tech workers</li>

          <li className="pb-2">
            How much do I need to start my investment journey?
          </li>
        </ul>
      </div>
    </Container>
  );
}
