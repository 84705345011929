import { Field } from "@app/types/Cue";
import { Name, Type } from "@app/types/enums";
import { getFieldValue } from "@util/helpers";

export type OmnyPodcastProps = {
  fields: Field[];
  className?: string;
};

export default function OmnyPodcast({
  className,
  fields,
}: OmnyPodcastProps): React.ReactElement {
  const embedSource = getFieldValue(fields, Name.EmbedUrl);
  return (
    <>
      {typeof embedSource === "string" ? (
        <div
          className={className}
          data-story-element={Type.OmnyPodcast}
          data-testid="omny-podcast-component"
        >
          <iframe
            className="border-none"
            src={embedSource}
            width="100%"
            title="omny-podcast-title"
          ></iframe>
        </div>
      ) : null}
    </>
  );
}
