import { Link, useSearchParams } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { BreakingNewsPageContentProps } from "@app/types/BreakingNews";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { NavigationItem } from "@app/types/Navigation";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import { ActiveType } from "@components/Breadcrumb/types";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import { topMobileNav } from "@components/Header/constant";
import Header from "@components/Header/Header";
import { useCategoriesFromPath } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useWindowSize } from "@hooks/useWindowSize";
import BreakingNewsAllCaughtUp from "@pages/BreakingNews/components/BreakingNewsAllCaughtUp";
import BreakingNewsStories from "@pages/BreakingNews/components/BreakingNewsStories";
import { getFilteredArticles } from "@pages/BreakingNews/helper";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { cn, GoogleAdsSlotFactory } from "@util/helpers";
import cx from "classnames";

export default function BreakingNewsPageContent({
  data: {
    title,
    articles: { today, yesterday, thedaybefore },
    sections,
  },
}: BreakingNewsPageContentProps): React.ReactElement {
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });

  const { isScreenLG } = useWindowSize();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  const { parentCategory } = useCategoriesFromPath();
  const parentCategoryData = sectionNavigationItems?.[parentCategory];

  const items: Record<string, NavigationItem> = {};
  sections.forEach((filter) => {
    const data = sectionNavigationItems[filter];

    if (!data) return;

    const params = new URLSearchParams();
    params.append("filter", filter);
    const item = { ...data, link: `/breaking-news?${params.toString()}` };

    items[filter] = item;
  });

  const filtersDataMapping = {
    ...sectionNavigationItems,
    "breaking-news": {
      ...sectionNavigationItems["breaking-news"],
      children: sections,
    },
    ...items,
  };

  const [searchParams] = useSearchParams();

  const activeFilter = searchParams.get("filter") || "";
  const todaysArticles = getFilteredArticles(today, activeFilter);
  const yesterdaysArticles = getFilteredArticles(yesterday, activeFilter);
  const thedaybeforesArticles = getFilteredArticles(thedaybefore, activeFilter);
  const totalArticles =
    todaysArticles.length +
    yesterdaysArticles.length +
    thedaybeforesArticles.length;

  return (
    <div className="w-full" data-testid="breaking-news-page-content-component">
      <MetaTags
        title={title}
        description="Read more at The Business Times."
        slug={RouteFactory.breakingNews}
        shortLink={RouteFactory.breakingNews}
      />

      <GAData title="Breaking_News_Index" level2="breaking news" />

      <div className="w-full">
        <Advertisement
          rootClassName="min-h-[50px] lg:min-h-[250px] bg-gray-125 py-1 md:py-3 border-b border-gray-175"
          adUnitProps={{
            type: AdvertisementTypeEnum.LB1,
            slot: GoogleAdsSlotFactory.lb1("breaking-news"),
            adsClassName:
              "min-h-[50px] lg:min-h-[250px] flex flex-col justify-center items-center",
          }}
        />

        <PrestitialAdWrapper
          slot={GoogleAdsSlotFactory.prestitial("breaking-news")}
          {...{
            isPrestitialEnabled,
            topOverlayImpressions,
            topOverlayValidity,
          }}
        />

        <Header />

        <Container>
          <Row rootClassName="pt-6">
            <Column rootClassName="mb-6 mx-auto lg:hidden ">
              <div className="flex gap-4">
                {topMobileNav.map((nav) => {
                  return (
                    <div className="group" key={nav.key}>
                      <Link
                        key={nav.key}
                        role="link"
                        to={`${nav.link}?ref=home-menubar`}
                        className={cn(
                          "font-poppins text-lg font-medium text-gray-515",
                          {
                            "font-semibold text-gray-850":
                              nav.key === "breaking-news",
                          }
                        )}
                        reloadDocument
                      >
                        {nav.label}
                      </Link>
                      <div
                        className={cx(
                          "mx-auto mt-1 h-[2px] w-[3rem] bg-gray-850 transition-all duration-300",
                          nav.key === "breaking-news"
                            ? "opacity-100"
                            : "opacity-0",
                          "group-hover:opacity-100"
                        )}
                      ></div>
                    </div>
                  );
                })}
              </div>
            </Column>

            <Column rootClassName="w-full mb-6">
              <div className="flex-none">
                <h1
                  className={cx(
                    "hidden pb-3 pt-6 font-playfair text-8xl font-semibold text-gray-850 lg:block"
                  )}
                >
                  <span className="text-10xl">B</span>REAKING{" "}
                  <span className="text-10xl">N</span>EWS
                </h1>
              </div>

              <div>
                <Breadcrumb
                  activeFilter={activeFilter}
                  activeType={ActiveType.QUERY}
                  className="border-b-0 border-t border-gray-175 pt-5 lg:border-b lg:border-t-0 lg:border-gray-850"
                  parentCategory={"breaking-news"}
                  categoryDataMapping={filtersDataMapping}
                  childCategory="international"
                  extraBreadcrumbItems={
                    <div
                      className={cn(
                        "relative box-border flex shrink-0 items-center border-r border-gray-175 pr-3 font-poppins text-sm tracking-tightest text-gray-850 hover:opacity-80",
                        { "font-bold": !activeFilter }
                      )}
                    >
                      <Link
                        to={`${parentCategoryData?.link}?ref=listing-menubar`}
                        reloadDocument
                      >
                        All
                      </Link>
                    </div>
                  }
                />
              </div>
            </Column>

            <Column
              rootClassName="w-full lg:w-8/12 mb-6"
              id="breakingnewspage-content-section"
            >
              <div className="space-y-20">
                {todaysArticles.length > 0 ? (
                  <BreakingNewsStories
                    articles={todaysArticles}
                    group="today"
                    disablePaywall={
                      OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
                    }
                  />
                ) : null}

                {yesterdaysArticles.length > 0 ? (
                  <BreakingNewsStories
                    articles={yesterdaysArticles}
                    articlesBefore={todaysArticles.length}
                    group="yesterday"
                    disablePaywall={
                      OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
                    }
                    rootClassName="border-gray-850 pt-2 lg:border-t first:border-t-0"
                  />
                ) : null}

                {thedaybeforesArticles.length > 0 ? (
                  <BreakingNewsStories
                    articles={thedaybeforesArticles}
                    articlesBefore={
                      todaysArticles.length + yesterdaysArticles.length
                    }
                    group="thedaybefore"
                    disablePaywall={
                      OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
                    }
                    rootClassName="border-gray-850 pt-2 lg:border-t first:border-t-0"
                  />
                ) : null}

                {totalArticles > 0 && totalArticles < 10 ? (
                  <Advertisement
                    rootClassName="w-[calc(100%+5rem)] -translate-x-20 overflow-hidden bg-white lg:w-auto lg:translate-x-0"
                    adUnitProps={{
                      type: AdvertisementTypeEnum.BN1,
                      slot: GoogleAdsSlotFactory.bn1("breaking-news"),
                    }}
                  />
                ) : null}
              </div>

              <BreakingNewsAllCaughtUp />
            </Column>

            <Column rootClassName="w-full lg:w-4/12 mb-6">
              <div className="h-full">
                {isScreenLG ? (
                  <>
                    {totalArticles <= 18 ? (
                      <Advertisement
                        rootClassName="lg:sticky lg:top-20"
                        adUnitProps={{
                          type: AdvertisementTypeEnum.IMU1,
                          slot: GoogleAdsSlotFactory.imu1("breaking-news"),
                        }}
                      />
                    ) : null}

                    {totalArticles > 18 && totalArticles <= 36 ? (
                      <>
                        <div className="mb-6 lg:h-[800px]">
                          <Advertisement
                            rootClassName="lg:sticky lg:top-20"
                            adUnitProps={{
                              type: AdvertisementTypeEnum.IMU1,
                              slot: GoogleAdsSlotFactory.imu1("breaking-news"),
                            }}
                          />
                        </div>

                        <Advertisement
                          rootClassName="lg:sticky lg:top-20"
                          adUnitProps={{
                            type: AdvertisementTypeEnum.IMU2,
                            slot: GoogleAdsSlotFactory.imu2("breaking-news"),
                          }}
                        />
                      </>
                    ) : null}

                    {totalArticles > 36 ? (
                      <>
                        <div className="mb-6 lg:h-[800px]">
                          <Advertisement
                            rootClassName="lg:sticky lg:top-20"
                            adUnitProps={{
                              type: AdvertisementTypeEnum.IMU1,
                              slot: GoogleAdsSlotFactory.imu1("breaking-news"),
                            }}
                          />
                        </div>

                        <div className="mb-6 lg:h-[800px]">
                          <Advertisement
                            rootClassName="lg:sticky lg:top-20"
                            adUnitProps={{
                              type: AdvertisementTypeEnum.IMU2,
                              slot: GoogleAdsSlotFactory.imu2("breaking-news"),
                            }}
                          />
                        </div>

                        <Advertisement
                          rootClassName="lg:sticky lg:top-20"
                          adUnitProps={{
                            type: AdvertisementTypeEnum.IMU3,
                            slot: GoogleAdsSlotFactory.imu3("breaking-news"),
                          }}
                        />
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </Column>
          </Row>
        </Container>

        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.CATFISH,
            slot: GoogleAdsSlotFactory.catfish("breaking-news"),
          }}
        />

        <Advertisement
          adUnitProps={{
            type: AdvertisementTypeEnum.ABM,
            slot: GoogleAdsSlotFactory.abm(),
          }}
        />
      </div>
    </div>
  );
}
