import thriveCareer from "@assets/thrive_images/thrive-career.svg";
import thriveMoney from "@assets/thrive_images/thrive-money.svg";
import thriveSelf from "@assets/thrive_images/thrive-self.svg";

export const cardAssets = (key: string) => {
  switch (key) {
    case "money":
      return thriveMoney;

    case "self":
      return thriveSelf;

    case "career":
    default:
      return thriveCareer;
  }
};
