import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Link, useSearchParams } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import chevronLeft from "@assets/icon-chevron-left-white.svg";
import chevronRight from "@assets/icon-chevron-right-white.svg";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import { getFormattedCaaSImageUrl } from "@components/OptimisedImage/helper";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import PulseEndingScreen from "@pages/Pulse/components/PulseEndingScreen";
import { SanitizedPulseData } from "@pages/Pulse/utils/types";
import { cn } from "@util/helpers";

import PulseIndividualMobileArticle from "./PulseIndividualMobileArticle";

export type PulseIndividualArticleProps = {
  articles: SanitizedPulseData[];
  currentSlide: number;
  setCurrentSlide: React.Dispatch<React.SetStateAction<number>>;
  setCurrentSlideId: React.Dispatch<React.SetStateAction<string>>;
};

export default function PulseMobileCarousel({
  articles,
  currentSlide,
  setCurrentSlide,
  setCurrentSlideId,
}: PulseIndividualArticleProps): React.ReactElement {
  const memorisedArticles = useMemo(() => articles, [articles]);

  const totalLength = memorisedArticles.length + 1;

  const [_, setSearchParams] = useSearchParams();

  const options: Options = {
    perMove: 1,
    perPage: 1,
    arrows: false,
    pagination: false,
    speed: 700,
  };

  const optionsForImage: Options = {
    perMove: 1,
    arrows: false,
    pagination: false,
    type: "fade",
    drag: false,
  };

  const thumbnailsRef = useRef<Splide>(null);
  const cardsRef = useRef<Splide>(null);

  useEffect(() => {
    if (
      thumbnailsRef.current &&
      cardsRef.current &&
      cardsRef.current.splide &&
      thumbnailsRef.current.splide
    ) {
      cardsRef.current.sync(thumbnailsRef.current.splide);
    }
  }, [thumbnailsRef, cardsRef]);

  const changeUrlPath = useCallback(
    (id: string) => {
      setSearchParams({ article: id });
    },
    [setSearchParams]
  );

  const slideChanges = (index: number, id: string) => {
    setCurrentSlide(index);
    setCurrentSlideId(id);
    if (index === totalLength - 1) {
      changeUrlPath("finished");
      return;
    }

    if (id === "") return;
    changeUrlPath(id);
  };

  const defaultFilters: CaaSImageFilters[] = [{ w: 800, dpr: 1, f: "webp" }];

  if (articles.length === 0) return <> </>;

  const handleNextSlide = () => {
    cardsRef.current?.go(currentSlide + 1);
  };

  const handlePrevSlides = () => {
    cardsRef.current?.go(currentSlide - 1);
  };

  return (
    <div
      className="relative flex h-svh w-screen flex-col"
      data-testid="pulse-mobile-carousel-component"
    >
      <div className="relative z-10 mt-2 flex h-[2px] w-full gap-1 px-2">
        {Array.from({ length: totalLength }).map((_, index) => {
          return (
            <div
              key={`pulse-mobile-carousel-pagination-${index}`}
              className={cn("h-[2px] bg-gray-650")}
              style={{
                width: `calc(100%/${memorisedArticles.length})`,
              }}
            >
              <div
                className={cn(
                  "h-[2px] w-0 bg-white transition-all duration-150",
                  {
                    "w-full": index <= currentSlide,
                  }
                )}
              />
            </div>
          );
        })}
      </div>

      <div className="absolute top-0 h-svh">
        <Splide
          options={optionsForImage}
          hasTrack={false}
          ref={thumbnailsRef}
          className="[&>div>ul>li]:!translate-x-0 [&>div>ul>li]:!transition-opacity [&>div>ul>li]:!duration-700"
        >
          <SplideTrack>
            {memorisedArticles.map((article) => {
              const imgSrcSets = defaultFilters.map(
                ({ breakpoint, ...imageFilters }) => {
                  return getFormattedCaaSImageUrl(article.media, {
                    ...imageFilters,
                  });
                }
              );

              return (
                <Fragment key={article.id}>
                  <SplideSlide
                    className={cn(REMOVE_FOCUS_CSS)}
                    data-article-id={article.id}
                  >
                    <div
                      className="h-svh w-screen"
                      style={{
                        background: `center / cover no-repeat url(${imgSrcSets[0]})`,
                      }}
                    >
                      <div className="absolute top-0 h-full w-full bg-gradient-to-b from-black to-80% opacity-70" />
                    </div>
                  </SplideSlide>
                </Fragment>
              );
            })}

            <SplideSlide>
              <div className=" h-svh w-screen bg-[#212121]">
                <div className="absolute top-0 h-full w-full bg-gradient-to-b from-black" />
              </div>
            </SplideSlide>
          </SplideTrack>
        </Splide>
      </div>

      <div className="relative top-0 flex-1 flex-grow items-center justify-center">
        <Splide
          className="h-full"
          options={options}
          hasTrack={false}
          onMove={(splide, index, prev) => {
            splide.Components.Elements.slides[prev].classList.remove("prev");
            splide.Components.Elements.slides[prev].classList.remove("next");
            splide.Components.Elements.slides[prev].classList.remove("active");

            if (prev > index) {
              splide.Components.Elements.slides[prev].classList.add("next");
            } else if (prev < index) {
              splide.Components.Elements.slides[prev].classList.add("prev");
            }

            splide.Components.Elements.slides[index].classList.add("active");
            splide.Components.Elements.slides[index].classList.remove("prev");
            splide.Components.Elements.slides[index].classList.remove("next");

            const activeSlideId =
              splide.Components.Elements.slides[splide.index].getAttribute(
                "data-article-id"
              ) || "";
            slideChanges(splide.index, activeSlideId);
          }}
          ref={cardsRef}
        >
          <SplideTrack className="h-full">
            {memorisedArticles.map((article, index) => {
              return (
                <Fragment key={article.id}>
                  <SplideSlide
                    className={cn(
                      REMOVE_FOCUS_CSS,
                      "group h-full !transition-all duration-700"
                    )}
                    data-article-id={article.id}
                    data-slide-index={index}
                  >
                    <PulseIndividualMobileArticle article={article} />
                  </SplideSlide>
                </Fragment>
              );
            })}

            <SplideSlide className={cn(REMOVE_FOCUS_CSS, "group")}>
              <div className="flex h-full flex-col items-center justify-center p-4">
                <PulseEndingScreen />
              </div>
            </SplideSlide>
          </SplideTrack>
        </Splide>
      </div>

      <div className="relative flex  w-full items-center justify-between bg-black px-3 py-4">
        <Link
          reloadDocument
          to={RouteFactory.home}
          className="inline-flex w-full items-center font-poppins text-sm font-semibold tracking-2% text-white"
        >
          <img
            src={chevronLeft}
            className="h-6 w-6"
            alt="left arrow"
            width={24}
            height={24}
          />
          Back to BT
        </Link>

        <div className="flex w-full justify-end">
          <button
            className={cn(
              REMOVE_FOCUS_CSS,
              "flex flex-none items-center gap-2 font-poppins text-sm font-semibold tracking-2% text-white transition-all duration-300",
              { "pointer-events-none opacity-50": currentSlide === 0 }
            )}
            onClick={handlePrevSlides}
          >
            <img
              src={chevronLeft}
              className="h-6 w-6"
              alt="right arrow"
              width={24}
              height={24}
            />
            Previous
          </button>

          <div className="mx-3 h-auto w-[1px] flex-none bg-gray-750"></div>
          <button
            onClick={handleNextSlide}
            className={cn(
              REMOVE_FOCUS_CSS,
              "flex flex-none items-center gap-2 font-poppins text-sm font-semibold tracking-2% text-white transition-all duration-300",
              {
                "pointer-events-none opacity-50":
                  currentSlide === totalLength - 1,
              }
            )}
          >
            Next
            <img
              src={chevronRight}
              className="h-6 w-6"
              alt="left arrow"
              width={24}
              height={24}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
