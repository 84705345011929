import { TikTokEmbed, TikTokEmbedProps } from "react-social-media-embed";
import { useWindowSize } from "@hooks/useWindowSize";

import { SOCIAL_EMBED_WIDTH } from "./constants";

export default function TiktokEmbedCustom(
  props: TikTokEmbedProps
): React.ReactElement {
  const { isScreenMD } = useWindowSize();

  return (
    <div className="flex justify-center">
      <TikTokEmbed
        {...props}
        width={isScreenMD ? SOCIAL_EMBED_WIDTH : "100%"}
      />
    </div>
  );
}
