import ArticlePurchaseLinkVersion1 from "./ArticlePurchaseLinkVersion1";
import ArticlePurchaseLinkVersion2 from "./ArticlePurchaseLinkVersion2";
import { ArticlePurchaseLinkProps, Versions } from "./types";

export const ArticlePurchaseLinkFactory = ({
  versions,
  ...props
}: ArticlePurchaseLinkProps) => {
  switch (versions) {
    case Versions.version2:
      return <ArticlePurchaseLinkVersion2 {...props} />;

    case Versions.version1:
    default:
      return <ArticlePurchaseLinkVersion1 {...props} />;
  }
};
