import { useState } from "react";
import { Transition, TransitionChild } from "@headlessui/react";
import Button from "@src/app/components/AccessibleComponents/Button";
import { cn, getErrorMessage } from "@util/helpers";

import LoadingAnimation from "./LoadingAnimation";

import styles from "./LoadMore.module.scss";

interface LoadMoreProps {
  rootClassName?: string;
  onLoadMore: (
    ...rest: (unknown & string & number & Record<string, unknown>)[]
  ) => Promise<unknown>;
  hasMore?: boolean;
  loadText?: string;
}

/**
 *
 * @param onLoadMore the action will be performed when user click the load more button, from the design point of view
 * the load more component should not "understand" or "care" about the action content
 * @param hasMore this boolean indicator from parent logic to tell the component to hide itself or not
 * @returns
 */
export default function LoadMore({
  rootClassName = "",
  onLoadMore,
  hasMore = true,
  loadText = "MORE STORIES",
}: LoadMoreProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleLoadMore = async () => {
    setIsLoading(true);

    if (onLoadMore) {
      try {
        await onLoadMore();
      } catch (_error: unknown) {
        setError(getErrorMessage(_error));
      }
    }

    setIsLoading(false);
  };

  const renderButtonText = () => {
    if (error) {
      return error;
    }

    return loadText;
  };

  return (
    <div
      className={cn(
        styles.componentLoadMore,
        "cursor-pointer border-y border-gray-850 py-4 text-center",
        {
          hidden: !hasMore,
        },
        rootClassName
      )}
      onClickCapture={handleLoadMore}
    >
      <Button className="uppercase outline-none">{renderButtonText()}</Button>
      <Loading isLoading={isLoading} />
    </div>
  );
}

export function Loading({ isLoading }: { isLoading: boolean }) {
  return (
    <Transition show={isLoading}>
      <TransitionChild
        enter="transition ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 left-0 z-[9999] bg-gray-850/80">
          <LoadingAnimation />
        </div>
      </TransitionChild>
    </Transition>
  );
}
