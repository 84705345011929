import { RouteFactory } from "@app/routePaths";
import thriveCurveWord from "@assets/thrive_images/thrive-curve-word.webp";
import GAData from "@components/GAData/GAData";
import MetaTags from "@components/MetaTags/MetaTags";
import ThriveFooter from "@pages/Thrive/components/ThriveFooter";
import ThriveGetSocial from "@pages/Thrive/components/ThriveGetSocial";
import ThriveHeader from "@pages/Thrive/components/ThriveHeader";
import ThriveMiniAboutUs from "@pages/Thrive/components/ThriveMiniAboutUs";
import ThriveHomeNewsletterSignUp from "@pages/Thrive/Home/components/ThriveHomeNewsletterSignUp/ThriveHomeNewsletterSignUp";
import ThriveStartConversation from "@pages/Thrive/Home/components/ThriveStartConversation/ThriveStartConversation";

export default function ThriveHome(): React.ReactElement {
  return (
    <div
      className="w-full overflow-hidden bg-verticals-thrive"
      id="thrive-home-page"
      data-testid="thrive-home-page"
    >
      <MetaTags
        title="Thrive"
        description="Read more at The Business Times."
        slug={RouteFactory.thrive}
        shortLink={RouteFactory.thrive}
      />

      <GAData title="Thrive_Events" level2="miscellaneous" />

      <ThriveHeader />

      <ThriveHomeNewsletterSignUp />

      <div className="overflow-hidden py-28">
        <img
          className="w-full scale-[3] lg:scale-[1.3]"
          src={thriveCurveWord}
          alt="One two thrive"
          height={99}
          width={1800}
        />
      </div>

      <ThriveStartConversation />

      <ThriveGetSocial />

      <ThriveMiniAboutUs />

      <div className="flex justify-center bg-gray-850 font-poppins">
        <ThriveFooter />
      </div>
    </div>
  );
}
