import { useCallback, useEffect } from "react";
import { PROD_URL } from "@app/util/constant";
import useOutBrainScript from "@hooks/useOutBrainScript";

export type OutBrainWidgetProps = {
  articleUrl: string;
  widgetId: string;
};

export default function OutBrainWidget({
  articleUrl,
  widgetId,
}: OutBrainWidgetProps): React.ReactElement {
  useOutBrainScript();

  const embedOutbrain = useCallback(() => {
    if (typeof window === "undefined") return;

    if (window.OBR) {
      window.OBR.extern.researchWidget();
    } else {
      setTimeout(embedOutbrain, 50);
    }
  }, []);

  useEffect(() => {
    embedOutbrain();
  }, [embedOutbrain]);

  return (
    <div
      className="OUTBRAIN"
      data-src={`${PROD_URL}${articleUrl}`}
      data-widget-id={widgetId}
      data-ob-template="BusinessTimes"
      data-testid="outbrain-widget-component"
    ></div>
  );
}
