import { AdvertisementTypeEnum } from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import StoryThreadStories from "@pages/StoryThread/components/StoryThreadStories";
import { StoryThreadContext } from "@pages/StoryThread/utilities/types";
import { cn, GoogleAdsSlotFactory } from "@util/helpers";

type DefaultStoryThreadLayoutProps = Pick<
  StoryThreadContext,
  "data" | "entity"
>;
export default function DefaultStoryThreadLayout({
  data: { title, articles },
  entity,
}: DefaultStoryThreadLayoutProps): React.ReactElement {
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting();
  const breakingNews = useTrimmedBreakingNews();
  const mostPopular = useMostReadArticleList();

  const { name, urlPath } = entity;
  const slot = urlPath.replace("/", "");

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${name} - Find ${name} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="article"
        slug={urlPath}
        robots="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      />

      <Advertisement
        rootClassName="min-h-[50px] lg:min-h-[250px] bg-gray-125 py-1 md:py-3 border-b border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(slot),
          adsClassName:
            "min-h-[50px] lg:min-h-[250px] flex flex-col justify-center items-center",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(slot)}
        {...{
          isPrestitialEnabled,
          topOverlayImpressions,
          topOverlayValidity,
        }}
      />

      <Header />

      <Container rootClassName="pt-6">
        <Row>
          <Column rootClassName="w-full">
            <h1
              className={cn(
                "mb-6 border-b border-gray-850 pb-3 pt-6 font-playfair text-6xl font-semibold uppercase text-gray-850",
                "md:text-8xl"
              )}
            >
              {entity.name}
            </h1>
          </Column>
        </Row>

        <Row>
          <Column rootClassName="w-full lg:w-8/12 mb-6">
            <StoryThreadStories
              {...{ storyThreadUrlPath: urlPath, articles }}
            />
          </Column>

          <Column rootClassName="w-full lg:w-4/12 mb-6">
            <div className="mb-6 lg:h-[800px]">
              <Advertisement
                rootClassName="lg:sticky lg:top-[60px]"
                adUnitProps={{
                  type: AdvertisementTypeEnum.IMU2,
                  slot: GoogleAdsSlotFactory.imu2(slot),
                }}
              />
            </div>

            <BreakingNews
              data={breakingNews}
              rootClassName="max-w-[300px] mx-auto mb-6"
            />

            <MostPopular
              data={mostPopular}
              rootClassName="max-w-[300px] mx-auto"
            />
          </Column>
        </Row>
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(slot),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </div>
  );
}
