import { Link } from "react-router-dom";
import Button from "@components/AccessibleComponents/Button";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ARTICLE_SUBSHARE_PROMPT_SUBSCRIBE_LINK } from "./constants";

export type ArticleSubsharePromptContentProps = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ArticleSubsharePromptContent({
  setIsModalOpen,
}: ArticleSubsharePromptContentProps) {
  return (
    <>
      <Button
        className="absolute right-4 top-4 outline-none"
        onPress={() => {
          setIsModalOpen(false);
        }}
      >
        <FontAwesomeIcon icon={faXmark} size="xl" />
      </Button>

      <div className="flex flex-col items-center p-8">
        <h1 className="mb-3 font-poppins text-4xl font-semibold text-black">
          Get unlimited access to premium BT stories
        </h1>
        <p className="font-poppins text-base">
          You, too, can read and gift premium stories with a BT subscription
        </p>

        <Link
          to={ARTICLE_SUBSHARE_PROMPT_SUBSCRIBE_LINK}
          onClick={() => {
            setIsModalOpen(false);
          }}
          className="w-full rounded-full bg-orange-300 px-9 py-3 font-poppins text-base font-semibold text-white outline-none"
          target="_blank"
          reloadDocument
        >
          SUBSCRIBE
        </Link>
      </div>
    </>
  );
}
