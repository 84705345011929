/**
 * Company: SPHMedia
 * Description: Section Divider component to separate bewteen the hero and news component
 */

import { ReactElement } from "react";

export type SectionDividerProps = {
  title?: string;
};

/**
 * SectionDivider
 * Description: Component that displays the divider text between the hero and the story content
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function SectionDivider({
  title = "This week on BT lifestyle",
}: SectionDividerProps): ReactElement {
  return (
    <>
      <div className="mb-10 mt-8 h-fit w-full text-center lg:mb-16">
        <h1 className="font-pt-sans text-6xl font-bold uppercase">{title}</h1>
      </div>
    </>
  );
}
