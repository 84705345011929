/**
 * Company: SPHMedia
 * Description: LuxeCard component
 */
import { RouteFactory } from "@app/routePaths";
import { Type } from "@app/types/enums";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import { getCardThumbnail } from "@components/ArticleCard/helpers/helpers";
import { BasicCardProps } from "@components/ArticleCard/types";
import { faCircle, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Category from "@src/app/components/ArticleMeta/Category";
import Description from "@src/app/components/ArticleMeta/Description";
import Kicker from "@src/app/components/ArticleMeta/Kicker";
import ReadMore from "@src/app/components/ArticleMeta/ReadMore";
import SubscriberFlag from "@src/app/components/ArticleMeta/SubscriberFlag";
import TitleLink from "@src/app/components/ArticleMeta/TitleLink";
import ArticleThumbnail from "@src/app/components/ArticleThumbnail/ArticleThumbnail";
import { getFormattedRedirectionUrl } from "@util/helpers";
import cx from "classnames";

/**
 * LuxeCard
 * Description: Component to display the text portion in luxehero
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function LuxeCard({
  rootClassName,
  id,
  media,
  kicker = "",
  section,
  title = "",
  paywall = false,
  blurb = "",
  defaultImage,
  slug,
}: BasicCardProps): React.ReactElement {
  const cardThumbnail = getCardThumbnail(title, media, defaultImage);
  const luxeCardImageFilter: CaaSImageFilters[] = [
    { breakpoint: "(min-width: 768px)", w: 750, h: 500, dpr: 1 },
    { w: 450, h: 300, dpr: 1 },
  ];

  const _media = media && "length" in media ? media?.[0] : media;

  const isVideo =
    _media &&
    (_media.content?.type === Type.ArticleBrightcoveVideo ||
      _media.content?.type === Type.ArticleYoutubeVideo);
  const articleLink = slug
    ? getFormattedRedirectionUrl(slug)
    : RouteFactory.article(id);

  return (
    <>
      <div
        className={cx("relative my-7", rootClassName)}
        data-testid="luxe-card"
      >
        <div className="container">
          <div className="flex flex-wrap-reverse px-3 lg:flex-nowrap lg:space-x-4">
            <div className="w-full xs:max-lg:mt-4 lg:w-1/2">
              <div className="flex flex-col space-y-2 pl-4 lg:pl-0">
                {section?.name ? (
                  <Category
                    hrefLink={RouteFactory.section(
                      section.uniqueName.replace("_", "/")
                    )}
                    categoryName={section.name}
                    rootClassName="text-4xs ml-2 bg-lifestyle-primary shadow-lightBlue flex-none w-fit"
                  />
                ) : null}

                {kicker ? (
                  <Kicker name={kicker} rootClassName="font-pt-sans" />
                ) : null}

                <TitleLink
                  title={title}
                  articleId={id}
                  rootClassName="text-8xl lg:text-11xl !leading-[1.2] hover:text-lifestyle-primary font-pt-serif"
                  linkRootClassName="!inline"
                  slug={slug}
                />

                {paywall ? (
                  <SubscriberFlag rootClassName="inline leading-1.4 text-4xs font-normal uppercase w-fit mt-1" />
                ) : null}

                <div>
                  <Description
                    text={blurb}
                    rootClassName="hidden lg:block font-pt-sans text-gray-750 text-xs font-normal leading-5 word-break"
                  />
                </div>

                <ReadMore
                  articleId={id}
                  isAnimated
                  rootClassName="!mt-5 py-1 font-pt-sans !w-[125px] leading-normal"
                  slug={slug}
                />
              </div>
            </div>
            <div className="relative w-full lg:w-1/2">
              <ArticleThumbnail
                thumbnail={cardThumbnail}
                imgRootClassName="w-full"
                orientation="landscape"
                filters={luxeCardImageFilter}
                articleLink={articleLink}
              />
              {isVideo ? (
                <div>
                  <FontAwesomeIcon
                    icon={faCircle}
                    inverse
                    className="fa-stack-2x"
                  />
                  <FontAwesomeIcon icon={faPlay} className="fa-stack-1x" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
