import { useEffect, useRef, useState } from "react";

/**
 * Description: Return the container for the header breadcrumbs
 * @returns html element(ref) and boolean
 */
export const useGetHeaderBreadcrumbsContainerWidth = () => {
  // Handles state for nav bar variation when header sticks to top of window
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const handleReset = () => {
      if (containerRef?.current?.clientWidth) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleReset);
      handleReset();
    }

    return () => {
      window.removeEventListener("resize", handleReset);
    };
  }, []);

  return { containerWidth, containerRef };
};
