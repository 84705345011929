import { useState } from "react";
import { ArticleDataObject } from "@app/types/Cue";
import { NewsletterArchiveKeywordsEnums } from "@app/types/enums";
import LoadMore from "@components/LoadMore/LoadMore";
import NewsletterPost from "@pages/Newsletter/Archive/components/NewsletterPost";
import { fetchMoreStoriesData } from "@pages/Section/Section.server";
import { STORIES_PAGE_SIZE } from "@util/constant";

export type NewsletterArchiveArticlesListProps = {
  articles: ArticleDataObject[];
  newsletterKeyword: NewsletterArchiveKeywordsEnums;
};

export default function NewsletterArchiveArticlesList({
  articles = [],
  newsletterKeyword,
}: NewsletterArchiveArticlesListProps): React.ReactElement {
  const [moreArticles, setMoreArticles] =
    useState<ArticleDataObject[]>(articles);
  const [hasMoreStories, setHasMoreStories] = useState(true);

  const handleLoadMore = async () => {
    const page = moreArticles.length / STORIES_PAGE_SIZE + 1;

    const newArticles = await fetchMoreStoriesData(
      newsletterKeyword,
      "keywords",
      page
    );

    setMoreArticles([...moreArticles, ...newArticles]);

    if (newArticles.length < STORIES_PAGE_SIZE) {
      setHasMoreStories(false);
    }
  };

  return (
    <div className="flex flex-wrap">
      <div className="hidden w-full lg:block lg:w-1/6">
        <h2 className="pt-4 font-poppins text-xl font-semibold">PAST ISSUES</h2>
      </div>

      <div className="flex w-full flex-row items-center justify-between pt-4 lg:hidden">
        <h2 className="font-poppins text-xl font-semibold">PAST ISSUES</h2>

        <span className="relative pl-4 text-base leading-[.3] text-orange-300 before:content-['\25CF']">
          <span className="pl-1 font-public-sans text-4xs font-normal uppercase text-gray-500">
            Subscribers
          </span>
        </span>
      </div>

      <div className="w-full pb-20 pt-4 lg:w-5/6 lg:pb-24">
        {moreArticles.map((article, index) => (
          <NewsletterPost
            rootClassName={
              index !== moreArticles.length - 1
                ? "mb-2 border-b-2 border-gray-250 pt-2"
                : ""
            }
            key={article.title}
            {...article}
          />
        ))}

        {moreArticles.length >= STORIES_PAGE_SIZE ? (
          <LoadMore
            rootClassName="my-4 !border-gray-250"
            onLoadMore={handleLoadMore}
            loadText="SHOW MORE"
            hasMore={hasMoreStories}
          />
        ) : null}
      </div>
    </div>
  );
}
