import Zoom from "react-medium-image-zoom";
import { Field, Relation } from "@app/types/Cue";
import { Name, Type } from "@app/types/enums";
import { cn, getFieldValue } from "@util/helpers";

import "react-medium-image-zoom/dist/styles.css";

export type ArticleInlineImageProps = {
  className?: string;
  fields: Field[];
  relation: Relation;
};

export default function ArticleInlineImage({
  className,
  fields,
  relation,
}: ArticleInlineImageProps): React.ReactElement {
  // @TODO Include caption in the popup.
  const cropSelection = getFieldValue(fields, Name.CropSelection);
  const caption = getFieldValue(fields, Name.Caption);
  const alignment = getFieldValue(fields, Name.Alignment);
  const displayCaption = getFieldValue(fields, Name.DisplayCaption);
  const credit = getFieldValue(fields, Name.Credit);
  const cssClass = getFieldValue(fields, Name.CssClass);
  const cssClassString = typeof cssClass === "string" ? cssClass : undefined;

  // More available data from CUE. We dont have a use of them, yet,
  // But we want to keep it here so we are aware of them.
  // const byline = getFieldValue(fields, Name.Byline);
  // const titleColor = getFieldValue(fields, Name.TitleColor);
  // const displayFullWidth = getFieldValue(fields, Name.DisplayFullWidth);
  // const convertToParallax = getFieldValue(fields, Name.ConvertToParallax);

  const imageMetadata =
    relation.fields?.[
      `${cropSelection}-caas` as keyof typeof relation.fields
    ] || relation.fields?.[cropSelection as keyof typeof relation.fields]; // Fallback to use non-caas image url if caas is not available

  if (typeof imageMetadata === "string") return <></>;
  if (typeof imageMetadata === "undefined") return <></>;

  return (
    <>
      {"url" in imageMetadata ? (
        <div
          className={className}
          data-story-element={Type.Image}
          data-testid="article-inline-image-component"
        >
          <figure
            className={cn(
              "table",
              {
                "mx-auto": alignment === "full" || alignment === "center",
                "ml-auto": alignment === "right",
              },
              cssClassString
            )}
          >
            <Zoom zoomMargin={32} classDialog="medium-zoom">
              <img
                src={imageMetadata.url}
                height={imageMetadata.height}
                width={imageMetadata.width}
                className={cn("w-auto max-w-full")}
                alt=""
              />
            </Zoom>

            {displayCaption && (caption || credit) ? (
              <figcaption className="mt-1 table-caption caption-bottom text-3xs text-gray-525">
                {caption} {credit}
              </figcaption>
            ) : null}
          </figure>
        </div>
      ) : null}
    </>
  );
}
