import { TestIdClassNameProps } from "@app/types/Common";
import { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { getArticleBlurb, getKickerFromArticleDataObject } from "@util/helpers";

export type MainArticleProps = TestIdClassNameProps & {
  articles: ArticleDataObject[];
};
export default function MainArticle({
  testId,
  articles = [],
}: MainArticleProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const isSubscriber = OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  return (
    <>
      {articles.length > 0 ? (
        <div
          className="mb-4 border-b border-gray-175 pb-4"
          data-testid={testId}
        >
          {articles.map((article) => {
            const { id, title, media, sections, slug } = article;
            const options: CardVariations = {
              image: {
                width: "w-1/2",
                position: "left",
              },
              content: {
                width: "w-1/2",
                extraClass: "pl-4",
              },
              title: {
                size: "text-base lg:text-4xl",
                color: "text-gray-850",
              },
              kicker: {
                color: "text-gray-515",
              },
            };
            const mainSection = sections?.[0];

            return (
              <BasicCard
                key={id}
                id={id}
                slug={slug}
                media={media}
                section={mainSection}
                title={title}
                paywall={
                  isSubscriber ? article.paywall?.contentAccess === "1" : false
                }
                variations={options}
                kicker={getKickerFromArticleDataObject(article)}
                blurb={getArticleBlurb(article)}
                defaultImage={
                  mainSection
                    ? { directoryName: mainSection.uniqueName }
                    : undefined
                }
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}
