import ArticleSubshareModal from "./ArticleSubshareModal";
import ArticleSubsharePromptContent from "./ArticleSubsharePromptContent";

type ArticleSubsharePromptProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ArticleSubsharePrompt({
  isModalOpen,
  setIsModalOpen,
}: ArticleSubsharePromptProps): React.ReactElement {
  return (
    <ArticleSubshareModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <ArticleSubsharePromptContent setIsModalOpen={setIsModalOpen} />
    </ArticleSubshareModal>
  );
}
