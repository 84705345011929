import { memo } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import chevronLeft from "@assets/icon-chevron-left-white.svg";
import pulseLogoSvg from "@assets/pulse-logo.svg";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export type PulseStartingScreenProps = {
  handleStartCarousel: () => void;
};

function PulseStartingScreen({
  handleStartCarousel,
}: PulseStartingScreenProps): React.ReactElement {
  const pulseLogo = "https://s3.sph.pm/pulseAnimation.lottie?v=2";

  return (
    <div className="flex h-svh w-svw items-center justify-center">
      <div
        className={
          "relative h-[650px] w-[430px] rounded-md bg-gradient-10 from-[#373737] to-[#2E2E2E] p-[1px] shadow-pulseActive"
        }
      >
        <div className="flex h-full flex-col items-center justify-center bg-[#212121] shadow-pulseActive">
          <p className="text-center font-poppins text-base font-light uppercase leading-normal tracking-widest text-gray-515">
            Welcome to
          </p>

          <div className="relative mb-4 h-[150px] w-[150px] rounded-full">
            <div className="absolute h-full w-full rounded-full bg-white/65 blur-lg" />
            <img
              className="absolute h-full w-full rounded-full"
              src={pulseLogoSvg}
              width={150}
              height={150}
              alt="pulse"
            />
            <div className="relative z-10 h-full w-full rounded-full">
              <DotLottieReact src={pulseLogo} loop autoplay />
            </div>
          </div>

          <p className="text-center font-poppins text-5xl font-light uppercase italic leading-10 tracking-20% text-gray-250 lg:text-11xl">
            pulse
          </p>

          <div className="mb-10 text-center font-poppins text-base font-light uppercase leading-normal tracking-20% text-gray-515 lg:mb-16">
            your 5 minutes <br />
            daily news summary
          </div>

          <button
            onClick={handleStartCarousel}
            className="inline-flex h-14 items-center justify-center gap-1 rounded-[30px] border border-gray-175 bg-white px-16 py-4"
          >
            <p className="tracking-tight font-['Poppins'] text-sm font-semibold leading-tight text-gray-850">
              Start
            </p>
          </button>
        </div>
      </div>

      <div className="absolute bottom-0 block h-[62px] w-full items-center justify-between bg-black px-3 py-4 lg:hidden">
        <Link
          reloadDocument
          to={RouteFactory.home}
          className="inline-flex w-full items-center font-poppins text-sm font-semibold tracking-2% text-white"
        >
          <img
            src={chevronLeft}
            className="h-6 w-6"
            alt="left arrow"
            width={24}
            height={24}
          />
          Back to BT
        </Link>
      </div>
    </div>
  );
}

export default memo(PulseStartingScreen);
