import parse from "html-react-parser";

export type NewsletterContributorProps = {
  imageSrc: string;
  quote: string;
  name: string;
};

export default function NewsletterContributor({
  imageSrc,
  quote,
  name,
}: NewsletterContributorProps): React.ReactElement {
  return (
    <div
      data-testid="newsletter-contributor"
      className="mb-8 flex flex-col lg:flex-row"
    >
      <div className="mb-4 w-4/20 lg:mb-0 lg:mr-3 lg:w-3/20">
        <img
          className="rounded-full"
          src={imageSrc}
          width="95"
          height="95"
          alt={name}
        />
      </div>

      <div className="w-full text-gray-850">
        <p className="mb-1 font-lct text-xl font-normal">{parse(quote)}</p>

        <p className="font-public-sans text-base font-normal">{name}</p>
      </div>
    </div>
  );
}
