import { ThriveSideMenuWrapper } from "./ThriveSideMenuWrapper";
import ThriveSideNavigation from "./ThriveSideNavigation";

interface SideMenuProps {
  isSideMenuOpen: boolean;
}

export default function ThriveSideMenu({
  isSideMenuOpen,
}: SideMenuProps): React.ReactElement {
  return (
    <ThriveSideMenuWrapper isOpen={isSideMenuOpen}>
      <ThriveSideNavigation />
    </ThriveSideMenuWrapper>
  );
}
