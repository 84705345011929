import { EmbeddedPost, FacebookProvider } from "react-facebook";
import { FB_APP_ID } from "@components/MetaTags/constants";
import { useWindowSize } from "@src/app/hooks/useWindowSize";

export type FacebookEmbedCustomProps = {
  url: string;
};

export default function FacebookEmbedCustom({
  url,
}: FacebookEmbedCustomProps): React.ReactElement {
  const { isScreenSM } = useWindowSize();

  return (
    <FacebookProvider appId={FB_APP_ID}>
      <div className="flex justify-center">
        <EmbeddedPost
          href={url}
          width={isScreenSM ? "500px" : "auto"}
          lazy
          showText
        />
      </div>
    </FacebookProvider>
  );
}
