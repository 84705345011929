import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { slidesData } from "@pages/Thrive/constants";
import cx from "classnames";

import "@greglaisph/react-splide/css";

export function ThriveSlider() {
  const options: Options = {
    perPage: 1,
    gap: "0.875rem",
    pagination: false,
    mediaQuery: "min",
    arrows: false,
    autoWidth: true,
    breakpoints: {
      1024: {
        perPage: 3,
        gap: "2.25rem",
      },
      992: {
        perPage: 4,
        gap: "2.25rem",
      },
    },
  };

  return (
    <Splide options={options} hasTrack={false}>
      <SplideTrack className="pb-4">
        {slidesData.map(({ image, label1, label2 }, index) => (
          <SplideSlide
            key={label1}
            className={cx(
              { "pl-9 md:pl-16 lg:pl-0": index == 0 },
              { "pr-8 md:pr-0": index == 3 }
            )}
          >
            <div className="relative">
              <div className="bg-gradient-to-r from-orange-400 from-0% via-orange-400 via-100% to-white">
                <img
                  className="h-[170px] w-[170px] mix-blend-multiply md:h-[185px] md:w-[185px]"
                  src={image}
                  alt={"Slide Image " + index}
                />
              </div>
              <h3 className="absolute -bottom-2 mx-2 ml-7 mr-5 inline text-end font-inter text-lg font-medium uppercase leading-normal text-white">
                <span className="inline-block bg-gray-850 p-2.5">{label1}</span>
                <span className="inline-block bg-gray-850 p-2.5">{label2}</span>
              </h3>
            </div>
          </SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  );
}
