import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdvertisementTypeEnum } from "@app/types/enums";
import {
  cn,
  getEnvironmentDomain,
  GoogleAdsSlotFactory,
} from "@app/util/helpers";
import Button from "@components/AccessibleComponents/Button";
import TextInput from "@components/AccessibleComponents/TextInput";
import Advertisement from "@components/Advertisement/Advertisement";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import { NEWSLETTER_UNSUBSCRIBE_PAGE_LINK } from "@components/Newsletter/constants";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newsletterRequests } from "@pages/Newsletter/Unsubscribe/NewsletterUnsubscribe.server";
import { ENVIRONMENT } from "@util/constant";

export default function NewsletterUnsubscribe(): React.ReactElement {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigateTo = useNavigate();
  const ENVIRONMENT_DOMAIN = getEnvironmentDomain(ENVIRONMENT);

  const { search, pathname } = useLocation();
  const url = new URL(`${ENVIRONMENT_DOMAIN}${pathname}${search}`);
  const urlParams = new URLSearchParams(url.search);

  const editionIdParams = urlParams.get("edition");
  const editionId = editionIdParams !== null ? editionIdParams : "";

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const target = event.target as typeof event.target & {
      email: { value: string };
    };

    const request = async () => {
      await newsletterRequests
        .processUnSubscription(target.email.value, editionId)
        .then((response) => {
          if (
            response.status === "error" &&
            response.message === "Something went wrong!"
          )
            setErrorMessage(response.message);
          if (response.status === "error")
            setErrorMessage("Please enter a valid email address.");
          if (response.status === "success")
            navigateTo(`/unsubscription?edition=${editionId}&sc=1`);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    request();
  };

  return (
    <>
      <MetaTags
        title="THE BUSINESS TIMES - Get the Latest Business & Financial News"
        description="Read more at The Business Times. UnSubscriber Here."
        slug={NEWSLETTER_UNSUBSCRIBE_PAGE_LINK}
      />

      <GAData title="big_money" level2="newsletter" />

      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(NEWSLETTER_UNSUBSCRIBE_PAGE_LINK),
          adsClassName: "min-h-[90px]",
        }}
      />

      <Header />

      <Container rootClassName="py-3">
        <div
          className={cn("w-full")}
          data-testid="newsletter-unsubscribe-component"
        >
          {errorMessage ? (
            <div className="mt-1 rounded-md bg-red-100 p-8 font-poppins text-xs font-semibold text-red-300">
              <h2 className="border-b-1 inline">Error message</h2>
              <p>{errorMessage}</p>
            </div>
          ) : null}
          <h3 className="mb-2 pb-3 font-poppins lg:text-8xl">
            Unsubscribe from this newsletter
          </h3>
          <form
            className={cn(
              "ml-auto w-full items-center justify-between pb-1",
              errorMessage ? "border-red" : "border-gray-850"
            )}
            onSubmit={handleSubmit}
          >
            <label
              htmlFor={`input-email`}
              className="font-poppins text-base text-[#212529]"
            >
              Please enter your email address
            </label>
            <TextInput
              className={cn(
                "mb-3 mt-2 w-full rounded-md border px-3 py-1.5 font-poppins",
                errorMessage ? "!border-red" : "text-gray-850"
              )}
              name="email"
              type="email"
              inputMode="email"
              defaultValue={""}
              required
              size={60}
              maxLength={254}
            />
            <Button
              className="rounded bg-[#0D6EFD] px-4 py-2 font-poppins text-base font-normal text-white"
              type="submit"
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Unsubscribe"
              )}
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
}
