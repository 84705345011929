import { useState } from "react";
import { newsletterLinks } from "@components/Newsletter/constants";
import { formatTitle } from "@components/Newsletter/helper";
import cx from "classnames";

interface NewsletterAnchorLinksProps {
  scrollToNewsletterSection: (newsletter: string) => void;
}

export default function NewsletterAnchorLinks({
  scrollToNewsletterSection,
}: NewsletterAnchorLinksProps): React.ReactElement {
  const [activeLink, setActiveLink] = useState<string>(() => {
    // Set the default active link to daily-newsletters
    return formatTitle(newsletterLinks[0].linkText);
  });

  const handleClick = (hashValue: string) => {
    scrollToNewsletterSection(hashValue);
    setActiveLink(hashValue);
  };

  return (
    <div id="newsletter-anchors" className="hidden md:block">
      {newsletterLinks.map(({ linkText }) => {
        const hashValue = formatTitle(linkText);

        return (
          <span
            key={hashValue}
            data-testid={hashValue}
            className={cx(
              "mb-2",
              "cursor-pointer",
              "block",
              "font-poppins",
              "text-base",
              "font-medium",
              {
                "text-black underline": activeLink === hashValue,
                "text-gray-550 hover:underline": activeLink !== hashValue,
              }
            )}
            onClick={() => {
              handleClick(hashValue);
            }}
          >
            {linkText}
          </span>
        );
      })}
    </div>
  );
}
