import { TestIdClassNameProps } from "@app/types/Common";
import imageClose from "@assets/icon-close-white.svg";
import Button from "@components/AccessibleComponents/Button";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

import { EmarsysResponse } from "./types/Emarsys";
import { Newsletter } from "./types/Newsletter";

export type NewsletterSuccessPanelProps = TestIdClassNameProps & {
  message: string;
  handleSelectedNewsletter: (
    action: "add" | "remove" | "reset",
    newsletters?: Newsletter
  ) => void;
  setEmarsysResponse: (response?: EmarsysResponse) => void;
};

export default function NewsletterSuccessPanel({
  testId,
  rootClassName,
  message,
  handleSelectedNewsletter,
  setEmarsysResponse,
}: NewsletterSuccessPanelProps): React.ReactElement {
  return (
    <div
      className={cx(
        rootClassName,
        "relative flex items-center justify-center gap-4 bg-gray-850 px-4 py-8"
      )}
      data-testid={testId}
    >
      <FontAwesomeIcon
        icon={faCheck}
        className="aspect-square rounded-full border border-white p-3 text-white"
      />

      <h6 className="font-base poppins font-semibold text-white">{message}</h6>

      <Button
        className="absolute right-4 top-4 opacity-75 transition-opacity hover:opacity-100"
        onPress={() => {
          handleSelectedNewsletter("reset");
          setEmarsysResponse(undefined);
        }}
      >
        <img
          src={imageClose}
          width={16}
          height={16}
          className="aspect-square"
        />
      </Button>
    </div>
  );
}
