import { renderLimeGreenLink } from "@pages/Newsletter/Archive/util/helpers";

const links = [
  {
    href: "https://www.businesstimes.com.sg/companies-markets/consumer-healthcare/lvmh-sales-fall-3-china-demand-luxury-goods-worsens",
    text: "ended its post-pandemic growth streak",
  },
  {
    href: "https://www.businesstimes.com.sg/international/global/bernard-arnaults-us37-billion-wealth-loss-comes-luxury-dims-china",
    text: "said",
  },
  {
    href: "https://www.businesstimes.com.sg/companies-markets/sasseur-reit-hits-record-276-1-million-yuan-golden-week-sales-chinas-domestic-consumption-surges",
    text: "record-breaking sales",
  },
  {
    href: "https://www.businesstimes.com.sg/international/chinas-briefing-stimulus-gets-lukewarm-investor-reception",
    text: "proposed measures",
  },
  {
    href: "https://www.businesstimes.com.sg/international/china-holiday-spending-lags-pre-covid-era-showing-stimulus-need",
    text: "almost 16 per cent higher",
  },
  {
    href: "https://www.businesstimes.com.sg/international/china-says-raised-serious-concerns-us-over-trade-curbs",
    text: "ratcheted upwards",
  },
  {
    href: "https://www.businesstimes.com.sg/companies-markets/false-alarm-no-longer-recession-fears-continue-spook-us-stock-market",
    text: "on the brink of a possible recession",
  },
  {
    href: "https://www.businesstimes.com.sg/international/global/china-may-raise-6-trillion-yuan-new-debt-over-three-years-spur-growth-says-report",
    text: "Reports abound",
  },
  {
    href: "https://www.businesstimes.com.sg/opinion-features/dear-uol-board-dont-stay-crouching-tiger-unleash-hidden-dragon-stock",
    text: "staggering market discount",
  },
  {
    href: "https://www.businesstimes.com.sg/companies-markets/singapores-moribund-ipo-market-looks-reits-lifeline",
    text: "anaemic initial public offering (IPO) scene",
  },
  {
    href: "https://www.businesstimes.com.sg/opinion-features/considerations-sgx-bursa-malaysias-success",
    text: "a few leaves from Bursa Malaysia’s book",
  },
];

export default function NewsletterSampleContent(): React.ReactElement {
  return (
    <>
      <div className="mx-auto w-full max-w-[600px] border-collapse border-t-4 border-gray-150 py-6">
        <h3 className="m-0 font-times text-[20px] font-bold leading-6 text-gray-700">
          In this issue:
        </h3>

        <div className="free-text font-times text-[20px] font-normal leading-6 text-gray-700">
          <ul className="mx-0 my-4 block list-disc pl-10 font-times text-[20px] font-normal leading-6 text-gray-700">
            <li>
              LVMH and Sasseur Reit give us a glimpse of the Chinese consumer
            </li>

            <li>UOL&#39;s valuation gap and what it can do about it</li>
          </ul>
        </div>
      </div>
      <div className="border-t-4 border-gray-150 py-6">
        <div className="text-start font-times text-xl font-normal leading-6 text-gray-700">
          <p className="m-0 mb-2.5">
            <br />
            Good morning, BT readers.
          </p>
          <p className="m-0 mb-2.5">
            <i>Big Money</i> is back after a brief hiatus, with a new face
            (mine) being thrust into your inbox every Monday morning. My face
            might be new to most readers, but it previously spent more than a
            decade glowering at Mr Market and newsmakers when I covered
            financial news for <i>The Business Times.</i>
          </p>
          <p className="m-0 mb-2.5">
            While much has changed since I first started in the business, this
            immortal line from Coolio’s Gangsta’s Paradise remains true for many
            of us – in that we’re educated fools with money on our minds. The
            market takes random walks and we’re at the mercy of mercurial macro
            forces – but we will keep chasing those sweet, sweet alpha returns
            regardless.
          </p>
          <p className="m-0 mb-2.5">
            We are cursed to live in interesting times, but as we navigate the
            markets, may we become better-educated fools and may the money on
            our minds become money in our pockets.
          </p>
          <p className="m-0 mb-2.5">
            Speaking of pockets, a tale of two kinds of Chinese consumers
            emerged last week, as updates from LVMH and Sasseur Reit gave us a
            glimpse of what to expect beyond the Great Wall.
          </p>
        </div>
      </div>
      <div>
        <div className="editors-choice-image-head mx-auto flex items-center justify-center">
          <img
            src="https://static1.businesstimes.com.sg/s3fs-public/what-is-happening.png"
            className="mx-auto w-[335px]"
          ></img>
        </div>
        <div className="pt-3 text-start font-times text-xl font-normal leading-6 text-gray-700">
          <p className="m-0 mb-2.5">
            Luxury goods heavyweight LVMH{" "}
            {renderLimeGreenLink(
              "ended its post-pandemic growth streak",
              links[0].href
            )}{" "}
            last week by posting a 3 per cent dip in Q3 organic sales and
            missing the street’s expectations. It was Chinese customers that let
            the side down, with sales in Asia (excluding Japan) – where China
            dominates – plummeting 16 per cent.
          </p>
          <p className="m-0 mb-2.5">
            “Consumer confidence in mainland China today is back in line with
            the all-time low reached during Covid,” LVMH’s chief financial
            officer Jean-Jacques Guiony{" "}
            {renderLimeGreenLink("said", links[1].href)}
          </p>
          <p className="m-0 mb-2.5">
            Post-earnings release, LVMH’s share price dropped as much as 7.5 per
            cent to a two-year low last week.
          </p>
          <p className="m-0 mb-2.5">
            But like calories, consumers are not created equal. Sasseur Reit, an
            outlet mall trust, saw a far better spending lollapalooza during
            Golden Week, posting{" "}
            {renderLimeGreenLink("record-breaking sales", links[2].href)}.
            During the period that spanned the first week of October, sales
            across its outlets were up nearly 28 per cent year on year and up 55
            per cent from pre-pandemic levels. The Reit attributed this to
            synchronising its outlet anniversary celebrations, which started in
            September and were then followed by Golden Week promotions.
          </p>
        </div>
      </div>
      <div>
        <div className="editors-choice-image-head mx-auto flex items-center justify-center">
          <img
            src="https://static1.businesstimes.com.sg/s3fs-public/why-it-matters.png"
            className="mx-auto w-[335px]"
          ></img>
        </div>
        <div className="pt-3 text-start font-times text-xl font-normal leading-6 text-gray-700">
          <p className="m-0 mb-2.5">
            The contrasting LVMH and Sasseur Reit scenarios tell us that Chinese
            consumers’ purses are bulging, but it will take some convincing for
            them to loosen the purse strings. They might be doing it more
            readily for discounted items at outlet stores than for full-price
            handbags at Louis Vuitton – but they’re doing it, regardless.
          </p>
          <p className="m-0 mb-2.5">
            Also, mere news about good news is enough to move the needle. While
            none of the Chinese government’s{" "}
            {renderLimeGreenLink("proposed measures", links[3].href)} have
            worked their way through the system yet, even the anticipation of
            stimulus might set off a self-fulfilling cycle.
          </p>
          <p className="m-0 mb-2.5">
            While 2024’s Golden Week spending in October still has not recovered
            to pre-pandemic levels, its daily spend was{" "}
            {renderLimeGreenLink("almost 16 per cent higher", links[4].href)}{" "}
            than during China’s Labour Day holidays earlier this year. This
            suggests that consumer sentiment has improved in the intervening
            five months, even as US-China trade tensions have{" "}
            {renderLimeGreenLink("ratcheted upwards", links[5].href)}.
          </p>
          <p className="m-0 mb-2.5">
            Investors are itching to deploy money into the Chinese market,
            especially as the US stands{" "}
            {renderLimeGreenLink(
              "on the brink of a possible recession",
              links[6].href
            )}{" "}
            and there are ample investment ideas being proffered.
          </p>
          <p className="m-0 mb-2.5">
            Analysts from CGS International, for example, have highlighted 25
            potential Singapore companies that could benefit from a turnaround
            in China. Among them are Wilmar International, which gets almost
            half its revenue from China, as well as CapitaLand Investment and
            Mapletree Logistics Trust, which have significant exposure to China.
          </p>
          <p className="m-0 mb-2.5">
            This is a rare case in which picking stocks is the easy part, and
            the question of timing is the more vexing one.{" "}
            {renderLimeGreenLink("Reports abound", links[7].href)} that the
            Chinese government may raise an additional six trillion yuan (S$1.1
            trillion) in debt over the next three years, which will go towards
            sorely needed fiscal stimulus. This seems like a great deal of
            stimulus until you consider that overall public debt is estimated at
            US$16 trillion, making the additional funds more of a handheld
            revolver than the awaited bazooka.
          </p>
          <p className="m-0 mb-2.5">
            The recent burst of Golden Week spending is akin to the recent
            run-up in Chinese stocks: a lack of dry powder isn’t the issue here;
            the lack of a bazooka is. This is China’s economic recovery to lose.
          </p>
        </div>
      </div>
      <div>
        <div className="editors-choice-image-head mx-auto flex items-center justify-center">
          <img
            src="https://static1.businesstimes.com.sg/s3fs-public/the-big-number.png"
            className="mx-auto w-[335px]"
          ></img>
        </div>
        <div className="pt-3 text-start font-times text-xl font-normal leading-6 text-gray-700">
          <div className="number-style pb-13.5 pb-14 pt-6 text-center text-14xl leading-tight text-gray-700">
            59%
          </div>
          <p className="mb-4">
            This is UOL Group’s{" "}
            {renderLimeGreenLink("staggering market discount", links[8].href)}{" "}
            to end-June’s net asset value (NAV), which BT’s senior correspondent
            Leslie Yee highlighted last week. Despite the property developer’s
            savvy investments and solid execution, the gulf between its stock
            price and NAV per share is as wide as the Mariana Trench is deep.
          </p>

          <p className="mb-4">
            “The NAV does not capture potential profit from housing development
            projects or the upside from marking to market the value of hotel
            properties carried under property, plant and equipment,” Yee pointed
            out in his column last week.
          </p>

          <p className="mb-4">
            To remedy this, he suggests that UOL explore capital-efficient ways
            of holding its assets, such as through listed or unlisted property
            funds. The group could also free up capital by reducing its
            ownership stake in assets while boosting its return on equity by
            earning fund management fee income.
          </p>

          <p className="mb-4">UOL, I hope you’re taking notes.</p>

          <p className="mb-4">
            The property developer’s plight exists alongside a wider malady.
            Poor valuations continue to plague Singapore listcos, alongside
            lower liquidity and an{" "}
            {renderLimeGreenLink(
              "anaemic initial public offering (IPO) scene",
              links[9].href
            )}{" "}
            . Some market watchers reckon that Singapore could take{" "}
            {renderLimeGreenLink(
              "a few leaves from Bursa Malaysia’s book",
              links[10].href
            )}{" "}
            to combat this. This includes making the IPO process more flexible
            from a regulatory standpoint and making retail investor
            participation more compelling.{" "}
          </p>

          <p className="mb-4">SGX and MAS, you can take notes, too.</p>

          <p className="mb-4">
            <i>
              (Disclosure: I own shares in LVMH, Sasseur Reit, CapitaLand
              Investment and Mapletree Logistics Trust.)
            </i>
          </p>
        </div>
      </div>

      <div className="mt-3 flex justify-center border-t-4 border-gray-150 pb-3 pt-12">
        <a
          target="_blank"
          href="https://www.businesstimes.com.sg/newsletter/sign-up"
          rel="noreferrer"
        >
          <img
            width={300}
            height={250}
            src="https://static1.businesstimes.com.sg/s3fs-public/image/2024/05/09/big-money-nl-banner-imu.gif?VersionId=ssfowKq0Vy4YVehq73.aY8uJWo.iZoJP"
          />
        </a>
      </div>
    </>
  );
}
