import { ReactElement } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";

export type WealthThirdRowProps = {
  invest: SanitizedSectionArticle[];
  advisor: SanitizedSectionArticle[];
  asset: SanitizedSectionArticle[];
};

export default function WealthThirdRow({
  invest,
  advisor,
  asset,
}: WealthThirdRowProps): ReactElement {
  const columns = [
    {
      articles: invest,
      blockTitle: "INVEST",
    },
    {
      articles: advisor,
      blockTitle: "TRUSTED ADVISOR",
    },
    {
      articles: asset,
      blockTitle: "ASSET MANAGER",
    },
  ];

  const variant: CardVariations = {
    content: {
      width: "w-7/10 lg:w-full",
      extraClass: "p-4 lg:pl-0 lg:pt-4 h-auto",
    },
    image: {
      width: "w-3/10 lg:w-full",
      position: "left",
    },
    kicker: {
      color: "text-gray-515",
    },
  };

  return (
    <>
      <Row data-testid="wealth-third-row-component">
        <Column rootClassName="w-full">
          <div className="border-t border-black pt-3"></div>
        </Column>

        <>
          {columns.map(({ articles, blockTitle }) => {
            return (
              <Column
                rootClassName="pb-6 w-full md:w-6/12 lg:w-4/12 flex flex-col"
                key={blockTitle}
              >
                <BlockTitle text={blockTitle} rootClassName="mb-3" />

                <>
                  {articles.map((article) => {
                    const {
                      id,
                      media,
                      title,
                      paywall,
                      blurb,
                      updated,
                      displaySetting,
                      kicker,
                      slug,
                    } = article;

                    return (
                      <BasicCard
                        key={id}
                        id={id}
                        slug={slug}
                        media={media}
                        kicker={kicker?.fields?.[0]?.value}
                        title={title}
                        blurb={blurb}
                        paywall={paywall?.contentAccess === "1"}
                        variations={variant}
                        updated={updated}
                        hasUpdatedTimestampDisplay={
                          displaySetting?.hasUpdatedTimeDisplayed
                        }
                      />
                    );
                  })}
                </>
              </Column>
            );
          })}
        </>
      </Row>
    </>
  );
}
