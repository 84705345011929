import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import BTLogo from "@assets/bt-icon-white.svg";
import { getIconSrc } from "@components/Button/helpers";
import { IconName } from "@components/Button/types";
import { Loading } from "@components/LoadMore/LoadMore";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import { Transition, TransitionChild } from "@headlessui/react";
import { RouteFactory } from "@src/app/routePaths";

import PulseStartingScreen from "./components/PulseStartingScreen";
import { useGetBTPulseData } from "./hooks/useGetBTPulseData";
import PulseDesktopPage from "./PulseDesktopPage";
import PulseMobilePage from "./PulseMobilePage";

export default function PulsePage(): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pulseData, sortedPulseData, isLoading, handleSortedPulseData } =
    useGetBTPulseData();

  const [startCarousel, setStartCarousel] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const handleStartCarousel = () => {
    if (pulseData.length === 0) return;
    setStartCarousel(true);
    setSearchParams({ article: pulseData[0].id });
  };

  useEffect(() => {
    if (pulseData.length === 0) return;
    if (!firstLoad) return;

    const getActiveId = searchParams.get("article") || "";

    const withArticleId = pulseData.find(
      (article) => article.id === getActiveId
    );

    if (withArticleId) {
      handleSortedPulseData(getActiveId);
      setStartCarousel(true);
      setFirstLoad(false);
      return;
    }

    setFirstLoad(false);
    setSearchParams();
  }, [
    pulseData,
    firstLoad,
    handleSortedPulseData,
    setSearchParams,
    searchParams,
  ]);

  return (
    <div
      className="flex min-h-svh w-full flex-col bg-[#121212]"
      data-testid="pulse-page-content"
    >
      <MetaTags
        title={renderPageTitleV2({
          kind: "pulse",
          sectionName: "Pulse",
        })}
        description={
          "The Business Times - Find latest business & financial news including analysis and opinion on top business stories, stock markets in Singapore, Asia-Pacific & global market news and more at The Business Times."
        }
        ogType="website"
        slug={RouteFactory.pulse}
      />

      <Loading isLoading={isLoading} />

      <div className="hidden justify-between p-4 pb-0 lg:flex">
        <Link to={RouteFactory.home} reloadDocument>
          <img src={BTLogo} width={200} height={18} alt="The Business Times" />
        </Link>

        <Link to={RouteFactory.home} reloadDocument>
          <img
            src={getIconSrc(IconName.closeWhite)}
            width={36}
            height={36}
            alt="close"
          />
        </Link>
      </div>

      <Transition show={!startCarousel}>
        <TransitionChild
          enter="transition ease-out duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 left-0">
            <PulseStartingScreen handleStartCarousel={handleStartCarousel} />
          </div>
        </TransitionChild>
      </Transition>

      <Transition show={startCarousel && sortedPulseData.length > 0}>
        <TransitionChild
          enter="transition ease duration-500 transform delay-1000"
          enterFrom="opacity-0 translate-x-40"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease duration-300 transform"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-24"
        >
          <div className="fixed inset-0 left-0">
            <div className="hidden lg:block">
              <PulseDesktopPage sortedPulseData={sortedPulseData} />
            </div>

            <div className="block lg:hidden">
              <PulseMobilePage sortedPulseData={sortedPulseData} />
            </div>
          </div>
        </TransitionChild>
      </Transition>

      <div className="absolute bottom-6 right-6 hidden font-poppins text-4xs font-light tracking-2% text-gray-550 lg:block">
        AI-generated summary.{" "}
        <a
          target="_blank"
          href="https://subscribe.sph.com.sg/contact-us/"
          className="underline-offset-4 hover:underline"
          rel="noreferrer"
        >
          Feedback.
        </a>
      </div>
    </div>
  );
}
