import { useState } from "react";
import { Section, Tag } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Container from "@components/Container/Container";
import { useWindowInnerWidth } from "@hooks/useWindowInnerWidth";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { cn, GoogleAdsSlotFactory } from "@util/helpers";

import Advertisement from "./Advertisement";

export type DynamicImuProps = {
  totalParagraphs: number;
  index: number;
  section: Section;
  cueId: string;
  keywords: Tag[];
  gsChannels: string;
  adsWrapperClass?: string;
  fullScreenAds?: boolean;
};

export default function DynamicImu({
  totalParagraphs,
  index,
  section,
  cueId,
  keywords,
  gsChannels,
  adsWrapperClass,
  fullScreenAds = false,
}: DynamicImuProps): React.ReactElement {
  const windowInnerSize = useWindowInnerWidth();
  const [adsHeight, setAdsHeight] = useState(0);

  const noAdsDisplayed = adsHeight <= 5;

  const adsExtraCss = {
    maxWidth: `${windowInnerSize.width}px`,
    marginLeft: fullScreenAds
      ? `calc(-1*(${windowInnerSize.width}px - 100%) / 2 )`
      : 0,
    height: fullScreenAds
      ? noAdsDisplayed
        ? 0
        : `${adsHeight + 125}px`
      : "auto",
  };

  const isHideAds = fullScreenAds && noAdsDisplayed;

  const slotPath = section.uniqueName.replaceAll("_", "/");
  const slotTargettings = [
    { key: "btarticleid", value: cueId },
    {
      key: "bttags",
      value:
        keywords
          ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
          .join(",") || "",
    },
    { key: "gs_channels", value: gsChannels },
  ];

  if ((totalParagraphs >= 1 && totalParagraphs < 3) || totalParagraphs === 3) {
    return (
      <div
        className={cn("mb-6", adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Advertisement
              setAdsHeight={setAdsHeight}
              adUnitProps={{
                id: index !== 0 ? `dfp-ad-imu1-${index}` : `dfp-ad-imu1`,
                type: AdvertisementTypeEnum.IMU1,
                slot: GoogleAdsSlotFactory.imu1(slotPath),
                isRefresh: index !== 0,
                slotTargettings: slotTargettings,
              }}
              rootClassName="text-center"
            />
          </div>
        </Container>
      </div>
    );
  }

  if (
    ((totalParagraphs > 3 && totalParagraphs < 6) || totalParagraphs === 6) &&
    fullScreenAds
  ) {
    return (
      <div
        className={cn("mb-6", adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Advertisement
              setAdsHeight={setAdsHeight}
              adUnitProps={{
                id: index !== 0 ? `dfp-ad-imu2-${index}` : `dfp-ad-imu2`,
                type: AdvertisementTypeEnum.IMU2,
                slot: GoogleAdsSlotFactory.imu2(slotPath),
                isRefresh: index !== 0,
                slotTargettings: slotTargettings,
              }}
              rootClassName="text-center"
            />
          </div>
        </Container>
      </div>
    );
  }

  if ((totalParagraphs > 3 && totalParagraphs < 6) || totalParagraphs === 6) {
    return (
      <div
        className={cn(adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Advertisement
              setAdsHeight={setAdsHeight}
              adUnitProps={{
                id:
                  index !== 0
                    ? `dfp-ad-midarticlespecial-${index}`
                    : `dfp-ad-midarticlespecial`,
                type: AdvertisementTypeEnum.MIDARTICLE,
                slot: GoogleAdsSlotFactory.midarticlespecial(slotPath),
                isRefresh: index !== 0,
                slotTargettings: slotTargettings,
                adsClassName: "my-8 md:my-12",
              }}
              rootClassName="text-center"
            />
          </div>
        </Container>
      </div>
    );
  }

  if ((totalParagraphs > 6 && totalParagraphs < 9) || totalParagraphs === 9) {
    return (
      <div
        className={cn("mb-6", adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Advertisement
              setAdsHeight={setAdsHeight}
              adUnitProps={{
                id: index !== 0 ? `dfp-ad-imu2-${index}` : `dfp-ad-imu2`,
                type: AdvertisementTypeEnum.IMU2,
                slot: GoogleAdsSlotFactory.imu2(slotPath),
                isRefresh: index !== 0,
                slotTargettings: slotTargettings,
              }}
              rootClassName="text-center"
            />
          </div>
        </Container>
      </div>
    );
  }

  if (totalParagraphs > 9) {
    return (
      <div
        className={cn("mb-6", adsWrapperClass, {
          "min-h-0 border-y-0 bg-none py-0": isHideAds,
        })}
        style={adsExtraCss}
      >
        <Container rootClassName="sticky top-[120px]">
          <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
            <Advertisement
              setAdsHeight={setAdsHeight}
              adUnitProps={{
                id: index !== 0 ? `dfp-ad-imu3-${index}` : `dfp-ad-imu3`,
                type: AdvertisementTypeEnum.IMU3,
                slot: GoogleAdsSlotFactory.imu3(slotPath),
                isRefresh: index !== 0,
                slotTargettings: slotTargettings,
              }}
              rootClassName="text-center"
            />
          </div>
        </Container>
      </div>
    );
  }

  return <></>;
}
