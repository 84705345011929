import { Link } from "react-router-dom";
import Banner from "@components/Banner/Banner";
import { PodcastKeywordData } from "@pages/Section/layouts/PodcastsPage/util/constants";
import { cn } from "@util/helpers";

export default function PodcastsBanner({
  label,
  spotify_link,
  apple_link,
  youtube_link,
  description,
  thumbnailSrc,
}: PodcastKeywordData): React.ReactElement {
  return (
    <Banner rootClassName="w-ful px-0 [&>div.container]:px-0">
      <div className={cn("flex flex-col items-start", "lg:flex-row")}>
        <img
          loading="lazy"
          src={thumbnailSrc}
          alt={label}
          width="120"
          height="120"
          aria-label={label}
          className="mb-4 mr-4 aspect-square"
        />

        <div className="d-flex flex-column justify-content-center lg:max-w-7/10">
          <h5
            className={cn(
              "mb-4 font-public-sans text-base font-normal text-gray-850 lg:text-lg"
            )}
          >
            {description}
          </h5>

          <div>
            <span
              className={cn(
                "mb-2 mr-2 block font-public-sans text-base font-medium text-gray-850",
                "lg:inline lg:text-lg"
              )}
            >
              Subscribe:
            </span>

            <Link
              className={cn(
                "mr-2 border-r border-gray-650 pr-2 font-poppins text-base font-semibold text-black hover:underline",
                "lg:text-lg"
              )}
              to={spotify_link}
              rel="noreferrer"
              target="_blank"
              aria-label="spotify link"
            >
              Spotify
            </Link>

            <Link
              className={cn(
                "mr-2 border-r border-gray-650 pr-2 font-poppins text-base font-semibold text-black hover:underline",
                "lg:text-lg"
              )}
              to={apple_link}
              rel="noreferrer"
              target="_blank"
              aria-label="apple link"
            >
              Apple Podcasts
            </Link>

            <Link
              className={cn(
                "mr-2 font-poppins text-base font-semibold text-black hover:underline",
                "lg:text-lg"
              )}
              to={youtube_link}
              rel="noreferrer"
              target="_blank"
              aria-label="youtube link"
            >
              YouTube
            </Link>
          </div>
        </div>
      </div>
    </Banner>
  );
}
