/**
 * Company: SPHMedia
 * Description: [Brief description of the component]
 */
import { useEffect, useState } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { faFacebookF, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

export type SocialMediaIconsProps = {
  rootClassName?: string;
};

/**
 * Description
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function SocialMediaIcons({
  rootClassName = "",
}: SocialMediaIconsProps): React.ReactElement {
  const [shareUrl, setShareUrl] = useState<string>("");

  const iconClassNameValues = cx(
    "relative flex h-8 w-8 items-center justify-center rounded-full !border !border-gray-300 z-0 transition-colors !border-solid",
    "after:absolute after:-bottom-[1px] after:-left-0 after:-right-0 after:shadow-socialShadow after:top-1/2 after:block after:bg-white",
    "group-hover:!border-lifestyle-primary"
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      // @TODO change the URL to shortened URL.
      setShareUrl(window?.location?.href);
    }
  }, []);

  return (
    <>
      <div className={cx("mr-12 flex flex-row space-x-2", rootClassName)}>
        <FacebookShareButton url={shareUrl} className={iconClassNameValues}>
          <FontAwesomeIcon
            icon={faFacebookF}
            className="group-hover:text-life z-10 text-gray-300 transition-colors group-hover:text-lifestyle-primary"
          />
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} className={iconClassNameValues}>
          <FontAwesomeIcon
            icon={faXTwitter}
            className="group-hover:text-life z-10 text-gray-300 transition-colors group-hover:text-lifestyle-primary"
          />
        </TwitterShareButton>
      </div>
    </>
  );
}
