import { sideMenuConstant } from "@components/Header/constant";
import type { NavigationA } from "@components/Header/types";

/**
 * Used in navigation
 * To get the hamburger list
 */

export const useGetHamburgerList = (headerData: NavigationA) => {
  const hamburgerMenu = headerData?.length
    ? headerData?.filter((x) => {
        return x.section?.key.split("-").includes("hamburger") && x;
      })
    : sideMenuConstant;

  return hamburgerMenu;
};
