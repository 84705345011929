/**
 * Enum Type, used for the assertEnum function
 */
type Enum<TEnum> = Record<keyof TEnum, number | string>;

/**
 * Gets a typed property from an object
 *
 * @param obj - Target object
 * @param key - Requested property
 *
 * @returns The value of the requested object property
 */
const getProperty = function getProperty<T, K extends keyof T>(
  obj: T,
  key: K
): T[K] {
  return obj[key];
};

/**
 * Hidden assertion for Object.keys()
 */
const getKeys = Object.keys as <T extends object>(
  obj: T
) => Array<Extract<keyof T, string>>;

/**
 * Asserts that a string is valid as an enum, returns null otherwise
 * @param enumActual Entire enum IE if enum ABC {} then pass in ABC
 * @param value string to assert as the passed in enum
 * @returns Enum | null
 */
export default function assertEnum<TEnum extends Enum<TEnum>>(
  enumActual: TEnum,
  value: string
): TEnum[keyof TEnum] | null {
  const enumKeys = getKeys(enumActual);
  const key =
    enumKeys.find((k: string) => {
      return (
        getProperty(enumActual, k as Extract<keyof TEnum, string>) === value
      );
    }) ?? null;

  if (key == null) {
    return null;
  }

  return getProperty(enumActual, key);
}
