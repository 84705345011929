import { useCallback, useEffect, useState } from "react";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Button from "@components/AccessibleComponents/Button";
import { adSlotSizes, adTargetings } from "@util/constant";
import cx from "classnames";
import Cookies, { CookieAttributes } from "js-cookie";

import { AdTargetingType } from "./AdUnit";
import { useAdvertLoaded } from "./helpers";

const ID = "dfp-ad-prestitial";
const TOPOVERLAYCLASS = "overlayWhite";

export type PrestitialAdProps = {
  rootClassName?: string;
  slot: string;
  totalImpressionsServed: number;
  topOverlayImpressions: number;
  topOverlayValidity: number;
  adBlockDetected?: boolean;
  slotTargettings?: AdTargetingType[];
};

export default function PrestitialAd({
  rootClassName,
  slot,
  totalImpressionsServed,
  topOverlayImpressions, // Maximum total number of impressions
  topOverlayValidity, // Validity of topoverlay cookie in hours
  adBlockDetected = false, // Check if there is adblocker
  slotTargettings = [],
}: PrestitialAdProps) {
  const [locked, setLocked] = useState(true);
  const isAdLoaded = useAdvertLoaded(AdvertisementTypeEnum.PRESTITIAL, ID);
  const [error, setError] = useState("");
  const [adSlot, setAdSlot] =
    useState<ReturnType<typeof googletag.defineSlot>>();

  const adSlotSize = adSlotSizes.prestitial;

  const closetopoverlay = (event: MessageEvent) => {
    if (
      event.data !== "close" &&
      event.data !== "bz-interstitial-close" &&
      event.data.detail !== "close"
    )
      return;

    console.log("[pres] closetopoverlay : setLocked false"); // eslint-disable-line no-console
    setLocked(false);
  };

  const slotRenderEnded = (event: googletag.events.SlotRenderEndedEvent) => {
    if (event.slot.getSlotElementId() !== ID) return;
    if (event.isEmpty) {
      console.log("[pres] slotRenderEnded : setLocked false"); // eslint-disable-line no-console
      setLocked(false);
    }
  };

  const handleDisplayAd = useCallback(() => {
    const adTarget = adTargetings.prestitial || [];
    const allTargettings = [...adTarget, ...slotTargettings];

    try {
      window.googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(function () {
        googletag
          .pubads()
          .getSlots()
          .forEach(function (_slot) {
            if (_slot.getSlotElementId()) {
              if (_slot.getSlotElementId() === ID) {
                googletag.destroySlots([_slot]);
              }
            }
          });

        if (adSlotSize) {
          const _adSlot = googletag.defineSlot(slot, adSlotSize, ID);

          if (_adSlot) {
            _adSlot.addService(googletag.pubads());

            allTargettings.forEach(({ key, value }) => {
              _adSlot.setTargeting(key, value);
            });

            googletag.pubads().enableSingleRequest();
            window.addEventListener("message", closetopoverlay, false);
            googletag.pubads().disableInitialLoad();
            googletag.enableServices();

            googletag
              .pubads()
              .addEventListener("slotRenderEnded", slotRenderEnded);

            window.googletag.slots["prestitial"] = _adSlot;

            // Store the ad slot for later use.
            setAdSlot(_adSlot);
          }
        }
      });
    } catch (_error: unknown) {
      setError("Please refresh");
    }
  }, [slot, adSlotSize, slotTargettings]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (typeof slot === "undefined") return;
    if (typeof adSlot !== "undefined") return;
    if (adBlockDetected) return;

    console.log("[pres] handleDisplayAd"); // eslint-disable-line no-console
    handleDisplayAd();
  }, [slot, handleDisplayAd, adSlot, adBlockDetected]);

  useEffect(() => {
    return () => {
      if (window?.googletag && window?.googletag?.apiReady && adSlot) {
        console.log("[pres] unmount destroySlots"); // eslint-disable-line no-console
        googletag.cmd.push(function () {
          googletag.destroySlots([adSlot]);
        });
      }
    };
  }, [adSlot]);

  useEffect(() => {
    return () => {
      console.log("[pres] unmount reloadScrollBars"); // eslint-disable-line no-console
      if (typeof document === "undefined") return;

      const html = document.documentElement;
      const { body } = document;

      html.style.overflowY = "visible";
      body.style.overflowY = "visible";
    };
  }, []);

  useEffect(() => {
    if (!isAdLoaded) return;

    const expiry = new Date();
    expiry.setTime(expiry.getTime() + topOverlayValidity * 60 * 60 * 1000); // Set cookie expiry in hours
    const cookieOptions: CookieAttributes = {
      path: "/",
      expires: expiry,
    };

    const totalImpressions = totalImpressionsServed + 1;

    Cookies.set(
      "topOverlayImpressionsServed",
      totalImpressions.toString(),
      cookieOptions
    );

    if (totalImpressions === topOverlayImpressions) {
      Cookies.set("topOverlayImpressionsServed", "0", cookieOptions);
      Cookies.set("topoverlayDisplayed", "yes", cookieOptions);
    }
  }, [isAdLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof document === "undefined") return;

    const html = document.documentElement;
    const { body } = document;
    const overflow = locked ? "hidden" : "visible";

    html.style.overflowY = overflow;
    body.style.overflowY = overflow;
  }, [locked]);

  return (
    <div data-testid="prestitial-ad-component">
      <div
        id="MyPageOverlay"
        className={locked ? TOPOVERLAYCLASS : undefined}
      ></div>

      <div
        className={cx(
          "container flex w-full flex-row items-center justify-center",
          rootClassName,
          { invisible: !isAdLoaded }
        )}
      >
        {!error ? <Button id={ID}></Button> : error}
      </div>
    </div>
  );
}
