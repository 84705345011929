/**
 * Provides a PostHog context and hook for accessing the PostHog instance.
 * https://posthog.com/docs/libraries/react
 * https://github.com/PostHog/posthog-js/issues/908
 */

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import useOKTAUserStore from "@store/useOKTAUserStore";
import posthog from "posthog-js";

type PostHogType = typeof posthog | undefined;

const PostHogContext = createContext<PostHogType>(undefined);

const POSTHOG_API_KEY: string = import.meta.env.VITE_POSTHOG_API_KEY;
const POSTHOG_API_HOST: string = import.meta.env.VITE_POSTHOG_API_HOST;

const posthogOptions = {
  api_host: POSTHOG_API_HOST,
};

const PostHogProvider = ({ children }: PropsWithChildren) => {
  const [posthogInstance, setPosthogInstance] = useState<PostHogType>();

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  useEffect(() => {
    if (!POSTHOG_API_KEY && !POSTHOG_API_HOST) return;

    const instance = posthog.init(POSTHOG_API_KEY, posthogOptions);
    setPosthogInstance(instance);

    if (OKTAUserInfo && instance) {
      const { loginid, display_name, usertype, reguserstatus, mysphw } =
        OKTAUserInfo;

      instance.identify(loginid, {
        email: loginid,
        name: display_name,
        usertype,
        reguserstatus,
        mysphw,
      });
    }
  }, [OKTAUserInfo]);

  return (
    <PostHogContext.Provider value={posthogInstance}>
      {children}
    </PostHogContext.Provider>
  );
};

const usePostHog = () => useContext(PostHogContext);

export { PostHogProvider, usePostHog };
