import andrewImg from "@assets/thrive_images/image-thrivers-andrew.png";
import cherylImg from "@assets/thrive_images/image-thrivers-cheryl.png";
import chloeImg from "@assets/thrive_images/image-thrivers-chloe.png";
import cpImg from "@assets/thrive_images/image-thrivers-cp.png";
import darylImg from "@assets/thrive_images/image-thrivers-daryl.png";
import vivienImg from "@assets/thrive_images/image-thrivers-vivien.png";

export const thriversCards = [
  {
    name: "Vivien Shiao",
    image: vivienImg,
    frontText: "Zookeeper",
    backText: [
      {
        topText: "I work hard so I can",
        bottomText: "Be just like Leo In Saint-Tropez",
      },
      {
        topText: "My special skill is",
        bottomText: "Getting things done. Somehow",
      },
      {
        topText: "On the weekends I will be",
        bottomText: "Sleeping till noon",
      },
      {
        topText: "Favourite pantry snack",
        bottomText: "Almond pepero",
      },
      {
        topText: "A movie scene that lives rent-free in my head",
        bottomText:
          "Currently, it's Titanic where Jack dies of hypothermia. Only because the passenger in front of me on the plane watched it 3 times in a row",
      },
    ],
  },
  {
    name: "Daryl Choo",
    image: darylImg,
    frontText: "Grammarly power user",
    backText: [
      {
        topText: "I work hard so I can",
        bottomText: "Buy my dogs nice things",
      },
      {
        topText: "My special skill is",
        bottomText: "Telling apart en dashes and em dashes",
      },
      {
        topText: "On the weekends I will be",
        bottomText: "Playing nanny to my two spoiled cockers",
      },
      {
        topText: "Favourite pantry snack",
        bottomText: "Wang Zai Milk",
      },
      {
        topText: "A movie scene that lives rent-free in my head",
        bottomText: "Opening scene of Eat Drink Man Woman",
      },
    ],
  },
  {
    name: "Chloe Lim",
    image: chloeImg,
    frontText: "ChatGPT hobbyist",
    backText: [
      {
        topText: "I work hard so I can",
        bottomText: "Support my travel era",
      },
      {
        topText: "My special skill is",
        bottomText: "Playing therapist to my friends",
      },
      {
        topText: "On the weekends I will be",
        bottomText: "At ✨brunch✨",
      },
      {
        topText: "Favourite pantry snack",
        bottomText: "Can't choose bc I love all snacks",
      },
      {
        topText: "A movie scene that lives rent-free in my head",
        bottomText:
          '"Surf\'s up duuuuuuuude" -- Crush to Marlin in Finding Nemo',
      },
    ],
  },
  {
    name: "Andrew Wong",
    image: andrewImg,
    frontText: "Button-presser",
    backText: [
      {
        topText: "I work hard so I can",
        bottomText: "Afford Five Guys",
      },
      {
        topText: "My special skill is",
        bottomText: "Understanding TikTok",
      },
      {
        topText: "On the weekends I will be",
        bottomText: "Cycling or playing Minecraft",
      },
      {
        topText: "Favourite pantry snack",
        bottomText: "None (I prefer my own peanut butter sandwich)",
      },
      {
        topText: "A movie scene that lives rent-free in my head",
        bottomText: "The namecard scene from American Psycho",
      },
    ],
  },
  {
    name: "Cheryl Tang",
    image: cherylImg,
    frontText: "Google sheets enthusiast",
    backText: [
      {
        topText: "I work hard so I can",
        bottomText: "Buy a drink without thinking twice",
      },
      {
        topText: "My special skill is",
        bottomText: "Photoshopping",
      },
      {
        topText: "On the weekends I will be",
        bottomText: "Travelling around Singapore",
      },
      {
        topText: "Favourite pantry snack",
        bottomText: "M&M's milk chocolate",
      },
      {
        topText: "A movie scene that lives rent-free in my head",
        bottomText: "THAT reunion scene in Spider-Man: No Way Home",
      },
    ],
  },
  {
    name: "Cheow Pong",
    image: cpImg,
    frontText: "Fun(d) Manager",
    backText: [
      {
        topText: "I work hard so I can",
        bottomText: "Wake up one day and do nothing",
      },
      {
        topText: "My special skill is",
        bottomText: "Bootstrapping",
      },
      {
        topText: "On the weekends I will be",
        bottomText: "Trying to get better at parenting",
      },
      {
        topText: "Favourite pantry snack",
        bottomText: "Chocolate Pocky",
      },
      {
        topText: "A movie scene that lives rent-free in my head",
        bottomText:
          "Ethan Hawke and Julie Delpy as Jesse and Céline, conversing non-stop about life and love in Before Sunrise",
      },
    ],
  },
];
