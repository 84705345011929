import { RouteFactory } from "@app/routePaths";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { WealthContext } from "@app/types/Page";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useTrimmedBreakingNews } from "@hooks/useTrimmedBreakingNews";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MetaTags from "@src/app/components/MetaTags/MetaTags";
import { sectionNavigationItems } from "@util/constant";
import { GoogleAdsSlotFactory } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";

import TodaysMarketData from "./components/TodaysMarketData";
import WealthRealEstate from "./components/WealthRealEstate";
import WealthThirdRow from "./components/WealthThirdRow";
import WealthTopStories from "./components/WealthTopStories";
import WealthWatches from "./components/WealthWatches";

export type WealthPageContentProps = Pick<WealthContext, "data">;

export default function WealthPageContent({
  data: { title, wealth, property, invest, advisor, asset, watches },
}: WealthPageContentProps): React.ReactElement {
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  const adBlockDetected = useDetectAdBlock();
  const breakingNews = useTrimmedBreakingNews();

  return (
    <>
      <MetaTags
        title={title}
        description="Read more at The Business Times."
        slug={RouteFactory.wealth}
      />

      <GAData
        level2={"wealth"}
        title={"Wealth_Index"}
        adblocker={adBlockDetected ? 1 : 0}
      />

      <Advertisement
        rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(RouteFactory.wealth.replace("/", "")),
          adsClassName: "min-h-[90px]",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(
          RouteFactory.wealth.replace("/", "")
        )}
        {...{
          isPrestitialEnabled,
          topOverlayImpressions,
          topOverlayValidity,
        }}
      />

      <Header
        displayBreadcrumb={sectionNavigationItems["wealth"] && isShowBreadCrumb}
        parentCategory={"wealth"}
        categoryDataMapping={sectionNavigationItems}
      />

      {sectionNavigationItems["wealth"] ? (
        <Container>
          <div>
            <h1 className="pb-3 pt-6 font-playfair text-8xl font-semibold uppercase text-gray-850">
              {"Wealth"}
            </h1>

            <div ref={breadcrumbsRef}>
              <Breadcrumb
                className="m-auto"
                parentCategory={"wealth"}
                categoryDataMapping={sectionNavigationItems}
              />
            </div>
          </div>
        </Container>
      ) : null}

      <Container rootClassName="pt-6">
        <Row rootClassName="mb-6">
          <Column rootClassName="w-full px-3 lg:w-8/12">
            <WealthTopStories data={wealth} />
          </Column>

          <Column rootClassName="w-full px-3 lg:w-4/12">
            <Advertisement
              rootClassName="bg-stripes mb-5 py-5"
              adUnitProps={{
                type: AdvertisementTypeEnum.IMU1,
                slot: GoogleAdsSlotFactory.imu1(
                  RouteFactory.wealth.replace("/", "")
                ),
              }}
            />

            <TodaysMarketData />

            <BreakingNews data={breakingNews} rootClassName="mx-auto mb-6" />
          </Column>
        </Row>

        <>
          {property ? (
            <Row rootClassName="mb-6">
              <Column rootClassName="w-full">
                <WealthRealEstate data={property} />
              </Column>
            </Row>
          ) : null}
        </>

        <>
          {invest && advisor && asset ? (
            <Row rootClassName="mb-6">
              <Column rootClassName="w-full">
                <WealthThirdRow
                  invest={invest}
                  advisor={advisor}
                  asset={asset}
                />
              </Column>
            </Row>
          ) : null}
        </>

        <>
          {watches ? (
            <Row rootClassName="mb-6">
              <Column rootClassName="w-full">
                <WealthWatches articles={watches} />
              </Column>
            </Row>
          ) : null}
        </>
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(
            RouteFactory.wealth.replace("/", "")
          ),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </>
  );
}
