import { TestIdClassNameProps } from "@app/types/Common";
import cx from "classnames";

export type ArticleSubscriberLabelProps = TestIdClassNameProps & {
  isPremium: boolean;
  displayDot?: boolean;
};

export default function ArticleSubscriberLabel({
  testId,
  rootClassName,
  displayDot = true,
  isPremium = false,
}: ArticleSubscriberLabelProps): React.ReactElement {
  return (
    <>
      {isPremium ? (
        <div
          className={cx(
            rootClassName,
            "font-poppins text-4xs tracking-[1px] text-gray-650"
          )}
          data-testid={testId}
        >
          {displayDot ? (
            <span className="inline-block h-[8px] w-[8px] rounded-full bg-orange-300"></span>
          ) : null}

          <span className="ml-1">SUBSCRIBERS</span>
        </div>
      ) : null}
    </>
  );
}
