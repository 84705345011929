import { ASEAN_BUSINESS_OTHER_READS } from "@components/Newsletter/constants";
import AseanBusinessNewsletterSampleAuthor from "@pages/Newsletter/Archive/AseanBusiness/SampleIssues/components/AseanBusinessNewsletterSampleAuthor";
import AseanBusinessNewsletterSampleContent from "@pages/Newsletter/Archive/AseanBusiness/SampleIssues/components/AseanBusinessNewsletterSampleContent";
import AseanBusinessNewsletterSampleHeader from "@pages/Newsletter/Archive/AseanBusiness/SampleIssues/components/AseanBusinessNewsletterSampleHeader";
import AseanBusinessNewsletterSampleOtherReads from "@pages/Newsletter/Archive/AseanBusiness/SampleIssues/components/AseanBusinessNewsletterSampleOtherReads";
import AseanBusinessNewsletterSampleTopReads from "@pages/Newsletter/Archive/AseanBusiness/SampleIssues/components/AseanBusinessNewsletterSampleTopReads";
import NewsletterSampleFooter from "@pages/Newsletter/Archive/components/NewsletterSampleFooter";
import { cn } from "@util/helpers";

export type AseanBusinessLatestIssueProps = {
  divRef?: React.RefObject<HTMLDivElement>;
  ClassName?: string;
  firstChildClassName?: string;
};

export default function AseanBusinessLatestIssue({
  divRef,
  ClassName = "",
  firstChildClassName = "",
}: AseanBusinessLatestIssueProps): React.ReactElement {
  return (
    <>
      <h2 className="mb-3 text-center font-poppins text-base font-medium">
        Sample issue
      </h2>

      <div
        data-testid="asean-iframe"
        ref={divRef}
        className={cn("rounded-2xl border-2 border-gray-850", ClassName)}
      >
        <div
          className={cn(
            "mt-0 h-[525px] overflow-y-scroll",
            firstChildClassName
          )}
        >
          <div className="mx-auto mb-9 mt-3 w-full max-w-[600px] border-collapse">
            <AseanBusinessNewsletterSampleHeader />

            <AseanBusinessNewsletterSampleAuthor />

            <AseanBusinessNewsletterSampleContent />

            <AseanBusinessNewsletterSampleTopReads />

            <AseanBusinessNewsletterSampleOtherReads
              newsletters={ASEAN_BUSINESS_OTHER_READS}
            />

            <NewsletterSampleFooter editionId={"53484"} />
          </div>
        </div>
      </div>
    </>
  );
}
