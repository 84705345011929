import { LambdaResponsePayload } from "@pages/Article/types/Alacrity";

import ArticleSubshareModal from "./ArticleSubshareModal";
import ArticleSubshareSentContent from "./ArticleSubshareSentContent";

type ArticleSubshareSentProps = {
  alacrityRes?: LambdaResponsePayload;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function ArticleSubshareSent({
  alacrityRes,
  isModalOpen,
  setIsModalOpen,
}: ArticleSubshareSentProps): React.ReactElement {
  return (
    <>
      <ArticleSubshareModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      >
        {alacrityRes ? (
          <ArticleSubshareSentContent
            setIsModalOpen={setIsModalOpen}
            alacrityRes={alacrityRes}
          />
        ) : null}
      </ArticleSubshareModal>
    </>
  );
}
