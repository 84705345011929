import { FormEvent, ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import { TestIdClassNameProps } from "@app/types/Common";
import Button from "@components/AccessibleComponents/Button";
import TextInput from "@components/AccessibleComponents/TextInput";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import { EmarsysResponse } from "@components/Newsletter/types/Emarsys";
import { Newsletter } from "@components/Newsletter/types/Newsletter";
import { faClose, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newsletterRequests } from "@pages/Newsletter/SignUp/Newsletter.server";
import cx from "classnames";

export type NewsletterSignUpProps = TestIdClassNameProps & {
  containerClassName?: string;
  newsletter: Newsletter[];
  btnColors?: string;
  linkColors?: string;
  descriptionComponent?: ReactElement | null;
  firstColumnClassName?: string;
  secondColumnClassName?: string;
};

export default function NewsletterSignUp({
  testId,
  rootClassName,
  containerClassName,
  newsletter,
  btnColors = "text-gray-850 bg-yellow hover:bg-white",
  linkColors = "text-verticals-btblue",
  descriptionComponent = null,
  firstColumnClassName,
  secondColumnClassName,
}: NewsletterSignUpProps) {
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);

    const target = event.target as typeof event.target & {
      email: { value: string };
    };

    const request = async () => {
      await newsletterRequests
        .processSubscription(target.email.value, newsletter)
        .then((response) => {
          setEmarsysResponse(response);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    request();
  };

  return (
    <div
      className={cx(rootClassName, "bg-gray-250 pb-10 pt-6")}
      data-testid={testId}
    >
      <Container rootClassName={containerClassName}>
        <Row rootClassName="items-center">
          <Column rootClassName={cx(firstColumnClassName, "w-full lg:w-7/12")}>
            {descriptionComponent ? (
              descriptionComponent
            ) : (
              <h4 className="mb-3 text-center font-poppins text-4xl font-bold lg:mb-0 lg:text-left">
                STAY UPDATED
              </h4>
            )}
          </Column>

          <Column rootClassName={cx(secondColumnClassName, "w-full lg:w-5/12")}>
            <>
              {emarsysResponse ? (
                <div
                  className={cx(
                    "mb-3 flex rounded-sm border p-2 font-poppins",
                    {
                      "border-green-300 bg-green text-green-200":
                        emarsysResponse.status === "success",
                      "border border-red-200 bg-red-100 text-red-300":
                        emarsysResponse.status === "error",
                    }
                  )}
                >
                  <span>{emarsysResponse.message}</span>

                  <Button
                    type="button"
                    className="ml-auto"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onPress={() => {
                      setEmarsysResponse(undefined);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faClose}
                      size="lg"
                      className="opacity-75 transition-opacity hover:opacity-100"
                    />
                  </Button>
                </div>
              ) : null}
            </>

            <form
              onSubmit={handleSubmit}
              className="relative grid grid-cols-3 gap-3"
            >
              <div className="col-span-3 sm:col-span-2">
                <TextInput
                  className={cx(
                    "w-full rounded-full border border-gray-400 px-6 py-1.5 text-center placeholder-gray-600 transition ease-in-out"
                  )}
                  name="email"
                  type="email"
                  inputMode="email"
                  placeholder="Your email address"
                  required
                />
              </div>

              <Button
                data-testid="newsletter-sign-up-button"
                type="submit"
                className={cx(
                  btnColors,
                  "col-span-3 whitespace-nowrap rounded-full px-6 py-1.5 font-poppins font-semibold transition-colors sm:col-span-1"
                )}
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "SIGN UP"
                )}
              </Button>

              <div className="col-span-3 w-full text-center text-8xs lg:absolute lg:top-full lg:pt-2">
                {"By signing up, you agree to our "}
                <Link
                  className={cx(
                    linkColors,
                    "hover:underline hover:brightness-75"
                  )}
                  to="https://www.sph.com.sg/tnc/privacy"
                  target="_blank"
                  rel="noopener"
                >
                  Privacy policy
                </Link>
                {" and "}
                <Link
                  className={cx(
                    linkColors,
                    "hover:underline hover:brightness-75"
                  )}
                  to="https://www.sph.com.sg/tnc/website"
                  target="_blank"
                  rel="noopener"
                >
                  Terms and Conditions
                </Link>
              </div>
            </form>
          </Column>
        </Row>
      </Container>
    </div>
  );
}
