import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import LoadMore from "@components/LoadMore/LoadMore";
import { useLoadMoreArticles } from "@hooks/useLoadMoreArticles";
import { brandedContentTestId } from "@pages/Hub/utils/constants";
import { fetchMoreBrandedContentData } from "@pages/Hub/utils/helpers";
import { cn } from "@util/helpers";

type HubBrandedContentArticlesProps = {
  articles: SanitizedSectionArticle[];
} & React.HTMLAttributes<HTMLDivElement>;

export default function HubBrandedContentArticles({
  articles,
  className,
}: HubBrandedContentArticlesProps): React.ReactElement {
  const { articleList, handleLoadMore, hasMoreArticles } = useLoadMoreArticles(
    articles,
    10,
    () => fetchMoreBrandedContentData(articles.at(-1)?.sort || [])
  );

  return (
    <>
      {articles.length > 0 ? (
        <div className={cn(className)} data-testid={brandedContentTestId}>
          <BlockTitle
            text="BRANDED CONTENT"
            rootClassName="text-lg antialiased mb-4"
          />

          <div className="stories divide-y divide-gray-175">
            {articleList.map(
              (
                { title, id, kicker, media, blurb, updated, sections, urlPath },
                index
              ) => {
                return (
                  <BasicCard
                    rootClassName={cn("story pt-6 pb-6 items-stretch", {
                      "pt-0": index === 0,
                      "pb-0": index === articles.length - 1,
                    })}
                    key={id}
                    id={id}
                    title={title}
                    kicker={kicker?.fields?.[0].value}
                    media={media?.[0]}
                    blurb={blurb}
                    updated={updated}
                    slug={urlPath}
                    defaultImage={{
                      directoryName: sections?.[0]?.uniqueName || "",
                    }}
                    variations={{
                      image: {
                        position: "right",
                        width: "w-120px md:w-1/4",
                        extraClass: "order-1 md:order-2",
                      },
                      content: {
                        width: "w-full-120px md:w-3/4",
                        extraClass: "order-2 pl-4 md:order-1 md:pr-4 md:pl-0",
                      },
                      title: {
                        size: "text-base",
                        color: "text-gray-850",
                      },
                    }}
                  />
                );
              }
            )}
          </div>

          <LoadMore
            rootClassName="my-4"
            onLoadMore={handleLoadMore}
            hasMore={hasMoreArticles}
          />
        </div>
      ) : null}
    </>
  );
}
