import Container from "@components/Container/Container";
import {
  FollowBT,
  SectionsNavigationItems,
} from "@components/Footer/FooterComponents/FooterSubComponents";
import LogoMasthead from "@components/Header/LogoMasthead/LogoMasthead";

export default function FooterTop() {
  return (
    <Container rootClassName="pt-0 pb-10 lg:pb-8">
      <div className="flex items-center justify-between border-t-2 border-gray-850 pb-5 pt-6 lg:pb-8">
        <LogoMasthead
          rootClassName="px-0 !py-0 max-w-[300px] lg:max-w-[360px]"
          imgWidth={360}
          imgHeight={31}
        />
        <div className="hidden lg:block">
          <FollowBT />
        </div>
      </div>
      <SectionsNavigationItems />
    </Container>
  );
}
