import { TestIdClassNameProps } from "@app/types/Common";
import { Section, Tag } from "@app/types/Cue";
import cx from "classnames";

import { NewsletterFactory } from "./NewsletterFactory";

export type ArticleNewsletterProps = TestIdClassNameProps & {
  sections: Section[];
  tags: Tag[];
  displayNewVersionNewsletter?: boolean;
};
export default function ArticleNewsletter({
  testId,
  rootClassName,
  tags,
  sections,
  displayNewVersionNewsletter,
}: ArticleNewsletterProps): React.ReactElement {
  return (
    <div className={cx(rootClassName)} data-testid={testId}>
      <NewsletterFactory
        sections={sections}
        tags={tags}
        displayNewVersionNewsletter={displayNewVersionNewsletter}
      />
    </div>
  );
}
