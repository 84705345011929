import { ChartbeatArticleDataObject } from "@app/types/Chartbeat";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import cx from "classnames";

import styles from "./MostPopular.module.scss";

export type MostPopularProps = {
  rootClassName?: string;
  data?: ChartbeatArticleDataObject[];
};

export default function MostPopularNew({
  rootClassName,
  data,
}: MostPopularProps): React.ReactElement {
  const variant: CardVariations = {
    title: {
      color: "!text-gray-850",
      size: "text-base",
    },
    content: {
      width: "auto",
      updatedTimeExtraClass:
        "mb-0 mt-auto font-poppins text-4xs uppercase text-gray-550",
    },
  };

  return (
    <>
      {data && data.length > 0 ? (
        <section className={rootClassName} data-testid="most-popular-component">
          <BlockTitle
            text="MOST POPULAR"
            rootClassName="border-t-2 border-gray-850 pt-3 font-poppins !text-lg font-semibold uppercase tracking-[0.05625rem] text-gray-850"
          />

          <div className={cx(styles.articleList, "stories ")}>
            {data?.map(
              (
                { title, article_url }: ChartbeatArticleDataObject,
                index: number
              ) => {
                return (
                  <div
                    className={cx(
                      styles.articleCard,
                      "story flex flex-row items-center justify-center border-b border-gray-175"
                    )}
                    key={`most_popular_${index}`}
                  >
                    <div
                      className={cx(
                        "mr-4 w-[20px] flex-shrink-0 py-4 !font-lct !text-10xl text-gray-850"
                      )}
                    >
                      {index + 1}
                    </div>

                    <div className="flex w-full flex-col justify-center py-4 text-gray-850">
                      <BasicCard
                        rootClassName="my-0"
                        id={`most_popular_${index}`}
                        title={title}
                        slug={article_url}
                        variations={variant}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </section>
      ) : null}
    </>
  );
}
