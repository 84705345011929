import type { NavigationA } from "@components/Header/types";
/**
 * Used in navigation
 * To get the data main navigation list
 */

export type NavigationProps = {
  navigationData?: NavigationA;
  position?: string;
};

export const useGetMainNavList = ({
  navigationData,
  position,
}: NavigationProps) => {
  const dataObj = navigationData?.find(
    (items) => items.section?.key === `navigation-${position}`
  );

  return dataObj?.section?.children;
};
