import { RouteFactory } from "@app/routePaths";
import { renderLink } from "@pages/Newsletter/Archive/util/helpers";

const links = [
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/flash-coffees-singapore-business-shutters-files-winding",
    text: "shock winding up",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/flash-coffee-exits-singapore-s14-million-debts-creditors-staff",
    text: "owed S$14 million",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/biofourmis-axes-staff-again-including-45-singapore-healthtech-vacating",
    text: "layoffs",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/ceo-be-replaced-after-healthtech-unicorn-biofourmis-lays-120-staff",
    text: "ousting of its CEO",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/temasek-backed-tessa-therapeutics-cease-operations-after-failure-raise",
    text: "closure of Temasek-backed Tessa Therapeutics",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/early-stage-startups-pique-investor-interest-amid-funding-slowdown",
    text: "started to slow down",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups-shed-fat-investors-turn-optimistic-about-deploying-capital",
    text: "opportunistic investors were",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/fresh-higher-quality-deal-opportunities-sprout-during-funding-drought",
    text: "looking at",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/investors-remain-cautious-even-tap-startup-funding-flows-again",
    text: "taps flowing again",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/5-questions-jenny-lee-star-venture-capitalist",
    text: "titans",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/5-questions-managing-director-google-clouds-ai-business-caroline-yap",
    text: "interesting back stories",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/5-questions-grab-co-founder-tan-hooi-ling",
    text: "elusive ones",
  },
  {
    href: "https://www.businesstimes.com.sg/keywords/honestbee",
    text: "honestbee saga",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/honestbee-creditor-benjamin-lim-applies-wind-company",
    text: "goes through liquidation",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/honestbees-art-stash-includes-iconic-lichtenstein-painting-bedroom",
    text: "iconic Lichtenstein painting Bedroom",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/gic-temasek-poised-reap-capital-gains-bonanza-tech-ipo-season",
    text: "stakes in the startup ecosystem",
  },
  {
    href: "https://www.techinasia.com/about/get-updates?utm_medium=email&utm_source=bt&utm_campaign=garage-basic",
    text: "free daily newsletter",
  },
  {
    href: "https://www.techinasia.com/subscription?promo=halfoff&utm_medium=email&utm_source=bt&utm_campaign=garage-premium",
    text: "subscribe to Tech in Asia",
  },
  {
    href: RouteFactory.newsletterGarageArchivePage,
    text: "archive page",
  },
];

export default function NewsletterSampleContent(): React.ReactElement {
  return (
    <>
      <div className="today-highlights-wrapper ">
        <p className="mb-2.5 font-arial text-base font-normal leading-6">
          <strong>Today, we&apos;ve got the deets on:</strong>
        </p>

        <ul className="mb-2.5 list-disc pl-7 font-arial text-base font-normal leading-6">
          <li>Garage’s top hits</li>
          <li>The ones we loved the most</li>
        </ul>
      </div>
      <div className="mt-9 border-t-[10px] border-gray-250 pb-5" />
      <div className="front-copy w-full max-w-2xl  text-left font-arial font-normal">
        <div className="greeting-text-wrapper ">
          <p className="mb-2.5 text-base leading-6">Dear Garage reader,</p>

          <p className="mb-2.5 text-base leading-6">
            It’s been half a decade since the inception of Garage and for this
            final newsletter, we’ve decided to take a look at some of the team’s
            greatest hits over the years.
          </p>

          <p className="mb-2.5 text-base leading-6">
            Who can forget how my colleagues Claudia and Sharanya broke the
            story of Flash Coffee’s{" "}
            {renderLink("shock winding up", links[0].href)} in Singapore or that
            it {renderLink("owed S$14 million", links[1].href)} to creditors?
          </p>

          <p className="mb-2.5 text-base leading-6">
            Also, do you recall the brouhaha around biotech startups as funding
            dried up, with the Garage team breaking the story of Biofourmis’{" "}
            {renderLink("layoffs", links[2].href)} and{" "}
            {renderLink("ousting of its CEO", links[3].href)}? And let’s not
            leave out the{" "}
            {renderLink(
              "closure of Temasek-backed Tessa Therapeutics",
              links[4].href
            )}
            , a story that spawned from an offhand comment.
          </p>

          <p className="mb-2.5 text-base leading-6">
            We’ve also documented investor sentiment as startup funding{" "}
            {renderLink("started to slow down", links[5].href)}, what{" "}
            {renderLink("opportunistic investors were", links[6].href)}{" "}
            {renderLink("looking at", links[7].href)}, and how cautious
            investors were despite the{" "}
            {renderLink("taps flowing again", links[8].href)}.
          </p>

          <p className="mb-2.5 text-base leading-6">
            Then there was our profile series 5 Questions, in which we
            interviewed {renderLink("titans", links[9].href)} of the startup
            ecosystem. Some had{" "}
            {renderLink("interesting back stories", links[10].href)} and some{" "}
            {renderLink("elusive ones", links[11].href)}.
          </p>

          <p className="mb-2.5 text-base leading-6">
            A memorable story that the founding reporters of Garage covered was
            the {renderLink("honestbee saga", links[12].href)}, which Sharanya
            has described as being a rollercoaster ride. The company’s odyssey
            was a crash course on what happens when a startup gets into trouble,
            finally runs out of money, and{" "}
            {renderLink("goes through liquidation", links[13].href)}.
          </p>

          <p className="mb-2.5 text-base leading-6">
            “I was surprised I even found myself writing not just restructuring
            stories but even a colourful piece on how honestbee had an art stash
            that included the{" "}
            {renderLink("iconic Lichtenstein painting Bedroom", links[14].href)}
            ,” Sharanya said.
          </p>

          <p className="mb-2.5 text-base leading-6">
            Another story Claudia found memorable was the one on GIC and
            Temasek’s{" "}
            {renderLink("stakes in the startup ecosystem", links[15].href)}. The
            piece involved trawling through prospectuses, cross-checking
            financial filings and press releases, and tracing investments made
            through vaguely-named SPVs.
          </p>

          <p className="mb-2.5 text-base leading-6">
            &quot;Most news publications hadn&apos;t realised that data on
            Temasek and GIC investments, traditionally so closely guarded, had
            been made public for the first time after several of their portfolio
            companies filed prospectuses during the tech IPO season,&quot; she
            said.
          </p>

          <p className="mb-2.5 text-base leading-6">
            So here we are, at the end of the run for the Garage newsletter.
            It’s been our pleasure to provide you with the latest startup and
            tech news from Singapore and the region over these past five years.
          </p>

          <p className="mb-2.5 text-base leading-6">
            To stay updated on the local and regional tech scene, sign up for
            Tech In Asia’s {renderLink("free daily newsletter", links[16].href)}
            , which will bring you the latest developments in bite-sized reads.
            You can also{" "}
            {renderLink("subscribe to Tech in Asia", links[17].href)} at 50 per
            cent off to get instant access to premium analyses, investigative
            pieces and data reports.
          </p>

          <p className="mb-2.5 text-base leading-6">
            For BT subscribers, you can also access past issues of the Garage
            newsletter on our {renderLink("archive page", links[18].href)}.
          </p>

          <p className="mb-2.5 text-base leading-6">
            Thank you again for being a part of our Garage journey and for your
            continuing support of The Business Times.
          </p>

          <p className="mb-2.5 text-base leading-6">
            Benjamin Cher
            <br />
            BT Garage
          </p>
        </div>
      </div>
    </>
  );
}
