import ThriveContent from "@assets/newsletter/Thrive/thrive-content.png";
import ThriveContent2 from "@assets/newsletter/Thrive/thrive-content-2.png";
import ThriveContent3 from "@assets/newsletter/Thrive/thrive-content-3.png";
import ThriveFBLogo from "@assets/thrive_images/thrive-facebook.svg";
import ThriveLinkedinLogo from "@assets/thrive_images/thrive-linkedin.svg";
import ThriveTelegramLogo from "@assets/thrive_images/thrive-telegram.svg";

export default function NewsletterSampleContent(): React.ReactElement {
  return (
    <>
      <div className="m-0 mt-10 box-border border-t-4 border-green-100 p-0 font-normal">
        &nbsp;
      </div>

      <div className="m-0 mb-6 mt-8 border-2 border-gray-850 p-8 font-normal">
        <h2 className="mb-2 mt-2 px-1.5 py-1.5 text-lg text-gray-850">
          What does the Singapore Dream 🌜⭐ mean to you?
        </h2>
        <p className="mb-2 text-base leading-7 text-gray-850">
          For dog grooming salon owner Christine Seah, 33, it’s about finding
          her own definition of success.
        </p>
        <p className="mb-2 text-base leading-7 text-gray-850">
          “My quip about these 5Cs that most Singaporeans dream about – most of
          it revolves around outside validation from your peers,” she says.
        </p>
        <p className="mb-2 text-base leading-7 text-gray-850">
          “My version of that is just a bit more intrinsic, where I think I live
          my life in search of purpose and in the pursuit of happiness.”
        </p>
        <p className="mb-2 text-base leading-7 text-gray-850">
          Watch her{" "}
          <a
            href="https://www.instagram.com/p/C_KLDeDy453/"
            className="border-b-2 border-green-400 text-gray-850 hover:underline"
          >
            full interview
          </a>{" "}
          as she shares her life philosophy and what motivated her to make a
          career switch from F&B.
        </p>
        <p className="text-base leading-7 text-gray-850">
          <em>
            Brought to you by the Ministry of Digital Development and
            Information in support of Forward Singapore.
          </em>
        </p>
      </div>

      <div className="m-0 mt-10 box-border border-t-4 border-green-100 p-0 font-normal">
        &nbsp;
      </div>
      <h2 className="m-0 mb-2 mt-2 box-border p-0 pb-1.5 pt-1.5 text-2xl text-gray-850">
        🐌 Sluggish performance
      </h2>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        The first investment I made back in 2015 when I was in national service
        was in a fund that tracked the STI.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        I had read about how great long-term investing in index funds was, and
        investing in foreign markets felt too risky to me back then.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        For six years, I added to my holdings whenever I had cash from my NS
        allowance or from part-time jobs during my university days. By the time
        I sold in 2021, after markets recovered from the Covid-19 crash, I made
        a profit of S$750 from a total of S$11,000 invested.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        The returns were far from what I had expected. If I had invested my
        money in an S&amp;P 500 index fund instead, which tracks the 500 largest
        companies in the US, I’d have made much more. But more on the US stock
        market later.&nbsp;
      </p>
      <div className="m-0 box-border p-0">
        <figure className="m-0 mb-6 box-border p-0">
          <img
            src={ThriveContent}
            alt=""
            width="100%"
            height="auto"
            className="relative m-0 box-border h-auto max-w-full p-0"
          />
          <figcaption className="m-0 box-border p-0 text-xs italic"></figcaption>
        </figure>
      </div>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        There’s been much talk about the sluggish performance of the Singapore
        stock market in recent months (see:{" "}
        <a
          href="https://www.businesstimes.com.sg/companies-markets/investing-gic-funds-not-solution-make-singapores-equity-market-more-attractive-chee-hong-tat"
          target="_blank"
          className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
          rel="noreferrer"
        >
          here
        </a>
        ,{" "}
        <a
          href="https://www.businesstimes.com.sg/opinion-features/stock-market-doldrums-sgx-may-want-listen-new-voices-solution"
          target="_blank"
          className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
          rel="noreferrer"
        >
          here
        </a>{" "}
        and{" "}
        <a
          href="https://www.businesstimes.com.sg/opinion-features/vibrancy-singapore-exchange-should-be-everyones-concern"
          target="_blank"
          className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
          rel="noreferrer"
        >
          here
        </a>
        ), so much so that the Monetary Authority of Singapore has set up a{" "}
        <a
          href="https://www.businesstimes.com.sg/companies-markets/mas-sets-review-group-improve-singapore-stock-market"
          target="_blank"
          className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
          rel="noreferrer"
        >
          review group
        </a>{" "}
        to recommend actions to revive it.&nbsp;
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        For many Singaporeans, the STI is likely the first market index we’d
        have heard of. Friends and family members may have recommended that we
        invest in it too.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        What I’ve learnt is that{" "}
        <strong className="m-0 box-border p-0">
          the STI is very different from what new investors think of when it
          comes to index funds{" "}
        </strong>
        🤔.
      </p>
      <h2 className="m-0 mb-2 mt-2 box-border p-0 pb-1.5 pt-1.5 text-2xl text-gray-850">
        🏧 It’s mostly banks
      </h2>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        For one, there are only 30 companies on the STI. Compare that with the
        S&amp;P 500 or the more than 2,500 companies on the Nasdaq composite
        index.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        So if you’re investing in an index fund to seek diversification, the STI
        won’t give you as broad of a base.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        And if you look deeper, the three local banks – DBS, OCBC and UOB – make
        up{" "}
        <a
          href="https://www.businesstimes.com.sg/companies-markets/dbs-ocbc-uob-swell-almost-half-st-index-after-recent-run"
          className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
        >
          around half
        </a>{" "}
        of the index. (That’s because the STI holds a proportion of each of
        these 30 companies based on their market capitalisation. So the bigger
        the company, the bigger its weightage in the index.)&nbsp;
      </p>
      <div className="m-0 box-border p-0">
        <figure className="m-0 mb-6 box-border p-0">
          <img
            src={ThriveContent2}
            alt=""
            width="100%"
            height="auto"
            className="relative m-0 box-border h-auto max-w-full p-0"
          />
          <figcaption className="m-0 box-border p-0 text-xs italic"></figcaption>
        </figure>
      </div>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        It’s why investors argue that the STI is not an accurate representation
        of the Singapore economy. Despite the huge holding in the index, the
        finance and insurance sectors make up only about{" "}
        <a
          href="https://www.singstat.gov.sg/modules/infographics/economy"
          target="_blank"
          className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
          rel="noreferrer"
        >
          14 per cent of the economy
        </a>
        .&nbsp;
      </p>
      <h2 className="m-0 mb-2 mt-2 box-border p-0 pb-1.5 pt-1.5 text-2xl text-gray-850">
        📈 Role of the STI
      </h2>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        If you had invested in the SPDR Straits Times Index ETF, a popular
        exchange-traded fund, over the past decade (and reinvested all the
        dividends), you’d have made{" "}
        <strong className="m-0 box-border p-0">3.81 per cent</strong> in returns
        per year on average.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        By comparison, the SPDR S&amp;P 500 ETF would have netted you{" "}
        <strong className="m-0 box-border p-0">12.52 per cent</strong> over the
        same period after accounting for taxes on dividends 💫.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        Especially before Covid, the performance of the STI had been “stagnant”
        for a very long time, says Katherine Chua, an investment advisor from
        investment platform FSMOne.com.&nbsp;
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        “So actually, you do not need to limit your investment choice to just an
        ETF in the Singapore equity market because, essentially, about half your
        portfolio is in financials,” she tells{" "}
        <em className="m-0 box-border p-0">thrive</em>.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        Past performance does not indicate future performance, of course.
        However, banking stocks – being mature businesses 🧓 – typically don’t
        experience the growth that tech stocks that feature heavily on global
        indices do.&nbsp;
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        “If you do want to have some exposure in tech, semiconductors or
        consumer discretionary names that are of a global international scale,
        you definitely need to venture out of the Singapore equity markets,”
        Chua says. “Usually, you’ll find most of these companies listed in the
        US stock exchanges.”&nbsp;
      </p>
      <div className="m-0 box-border p-0">
        <figure className="m-0 mb-6 box-border p-0">
          <img
            src={ThriveContent3}
            alt=""
            width="100%"
            height="auto"
            className="relative m-0 box-border h-auto max-w-full p-0"
          />
          <figcaption className="m-0 box-border p-0 text-xs italic"></figcaption>
        </figure>
      </div>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        One reason some investors prefer not to invest overseas is because of{" "}
        <strong className="m-0 box-border p-0">currency risk</strong> 💱. When
        you invest in a US stock, for example, and the US dollar weakens against
        the Singdollar, you risk losing money from the currency exchange.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        But there’s a bigger risk at play here, says Terence Wong, the founder
        and CEO of fund management firm Azure Capital.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        “There’s an opportunity cost to not investing in the US. If the (US
        market) goes up by 10 per cent versus 2 per cent for the STI, then you
        can afford the currency risk.”&nbsp;
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        Still, Wong believes that young investors can benefit from holding a
        percentage of the STI in their portfolios.&nbsp;
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        Investing in the STI is a different proposition altogether from
        investing in a growth-oriented index like the S&amp;P 500, he
        says.&nbsp;
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        Many of the companies in the STI are yield-driven and pay{" "}
        <strong className="m-0 box-border p-0">
          relatively high dividends
        </strong>
        .&nbsp;
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        “There was some life this year, but it has been very, very slow moving,
        also because it is a function of the underlying assets,” says Wong.
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        “Going back to the roots, it’s a yielding asset – it is boring, but
        relatively safe.”
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        &nbsp;
      </p>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        <strong className="m-0 box-border p-0">TL;DR</strong>
      </p>
      <p className="pl-8">
        <ul className="list-disc">
          <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
            Often the first index introduced to new investors in Singapore, the
            Straits Times Index has lagged global indices in performance
          </li>
          <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
            The STI comprises just 30 stocks, far less than the hundreds or
            thousands in other indices
          </li>
          <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
            Three banks, alone, make up around half of the STI
          </li>
          <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
            Unlike indices like the S&amp;P 500, the attractiveness of investing
            in the STI often isn’t capital appreciation, but dividends
          </li>
        </ul>
      </p>
      <div className="m-0 mx-auto">
        <div className="border-teal-600 flex items-center justify-end border-b-2 py-2">
          <span className="m-0 box-border px-1 align-middle font-inter text-base text-gray-800">
            Share this story
          </span>
          <a
            href="https://telegram.me/share/url?url=https://www.businesstimes.com.sg/companies-markets/investing-straits-times-index-alone-enough"
            target="_blank"
            className="border-teal-600 m-0 box-border inline-block border-b-2 p-0 px-1 text-gray-800 no-underline"
            rel="noreferrer"
          >
            <img
              alt="Telegram"
              className="relative m-0 box-border h-auto max-w-full p-0"
              height="50"
              src={ThriveTelegramLogo}
              width="50"
            />
          </a>
          <a
            href="https://www.linkedin.com/sharing/share-offsite/?url=https://www.businesstimes.com.sg/companies-markets/investing-straits-times-index-alone-enough"
            target="_blank"
            className="border-teal-600 m-0 box-border inline-block border-b-2 p-0 px-1 text-gray-800 no-underline"
            rel="noreferrer"
          >
            <img
              alt=""
              className="relative m-0 box-border h-auto max-w-full p-0"
              height="50"
              src={ThriveLinkedinLogo}
              width="50"
            />
          </a>
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://www.businesstimes.com.sg/companies-markets/investing-straits-times-index-alone-enough"
            target="_blank"
            className="border-teal-600 m-0 box-border inline-block border-b-2 p-0 px-1 text-gray-800 no-underline"
            rel="noreferrer"
          >
            <img
              alt="Facebook"
              className="relative m-0 box-border h-auto max-w-full p-0"
              height="50"
              src={ThriveFBLogo}
              width="50"
            />
          </a>
        </div>
      </div>
      <div className="m-0 mt-7 box-border border-t-4 border-green-400 p-0 font-normal">
        &nbsp;
      </div>

      <h2 className="m-0 mb-2 mt-2 box-border p-0 pb-1.5 pt-1.5 text-2xl text-gray-850">
        In other news…
      </h2>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        <strong className="m-0 box-border p-0">💰 Money</strong>
      </p>
      <ul>
        <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
          The cut-off yield on Singapore’s latest six-month Treasury bill fell
          to{" "}
          <a
            href="https://www.businesstimes.com.sg/companies-markets/latest-singapore-6-month-t-bill-cut-yield-falls-3-13"
            target="_blank"
            className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            rel="noreferrer"
          >
            3.13 per cent
          </a>
          , down from 3.34 previously, as investors expect the US central bank
          to cut interest rates.
        </li>
        <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
          With the rising cost of living, lower-income families simply can’t
          afford to{" "}
          <a
            href="https://www.todayonline.com/features/low-income-families-eat-fresh-health-food-costlier-charities-strapped-donations-2479976"
            target="_blank"
            className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            rel="noreferrer"
          >
            eat healthy
          </a>
          .&nbsp;
        </li>
        <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
          Got enough annual leave saved up for another overseas vacation? The{" "}
          <a
            href="https://www.businesstimes.com.sg/companies-markets/capital-markets-currencies/singapore-dollar-trades-around-10-year-high-policy-outlook"
            target="_blank"
            className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            rel="noreferrer"
          >
            Singdollar
          </a>{" "}
          rose to a 10-year high, gaining about 1.5 per cent this year.
        </li>
      </ul>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        <strong className="m-0 box-border p-0">💻 Tech</strong>
      </p>
      <ul>
        <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
          Telegram’s founder{" "}
          <a
            href="https://www.businesstimes.com.sg/companies-markets/telcos-media-tech/telegram-boss-durov-charged-banned-leaving-france"
            target="_blank"
            className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            rel="noreferrer"
          >
            Pavel Durov
          </a>{" "}
          was charged and banned from leaving France for allegedly failing to
          curb extremist and illegal content on the popular messaging app.{" "}
          <a
            href="https://www.nytimes.com/2024/08/26/technology/pavel-durov-telegram-founder.html"
            target="_blank"
            className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            rel="noreferrer"
          >
            Here’s
          </a>{" "}
          how he went from Russia’s Mark Zuckerberg to a wanted man.&nbsp;
        </li>
        <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
          “Big Tech stock beats earnings predictions, but takes beating in stock
          prices” is becoming the narrative for today’s stock market. Chipmaker{" "}
          <a
            href="https://www.businesstimes.com.sg/international/global/ai-chip-giant-nvidia-beats-expectations-shares-take-hit"
            target="_blank"
            className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            rel="noreferrer"
          >
            Nvidia’s share price
          </a>{" "}
          fell by about 4 per cent in after-hours trading after reporting
          earnings because, well, it wasn’t growing fast enough.
        </li>
      </ul>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        <strong className="m-0 box-border p-0">💼 Career</strong>
      </p>
      <ul>
        <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
          Retrenched Singaporean workers will start receiving{" "}
          <a
            href="https://www.businesstimes.com.sg/singapore/skillsfuture-jobseeker-support-starts-april-2025-locals-previous-wage-s5000-and-below"
            target="_blank"
            className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            rel="noreferrer"
          >
            financial support
          </a>{" "}
          from April 2025. One of the eligibility criteria is that the
          applicant’s previous average monthly salary must not exceed
          S$5,000.&nbsp;
        </li>
        <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
          Haven’t spent your SkillsFuture credits? These can now be used to
          offset subscription fees on Cousera and Udemy Business to access{" "}
          <a
            href="https://www.straitstimes.com/singapore/skillsfuture-credits-can-be-used-for-more-than-30000-new-online-courses-from-aug-28"
            target="_blank"
            className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            rel="noreferrer"
          >
            more than 30,000 courses
          </a>
          .
        </li>
      </ul>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        <strong className="m-0 box-border p-0">📈 Investing</strong>
      </p>
      <ul>
        <li>
          Singapore Reits, which could benefit from lower borrowing costs.
        </li>
      </ul>
      <p className="m-0 mb-2 box-border p-0 text-base leading-7 text-gray-850">
        <strong className="m-0 box-border p-0">🧬 Life</strong>
      </p>
      <ul>
        <li className="m-0 box-border px-5 py-2 leading-7 text-gray-850">
          How healthy is{" "}
          <a
            href="https://www.todayonline.com/features/oat-milk-good-healthy-nutrition-diet-2480166"
            target="_blank"
            className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            rel="noreferrer"
          >
            oat milk
          </a>
          , actually? TL;DR: More carbs, less protein and potential
          bloating.&nbsp;
        </li>
      </ul>
      <div className="fw-400 m-0 mb-20 mt-20 box-border border-2 border-gray-800 p-8 font-normal">
        <h1 className="mt-3.25 mb-4.5 m-0 box-border p-0 text-xl text-gray-800">
          Poll of the week
        </h1>
        <br />
        <p className="m-0 mb-2 box-border p-0 font-arial text-base leading-5 text-gray-850">
          Do you invest in the Straits Times Index?
        </p>
        <ul className="px-5">
          <li className="m-0 box-border list-disc p-2.5 leading-6 text-gray-850">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeXiSQMNY0sOTb9JdgTerL3BqkavyIpRzLsqshcnDakPUFu7A/viewform?usp=sf_link"
              className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            >
              <span className="m-0 box-border whitespace-pre p-0 text-xs">
                Yes
              </span>
            </a>
          </li>
          <li className="m-0 box-border list-disc p-2.5 leading-6 text-gray-850">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeXiSQMNY0sOTb9JdgTerL3BqkavyIpRzLsqshcnDakPUFu7A/viewform?usp=sf_link"
              className="m-0 box-border border-b-2 border-green-400 p-0 text-gray-850 no-underline"
            >
              <span className="m-0 box-border whitespace-pre p-0 text-xs">
                No
              </span>
            </a>
          </li>
        </ul>
        <p className="m-0 mb-2 box-border bg-white p-0 text-base leading-6 text-gray-800">
          &nbsp;
        </p>
      </div>
      <h1 className="mt-3.25 mb-4.5 m-0 box-border p-0 text-xl text-gray-800">
        Last week&apos;s Poll
      </h1>
      <br />
      <p className="m-0 mb-2 box-border bg-white p-0 font-inter text-base leading-6 text-gray-800">
        Would you want to experience working overseas as a young adult?
      </p>
      <p className="m-0 mb-2 box-border p-0 font-inter text-xs font-bold leading-5 text-gray-850">
        Yes! I’d love to see the world and work with people of different
        nationalities – 62.5%
      </p>
      <p className="m-0 mb-2 box-border p-0 font-inter text-xs leading-5 text-gray-850">
        Nope…I think I’ll just stay home. I’m comfortable where I am – 37.5%
      </p>
    </>
  );
}
