import Container from "@components/Container/Container";
import ThriveCenterSubscription from "@pages/Thrive/components/ThriveCenterSubscription";

import ThriveIndividualConversationCard from "./ThriveIndividualConversationCard";

export default function ThriveStartConversation(): React.ReactElement {
  return (
    <div
      className="overflow-hidden font-inter"
      data-testid="thrive-home-start-conversation-component"
    >
      <Container rootClassName="px-5 md:px-8 relative ">
        <p className="mb-20 text-center text-7xl lg:text-9xl">
          Starting Conversations<span className="text-green-400">.</span>
        </p>

        <ThriveIndividualConversationCard />
      </Container>

      <ThriveCenterSubscription className="-mt-40 lg:-mt-28" />
    </div>
  );
}
