import { memo } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import pulseEndingLogo from "@assets/pulse-end-logo.svg";
import { cn } from "@util/helpers";

export type PulseEndingScreenProps = {
  cardWidth?: number;
  smallCardWidth?: number;
  testId?: string;
  activeSlide?: number;
  index?: number;
};

function PulseEndingScreen({
  cardWidth,
  smallCardWidth,
  activeSlide,
  index,
}: PulseEndingScreenProps): React.ReactElement {
  return (
    <div
      data-testid="pulse-ending-screen-component"
      className={cn(
        "relative h-[500px] w-full rotate-3 overflow-hidden rounded-md bg-gradient-10 from-[#373737] to-[#2E2E2E] p-[1px] lg:h-[360px] lg:w-auto lg:rotate-0",
        "transition-size duration-300 group-[[data-is-active='true']]:h-full group-[[data-is-prev='true']]:opacity-0 group-[[data-is-active='true']]:shadow-pulseActive",
        "group-[.active]:rotate-0 group-[.next]:rotate-3 group-[.prev]:!-rotate-3 group-[[data-slide-index='0']]:rotate-0"
      )}
      style={{
        width: `${activeSlide === index ? cardWidth : smallCardWidth}px`,
      }}
    >
      <div className="flex h-full flex-col items-center justify-center bg-[#212121]">
        <div
          className={cn(
            "relative mx-auto mb-8 h-[150px] w-[150px] transition-size duration-300",
            "group-[[data-is-next='true']]:h-[80px] group-[[data-is-next='true']]:!w-[80px]"
          )}
        >
          <div className="absolute h-full w-full rounded-full bg-white opacity-65 blur-lg" />

          <div className="relative z-10 h-full w-full rounded-full">
            <img
              src={pulseEndingLogo}
              width={150}
              height={150}
              alt="bt pulse ending logo"
            />
          </div>
        </div>

        <p
          className={cn(
            "mx-auto mb-1 px-4 text-center font-poppins text-4xl font-semibold uppercase tracking-widest text-gray-250 transition-all duration-300",
            "group-[[data-is-next='true']]:text-3xs"
          )}
        >
          All caught up
        </p>

        <div
          className={cn(
            "mb-10 px-4 text-center font-poppins text-xs font-light uppercase leading-normal tracking-widest text-gray-515 transition-all duration-300 lg:mb-16 lg:text-base",
            "group-[[data-is-next='true']]:mb-5 group-[[data-is-next='true']]:text-10xs"
          )}
        >
          Congratulations, you have read all major news for today.
        </div>

        <Link
          to={RouteFactory.breakingNews}
          reloadDocument
          className={cn(
            "tracking-tight mx-auto rounded-[30px] bg-white px-18 py-4 font-poppins text-sm font-semibold leading-tight text-gray-850 transition-all duration-300",
            "group-[[data-is-next='true']]:pointer-events-none group-[[data-is-next='true']]:px-9 group-[[data-is-next='true']]:py-2 group-[[data-is-next='true']]:text-10xs"
          )}
        >
          Breaking News
        </Link>

        <Link
          to={RouteFactory.home}
          reloadDocument
          className={cn(
            "tracking-tight mx-auto py-4 font-poppins text-sm font-semibold leading-tight text-white transition-all duration-300",
            "group-[[data-is-next='true']]:pointer-events-none group-[[data-is-next='true']]:text-10xs"
          )}
        >
          Close
        </Link>
      </div>
    </div>
  );
}

export default memo(PulseEndingScreen);
