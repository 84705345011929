type MboReadMoreProps = React.HTMLAttributes<HTMLDivElement>;

export default function MboReadMore({
  className,
}: MboReadMoreProps): React.ReactElement {
  return (
    <div className={className} data-testid="mbo-readmore-component">
      <span>
        Read more: <b>bt.sg</b>
      </span>

      <span>
        Subscribe: <b>btsub.sg/mbo</b>
      </span>
    </div>
  );
}
