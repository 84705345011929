import { ESG_INSIGHTS_OTHER_READS } from "@components/Newsletter/constants";
import NewsletterSampleFooter from "@pages/Newsletter/Archive/components/NewsletterSampleFooter";
import EsgInsightsNewsletterSampleAuthor from "@pages/Newsletter/Archive/EsgInsight/SampleIssues/components/EsgInsightsNewsletterSampleAuthor";
import EsgInsightsNewsletterSampleContent from "@pages/Newsletter/Archive/EsgInsight/SampleIssues/components/EsgInsightsNewsletterSampleContent";
import EsgInsightsNewsletterSampleHeader from "@pages/Newsletter/Archive/EsgInsight/SampleIssues/components/EsgInsightsNewsletterSampleHeader";
import EsgInsightsNewsletterSampleTopReads from "@pages/Newsletter/Archive/EsgInsight/SampleIssues/components/EsgInsightsNewsletterSampleTopReads";
import { cn } from "@util/helpers";

import EsgInsightsNewsletterSampleOtherReads from "./components/EsgInsightsNewsletterSampleOtherReads";

export type EsgInsightsLatestIssueProps = {
  divRef?: React.RefObject<HTMLDivElement>;
  ClassName?: string;
  firstChildClassName?: string;
};

export default function EsgInsightsLatestIssue({
  divRef,
  ClassName = "",
  firstChildClassName = "",
}: EsgInsightsLatestIssueProps): React.ReactElement {
  return (
    <>
      <h2 className="mb-3 text-center font-poppins text-base font-medium">
        Sample issue
      </h2>

      <div
        data-testid="esg-insights-iframe"
        ref={divRef}
        className={cn("rounded-2xl border-2 border-gray-850", ClassName)}
      >
        <div
          className={cn(
            "mt-0 h-[525px] overflow-y-scroll",
            firstChildClassName
          )}
        >
          <div className="mx-auto mb-9 mt-3 w-full max-w-[600px] border-collapse">
            <EsgInsightsNewsletterSampleHeader />

            <EsgInsightsNewsletterSampleAuthor />

            <EsgInsightsNewsletterSampleContent />

            <EsgInsightsNewsletterSampleTopReads />

            <EsgInsightsNewsletterSampleOtherReads
              newsletters={ESG_INSIGHTS_OTHER_READS}
            />

            <NewsletterSampleFooter editionId={"59770"} />
          </div>
        </div>
      </div>
    </>
  );
}
