import { Link } from "react-router-dom";
import defaultMastheadImage from "@assets/logo-masthead.svg";
import { RouteFactory } from "@src/app/routePaths";
import cx from "classnames";

export type LogoMastheadProps = {
  rootClassName?: string;
  src?: string;
  link?: string;
  title?: string;
  imgWidth?: number;
  imgHeight?: number;
};

export default function LogoMasthead({
  rootClassName = "",
  src = defaultMastheadImage,
  link = RouteFactory.home,
  title = "The Business Times",
  imgWidth = 607,
  imgHeight = 61,
}: LogoMastheadProps): React.ReactElement {
  return (
    <div className={cx(rootClassName, "py-4 lg:py-0")}>
      <Link to={`${link}?ref=logo`} title={title} reloadDocument>
        <img src={src} alt={title} width={imgWidth} height={imgHeight} />
      </Link>
    </div>
  );
}
