import { ReactElement } from "react";
import cx from "classnames";

export type SummaryProps = {
  rootClassName?: string;
  summary?: string;
};

/**
 * Summary
 * Description: The summary of the article
 * @param {SummaryProps} props
 * @returns {ReactElement}
 */
export default function Summary({
  rootClassName = "",
  summary = "",
}: SummaryProps): ReactElement {
  return <p className={cx("max-lg:hidden", rootClassName)}>{summary}</p>;
}
