import { RouteFactory } from "@app/routePaths";
import CirculationBanner from "@assets/newsletter/circulation_banner.gif";
import FiveReads from "@assets/newsletter/newsletter-five-things-to-know.png";
import NLBannerIMU from "@assets/newsletter/nl-banner-imu.gif";
import SubscriberLabel from "@assets/newsletter/subscribers-label-hr4x.png";
import { cn } from "@util/helpers";

export default function NewsletterSampleFiveReads(): React.ReactElement {
  const bannerLink =
    "https://subscribe.sph.com.sg/promotions/bt-personal/?utm_campaign=btp99&utm_medium=email&utm_source=emarsys&utm_content=840x270-bottom-bt-garage";
  return (
    <>
      <div className="mt-9 border-t-[10px] border-gray-250 pb-5" />
      <div className="font-arial text-base font-normal">
        <div className="m-0 p-0">
          <div className="pb-4">
            <img
              width="200"
              height="38"
              src={FiveReads}
              alt="READ MORE"
              className="mx-auto block h-auto max-w-full"
            />
          </div>
        </div>

        {stories.map((story, index) => (
          <div
            key={story.id}
            data-id={`garageStory${story.id}`}
            className={cn("px-2 py-5", {
              "border-b-4 border-solid border-gray-250":
                index !== stories.length - 1,
            })}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={story.link}
              className="text-black no-underline"
            >
              <span className="story-counter font-arial text-5xl font-bold text-black-200">
                {story.counter}
              </span>
              <span className="story-counter-seperator pr-1 font-arial text-4xl font-bold text-verticals-garage">
                /
              </span>
              <span className="story-title pl-3 pr-3 font-arial text-3xl font-bold text-black-200">
                {story.title}
              </span>
            </a>

            {story.imgSrc && (
              <img
                src={story.imgSrc}
                width="104"
                height="auto"
                alt={story.title}
                className="m-0 mb-3 block h-auto max-w-full"
              />
            )}
            <div className="story-description mb-3 font-arial text-sm text-black-200">
              <p className="m-0 p-0">{story.description}</p>
            </div>
            <a
              className="story-read-more border-b-[1px] border-solid border-b-cyan-100 font-arial text-sm font-bold text-cyan-100"
              target="_blank"
              rel="noopener noreferrer"
              href={story.link}
            >
              Read more
            </a>
          </div>
        ))}
      </div>
      <div className="p-5 text-center">
        <a
          className="inline-block rounded-full bg-green px-10 py-2 no-underline"
          target="_blank"
          href={RouteFactory.section("startups-tech/startups")}
          rel="noopener noreferrer"
        >
          <span className="tracking-wider font-arial text-sm font-bold uppercase leading-snug text-white">
            Read more
          </span>
        </a>
      </div>
      <div className="front-copy mx-auto max-w-[800px] border-collapse p-0">
        <div className="flex justify-center border-b-2 border-t-2 border-b-gray-250 border-t-gray-250 py-6">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={RouteFactory.newsletterSignup}
            className="no-underline"
          >
            <img
              src={NLBannerIMU}
              className="h-auto w-[300px] max-w-full p-3"
              alt="Newsletter Banner"
            />
          </a>
        </div>
        <div className="mt-9 border-t-[10px] border-gray-250 pb-5" />

        <div className="pt-6">
          <span className="font-arial text-3xl font-bold text-cyan-100">
            Quote of the week
          </span>
        </div>

        <div className="pt-4 font-arial text-base leading-6 text-black-200">
          <div className="quote-of-the-week-wrapper bg-gray-250 p-7">
            <div className="m-0 p-0">
              <p className="m-0 pb-3 font-arial text-3xl font-bold text-gray-875">
                “I’m not going to have any one of them (state-linked funds) in
                my shareholder portfolio as an investor. I’m building the
                company to be geopolitically very free and neutral.”
              </p>
              <p className="m-0 font-arial text-base font-normal text-gray-875">
                Han Byung Joon, CEO of Silicon Box, on avoiding the{" "}
                <a
                  className="color-blue text-blue no-underline"
                  href="https://www.businesstimes.com.sg/startups-tech/startups/semiconductor-unicorn-silicon-box-vows-avoid-geopolitical-mire"
                >
                  quagmire of geopolitics
                </a>
                .
              </p>
            </div>
          </div>
        </div>

        <div className="mt-9 border-t-[10px] border-gray-250 pb-5" />

        <div className="mx-auto flex max-w-[300px] justify-center p-0">
          <a
            href={bannerLink}
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline"
          >
            <img
              src={CirculationBanner}
              alt="Circulation Banner"
              width="220"
              height="180"
              className="block h-auto max-w-full"
            />
          </a>
        </div>
      </div>
    </>
  );
}

const stories = [
  {
    id: 0,
    title: "Staying neutral",
    link: "https://www.businesstimes.com.sg/startups-tech/startups/semiconductor-unicorn-silicon-box-vows-avoid-geopolitical-mire?utm_source=emarsys&utm_medium=email&utm_campaign=BT_Garage&utm_term=Staying+neutral&utm_content=13%2F07%2F2024&ref=5things",
    description:
      "SINGAPORE’S latest tech unicorn Silicon Box, which pushed past a US$1 billion valuation after a US$200 million funding this year, intends to steer clear of the geopolitical tug of war that has jolted the global semiconductor industry.",
    imgSrc: SubscriberLabel,
    counter: 1,
  },
  {
    id: 1,
    title: "Focusing on dry powder",
    link: "https://www.businesstimes.com.sg/startups-tech/startups/funding-concentration-seen-emerging-tech-startups-sginnovate-report?utm_source=emarsys&utm_medium=email&utm_campaign=BT_Garage&utm_term=Focusing+on+dry+powder&utm_content=13%2F07%2F2024&ref=5things",
    description:
      "THE number of funding deals has remained fairly constant between 2022 and 2023 in Singapore’s emerging tech ecosystem, but funding is concentrated on a few companies.",
    imgSrc: null,
    counter: 2,
  },
  {
    id: 2,
    title: "Widening the pool",
    link: "https://www.businesstimes.com.sg/startups-tech/startups/gojek-and-comfortdelgro-taxi-send-untaken-rides-each-other-s-platforms?utm_source=emarsys&utm_medium=email&utm_campaign=BT_Garage&utm_term=Widening+the+pool&utm_content=13%2F07%2F2024&ref=5things",
    description:
      "RIDE-HAILING app Gojek and ComfortDelGro Taxi will dispatch rides not taken up by drivers to each other’s platform.",
    imgSrc: null,
    counter: 3,
  },
  {
    id: 3,
    title: "Fulfilling solar needs",
    link: "https://www.businesstimes.com.sg/startups-tech/startups/spore-strikes-166m-australian-solar-deal-in-2024?utm_source=emarsys&utm_medium=email&utm_campaign=BT_Garage&utm_term=Fulfilling+solar+needs&utm_content=13%2F07%2F2024&ref=5things",
    description:
      "A SINGAPORE firm has struck a A$166 million solar power deal with an Australian company in a bid to meet rising demand for renewable energy.",
    imgSrc: null,
    counter: 4,
  },
  {
    id: 4,
    title: "Boosting talent",
    link: "https://www.businesstimes.com.sg/startups-tech/startups/spore-startup-joining-forces-700k-hackathon-boost-cybersecurity-talent?utm_source=emarsys&utm_medium=email&utm_campaign=BT_Garage&utm_term=Boosting+talent&utm_content=13%2F07%2F2024&ref=5things",
    description:
      "A SINGAPORE startup is collaborating with other firms to hold a S$700,000 hackathon aimed at boosting the cybersecurity talent pool in the region.",
    imgSrc: null,
    counter: 5,
  },
];
