import { Author, ContributorProfile, Profile } from "@app/types/Cue";

import { getAuthorProfile } from "./BylineHelper";

/**
 * Helper function to get all the profiles from author and contributed.
 * @param authors List of authors.
 * @param conributor List of contributed profiles.
 * @returns {(Profile | ContributorProfile)[]}
 */
export const getAllProfiles = (
  authors: Author[] = [],
  conributor: ContributorProfile[] = []
): (Profile | ContributorProfile)[] => {
  const author = authors.reduce<Profile[]>((prev, cur) => {
    const { currentAuthorProfile } = getAuthorProfile(cur.profiles);

    if (typeof currentAuthorProfile === "undefined") return [...prev];

    return [...prev, currentAuthorProfile];
  }, []);

  return [...author, ...conributor];
};

/**
 * Helper function to get the separator.
 * @param index Index of the profile.
 * @param profilesCount Total count of profiles.
 * @returns {"&" | "," | ""}
 */
export const getSeparator = (
  index: number,
  profilesCount: number
): "&" | "," | "" => {
  if (index === profilesCount - 2) return "&";
  if (index < profilesCount - 1) return ",";

  return "";
};
