import { BIGMONEY_BIG_READS } from "@components/Newsletter/constants";

export default function NewsletterSampleBigReads(): React.ReactElement {
  return (
    <>
      <div className="mt-12 border-t-4 border-gray-150">
        <div className="mx-auto flex items-center justify-center">
          <img
            src="https://static1.businesstimes.com.sg/s3fs-public/5-big-reads.png"
            className="mx-auto h-auto w-80"
            alt="Big Reads Banner"
          />
        </div>

        <div className="py-3">
          <a
            href="https://www.businesstimes.com.sg/opinion-features/columns/telco-m1-struggles-find-fit-keppels-transformation-plans?utm_source=emarsys&amp;amp;utm_medium=email&amp;amp;utm_campaign=bt_big_money&amp;amp;utm_term=Telco+M1+struggles+to+find+a+fit+in+Keppel%E2%80%99s+transformation+plans+&amp;amp;utm_content=09%2F06%2F2024"
            target="_blank"
            rel="noopener noreferrer"
            className="block"
          >
            <img
              src="https://static1.businesstimes.com.sg/s3fs-public/styles/560x372/public/articles/2024/10/17/418097497.jpg?VersionId=Iso_zKBl.VEKh3bxLhvQK2OaiO_fiUtv&itok=e9R7AGRl"
              className="mx-auto w-full"
              alt="China’s economic ills are serious but not incurable"
            />
          </a>
        </div>

        {BIGMONEY_BIG_READS.map((article) => (
          <div key={article.title} className="border-b-4 border-gray-150 py-4">
            <h2 className="m-0 leading-9">
              <a
                target="_blank"
                href={article.articleUrl}
                className="text-gray-750 no-underline"
                rel="noreferrer"
              >
                <span className="font-times text-6xl font-bold leading-3">
                  {article.title}
                </span>
              </a>
            </h2>

            <div className="py-3 font-public-sans text-lg font-normal leading-[1.4] text-gray-750">
              <p className="m-0">{article.description}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="border-b-4 border-gray-150">
        <div className="py-9">
          <p className="m-0 pb-5 font-times text-xl font-normal leading-[1.4] text-gray-750">
            Did you receive this newsletter from someone?
          </p>

          <p className="m-0 font-times text-xl font-normal leading-[1.4] text-gray-750">
            <a
              href="https://www.businesstimes.com.sg/newsletter/sign-up"
              className="font-times text-xl font-normal leading-[1.4] text-[#16B667] underline"
            >
              Sign up here
            </a>{" "}
            to get the latest updates sent right to your inbox.
          </p>
        </div>
      </div>

      <div className="mx-auto w-full max-w-80 border-collapse">
        <div className="py-14 text-center">
          <a
            href="https://subscribe.sph.com.sg/promotions/bt-personal/?utm_campaign=btevgreenbanner1&amp;utm_medium=banner&amp;utm_source=newsletter&amp;utm_content=Bigmoney-Evergreen1-2024"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://static1.businesstimes.com.sg/s3fs-public/image/2024/05/09/circulation_banner_1.gif?VersionId=SxvsJkt0cj9_0S6TEIX13NFmPG5FVcY2"
              className="w-full"
              alt="Subscription Banner"
            />
          </a>
        </div>
      </div>
    </>
  );
}
