import { useState } from "react";
import { ArticleDataObject } from "@app/types/Cue";
import axios from "axios";

export const useLoadMoreStoriesBySectionKeyword = (
  section: string,
  keyword: string,
  initialList: ArticleDataObject[],
  pageSize: number
) => {
  const [articleList, setArticleList] =
    useState<ArticleDataObject[]>(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length >= pageSize
  );

  const handleLoadMore = async () => {
    const lastArticle = articleList?.[articleList.length - 1];
    const sort = lastArticle?.sort;

    if (articleList.length === 0) return;
    if (typeof lastArticle === "undefined") return;
    if (typeof sort === "undefined") return;

    const newMoreArticles = await fetchStories(section, keyword, sort);

    if (newMoreArticles.length < pageSize) setHasMoreArticles(false);

    setArticleList((prev) => [...prev, ...newMoreArticles]);
  };

  return { articleList, handleLoadMore, hasMoreArticles };
};

const fetchStories = async (
  section: string,
  keyword: string,
  sort: (string | number)[]
) => {
  try {
    const response = await axios.post(
      "/_plat/api/v1/more-stories-by-section-keywords",
      { section, keyword, sort, size: 10 }
    );

    return response?.data || [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Error occurred while fetching stories from v1/more-stories-by-section-keywords for section: ${section}, keyword: ${keyword}, sort: ${JSON.stringify(sort)}. Details: ${(error as Error).message}`
    ); // eslint-disable-line no-console

    return [];
  }
};
