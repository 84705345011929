import { FormEvent, useState } from "react";
import { EmarsysCodesEnums, EmarsysStatusEnums } from "@app/types/enums";
import Button from "@components/AccessibleComponents/Button";
import TextInput from "@components/AccessibleComponents/TextInput";
import { EmarsysResponse } from "@components/Newsletter/types/Emarsys";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { newsletterRequests } from "@pages/Newsletter/SignUp/Newsletter.server";
import { cn } from "@util/helpers";
import cx from "classnames";

type ThriveSubscriptionFormProps = React.HTMLAttributes<HTMLDivElement> & {
  formRootClass?: string;
};

export default function ThriveSubscriptionForm({
  className,
  formRootClass,
}: ThriveSubscriptionFormProps): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const newsletters = BT_NEWSLETTERS.filter(
    ({ type }) => type === NEWSLETTER_TYPE.THRIVE
  );
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    newsletterRequests
      .processSubscription(email, newsletters)
      .then((response) => {
        setEmarsysResponse(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className={className} data-testid="thrive-subscription-component">
        {emarsysResponse?.status !== EmarsysStatusEnums.Success ? (
          <form
            onSubmit={handleSubmit}
            className={cn(
              "flex flex-col items-start border-b",
              "border-gray-850 lg:w-458px",
              formRootClass
            )}
            aria-label="thrive-newsletter-form"
          >
            <div className="flex items-center self-stretch">
              <TextInput
                className="flex w-1/2 flex-grow flex-col items-start bg-white px-5 py-4 font-inter text-gray-850 outline-none placeholder:text-gray-600 lg:w-auto"
                name="email"
                type="email"
                inputMode="email"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />

              <Button
                className="flex-center lg:flex-end flex items-center bg-orange-400 px-5 py-4  font-interBold text-base uppercase leading-6 text-white outline-none"
                type="submit"
              >
                {loading ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={loading}
                    className="px-5"
                    size="xl"
                    color="text-gray-850"
                  />
                ) : (
                  "Sign up"
                )}
              </Button>
            </div>
          </form>
        ) : (
          <div
            role="alert"
            className="mt-1 bg-white p-4 text-center font-inter text-lg font-semibold text-green-400 lg:w-458px "
            data-testid="newsletter-welcome-message"
          >
            Welcome to the Thrive community! 🙆
          </div>
        )}
      </div>
      {emarsysResponse?.status !== EmarsysStatusEnums.Success ? (
        <div
          role="alert"
          data-testid="emarsys-newsletter-error"
          className={cx(
            "relative col-span-6 flex font-inter text-xs font-semibold text-red lg:w-458px",
            {
              "mt-3":
                emarsysResponse?.status === EmarsysStatusEnums.Error ||
                emarsysResponse?.emarsysCode ===
                  EmarsysCodesEnums.EmailIsRequired,
            }
          )}
        >
          {emarsysResponse?.status === EmarsysStatusEnums.Error &&
          emarsysResponse?.emarsysCode === EmarsysCodesEnums.EmailIsRequired
            ? "E-mail field is required."
            : emarsysResponse?.message}
        </div>
      ) : null}
    </>
  );
}
