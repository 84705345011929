import {
  emarsysCodes,
  EmarsysResponse,
} from "@components/Newsletter/types/Emarsys";
import {
  Newsletter,
  NEWSLETTER_REQUESTS_RESPONSES,
} from "@components/Newsletter/types/Newsletter";
import axios from "axios";

export const newsletterRequests = {
  processSubscription: async (
    email: string,
    newsletters: Newsletter[]
  ): Promise<EmarsysResponse> => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailPattern.test(email))
      return NEWSLETTER_REQUESTS_RESPONSES.INVALIDEMAIL;

    const selectedKeys = newsletters.map((newsletter) => newsletter.type);

    const apiPayload = {
      service: "emarsys",
      payload: {
        email: email,
        selectedKeys: selectedKeys,
      },
    };

    const response = await axios.post("/_plat/api/v1/bff", apiPayload);

    // Guard clauses
    if (response.status !== 200) return NEWSLETTER_REQUESTS_RESPONSES.ERROR;
    if (response.data.statusCode !== 200)
      return NEWSLETTER_REQUESTS_RESPONSES.ERROR;
    if (!emarsysCodes.includes(response.data.body))
      return NEWSLETTER_REQUESTS_RESPONSES.ERROR;

    return (
      Object.values(NEWSLETTER_REQUESTS_RESPONSES).find(
        (emarsysResponse) => emarsysResponse.emarsysCode === response.data.body
      ) || NEWSLETTER_REQUESTS_RESPONSES.ERROR
    );
  },
};
