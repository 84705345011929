import { SanitizedSectionArticle } from "@app/types/Cue";

export enum AwardType {
  E50 = "events-awards_enterprise-50",
  EE = "events-awards_emerging-enterprise",
  SBA = "events-awards_singapore-business-awards",
  SCA = "events-awards_singapore-corporate-awards",
  SIA = "events-awards_sustainability-impact-awards",
}

export type EventsAwardsPageContext<
  T extends EventsAwardsPageContextData | EventsAwardsLandingPageContextData,
> = {
  kind: "section";
  data: T;
};

export type EventsAwardsPageContextData = {
  title: string;
  e50Article?: SanitizedSectionArticle;
  eeArticle?: SanitizedSectionArticle;
  sbaArticle?: SanitizedSectionArticle;
  scaArticle?: SanitizedSectionArticle;
  siaArticle?: SanitizedSectionArticle;
};

export type EventsAwardsLandingPageContextData = {
  title: string;
  article?: SanitizedSectionArticle;
};
