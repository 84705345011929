import type { ArticleDataObject } from "@app/types/Cue";
import { KeywordStoriesRowProps } from "@app/types/Verticals";
import VerticalLatest from "@components/ArticleCard/VerticalLatest";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import SeeMore from "@components/SeeMore/SeeMore";
import VerticalBlockTitle from "@components/VerticalBlockTitle/VerticalBlockTitle";
import { getKickerFromArticleDataObject } from "@util/helpers";
import cx from "classnames";

export default function KeywordStoriesRow({
  testId,
  seeMoreLink,
  verticalName,
  verticalBlockTitle,
  data = [],
}: KeywordStoriesRowProps): React.ReactElement {
  const keywordStories1 = data?.slice(0, 2) ?? [];
  const keywordStories2 = data?.slice(2, 4) ?? [];

  return (
    <>
      {data.length > 0 ? (
        <Row rootClassName="mb-12" data-testid={testId}>
          <Column rootClassName="w-full">
            <VerticalBlockTitle
              text={verticalBlockTitle}
              rootClassName="mb-5"
            />
          </Column>

          <>
            {keywordStories1.length > 1 ? (
              <Column rootClassName={cx("w-full", "lg:w-6/12")}>
                {keywordStories1?.map(
                  (article: ArticleDataObject, index: number) => (
                    <VerticalLatest
                      articleData={article}
                      rootClassName="mb-4 pb-4 border-b border-gray-250"
                      key={index}
                      verticalName={verticalName}
                      kicker={
                        article?.kicker?.fields?.[0]?.value ||
                        getKickerFromArticleDataObject(article)
                      }
                    />
                  )
                )}
              </Column>
            ) : null}
          </>

          <>
            {keywordStories2.length > 1 ? (
              <Column rootClassName={cx("w-full", "lg:w-6/12")}>
                {keywordStories2?.map(
                  (article: ArticleDataObject, index: number) => (
                    <VerticalLatest
                      articleData={article}
                      rootClassName="mb-4 pb-4 border-b border-gray-250"
                      key={index}
                      verticalName={verticalName}
                      kicker={
                        article?.kicker?.fields?.[0]?.value ||
                        getKickerFromArticleDataObject(article)
                      }
                    />
                  )
                )}
              </Column>
            ) : null}
          </>

          <>
            {data.length > 4 ? (
              <Column rootClassName="w-full">
                <SeeMore
                  text="View More"
                  verticalName={verticalName}
                  link={seeMoreLink}
                />
              </Column>
            ) : null}
          </>
        </Row>
      ) : null}
    </>
  );
}
