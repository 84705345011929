import { Field } from "@app/types/Cue";
import { Name } from "@app/types/enums";
import { getFieldValue } from "@util/helpers";
import { isString } from "lodash-es";

import DynamicIframe from "./DynamicIframe";

export type DynamicIframeWrapperProps = {
  fields: Field[];
  className?: string;
};

export default function DynamicIframeWrapper({
  fields,
  className,
}: DynamicIframeWrapperProps) {
  const source = getFieldValue(fields, Name.DynamicIframe);

  return (
    <>
      {source && isString(source) ? (
        <DynamicIframe source={source} className={className} />
      ) : null}
    </>
  );
}
