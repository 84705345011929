import {
  EmailShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import iconEmail from "@assets/subshare/icon-email.svg";
import iconTelegram from "@assets/subshare/icon-telegram.svg";
import iconTwitter from "@assets/subshare/icon-twitter.svg";
import iconWhatsapp from "@assets/subshare/icon-whatsapp.svg";
import {
  LAMBDA_RESPONSE_MESSAGES,
  LAMBDA_RESPONSE_STATUS,
  LAMBDA_RESPONSE_TYPES,
  LambdaResponsePayload,
} from "@pages/Article/types/Alacrity";

/**
 * Mock responsePayload for generateShortnerURL in useAlacrity.ts
 */
export const ARTICLE_SUCCESS_SHORTNER: LambdaResponsePayload = {
  status: LAMBDA_RESPONSE_STATUS.SUCCESS,
  message: LAMBDA_RESPONSE_MESSAGES.SUCCESS_SHORTNER,
  data: {
    responseType: LAMBDA_RESPONSE_TYPES.SUCCESS_SHORTNER,
    shortURL: "https://bt.sg/SoKG",
  },
};

/**
 * Mock responsePayload for generateGiftURL in useAlacrity.ts
 */
export const ARTICLE_SUCCESS_GIFT: LambdaResponsePayload = {
  status: LAMBDA_RESPONSE_STATUS.SUCCESS,
  message: LAMBDA_RESPONSE_MESSAGES.SUCCESS_GIFT,
  data: {
    responseType: LAMBDA_RESPONSE_TYPES.SUCCESS_GIFT,
    shortURL: "https://bt.sg/SoKG",
    giftCount: 1,
  },
};

/**
 * Mock responsePayload for generateGiftURL in useAlacrity.ts
 */
export const ARTICLE_SUCCESS_GIFTING_LIMIT_REACHED: LambdaResponsePayload = {
  status: LAMBDA_RESPONSE_STATUS.SUCCESS,
  message: LAMBDA_RESPONSE_MESSAGES.ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED,
  data: {
    responseType: LAMBDA_RESPONSE_TYPES.ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED,
    shortURL: "https://bt.sg/SoKG",
    giftCount: 2,
  },
};

/**
 * Mock responsePayload for generateGiftURL in useAlacrity.ts
 */
export const ARTICLE_SUCCESS_GIFTER_ALREADY: LambdaResponsePayload = {
  status: LAMBDA_RESPONSE_STATUS.SUCCESS,
  message: LAMBDA_RESPONSE_MESSAGES.SUCCESS_ALREADY_GIFTED,
  data: {
    responseType: LAMBDA_RESPONSE_TYPES.SUCCESS_ALREADY_GIFTED,
    shortURL: "https://bt.sg/SoKG",
    giftCount: 2,
  },
};

/**
 * Mock responsePayload for generateGiftURL in useAlacrity.ts
 */
export const ARTICLE_ERROR_GIFT: LambdaResponsePayload = {
  status: LAMBDA_RESPONSE_STATUS.ERROR,
  message: LAMBDA_RESPONSE_MESSAGES.ERROR_GIFT,
  data: {
    responseType: LAMBDA_RESPONSE_TYPES.ERROR_GIFT,
  },
};

/**
 * Mock responsePayload for verifyGiftToken in useAlacrity.ts
 */
export const ARTICLE_SUCCESS_VALID_GIFT_TOKEN: LambdaResponsePayload = {
  status: LAMBDA_RESPONSE_STATUS.SUCCESS,
  message: LAMBDA_RESPONSE_MESSAGES.SUCCESS_VALID_GIFT_TOKEN,
  data: {
    responseType: LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN,
  },
};

/**
 * Mock responsePayload for verifyGiftToken in useAlacrity.ts
 */
export const ARTICLE_ERROR_INVALID_GIFT_TOKEN: LambdaResponsePayload = {
  status: LAMBDA_RESPONSE_STATUS.ERROR,
  message: LAMBDA_RESPONSE_MESSAGES.ERROR_INVALID_GIFT_TOKEN,
  data: {
    responseType: LAMBDA_RESPONSE_TYPES.ERROR_INVALID_GIFT_TOKEN,
  },
};

/**
 * responsePayload for generic errors in useAlacrity.ts and useArticleGiftReceived.ts
 */
export const ARTICLE_ERROR_GENERIC: LambdaResponsePayload = {
  status: LAMBDA_RESPONSE_STATUS.ERROR,
  message: LAMBDA_RESPONSE_MESSAGES.ERROR_GENERIC,
  data: {
    responseType: LAMBDA_RESPONSE_TYPES.ERROR_GENERIC,
  },
};

export const GIFTER_SOCIALS: {
  label: string;
  icon: string;
  element:
    | typeof WhatsappShareButton
    | typeof TelegramShareButton
    | typeof EmailShareButton
    | typeof TwitterShareButton;
}[] = [
  { label: "Whatsapp", icon: iconWhatsapp, element: WhatsappShareButton },
  { label: "Telegram", icon: iconTelegram, element: TelegramShareButton },
  { label: "Email", icon: iconEmail, element: EmailShareButton },
  { label: "Twitter", icon: iconTwitter, element: TwitterShareButton },
];

export const SUCCESS_RESPONSE_GIFT_ICON = [
  LAMBDA_RESPONSE_TYPES.SUCCESS_SHORTNER,
  LAMBDA_RESPONSE_TYPES.SUCCESS_GIFT,
  LAMBDA_RESPONSE_TYPES.SUCCESS_ALREADY_GIFTED,
  LAMBDA_RESPONSE_TYPES.SUCCESS_VALID_GIFT_TOKEN,
];

export const ERROR_RESPONSE_GIFT_ICON = [
  LAMBDA_RESPONSE_TYPES.ERROR_GIFT,
  LAMBDA_RESPONSE_TYPES.ERROR_INVALID_GIFT_TOKEN,
  LAMBDA_RESPONSE_TYPES.ERROR_MONTHLY_GIFT_QUOTA_LIMIT_REACHED,
  LAMBDA_RESPONSE_TYPES.ERROR_UNSUPPORTED_ACTION,
  LAMBDA_RESPONSE_TYPES.ERROR_GENERIC,
];

export const ARTICLE_SUBSHARE_BODY_TEXT = `Hi,\n\nPlease enjoy this premium article from The Business Times that I've sent you as a gift! You'll have unlimited access to the article for 14 days.\n\n`;
export const ARTICLE_SUBSHARE_EMAIL_SUBJ =
  "Please enjoy this premium article from The Business Times that I've sent you as a gift!";
export const ARTICLE_SUBSHARE_PROMPT_SUBSCRIBE_LINK =
  "https://subscribe.sph.com.sg/promotions/bt-personal/?utm_campaign=giftastory&utm_medium=sph-publication&utm_source=bt&utm_content=subscribebutton";

export const MAX_GIFTS_PER_MONTH = 5;
