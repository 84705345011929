import React, { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import chevronLeft from "@assets/icon-chevron-left-white.svg";
import chevronRight from "@assets/icon-chevron-right-white.svg";
import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import { SanitizedPulseData } from "@pages/Pulse/utils/types";
import { cn } from "@util/helpers";

import PulseIndividualArticle from "./PulseIndividualDesktopArticle";
import PulseEndingScreen from "../PulseEndingScreen";

export type PulseIndividualArticleProps = {
  articles: SanitizedPulseData[];
  activeSlide: number;
  setActiveSlide: React.Dispatch<React.SetStateAction<number>>;
};

export default function PulseDesktopCarousel({
  articles,
  activeSlide,
  setActiveSlide,
}: PulseIndividualArticleProps): React.ReactElement {
  const totalLength = articles.length + 1;
  const [_, setSearchParams] = useSearchParams();
  const changeUrlPath = useCallback(
    (index: number) => {
      if (index === totalLength - 1) {
        setSearchParams({ article: "finished" });
        return;
      }
      setSearchParams({ article: articles[index].id });
    },
    [articles, totalLength, setSearchParams]
  );

  const handleNext = useCallback(() => {
    if (activeSlide + 1 > totalLength - 1) return;
    setActiveSlide(activeSlide + 1);
    changeUrlPath(activeSlide + 1);
  }, [activeSlide, totalLength, changeUrlPath, setActiveSlide]);

  const handlePrev = useCallback(() => {
    if (activeSlide - 1 < 0) return;
    setActiveSlide(activeSlide - 1);
    changeUrlPath(activeSlide - 1);
  }, [activeSlide, changeUrlPath, setActiveSlide]);

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      const { code } = e;

      if (code === "ArrowRight") {
        handleNext();
      } else if (code === "ArrowLeft") {
        handlePrev();
      }
    },
    [handleNext, handlePrev]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress, true);

    return function () {
      window.removeEventListener("keydown", handleKeyPress, true);
    };
  }, [handleKeyPress]);

  const cardWidth = 430;
  const smallCardWidth = 230;
  const padding = 24;
  const arrowSize = 36;

  const arrowTotalWidth = arrowSize + padding;
  const diffBetweenSmallAndBigCard =
    cardWidth + padding - (smallCardWidth + padding);

  if (articles.length === 0) return <> </>;

  return (
    <div
      data-testid="pulse-desktop-carousel-components"
      className="relative z-10 h-full w-full"
    >
      <div
        className="relative left-1/2 top-1/2 h-[650px] -translate-x-1/2 -translate-y-1/2"
        style={{ width: `${cardWidth}px` }}
      >
        <div
          className="absolute z-20 h-full min-w-[340px] bg-[#121212]"
          style={{
            left: `calc(-1 * ${300 + arrowTotalWidth + padding * 2}px)`,
          }}
        />

        <button
          className={cn(
            REMOVE_FOCUS_CSS,
            "absolute top-1/2 z-30 -translate-y-1/2 rounded-full bg-[#353535] p-1 transition-all duration-300 hover:bg-[#555555] active:scale-75",
            { "pointer-events-none opacity-0": activeSlide === 0 }
          )}
          style={{ left: `calc(-1 * ${arrowSize + padding}px)` }}
          onClick={handlePrev}
        >
          <img
            src={chevronLeft}
            className="h-6 w-6"
            height={24}
            width={24}
            alt="left arrow"
          />
        </button>

        <button
          className={cn(
            REMOVE_FOCUS_CSS,
            "absolute top-1/2 z-30 -translate-y-1/2 rounded-full bg-[#353535] p-1 transition-all duration-300 hover:bg-[#555555] active:scale-75",
            {
              "pointer-events-none opacity-0": activeSlide === totalLength - 1,
            }
          )}
          style={{ right: `calc(-1 * ${arrowSize + padding}px)` }}
          onClick={handleNext}
        >
          <img
            src={chevronRight}
            className="h-6 w-6"
            height={24}
            width={24}
            alt="right arrow"
          />
        </button>

        <div className="absolute -top-4 left-1/2 z-10 flex -translate-x-1/2 justify-center pb-4">
          {Array.from({ length: totalLength }).map((_, index) => {
            return (
              <div
                key={`pulse-mobile-carousel-pagination-${index}`}
                className={cn(
                  "mx-1 h-1 w-1 rounded-full bg-gray-650 transition-all duration-700",
                  { "bg-white": index <= activeSlide },
                  {
                    "w-4 bg-white": index === activeSlide,
                  }
                )}
              />
            );
          })}
        </div>

        {Array.from({ length: totalLength }).map((_, index) => {
          const article = articles[index];

          return (
            <div
              key={index === totalLength - 1 ? "ending-screen" : article.id}
              className="group absolute flex h-full items-center justify-center text-white transition-[left] duration-300"
              style={{
                left:
                  activeSlide === index
                    ? `0`
                    : activeSlide > index
                      ? `calc(-1 * ${smallCardWidth + arrowTotalWidth}px)`
                      : activeSlide + 1 === index
                        ? `calc((${cardWidth + padding}px * ${index - activeSlide}) + ${arrowTotalWidth}px)`
                        : `calc((${smallCardWidth + padding}px * ${index - activeSlide}) + ${arrowTotalWidth}px + ${diffBetweenSmallAndBigCard}px)`,
              }}
              data-is-active={activeSlide === index}
              data-is-prev={activeSlide > index}
              data-is-next={activeSlide < index}
            >
              {index === totalLength - 1 ? (
                <PulseEndingScreen
                  key="ending-screen"
                  cardWidth={cardWidth}
                  smallCardWidth={smallCardWidth}
                  activeSlide={activeSlide}
                  index={index}
                />
              ) : (
                <PulseIndividualArticle
                  article={article}
                  cardWidth={cardWidth}
                  smallCardWidth={smallCardWidth}
                  activeSlide={activeSlide}
                  index={index}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
