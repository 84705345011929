import { useStockData } from "@components/StockPickerWidget/helpers";
import { ArticleStockData } from "@pages/Article/components/ArticleStockSidebar/ArticleStockData";

interface ArticleStockCodeProps {
  code: string;
}

export function ArticleStockItem({
  code,
}: ArticleStockCodeProps): React.ReactElement {
  const response = useStockData(code);

  return (
    <>
      {response?.stockData ? (
        <ArticleStockData stockData={response?.stockData} />
      ) : null}
    </>
  );
}
