/**
 * Company: SPHMedia
 * Description: [Brief description of the component]
 */

import { ReactElement } from "react";
import cx from "classnames";

export type DescriptionProps = {
  text: string;
  rootClassName?: string;
};

/**
 * Description
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function Description({
  text = "",
  rootClassName = "",
}: DescriptionProps): ReactElement {
  return (
    <div>
      <p className={cx("font-pt-sans", rootClassName)}>{text}</p>
    </div>
  );
}
