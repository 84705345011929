import { useEffect, useState } from "react";
import { BreakingNewsCardProps } from "@app/types/BreakingNews";
import CreateTime from "@components/ArticleMeta/CreateTime";
import SectionLink from "@components/ArticleMeta/SectionLink";
import SubscriberFlag from "@components/ArticleMeta/SubscriberFlag";
import TitleLink from "@components/ArticleMeta/TitleLink";
import { useWindowSize } from "@hooks/useWindowSize";
import cx from "classnames";
import dayjs from "dayjs";

export default function BreakingNewsCard({
  testId,
  rootClassName,
  basicCardProps,
  isLast = false,
}: BreakingNewsCardProps): React.ReactElement {
  const {
    id,
    title,
    updated = "",
    edited = "",
    section,
    slug,
    paywall,
    hasUpdatedTimestampDisplay,
  } = basicCardProps;
  const { isScreenLG } = useWindowSize();

  const [isUpdatedWithinADay, setIsUpdatedWithinADay] = useState(false);
  const hasArticleBeenUpdated = updated < edited;
  const latestUpdatedOrEdited = dayjs(updated).isAfter(edited)
    ? updated
    : edited;

  useEffect(() => {
    const isLessThanOneDay = dayjs().diff(edited, "hours") <= 23;
    setIsUpdatedWithinADay(isLessThanOneDay && hasArticleBeenUpdated);
  }, [hasArticleBeenUpdated, edited]);

  return (
    <div
      className={cx(rootClassName, "story relative flex flex-grow flex-col")}
      data-testid={testId}
    >
      <div className="flex lg:space-x-2">
        {latestUpdatedOrEdited ? (
          <CreateTime
            created={latestUpdatedOrEdited}
            dateFormat="hh:mm A"
            rootClassName={cx(
              "text-gray-650 font-medium font-poppins font text-4xs",
              "absolute right-full bg-white py-2 mt-4 mr-4",
              "lg:static lg:p-0 lg:m-0",
              {
                "pb-24 lg:pb-0": isLast,
              }
            )}
          />
        ) : null}

        {section ? (
          <SectionLink
            sectionName={section.name}
            sectionUniqueName={section.uniqueName}
            rootClassName={cx(
              "lg:border-l lg:border-gray-175 lg:pl-2 lg:text-gray-650"
            )}
          />
        ) : null}

        {paywall && isScreenLG ? (
          <div className="flex border-l border-gray-175 pl-2">
            <SubscriberFlag rootClassName="font-light !mb-0" />
          </div>
        ) : null}
      </div>

      <TitleLink
        title={title}
        articleId={id}
        slug={slug}
        rootClassName="text-base"
      />

      {!isScreenLG ? (
        <div className="flex gap-x-4 divide-x">
          {paywall ? <SubscriberFlag rootClassName="font-light !mb-0" /> : null}

          {hasUpdatedTimestampDisplay && isUpdatedWithinADay ? (
            <div
              className={cx(
                "border-gray-175 font-public-sans text-4xs text-gray-650",
                { "pl-4": paywall }
              )}
            >
              Updated
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
