export type NewsletterOtherReadsProps = {
  link: string;
  title: string;
  description: string;
  image: string;
};

export type NewsletterSampleOtherReadsProps = {
  newsletters: NewsletterOtherReadsProps[];
};

export default function NewsletterSampleOtherReads({
  newsletters,
}: NewsletterSampleOtherReadsProps): React.ReactElement {
  const commonTextStyle =
    "text-lg font-poppins font-semibold leading-[1.3] text-gray-850";
  const commonTextNormalStyle =
    "text-base font-public-sans font-normal leading-[1.5] text-gray-750";

  return (
    <>
      <div className="border-t-4 border-solid border-gray-150">
        <div className="pb-6 pl-0">
          <h5 className="mt-0 pt-12 font-times text-11xl font-normal text-gray-750">
            Other Good Reads
          </h5>
        </div>

        <div className="pl-0 pr-2.5">
          <div className="promote-newsletter w-full">
            {newsletters.map((newsletter, index) => (
              <div
                className="flex flex-row border-b border-gray-250 py-6"
                key={index}
              >
                <div className="w-3/4 pb-5 pr-3 pt-0">
                  <a
                    href={newsletter.link}
                    target="_blank"
                    className="text-gray-850 no-underline"
                    rel="noreferrer"
                  >
                    <h5 className={`m-0 ${commonTextStyle}`}>
                      {newsletter.title}
                    </h5>
                  </a>

                  <p className={`m-0 ${commonTextNormalStyle}`}>
                    {newsletter.description}
                  </p>
                </div>

                <div>
                  <img src={newsletter.image} height={60} width={60} />
                </div>
              </div>
            ))}

            <div className="border-t border-gray-250 pb-0 pl-0 pt-0"></div>
          </div>
        </div>

        <div className="pb-16 pl-0 pr-2.5 pt-2.5 text-right">
          <a
            href="https://www.businesstimes.com.sg/newsletter/sign-up"
            target="_blank"
            className="pr-1 text-base font-bold uppercase leading-[1.5] text-gray-850 no-underline"
            rel="noreferrer"
          >
            NEWSLETTERS
            <img
              src="https://static1.businesstimes.com.sg/s3fs-public/right-arrow.png"
              alt="Newsletter"
              className="mb-[2px] ml-1 inline w-[10px]"
            />
          </a>
        </div>
      </div>
    </>
  );
}
