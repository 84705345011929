import { RouteFactory } from "@app/routePaths";
import profileAileng from "@assets/newsletter/aileng.png";
import profilebyline20image from "@assets/newsletter/byline20image.png";
import profileJanicelim from "@assets/newsletter/janicelim.png";
import profileKennethLim from "@assets/newsletter/kenneth_lim.png";
import profilelee20u from "@assets/newsletter/lee20u-wen.png";
import profileLeslie20yee from "@assets/newsletter/leslie20yee.png";
import profileMichelle from "@assets/newsletter/michelle.png";
import aseanThumbnail from "@assets/newsletter_thumbnails/newsletter_asean.png";
import morningThumbnail from "@assets/newsletter_thumbnails/newsletter_breakfast_brief.png";
import esgThumbnail from "@assets/newsletter_thumbnails/newsletter_esg.png";
import PropertyThumbnail from "@assets/newsletter_thumbnails/newsletter_property.png";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";

export const NEWSLETTER_PAGE_LINK = RouteFactory.newsletterSignup;
export const PREMIUM_NEWSLETTERS = BT_NEWSLETTERS.filter((newsletter) =>
  [NEWSLETTER_TYPE.BigMoney].includes(newsletter.type)
);
import profileJoyceHooi from "@assets/newsletter/Joyce-Hooi.jpeg";

export const DAILY_NEWSLETTERS = BT_NEWSLETTERS.filter((newsletter) =>
  [NEWSLETTER_TYPE.MORNING, NEWSLETTER_TYPE.EVENING].includes(newsletter.type)
);

export const WEEKLY_NEWSLETTERS = BT_NEWSLETTERS.filter((newsletter) =>
  [
    NEWSLETTER_TYPE.ASEAN,
    NEWSLETTER_TYPE.ESG,
    NEWSLETTER_TYPE.LIFESTYLE,
    NEWSLETTER_TYPE.PROPERTY,
    NEWSLETTER_TYPE.SGSME,
    NEWSLETTER_TYPE.THRIVE,
  ].includes(newsletter.type)
);

export const newsletterLinks = [
  { linkText: "Premium Newsletters" },
  { linkText: "Daily Newsletters" },
  { linkText: "Weekly Newsletters" },
];
export const BIGMONEY_PAGE_LINK = RouteFactory.newsletterBigMoneyArchivePage;
export const BIGMONEY_SUBSCRIBE_CTA =
  "https://subscribe.sph.com.sg/promotions/bt-personal/?utm_campaign=bigmoneynewsletter&utm_medium=sph_publication&utm_source=bt&utm_content=newsletterarchivesubscribelink";

export const BIGMONEY_TITLE = "Big Money";
export const BIGMONEY_DESC =
  "A weekly breakdown of the latest developments affecting financial markets.";
export const BIGMONEY_CONTRIBUTORS = [
  {
    imageSrc: profileJoyceHooi,
    quote:
      "“The financial markets can be perilous – you risk being chased by a bear or gored by a bull. <i>Big Money</i> will help you stay in the black rather than end up black and blue.”",
    name: "Joyce Hooi, Columnist",
  },
];

export const THRIVE_PAGE_LINK = RouteFactory.newsletterThriveArchivePage;
export const THRIVE_TITLE = "Thrive";
export const THRIVE_DESC =
  "Money, career and life hacks to help young adults stay ahead of the curve.";
export const THRIVE_CONTRIBUTORS = [
  {
    imageSrc:
      "https://static1.businesstimes.com.sg/s3fs-public/styles/profile_photo/public/image/2023/03/01/vivien20shiao20headshot.png",
    quote:
      "“Adulting is tough, but young adults don't have to do it alone. We examine issues close to their hearts on career, money and life.”",
    name: "Vivien Shiao, Young Audience Editor",
  },
  {
    imageSrc:
      "https://static1.businesstimes.com.sg/s3fs-public/styles/profile_photo/public/author/2024/08/30/daryl20choo20headshot.jpg?VersionId=KaFYTeSZxkRU1EsmaOOGb1iVSryZMfFc",
    quote:
      "“Don't be too hard on yourself for feeling overwhelmed by work pressures. Even in adulthood, everyone’s still learning.”",
    name: "Daryl Choo, Young Audience Journalist",
  },
  {
    imageSrc:
      "https://static1.businesstimes.com.sg/s3fs-public/styles/profile_photo/public/author/2024/08/22/image20230208120700477_1.jpeg?VersionId=t4F4aLdhKKiS4rxOb4X8JxvZjwRegunh",
    quote:
      "“With Thrive, maybe managing personal finances or figuring out a career path doesn't have to feel like such a chore anymore.”",
    name: "Chloe Lim, Young Audience Journalist",
  },
];

export const GARAGE_CONTRIBUTORS = [
  {
    imageSrc:
      "https://static1.businesstimes.com.sg/s3fs-public/styles/profile_photo/public/author/2024/03/11/benjamin20cher.png",
    quote:
      "“Stories that connect the dots between tech trends and their impact are the best kind - and what Garage does best.”",
    name: "Benjamin Cher, Garage Correspondent",
  },
  {
    imageSrc:
      "https://static1.businesstimes.com.sg/s3fs-public/styles/profile_photo/public/author/2023/03/23/claudia20chong.png",
    quote:
      "“The tech world is a place where a lot of things don't make sense at first, but there's still value in knowing how it works.”",
    name: "Claudia Chong, Garage Correspondent",
  },
  {
    imageSrc:
      "https://static1.businesstimes.com.sg/s3fs-public/styles/profile_photo/public/author/2023/08/02/daphne.jpg",
    quote:
      "“No two days are the same in the startup and tech space - not with the many budding shoots aspiring to change the world, one idea at a time.”",
    name: "Daphne Yow, Garage Journalist",
  },
];

export const BIGMONEY_POSTS = [
  {
    title:
      "Issue 101: Singapore keeps cheap green borrowing streak alive; Indonesia’s mixed signals on coal",
    link: "/esg/issue-101-singapore-keeps-cheap-green-borrowing-streak-alive-indonesias-mixed-signals-coal",
    date: "May 24, 2024",
  },
  {
    title:
      "Issue 100: Lost MSCI, still got ESG; a mixed bag for Asia’s blended finance in 2023",
    link: "/esg/issue-100-lost-msci-still-got-esg-mixed-bag-asias-blended-finance-2023",
    date: "May 17, 2024",
  },
];

export const BIGMONEY_BIG_READS = [
  {
    title: "China’s economic ills are serious but not incurable",
    articleUrl:
      "https://www.businesstimes.com.sg/opinion-features/chinas-economic-ills-are-serious-not-incurable?utm_source=emarsys&utm_medium=email&utm_campaign=bt_big_money&utm_term=China%E2%80%99s+economic+ills+are+serious+but+not+incurable&utm_content=21%2F10%2F2024",
    description:
      "Unfortunately, policymakers have made things worse by resorting to temporary palliatives.",
  },
  {
    title: "Why the American stock market reigns supreme",
    articleUrl:
      "https://www.businesstimes.com.sg/opinion-features/why-american-stock-market-reigns-supreme?utm_source=emarsys&utm_medium=email&utm_campaign=bt_big_money&utm_term=Why+the+American+stock+market+reigns+supreme&utm_content=21%2F10%2F2024",
    description:
      "Lower returns are coming, but they should continue to be world-beating.",
  },
  {
    title:
      "S-Reits earnings, DPU likely to decline in Q3 despite interest rate cut",
    articleUrl:
      "https://www.businesstimes.com.sg/companies-markets/s-reits-earnings-dpu-likely-decline-q3-despite-interest-rate-cut?utm_source=emarsys&utm_medium=email&utm_campaign=bt_big_money&utm_term=S-Reits+earnings%2C+DPU+likely+to+decline+in+Q3+despite+interest+rate+cut&utm_content=21%2F10%2F2024",
    description:
      "Analysts say that the sector’s DPUs are likely to “stay flat” or even contract, given that most S-Reits have their debts pegged to fixed interest rates.",
  },
  {
    title:
      "Sabana Reit better off if major unitholders not represented on board of new internal manager",
    articleUrl:
      "https://www.businesstimes.com.sg/opinion-features/sabana-reit-better-if-major-unitholders-not-represented-board-new-internal-manager?utm_source=emarsys&utm_medium=email&utm_campaign=bt_big_money&utm_term=Sabana+Reit+better+off+if+major+unitholders+not+represented+on+board+of+new+internal+manager&utm_content=21%2F10%2F2024",
    description:
      "Would-be directors should commit to purchasing S$1 million worth of units in the market to align interest with unitholders.",
  },
  {
    title: "VW, BMW and Mercedes are getting left in the dust by China’s EVs",
    articleUrl:
      "https://www.businesstimes.com.sg/international/global/vw-bmw-and-mercedes-are-getting-left-dust-chinas-evs?utm_source=emarsys&utm_medium=email&utm_campaign=bt_big_money&utm_term=VW%2C+BMW+and+Mercedes+are+getting+left+in+the+dust+by+China%E2%80%99s+EVs&utm_content=21%2F10%2F2024",
    description:
      "After falling behind on tech trends, German carmakers are scrambling to recover in their biggest and most lucrative market.",
  },
];

export const NEWSLETTER_FOOTER_SOCIAL_LINKS = [
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/fb.png",
    url: "https://www.facebook.com/thebusinesstimes/",
  },
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/nl-twitter.png",
    url: "https://twitter.com/businesstimes/",
  },
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/linkedin.png",
    url: "https://www.linkedin.com/showcase/the-business-times/",
  },
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/tgram.png",
    url: "https://t.me/BizTimes",
  },
  {
    icon: "https://static1.businesstimes.com.sg/s3fs-public/insta.png",
    url: "https://www.instagram.com/businesstimessg/",
  },
];

export const BIGMONEY_OTHER_READS = [
  {
    title: "Property Insights",
    description:
      "Get an exclusive analysis of real estate and property news in Singapore and beyond.",
    link: "https://www.businesstimes.com.sg/newsletter/property",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_property.png",
  },
  {
    title: "ESG Insights",
    description:
      "An exclusive weekly report on the latest environmental, social and governance issues.",
    link: "https://www.businesstimes.com.sg/newsletter/esg",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_esg.png",
  },
  {
    title: "Breakfast Brief",
    description:
      "All the latest news you need to know to start your day, right in your inbox.",
    link: "https://www.businesstimes.com.sg/newsletter/sign-up",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_breakfast_brief.png",
  },
];

export const NEWSLETTER_UNSUBSCRIBE_PAGE_LINK =
  RouteFactory.newsletterUnsubscribe;

export const NEWSLETTER_UNSUBSCRIBE_SUCCESS_PAGE_LINK =
  RouteFactory.newsletterUnsubscribeSuccess;

export const GARAGE_OTHER_READS = [
  {
    title: "Asean Business",
    description:
      "Business insights centering on South-east Asia's fast-growing economies.",
    link: "https://www.businesstimes.com.sg/newsletter/asean",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_asean.png",
  },
  {
    title: "Property Insights",
    description:
      "Get an exclusive analysis of real estate and property news in Singapore and beyond.",
    link: "https://www.businesstimes.com.sg/newsletter/property",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_property.png",
  },
  {
    title: "ESG Insights",
    description:
      "An exclusive weekly report on the latest environmental, social and governance issues.",
    link: "https://www.businesstimes.com.sg/newsletter/esg",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_esg.png",
  },
];

export const PROPERTY_INSIGHT_CONTRIBUTORS = [
  {
    imageSrc: profileMichelle,
    quote:
      "We cut through the noise, put the news in context and sift out what matters in Singapore’s property market.",
    name: "Michelle Low, Deputy News Editor",
  },
  {
    imageSrc: profileLeslie20yee,
    quote:
      "“Property is a key economic pillar. Many of us are vested in the property market. Build knowledge on changing real estate trends.”",
    name: "Leslie Yee, Senior Correspondent",
  },
];

export const PROPERTY_INSIGHT_OTHER_READS = [
  {
    title: "ESG Insights",
    description:
      "An exclusive weekly report on the latest environmental, social and governance issues.",
    link: "https://www.businesstimes.com.sg/newsletter/esg",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_esg.png",
  },
  {
    title: "Breakfast Brief",
    description:
      "All the latest news you need to know to start your day, right in your inbox.",
    link: "https://www.businesstimes.com.sg/newsletter/sign-up",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_breakfast_brief.png",
  },
  {
    title: "Asean Business",
    description:
      "Business insights centering on South-east Asia's fast-growing economies.",
    link: "https://www.businesstimes.com.sg/newsletter/asean",
    image:
      "https://static1.businesstimes.com.sg/s3fs-public/image/2023/03/09/newsletter_asean.png",
  },
];

export const ASEAN_BUSINESS_OTHER_READS = [
  {
    title: "Breakfast Brief",
    description:
      "All the latest news you need to know to start your day, right in your inbox.",
    link: RouteFactory.newsletterSignup,
    image: morningThumbnail,
  },
  {
    title: "Property Insights",
    description:
      "Get an exclusive analysis of real estate and property news in Singapore and beyond.",
    link: RouteFactory.newsletterPropertyInsightArchivePage,
    image: PropertyThumbnail,
  },
  {
    title: "ESG Insights",
    description:
      "An exclusive weekly report on the latest environmental, social and governance issues.",
    link: "https://www.businesstimes.com.sg/newsletter/esg",
    image: esgThumbnail,
  },
];

export const ASEAN_BUSINESS_CONTRIBUTORS = [
  {
    imageSrc: profilelee20u,
    quote:
      "“Put simply, the eyes and ears of the world are focused on South-east Asia. There are business and investment opportunities aplenty in the region, and BT aims to bring you the news that matters.”",
    name: "Lee U-Wen, News Editor",
  },
  {
    imageSrc: profileAileng,
    quote:
      "“In light of glocalisation, understanding the market from a local perspective is crucial for improving competitiveness and achieving sustainable growth.”",
    name: "Tan Ai-Leng, Malaysia Correspondent",
  },
  {
    imageSrc: profilebyline20image,
    quote:
      "“Think of us as your go-to weekly read that helps you keep abreast of the ever-evolving landscape of South-east Asia.”",
    name: "Goh Ruoxue, Journalist",
  },
];

export const ESG_INSIGHTS_CONTRIBUTORS = [
  {
    imageSrc: profileKennethLim,
    quote:
      "“We need to look at ESG through the lenses of businesses and investors in Singapore and South-east Asia.”",
    name: "Kenneth Lim, Columnist",
  },
  {
    imageSrc: profileJanicelim,
    quote:
      "“While very much a work-in-progress, ESG might just be the best lever thus far to check on the excesses of unbridled capitalism.”",
    name: "Janice Lim, Correspondent",
  },
];

export const ESG_INSIGHTS_OTHER_READS = [
  {
    title: "Property Insights",
    description:
      "Get an exclusive analysis of real estate and property news in Singapore and beyond.",
    link: RouteFactory.newsletterPropertyInsightArchivePage,
    image: PropertyThumbnail,
  },
  {
    title: "Breakfast Brief",
    description:
      "All the latest news you need to know to start your day, right in your inbox.",
    link: RouteFactory.newsletterSignup,
    image: morningThumbnail,
  },
  {
    title: "Asean Business",
    description:
      "Business insights centering on South-east Asia's fast-growing economies.",
    link: RouteFactory.newsletterAseanBusinessArchivePage,
    image: aseanThumbnail,
  },
];
