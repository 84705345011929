import thriveNewsletterHeader from "@assets/thrive_images/thrive_newsletter_logo.png";
import Container from "@components/Container/Container";

import ThriveSampleNewsletterBottomFooter from "./ThriveSampleNewsletterBottomFooter";
import ThriveSampleNewsletterIntroduction from "./ThriveSampleNewsletterIntroduction";
import ThriveSampleNewsletterOthers from "./ThriveSampleNewsletterOthers";
import ThriveSampleNewsletterPoll from "./ThriveSampleNewsletterPoll";
import ThriveSampleNewsletterPollResult from "./ThriveSampleNewsletterPollResult";
import ThriveSampleNewsletterStory from "./ThriveSampleNewsletterStory";
import ThriveSampleNewsletterTopFooter from "./ThriveSampleNewsletterTopFooter";

export default function ThriveSampleNewsletter(): React.ReactElement {
  return (
    <Container rootClassName="px-0 lg:min-h-[40rem] text-gray-850 ">
      <div>
        <p className="m-0 p-0 py-2 text-center text-2xs text-gray-550">
          {"Fri, Jul 12, 2024"}
        </p>

        <img
          src={thriveNewsletterHeader}
          className="py-3"
          alt="thrive newsletter"
        />
      </div>

      <div className="border-t-2 border-green-400 bg-white px-6 pt-4">
        <ThriveSampleNewsletterIntroduction />

        <div className="mt-10 border-t-2 border-green-100 pt-4">
          <ThriveSampleNewsletterStory />
        </div>

        <div className="mt-10 border-t-2 border-green-400">
          <ThriveSampleNewsletterOthers />
        </div>

        <div className="pb-20">
          <ThriveSampleNewsletterPoll />
        </div>

        <div className="pb-16">
          <ThriveSampleNewsletterPollResult />
        </div>
      </div>

      <div className="border-gray-[#e8e8e8] border-t-4 border-double bg-white pt-10">
        <ThriveSampleNewsletterTopFooter />

        <ThriveSampleNewsletterBottomFooter />
      </div>
    </Container>
  );
}
