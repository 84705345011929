import { Link, useLocation } from "react-router-dom";
import {
  DEFAULT_UTM_CAMPAIGN_PR,
  DEFAULT_UTM_MEDIUM_PR,
  DEFAULT_UTM_SOURCE_PR,
  PR_PAGE_FORM_URL,
} from "@pages/Content/constants";
import { ENVIRONMENT } from "@util/constant";
import { addParamsToURL, getEnvironmentDomain } from "@util/helpers";

export default function PrPageIntroduction(): React.ReactElement {
  const { pathname, search, hash } = useLocation();
  const url = new URL(
    `${getEnvironmentDomain(ENVIRONMENT)}${pathname}${search}${hash}`
  );
  const urlParams = new URLSearchParams(url.search);

  // Define the default UTM parameters
  const defaultUtmParams: {
    [key: string]: string;
  } = {
    utm_source: DEFAULT_UTM_SOURCE_PR,
    utm_medium: DEFAULT_UTM_MEDIUM_PR,
    utm_campaign: DEFAULT_UTM_CAMPAIGN_PR,
    utm_term: "",
    utm_content: "",
  };
  const hasParams = !urlParams.entries().next().done;
  const utmParams = Object.keys(defaultUtmParams).map((label) => ({
    label,
    value: hasParams
      ? urlParams.get(label) ?? ""
      : urlParams.get(label) ?? defaultUtmParams[label],
  }));
  const prPageCtaWithUtm = addParamsToURL(
    PR_PAGE_FORM_URL,
    utmParams
  ).toString();

  return (
    <div className="mb-8" data-testid="pr-page-introduction-component">
      <p>
        {
          "This page provides a listing of announcements from companies across Asia. All published press releases are reproduced as they are received, with no edits."
        }
      </p>
      <p>
        {"To have your press release published in The Business Times, fill in "}

        <Link
          to={prPageCtaWithUtm}
          target="_blank"
          className="text-green-200 underline hover:text-black hover:underline"
          rel="noopener noreferrer"
        >
          this form
        </Link>

        {" and our team will get back to you within 2 business days."}
      </p>
    </div>
  );
}
