export default function Spinner(): React.ReactElement {
  return (
    <div
      data-testid="Spinner"
      className="flex flex-grow items-center justify-center"
    >
      <div className="from-slate-800 flex h-12 w-12 animate-spin items-center justify-center rounded-full bg-gradient-to-tr from-gray-850 to-gray-400">
        <div className="h-8 w-8 rounded-full bg-white"></div>
      </div>
    </div>
  );
}
