import { Author, ContributorProfile, Section, Tag } from "@app/types/Cue";
import { TagType } from "@pages/Section/types";
import {
  OKTARegistrationStatus,
  OKTAUserTypeEnum,
} from "@store/useOKTAUserStore";
import { textToSlug } from "@util/helpers";

export function getArticleAuthorNames(
  authors: Author[] = [],
  contributors: ContributorProfile[] = []
) {
  const authorList = [] as string[];

  authors.map((author: Author) => {
    // Check if profile is not empty before adding the author
    if (author.profiles && author.profiles.length > 0) {
      authorList.push(author.name.trim());
    }
  });

  contributors.map((contributor: ContributorProfile) => {
    authorList.push(contributor.content.fields.name.trim());
  });

  return authorList.join(", ");
}

export function getArticleChapter1(section: Section) {
  let chapter1 = "";

  if (section.parent.uniqueName !== "ece_frontpage") {
    chapter1 = section.name;
  }

  return textToSlug(chapter1).replaceAll("-", " ");
}

export function getArticleLevel2(section: Section) {
  let level2 = section.name;

  if (section.parent.uniqueName !== "ece_frontpage") {
    level2 = section.parent.name;
  }

  return textToSlug(level2).replaceAll("-", " ");
}

export function getArticleKeywords(keywords: Tag[]) {
  const keywordsList = [] as string[];

  keywords.map((keyword: Tag) => {
    keywordsList.push(keyword.name.toLowerCase());
  });

  return keywordsList.join(",");
}

/**
 * Helper function to get the story thread from the tags.
 * @param tags The tags of the article.
 * @returns {Tag|undefined}
 */
export const getArticleStoryThread = (tags: Tag[]): Tag | undefined => {
  return tags.find(({ type }) => type === TagType.StoryThread);
};

/**
 * Helper function to get the visitor category value.
 * @param userType UserType of the user
 * @param registrationStatus Regustration status of the user
 * @param serviceType Service type of the user
 * @returns {number}
 */
export const getVisitorCat = (
  userType?: OKTAUserTypeEnum,
  registrationStatus?: OKTARegistrationStatus,
  serviceType?: string
): number => {
  if (userType === OKTAUserTypeEnum.SUBSCRIBER) return 2; // Premium user

  // Email Verified Registered User
  if (userType === OKTAUserTypeEnum.REGISTERED) {
    // Subscrber of BT
    if (serviceType === "reg_bt") {
      // Email Verified Registered User
      if (registrationStatus === OKTARegistrationStatus.VERIFIED) return 12;

      // Email UnVerified Registered User
      return 13;
    }

    // Registered user (Subscriber of other pubs)
    return 3;
  }

  // Anonymous user
  return 1;
};

/**
 * Helper function to get the permutive visitor category value.
 * @param visitorcat The visitor category value
 * @returns {string} The permutive visitor category value
 */
export const getPermutiveVisitorCat = (visitorcat: number): string => {
  switch (visitorcat) {
    case 2:
      return "subscriber";

    case 3:
      return "registered";

    case 1:
    default:
      return "anonymous";
  }
};

/**
 * Helper function to get the permutive content type value.
 * @param contentType The content type value
 * @returns {string} The permutive content type value
 */
export const getPermutiveContentType = (
  contentType: "" | "1" | "2" | "3"
): string => {
  switch (contentType) {
    case "2":
      return "photo";

    case "3":
      return "video";

    case "1":
    default:
      return "article";
  }
};

/**
 * Helper function to get the permutive content category value.
 * @param contentcat The content category value
 * @returns {string} The permutive content category value
 */
export const getPermutiveContentCat = (contentcat: 1 | 2): string => {
  switch (contentcat) {
    case 2:
      return "premium";

    case 1:
    default:
      return "free";
  }
};
