/* eslint-disable @typescript-eslint/no-explicit-any */
import { CDP_ACTIVATION_URL } from "@util/constant";

import useScript from "./useScript";

export default function useCasScript() {
  const { isScriptReady } = useScript({
    id: "cas-script",
    src: CDP_ACTIVATION_URL,
    checkScriptIsLoaded: () => {
      return (
        typeof (window as any).SPH_CDP === "function" &&
        typeof (window as any)._data !== "undefined"
      );
    },
  });

  return { isScriptReady };
}
