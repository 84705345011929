import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import { NEWSLETTER_UNSUBSCRIBE_SUCCESS_PAGE_LINK } from "@components/Newsletter/constants";

export default function NewsletterUnsubscribeSuccess(): React.ReactElement {
  return (
    <>
      <MetaTags
        title="THE BUSINESS TIMES - Get the Latest Business & Financial News"
        description="Read more at The Business Times. UnSubscriber Here."
        slug={NEWSLETTER_UNSUBSCRIBE_SUCCESS_PAGE_LINK}
      />

      <Header />

      <Container
        rootClassName="py-3 font-poppins"
        data-testid="newsletter-unsubscribe-success-component"
      >
        <h1 className="mb-2 text-11xl">Your BT newsletter subscriptions</h1>
        <p className="text-gray-900">
          Your BT newsletter subscriptions have been successfully updated.
        </p>
      </Container>
    </>
  );
}
