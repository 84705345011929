import { useEffect, useRef, useState } from "react";
import { SanitizedRelatedStory } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useWindowSize } from "@hooks/useWindowSize";
import { gaEventTracker } from "@util/helpers";

export type RelatedArticlesProps = {
  readMoreArticle?: SanitizedRelatedStory[];
};

export default function ArticleReadMore({
  readMoreArticle,
}: RelatedArticlesProps): React.ReactElement {
  const [childrenHeight, setChildrenHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const childrenRef = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (childrenRef.current) {
      setChildrenHeight((childrenRef.current as HTMLDivElement).scrollHeight);
    }
  }, [windowSize]);

  const variant: CardVariations = {
    image: {
      position: "left",
      width: "w-[70px] lg:w-[90px]",
      filters: [
        { breakpoint: "(min-width: 992px)", w: 180, h: 120, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 140, h: 100, dpr: 1, f: "webp" },
        { w: 300, h: 200, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: "w-full-120px lg:w-2/3",
      extraClass: "text-white",
      updatedTimeColor: "!text-white",
    },
    title: {
      color: "!text-gray-850",
      size: "!text-base",
      extraClass: "my-0 leading-[1.3rem] tracking-normal !font-lct",
    },
  };

  const toggleMoreLess = () => {
    gaEventTracker("see also", "click", !isExpanded ? "more" : "less");
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {readMoreArticle && readMoreArticle.length > 0 ? (
        <div
          className="mb-6 mt-8 border border-gray-175 p-6 md:-mx-8 md:mt-0 md:p-8"
          data-testid="article-read-more"
        >
          <div>
            <div className="mb-6 border-b border-gray-850 pb-2 !font-poppins text-base font-semibold leading-normal tracking-5% text-gray-850 md:text-lg">
              SEE ALSO
            </div>

            <div data-testid="section-article-read-more">
              {readMoreArticle
                .slice(0, 3)
                .map((relatedArticle: SanitizedRelatedStory) => {
                  const uniqueName =
                    relatedArticle?.sections?.[0]?.uniqueName || "";

                  return relatedArticle.id && relatedArticle.title ? (
                    <div
                      key={relatedArticle.id}
                      className="mb-6"
                      data-testid="article-read-more-items"
                    >
                      <BasicCard
                        id={relatedArticle.id}
                        title={relatedArticle.title}
                        media={relatedArticle.media}
                        slug={relatedArticle.urlPath}
                        variations={variant}
                        defaultImage={{
                          directoryName: uniqueName,
                        }}
                        rootClassName={"gap-4"}
                      />
                    </div>
                  ) : null;
                })}
            </div>

            {readMoreArticle.length > 3 ? (
              <>
                <div
                  ref={childrenRef}
                  data-testid="section-related-stories"
                  className="overflow-hidden transition-all duration-300"
                  style={{
                    height:
                      isExpanded && readMoreArticle.length > 3
                        ? childrenHeight
                        : 0,
                  }}
                >
                  {readMoreArticle
                    .slice(3)
                    .map((relatedArticle: SanitizedRelatedStory) => {
                      const uniqueName =
                        relatedArticle?.sections?.[0]?.uniqueName || "";

                      return relatedArticle.id && relatedArticle.title ? (
                        <div
                          key={relatedArticle.id}
                          className="mb-6"
                          data-testid="article-read-more-items"
                        >
                          <BasicCard
                            id={relatedArticle.id}
                            title={relatedArticle.title}
                            media={relatedArticle.media}
                            slug={relatedArticle.urlPath}
                            variations={variant}
                            defaultImage={{
                              directoryName: uniqueName,
                            }}
                            rootClassName={"gap-4"}
                          />
                        </div>
                      ) : null;
                    })}
                </div>
                <div className="w-full">
                  {isExpanded ? (
                    <div
                      className="cursor-pointer place-self-center border-t border-gray-175 pt-3 text-right !font-poppins text-base font-semibold capitalize tracking-normal !text-gray-850 [@media(hover:hover)]:hover:underline"
                      onClick={toggleMoreLess}
                    >
                      Less
                    </div>
                  ) : null}

                  {!isExpanded ? (
                    <div
                      className="cursor-pointer place-self-center border-t border-gray-175 pt-3 text-right !font-poppins text-base font-semibold capitalize tracking-normal !text-gray-850 [@media(hover:hover)]:hover:underline"
                      onClick={toggleMoreLess}
                    >
                      More
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
}
