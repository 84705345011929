/**
 * Company: SPHMedia
 * Description: Vertical link of the article (e.g. Sponsored by)
 */

import { ReactElement } from "react";
import cx from "classnames";

import styles from "./ArticleMeta.module.scss";

export type KickerProps = {
  rootClassName?: string;
  name?: string;
  isLinkRequired?: boolean;
};

export default function Kicker({
  rootClassName = "",
  name = "",
}: KickerProps): ReactElement {
  return (
    <div
      className={cx(styles.sponsored, "uppercase", rootClassName)}
      data-testid="kicker-component"
    >
      {name}
    </div>
  );
}
