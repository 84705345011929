import type { Tag } from "@app/types/Cue";
import KeywordFilter from "@components/KeywordFilter";
import { MYBT_EXCLUDE_KEYWORDS } from "@util/constant";
import cx from "classnames";

export enum KeywordDisplayOption {
  First = "first",
  Rest = "rest",
}

export type ArticleKeywordsProps = {
  keywords: Tag[];
  keywordDisplayOption?: KeywordDisplayOption;
  rootClassName?: string;
  tagClassName?: string;
  firstKeyworkDisplayClassname?: string;
  followtextButton?: boolean;
  toolTipClassName?: string;
  toolTipArrowClassName?: string;
  disableTooltip?: boolean;
  withGiftIcon?: boolean;
  disableBorder?: boolean;
};

export default function ArticleKeywords({
  keywords,
  keywordDisplayOption,
  firstKeyworkDisplayClassname,
  rootClassName,
  tagClassName,
  followtextButton = false,
  toolTipClassName,
  toolTipArrowClassName,
  disableTooltip,
  withGiftIcon,
  disableBorder,
}: ArticleKeywordsProps): React.ReactElement {
  const tags = keywords.filter((tag) => {
    return !MYBT_EXCLUDE_KEYWORDS.some((t) => t === tag.name);
  });

  switch (keywordDisplayOption) {
    case KeywordDisplayOption.First:
      return (
        <div
          data-testid="article-keyword-filter-first"
          className={firstKeyworkDisplayClassname}
        >
          {tags && tags[0] ? (
            <KeywordFilter
              testId={"article-top-keyword"}
              tag={tags[0]}
              rootClassName={cx("bg-gray-250 p-3 inline-block", rootClassName)}
              tagClassName={cx("!text-gray-850", tagClassName)}
              followtextButton={followtextButton}
              toolTipClassName={toolTipClassName}
              toolTipArrowClassName={toolTipArrowClassName}
              disableTooltip={disableTooltip}
              withGiftIcon={withGiftIcon}
              disableBorder={disableBorder}
            />
          ) : null}
        </div>
      );
    case KeywordDisplayOption.Rest:
      return (
        <div data-testid="article-keyword-filter-rest">
          {tags && tags.length > 1 && (
            <div className="border-gray-75 mb-6 mt-3 border-t pt-3">
              <h2 className="mb-3 font-poppins text-lg font-semibold">
                KEYWORDS IN THIS ARTICLE
              </h2>

              <div className="flex flex-wrap gap-3">
                {/* Skip the first keyword as it has already been displayed below the author details */}
                {tags.slice(1).map((keyword) => (
                  <KeywordFilter
                    key={keyword.uri}
                    tag={keyword}
                    rootClassName="bg-gray-250 p-3 inline-block"
                    tagClassName="!text-gray-850"
                    disableTooltip
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      );
    default:
      return <></>;
  }
}
