import { useEffect } from "react";
import { useNeuron } from "@sphtech/neuron";

export default function NeuronImplementation() {
  // Refer to https://mirror.neuron-doc.platform.sphdigital.com.sg/tutorial.html
  const neuron = useNeuron();

  useEffect(() => {
    if (neuron !== null) {
      const { track } = neuron;
      track("load");
      track("scrollDepth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
