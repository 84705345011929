import profileJoyceHooi from "@assets/newsletter/Joyce-Hooi.jpeg";

export default function NewsletterSampleAuthor(): React.ReactElement {
  return (
    <div className="flex w-full flex-row pb-6 pt-7">
      <div className="mr-4">
        <img
          src={profileJoyceHooi}
          className="rounded-full bg-[#D6F0FF]"
          width={80}
          height={80}
        ></img>
      </div>
      <div>
        <span className="text-base font-bold leading-snug text-gray-700">
          Joyce Hooi
        </span>

        <span className="block text-base font-normal leading-snug text-gray-700">
          Columnist
        </span>

        <a
          href="mailto:joyceh@sph.com.sg"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center pt-1 text-base font-normal leading-snug text-gray-700 no-underline"
        >
          <img
            className="mr-1 mt-0.5 inline align-middle"
            alt="author e-mail"
            src="https://static1.businesstimes.com.sg/s3fs-public/nl-email-byline.png"
            width="16"
            height="16"
          ></img>

          <span className="user_name align-middle text-base text-gray-700">
            JoyceHooi
          </span>
        </a>
      </div>
    </div>
  );
}
