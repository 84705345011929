import BigMoneyLogo from "@assets/newsletter/big_money_logo.png";

export default function NewsletterSampleHeader(): React.ReactElement {
  return (
    <>
      <div data-testid="businesstimes-logo" className="flex justify-center">
        <a
          href="https://www.businesstimes.com.sg"
          target="_blank"
          className="block text-center"
          rel="noreferrer"
        >
          <img
            width="335"
            height="83"
            className="pt-6"
            src={BigMoneyLogo}
          ></img>
        </a>
      </div>

      <div
        data-testid="businesstimes-nl-time"
        className="flex justify-center py-6"
      >
        <span className="text-center font-times text-xl font-normal leading-relaxed text-gray-700">
          Monday, October 21, 2024
        </span>
      </div>
    </>
  );
}
