import { SanitizedSectionArticle } from "@app/types/Cue";
import LoadMore from "@components/LoadMore/LoadMore";
import { useLoadMoreArticlesByStoryThread } from "@hooks/useLoadMoreArticlesByStoryThread";
import SectionListingStories from "@pages/Section/components/Overview/SectionListingStories";

type StoryThreadStoriesProps = {
  storyThreadUrlPath: string;
  articles: SanitizedSectionArticle[];
};

export default function StoryThreadStories({
  storyThreadUrlPath,
  articles,
}: StoryThreadStoriesProps): React.ReactElement {
  const { articleList, handleLoadMore, hasMoreArticles } =
    useLoadMoreArticlesByStoryThread(storyThreadUrlPath, articles, 10);

  return (
    <section data-testid="story-thread-stories-component">
      <div className="stories">
        {articleList.map((article, index) => {
          return (
            <SectionListingStories
              key={article.id}
              index={index}
              article={article}
            />
          );
        })}
      </div>

      {hasMoreArticles ? (
        <LoadMore
          rootClassName="my-4"
          onLoadMore={handleLoadMore}
          hasMore={hasMoreArticles}
        />
      ) : null}
    </section>
  );
}
