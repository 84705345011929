import { useState } from "react";
import { Link, To, useLocation } from "react-router-dom";
import type { NavigationItem } from "@app/types/Navigation";
import iconBreadcrumb from "@assets/icon-breadcrumb.svg";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useGetHeaderBreadcrumbsContainerWidth } from "@hooks/useGetHeaderBreadcrumbsContainerWidth";
import { cn } from "@util/helpers";

import IndividualCategoryLink from "./IndividualCategoryLink";
import { getActiveSectionPath } from "../Breadcrumb/helpers";

export type BreadcrumbProps = {
  parentCategory: string;
  childCategory?: string;
  rootClassName?: string;
  categoryDataMapping: Record<string, NavigationItem>;
  enableTopStoriesLink?: boolean;
};

const excludeCategories = ["story-threads", "tags"];

export default function HeaderBreadcrumb({
  parentCategory,
  childCategory,
  categoryDataMapping,
  enableTopStoriesLink = true,
}: BreadcrumbProps): React.ReactElement {
  const { pathname } = useLocation();
  const active_section_path = getActiveSectionPath(pathname, childCategory);
  const parentCategoryData = categoryDataMapping?.[parentCategory];
  const [contentWidth, setContentWidth] = useState(0);
  const { containerRef, containerWidth } =
    useGetHeaderBreadcrumbsContainerWidth();
  const shouldRenderDropdown = contentWidth > containerWidth;
  const isLinkActive = (link: To) => {
    return active_section_path === link;
  };

  const navItemStyle = cn(
    "p-3 duration-300 flex-none p-3 font-poppins text-2xs font-normal leading-none tracking-tightest text-gray-850",
    "hover:bg-gray-250 rounded-[25px]"
  );

  const parentChild = [
    parentCategoryData?.key,
    ...(parentCategoryData?.children || ""),
  ];

  const withoutDropdown = () => {
    return (
      <div
        className="flex w-full items-center gap-3"
        ref={(e) => {
          if (e?.scrollWidth) setContentWidth(e?.scrollWidth);
        }}
      >
        <>
          {parentChild.map((fullChildCategoryPath: string) => {
            let isCategoryExcluded = false;

            excludeCategories.forEach((category) => {
              if (fullChildCategoryPath.startsWith(category)) {
                isCategoryExcluded = true;
              }
            });
            const childCategoryData =
              categoryDataMapping?.[fullChildCategoryPath];

            if (
              parentCategoryData.key === childCategoryData.key &&
              !enableTopStoriesLink
            ) {
              return null;
            }

            return !isCategoryExcluded && childCategoryData ? (
              <IndividualCategoryLink
                key={`without-dropdown-${childCategoryData.key}`}
                childCategoryData={childCategoryData}
                isLinkActive={isLinkActive(childCategoryData.link)}
                navItemStyle={navItemStyle}
                parentCategoryData={parentCategoryData}
              />
            ) : null;
          })}
        </>
      </div>
    );
  };

  const withDropdown = () => {
    return (
      <div className="flex items-center gap-3">
        <>
          {parentChild.slice(0, 5).map((fullChildCategoryPath: string) => {
            let isCategoryExcluded = false;

            excludeCategories.forEach((category) => {
              if (fullChildCategoryPath.startsWith(category)) {
                isCategoryExcluded = true;
              }
            });
            const childCategoryData =
              categoryDataMapping?.[fullChildCategoryPath];

            if (
              parentCategoryData.key === childCategoryData.key &&
              !enableTopStoriesLink
            ) {
              return null;
            }

            return !isCategoryExcluded && childCategoryData ? (
              <IndividualCategoryLink
                key={`with-dropdown-${childCategoryData.key}`}
                childCategoryData={childCategoryData}
                isLinkActive={isLinkActive(childCategoryData.link)}
                navItemStyle={navItemStyle}
                parentCategoryData={parentCategoryData}
                linkClassName="max-[1250px]:last:hidden max-[1050px]:[&:nth-last-child(2)]:hidden"
              />
            ) : null;
          })}
        </>
      </div>
    );
  };

  const dropdownList = () => {
    return (
      <Popover className="relative flex items-center rounded-full focus-within:outline-0 hover:bg-gray-175 focus:outline-0">
        <PopoverButton
          className={
            "rounded-full focus-within:outline-0 focus:outline-0 data-[active]:bg-gray-175"
          }
        >
          <img src={iconBreadcrumb} />
        </PopoverButton>
        <PopoverPanel
          anchor={{ to: "bottom start", gap: "6px" }}
          className="min-w-[300px] bg-white px-3 py-3"
        >
          <>
            {parentChild.map((fullChildCategoryPath: string) => {
              let isCategoryExcluded = false;

              excludeCategories.forEach((category) => {
                if (fullChildCategoryPath.startsWith(category)) {
                  isCategoryExcluded = true;
                }
              });
              const childCategoryData =
                categoryDataMapping?.[fullChildCategoryPath];

              if (
                parentCategoryData.key === childCategoryData.key &&
                !enableTopStoriesLink
              ) {
                return null;
              }

              return !isCategoryExcluded && childCategoryData ? (
                <IndividualCategoryLink
                  key={`dropdown-list-${childCategoryData.key}`}
                  childCategoryData={childCategoryData}
                  isLinkActive={isLinkActive(childCategoryData.link)}
                  navItemStyle={navItemStyle}
                  parentCategoryData={parentCategoryData}
                  linkClassName="w-max [&:nth-child(-n+5)]:hidden max-[1250px]:[&:nth-child(5)]:block max-[1050px]:[&:nth-child(4)]:block"
                />
              ) : null;
            })}
          </>
        </PopoverPanel>
      </Popover>
    );
  };

  return (
    <div className="ml-3 hidden w-full flex-1 items-center overflow-hidden lg:flex">
      <Link
        className={cn(
          "m-0 p-3 font-poppins text-2xs font-bold leading-none tracking-tightest text-gray-850"
        )}
        to={`${parentCategoryData?.link}?ref=listing-menubar`}
        reloadDocument
      >
        {parentCategoryData?.stickyHeaderLabel || parentCategoryData.label}
      </Link>

      {parentCategoryData ? (
        <>
          <div className="flex items-center overflow-hidden" ref={containerRef}>
            <div className="mx-3 hidden h-[24px] w-[1px] flex-none bg-gray-175 lg:block"></div>
            <div className="group flex gap-3 overflow-hidden">
              {!shouldRenderDropdown ? (
                <> {withoutDropdown()}</>
              ) : (
                <div className="group flex items-center gap-3">
                  <>{withDropdown()}</>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}

      {shouldRenderDropdown ? <>{dropdownList()}</> : null}
    </div>
  );
}
