import Advertisement from "@app/components/Advertisement/Advertisement";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Container from "@components/Container/Container";
import { useWindowInnerWidth } from "@hooks/useWindowInnerWidth";
import { SearchResultProps } from "@pages/Search/types";
import { cn, GoogleAdsSlotFactory } from "@util/helpers";

import SearchBasicCard from "./SearchBasicCard";

export default function SearchResults({
  result,
  cardCount,
}: SearchResultProps): React.ReactElement {
  const windowInnerSize = useWindowInnerWidth();

  const adsExtraCss = {
    maxWidth: `${windowInnerSize.width}px`,
    marginLeft: `calc(-1*(${windowInnerSize.width}px - 100%) / 2 )`,
  };

  return (
    <>
      <SearchBasicCard result={result} cardCount={cardCount} />

      {cardCount === 4 ? (
        <div
          className={cn(
            "my-8 w-screen border-y border-gray-175 bg-gray-125 py-6 md:my-12"
          )}
          style={adsExtraCss}
        >
          <Container rootClassName="sticky top-[120px]">
            <div className="mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
              <Advertisement
                adUnitProps={{
                  type: AdvertisementTypeEnum.BN1,
                  slot: GoogleAdsSlotFactory.bn1("search"),
                  isRefresh: true,
                }}
                rootClassName="text-center"
              />
            </div>
          </Container>
        </div>
      ) : null}
    </>
  );
}
