import { MAX_GIFTS_PER_MONTH } from "@pages/Article/components/ArticleSubshare/constants";

export type GiftedAlreadyProps = {
  giftCount?: number;
};

export default function GiftedAlready({
  giftCount = 0,
}: GiftedAlreadyProps): React.ReactElement {
  const giftLeft = MAX_GIFTS_PER_MONTH - giftCount;
  const articleText = giftLeft > 1 ? "articles" : "article";

  return (
    <>
      <p className="font-poppins text-base">
        {`You can share the link again without reducing your remaining ${giftLeft} ${articleText} for this month`}
      </p>
    </>
  );
}
