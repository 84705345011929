/**
 * Company: SPHMedia
 * Description: Component for article links to show header/title
 */

import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@src/app/routePaths";
import { cn, getFormattedRedirectionUrl } from "@util/helpers";
import cx from "classnames";

import styles from "./ArticleMeta.module.scss";

export type TitleLinkProps = {
  rootClassName?: string;
  linkRootClassName?: string;
  themes?: string[];
  title?: string;
  slug?: string | null;
  articleId: string;
  isTitleLinkRequired?: boolean;
  h1Tag?: boolean;
};

/**
 * TitleLink
 * Description: Component for article links to show header/title
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function TitleLink({
  rootClassName = "",
  linkRootClassName = "",
  themes = [],
  title = "",
  slug = "",
  articleId,
  isTitleLinkRequired = true,
  h1Tag,
}: TitleLinkProps): ReactElement {
  return (
    <h3
      className={cn(
        "my-1",
        styles.title,
        ...themes.map((t: string) => styles[t]),
        rootClassName
      )}
      data-testid={`title_link_${articleId}`}
    >
      {isTitleLinkRequired ? (
        <Link
          className={cx(
            "inherit word-break hover:underline",
            linkRootClassName
          )}
          to={
            slug
              ? getFormattedRedirectionUrl(slug)
              : RouteFactory.article(articleId)
          }
          target="_blank"
          rel="noopener"
          title={title}
        >
          {h1Tag ? (
            <h1 className="font-inherit text-inherit">{title}</h1>
          ) : (
            title
          )}
        </Link>
      ) : (
        title
      )}
    </h3>
  );
}
