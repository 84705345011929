import { Element } from "@app/types/Cue";
import { Name, Type } from "@app/types/enums";
import { useTextRangesWithAnnotations } from "@hooks/useTextRangesWithAnnotations";
import { cn, getFieldValue } from "@src/app/util/helpers";

export type ParagraphProps = {
  className?: string;
  element: Element;
};

export default function Paragraph({
  className,
  element,
}: ParagraphProps): React.ReactElement {
  const paragraph = element.fields.find((field) => {
    return field.name === Name.Paragraph;
  });
  const cssClass = getFieldValue(element.fields, Name.CssClass);
  const cssClassString = typeof cssClass === "string" ? cssClass : undefined;

  const fieldValue = paragraph?.value || "";
  const annotations = paragraph?.annotations || [];

  const { componentToRender } = useTextRangesWithAnnotations(
    fieldValue,
    annotations
  );

  return (
    <p
      className={cn(className, cssClassString)}
      data-story-element={Type.Paragraph}
      data-testid="paragraph-component"
    >
      {componentToRender}
    </p>
  );
}
