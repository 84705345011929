import { useEffect, useState } from "react";
import { Button } from "react-aria-components";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { TestIdClassNameProps } from "@app/types/Common";
import { EmarsysResponse } from "@components/Newsletter/types/Emarsys";
import { Newsletter } from "@components/Newsletter/types/Newsletter";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@hooks/useWindowSize";
import { ArticleNewsletterForm } from "@pages/Article/components/ArticleNewsletter/ArticleNewsletterForm";
import { ArticleNewsletterFormSuccess } from "@pages/Article/components/ArticleNewsletter/ArticleNewsletterFormSuccess";
import { NewsletterFormTrigger } from "@pages/Article/components/ArticleNewsletter/NewsletterFormTrigger";
import { cn } from "@util/helpers";

type ArchiveNewsletterFormProps = TestIdClassNameProps & {
  newsletter: Newsletter;
};

export default function ArchiveNewsletterForm({
  testId,
  rootClassName,
  newsletter,
}: ArchiveNewsletterFormProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);

  useEffect(() => {
    if (emarsysResponse?.status === "success") {
      setIsSuccessVisible(true);
    }
  }, [emarsysResponse]);

  const handleCloseSuccess = () => {
    setIsSuccessVisible(false);
  };

  useEffect(() => {
    setIsFormVisible(!isScreenLG);
  }, [isScreenLG]);

  return (
    <div
      className={cn(rootClassName)}
      data-testid={testId}
      data-component="archive-newsletter-form"
    >
      {emarsysResponse?.status !== "success" ? (
        <div className="flex pb-8 pt-3">
          {!isFormVisible ? (
            <NewsletterFormTrigger
              setShowForm={setIsFormVisible}
              showViewAll={false}
            />
          ) : null}

          <ArticleNewsletterForm
            showForm={isFormVisible}
            newsletters={[newsletter]}
            emarsysResponse={emarsysResponse}
            setEmarsysResponse={setEmarsysResponse}
          />
        </div>
      ) : null}

      {isSuccessVisible ? (
        <>
          <Button
            type="button"
            className="ml-auto mr-3 block pt-3 md:hidden"
            aria-label="Close"
            onPress={handleCloseSuccess}
          >
            <FontAwesomeIcon
              icon={faClose}
              size="2x"
              className="opacity-75 transition-opacity hover:opacity-100"
            />
          </Button>

          <ArticleNewsletterFormSuccess>
            <p className="mb-0 mt-4 text-base font-light">
              {"We have other newsletters you might enjoy. "}
              <Link
                to={RouteFactory.newsletterSignup}
                className="!text-gray-850 underline"
                target="_blank"
                rel="noreferrer noopener"
                reloadDocument
              >
                Take a look.
              </Link>
            </p>
          </ArticleNewsletterFormSuccess>
        </>
      ) : null}
    </div>
  );
}
