import { useCallback, useEffect, useState } from "react";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { cn } from "@app/util/helpers";
import { adSizeMappings, adSlotSizes, adTargetings } from "@util/constant";
import { isEmpty } from "lodash-es";

import { generateAdvertElementId } from "./helpers";

export type AdUnitProps = {
  type: AdvertisementTypeEnum;
  slot: string;
  id?: string;
  isRefresh?: boolean;
  slotTargettings?: AdTargetingType[];
  adsClassName?: string;
};

export type AdTargetingType = {
  key: string;
  value: string | string[];
};

/**
 * This component renders an IMU ad.
 * @param type - The type of ad unit.
 * @param slot - The ad slot.
 * @param id - The id of the ad.
 * @see https://developers.google.com/doubleclick-gpt/reference
 * @returns A IMU ad component. If the script is not ready, it returns error. If the script is ready, it returns the ad.
 */
export const AdUnit = ({
  type,
  slot = "",
  id = generateAdvertElementId(type),
  isRefresh = false,
  slotTargettings = [],
  adsClassName,
}: AdUnitProps) => {
  const [error, setError] = useState<string>("");
  const [adSlot, setAdSlot] =
    useState<ReturnType<typeof googletag.defineSlot>>();

  /**
   * This function is called when the Google script is loaded.
   * It defines the ad slot and loads the ad.
   * @returns void
   * @see https://developers.google.com/doubleclick-gpt/reference#googletag.cmd.push
   */
  const handleDisplayAd = useCallback(() => {
    const adSlotSize = adSlotSizes[type];
    const adSizeMapping = adSizeMappings[type] || [];
    const adTargets = adTargetings[type] || [];
    const allTargettings = [...adTargets, ...slotTargettings];

    try {
      // Make sure that googletag.cmd exists.
      window.googletag = window.googletag || {};
      window.googletag.cmd = window.googletag.cmd || [];
      // Correct: Queueing the callback on the command queue.
      googletag.cmd.push(function () {
        googletag
          .pubads()
          .getSlots()
          .forEach((_slot) => {
            if (_slot.getSlotElementId() === id) {
              googletag.destroySlots([_slot]);
            }
          });

        // Create the ad slot.
        const _adSlot = googletag.defineSlot(slot, adSlotSize, id);

        if (_adSlot) {
          _adSlot
            .addService(googletag.pubads())
            .defineSizeMapping(adSizeMapping);

          allTargettings.forEach((target: AdTargetingType) => {
            _adSlot.setTargeting(target.key, target.value);
          });

          googletag.pubads().enableSingleRequest();
          googletag.enableServices();

          if (isRefresh)
            googletag.pubads().refresh([_adSlot], { changeCorrelator: true });

          window.googletag.slots[type] = _adSlot;

          // Store the ad slot for later use.
          setAdSlot(_adSlot);
        }
      });
    } catch (_error: unknown) {
      setError("Please refresh");
    }
  }, [id, slot, type, isRefresh, slotTargettings]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (isEmpty(slot)) return;
    if (adSlot) return;
    if (type === "catfish" && window.innerWidth > 767) return;

    handleDisplayAd();
  }, [slot, handleDisplayAd, adSlot, type]);

  useEffect(() => {
    /**
     * This function destroys the ad slot when the component is unmounted.
     * @returns void
     * @see https://developers.google.com/doubleclick-gpt/reference#googletag.destroySlots
     */
    return () => {
      if (window?.googletag && window?.googletag?.apiReady && adSlot) {
        googletag.cmd.push(function () {
          googletag.destroySlots([adSlot]);
        });
      }
    };
  }, [adSlot]);

  return (
    <>
      {!error ? (
        <div id={id} className={cn("adunit-component", adsClassName)}></div>
      ) : (
        error
      )}
    </>
  );
};
