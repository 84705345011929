import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import {
  getArticleAuthorNames,
  getArticleChapter1,
  getArticleKeywords,
  getArticleLevel2,
  getArticleStoryThread,
} from "@components/GAData/helper";
import { dayjsSingaporeTimezone } from "@util/constant";
import { toUnderscoreFromSpace } from "@util/helpers";
import dayjs from "dayjs";

import { ArticleDataObject } from "../types/Cue";

// Variable to track the last article ID pushed to dataLayer
let lastPushedArticleId: string | null = null;
export const useHookUpdateUrlArticle = (
  urlPath: string,
  isNavigate: boolean,
  article: ArticleDataObject
) => {
  const navigate = useNavigate();

  const publishedDate = dayjs(article?.updated)
    .tz(dayjsSingaporeTimezone)
    .format("YYYY-MM-DD HH:mm:ss");

  const { ref } = useInView({
    threshold: 0.1,
    onChange: (inView) => {
      if (!inView || !isNavigate || !article?.id) return;

      // Only push to dataLayer if the current article is different from the last one
      if (lastPushedArticleId === article.id) return;
      // Update the last pushed article ID
      lastPushedArticleId = article.id;

      navigate(urlPath, { preventScrollReset: true });

      if (typeof window === "undefined") return;
      window.dataLayer = window.dataLayer || [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window.dataLayer as any[]).push({
        ...window._data,
        ...{
          event: "virtual_pv",
          virtual_url: urlPath.replace("/", ""),
          cue_articleid: article?.id,
          articleid: article?.id,
          author: getArticleAuthorNames(
            article?.authors,
            article.relatedContributorProfile
          ),
          chapter1: article?.sections?.[0]
            ? getArticleChapter1(article?.sections?.[0])
            : "",
          contentcat: article?.paywall?.contentAccess ? 2 : 1,
          contenttype: "1",
          keyword: getArticleKeywords(article.tags || []),
          level2: article?.sections?.[0]
            ? getArticleLevel2(article?.sections[0])
            : "",
          pubdate: publishedDate,
          title: toUnderscoreFromSpace(article?.title),
          story_threads: getArticleStoryThread(
            article.tags || []
          )?.name.toLowerCase(),
        },
      });
    },
  });

  return { ref };
};
