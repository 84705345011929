import { Link } from "react-router-dom";
import {
  ArticleCardProps,
  WealthMagazineHeroHighlight,
} from "@components/ArticleCard/types";
import Kicker from "@components/ArticleMeta/Kicker";
import TitleLink from "@components/ArticleMeta/TitleLink";
import ArticleThumbnail from "@components/ArticleThumbnail/ArticleThumbnail";
import { RouteFactory } from "@src/app/routePaths";
import cx from "classnames";

import { extractMagazineData } from "./helpers/magazine";

export default function WealthMagazineHero({
  id = "",
  articleData,
}: ArticleCardProps) {
  const { title, media, kicker, id: articleId = "" } = articleData;
  const { magazineLink, magazineTitle, magazineHighlights } =
    extractMagazineData(articleData);

  const componentId = id || `article_${articleId}`;
  const _media = media && "length" in media ? media?.[0] : media;
  const thumbnail = _media?.content;
  const previewText = _media?.summary?.[0]?.value;
  const kickerValue = kicker?.fields?.[0]?.value;

  return (
    <div id={componentId} data-testid={componentId}>
      <div className="mb-2 font-poppins uppercase">
        <Link
          className="hover:underline"
          to={RouteFactory.section(magazineLink)}
          target="_blank"
          rel="noopener"
        >
          {magazineTitle}
        </Link>
      </div>
      <div className={cx("bg-gray-150")}>
        <div
          className={cx(
            "flex flex-col border-b border-gray-175",
            "lg:flex-row"
          )}
        >
          <div className={cx("w-full lg:w-4/10")}>
            {thumbnail ? <ArticleThumbnail thumbnail={thumbnail} /> : null}
          </div>
          <div className={cx("w-full px-4 py-2 lg:w-6/10")}>
            {kickerValue ? <Kicker name={kickerValue} /> : null}
            <div>
              {title ? (
                <TitleLink
                  rootClassName={cx(
                    "w-full font-semibold my-2 !text-lg",
                    "lg:!text-6xl "
                  )}
                  title={title}
                  articleId={articleId}
                />
              ) : null}
            </div>
            {previewText ? (
              <div
                className={cx(
                  "hidden font-public-sans text-xs text-gray-750",
                  "lg:line-clamp-3"
                )}
              >
                {previewText}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={cx(
            "flex flex-col divide-y divide-gray-175 px-4",
            "lg:flex-row lg:divide-y-0"
          )}
        >
          {magazineHighlights.map((highlight) => (
            <HeroHighlight key={highlight.title} highlight={highlight} />
          ))}
        </div>
      </div>
    </div>
  );
}

function HeroHighlight({
  highlight,
}: {
  highlight: WealthMagazineHeroHighlight;
}) {
  return (
    <div className={cx("w-full py-3")}>
      <Kicker name={highlight.kickerValue} />
      <TitleLink
        rootClassName={cx("!text-lg font-semibold my-2")}
        title={highlight.title}
        articleId={highlight.articleId}
      />
    </div>
  );
}
