import { RouteFactory } from "@app/routePaths";
import CirculationBanner from "@assets/newsletter/circulation_banner.gif";
import { renderOrangeLink } from "@pages/Newsletter/Archive/util/helpers";
import { cn } from "@util/helpers";

const links = [
  {
    href: RouteFactory.newsletterSignup,
    text: "Sign up here",
  },
];

export default function PropertyInsightNewsletterSampleTopReads(): React.ReactElement {
  const bannerLink =
    "https://subscribe.sph.com.sg/promotions/bt-personal/?utm_campaign=btp99&utm_medium=email&utm_source=emarsys&utm_content=840x270-bottom-bt-garage";
  return (
    <>
      <div className="mx-5 pb-6 pl-0">
        <h5 className="box-border border-gray-250 pt-1 font-pullQuote text-4xl font-normal leading-6 text-orange-500">
          Top reads this week
        </h5>
      </div>

      <div>
        {stories.map((story) => (
          <div
            key={story.id}
            data-id={`PropertyStory${story.id}`}
            className={cn("px-2 py-5")}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={story.link}
              className="flex text-black no-underline"
            >
              <div className="story-counter my-auto min-w-16 text-center font-pullQuote text-12xl font-normal text-orange-500">
                {story.counter}
              </div>

              <div className="story-title pl-3 pr-3 font-pullQuote text-xl font-bold text-black-200">
                {story.title}
              </div>
            </a>
          </div>
        ))}
      </div>
      <div className="border-t-[3px] border-t-gray-250 p-5">
        <p className="mb-2.5 font-times text-base leading-6">
          Did you receive this newsletter from someone?
        </p>
        <p className="mb-2.5 font-times text-base leading-6">
          {renderOrangeLink("Sign up here", links[0].href)} to get the latest
          updates sent right to your inbox.
        </p>
      </div>
      <div className="front-copy mx-auto max-w-[800px] border-collapse p-0">
        <div className="border-t-[3px] border-gray-250 px-5 pb-6" />

        <div className="mx-auto flex max-w-[300px] justify-center p-0 py-11">
          <a
            href={bannerLink}
            target="_blank"
            rel="noopener noreferrer"
            className="no-underline"
          >
            <img
              src={CirculationBanner}
              alt="Circulation Banner"
              width="300"
              height="180"
              className="block h-auto max-w-full"
            />
          </a>
        </div>
      </div>
    </>
  );
}

const stories = [
  {
    id: 0,
    title:
      "Subdued bidding for housing sites will persist if sales at new condo launches are tepid",
    link: "https://www.businesstimes.com.sg/opinion-features/subdued-bidding-housing-sites-will-persist-if-sales-new-condo-launches-are-tepid",
    counter: 1,
  },
  {
    id: 1,
    title:
      "Tighter HDB loan limit will cool resale flat prices; choice units should still see firm demand",
    link: "https://www.businesstimes.com.sg/opinion-features/tighter-hdb-loan-limit-will-cool-resale-flat-prices-choice-units-should-still-see-firm-demand",
    counter: 2,
  },
  {
    id: 2,
    title:
      "BlackRock close to buying 299-unit Citadines Raffles Place for S$300 million",
    link: "https://www.businesstimes.com.sg/property/blackrock-close-buying-299-unit-citadines-raffles-place-s300-million",
    imgSrc: null,
    counter: 3,
  },
  {
    id: 3,
    title:
      "S-Reit DPUs weighed down by higher interest rates in H1, but tide to turn in H2",
    link: "https://www.businesstimes.com.sg/companies-markets/s-reit-dpus-weighed-down-higher-interest-rates-h1-tide-turn-h2",
    imgSrc: null,
    counter: 4,
  },
  {
    id: 4,
    title: "New dawn for S-Reits as Fed rate cuts get underway",
    link: "https://www.businesstimes.com.sg/opinion-features/new-dawn-s-reits-fed-rate-cuts-get-underway",
    imgSrc: null,
    counter: 5,
  },
];
