import { Fragment, ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { ArticleDataObject } from "@app/types/Cue";
import type { NavigationItem } from "@app/types/Navigation";
import defaultBTMobileLogo from "@assets/logo-bt-mobile-black.svg";
import Container from "@components/Container/Container";
import { useHeaderState } from "@components/Header/helpers";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { type UserInfoType, useUserInfo } from "@hooks/useUserInfo";
import { ArticleSocialSharingHeader } from "@pages/Article/components/ArticleSocialSharing";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { cn } from "@util/helpers";
import cx from "classnames";

import LoginTrigger from "./LoginTrigger/LoginTrigger";
import LogoMasthead from "./LogoMasthead/LogoMasthead";
import { Navigation } from "./Navigation/Navigation";
import SearchTrigger from "./SearchTrigger/SearchTrigger";
import SideMenu from "./SideMenu/SideMenu";
import SubscribeTrigger from "./SubscribeTrigger/SubscribeTrigger";
import ToggleSideMenuButton from "./ToggleSideMenuButton/ToggleSideMenuButton";
import UserInfo from "./UserInfo/UserInfo";
import type { NavigationA } from "./types";
import HeaderBreadcrumb from "../HeaderBreadcrumb/HeaderBreadcrumb";

import styles from "./Header.module.scss";

export type SocialLinkConfig = {
  /**
   * @description icon image of the social link
   */
  src: string;
  link?: string;
};

export interface MainHeaderProps {
  prefix?: string;
  isFromHome?: boolean;
  displayBreadcrumb?: boolean;
  displayArticle?: boolean;
  parentCategory?: string;
  childCategory?: string;
  categoryDataMapping?: Record<string, NavigationItem>;
  article?: ArticleDataObject;
  scrollObj?: ScrollObjArray[];
  masthead?: ReactNode;
  enableTopStoriesLink?: boolean;
}

type ScrollObjArray = {
  id: string;
  scrollW: number;
};

export default function Header({
  prefix,
  displayBreadcrumb = false,
  parentCategory = "",
  childCategory,
  categoryDataMapping = {},
  article,
  displayArticle,
  scrollObj,
  masthead,
  isFromHome,
  enableTopStoriesLink = true,
}: MainHeaderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const headerData: NavigationA = [];

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <HeaderContainer
        prefix={prefix}
        headerData={headerData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        displayBreadcrumb={displayBreadcrumb}
        parentCategory={parentCategory}
        childCategory={childCategory}
        categoryDataMapping={categoryDataMapping}
        displayArticle={displayArticle}
        article={article}
        scrollObj={scrollObj}
        isFromHome={isFromHome}
        enableTopStoriesLink={enableTopStoriesLink}
      />

      {masthead}

      <Modal headerData={headerData} isOpen={isOpen} setIsClose={handleClose} />
    </>
  );
}

export type HeaderProps = {
  /**
   * @description for prefixing custom attributes as component might be used by different scope
   * @example data-testid={`${prefix}-xxx`}
   */
  prefix?: string;
  children?: ReactNode;
  mastheadTitle?: string;
  mastheadCustomElement?: React.ReactElement;
  headerData?: NavigationA;
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  displayBreadcrumb?: boolean;
  parentCategory: string;
  childCategory?: string;
  categoryDataMapping: Record<string, NavigationItem>;
  displayArticle?: boolean;
  article?: ArticleDataObject;
  scrollObj?: ScrollObjArray[];
  isFromHome?: boolean;
  enableTopStoriesLink?: boolean;
};

function HeaderContainer({
  headerData,
  displayBreadcrumb = false,
  setIsOpen,
  parentCategory = "",
  childCategory = "",
  displayArticle,
  article,
  scrollObj,
  isFromHome,
  enableTopStoriesLink = true,
}: HeaderProps) {
  const { userInfo } = useUserInfo();
  // TODO change to OKTA user info
  // check user typing in useOKTAUserInfo.ts
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;
  const { isHeaderStuck, headerRef } = useHeaderState();

  const toggleSideMenu = () => {
    setIsOpen(true);
  };

  const parentCategoryData = sectionNavigationItems?.[parentCategory];

  const scrollW =
    article && scrollObj && scrollObj.find((x) => x.id === article.id);

  const displaySubscribe =
    OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER;
  return (
    <>
      <header
        ref={headerRef}
        data-testid="navigation-bar"
        className={cx(
          styles.componentHeader,
          "sticky left-0 top-[-1px] z-30 w-full flex-none"
        )}
      >
        <div
          className={cx(
            "relative flex min-h-[50px] w-full items-center border-b border-gray-175 bg-white"
          )}
        >
          <div
            className={cn(
              "mx-auto flex h-full min-h-[50px] w-full max-w-[1900px] px-2 lg:px-6",
              { "justify-between": !isFromHome },
              { "justify-normal": displayBreadcrumb },
              { "justify-between lg:justify-normal": isFromHome }
            )}
          >
            <div
              className={cn(
                "flex items-center",
                {
                  "overflow-hidden min-[1281px]:min-w-[920px] min-[1281px]:overflow-visible":
                    isFromHome,
                },
                { "hidden lg:flex": isHeaderStuck && article }
              )}
            >
              <ToggleSideMenuButton onClick={toggleSideMenu} />

              <div className="hidden flex-none lg:block">
                <SearchTrigger />
              </div>

              {isFromHome ? (
                <div className="hidden pl-3 lg:block">
                  <Navigation data={headerData} position="left" />
                </div>
              ) : null}
            </div>

            <div
              className={cn(
                "absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2",
                { hidden: displayArticle && article },
                { "block lg:hidden": isFromHome || displayBreadcrumb }
              )}
            >
              <LogoMasthead
                rootClassName="mx-auto"
                imgWidth={220}
                imgHeight={19}
              />
            </div>

            <div className="mr-auto flex items-center">
              {article && displayArticle ? (
                <div className="hidden h-9 w-9 flex-none cursor-pointer items-center justify-center opacity-100 duration-300 focus-within:outline-0 focus:outline-0 lg:flex lg:hover:rounded-full lg:hover:bg-gray-250">
                  <Link
                    to={`${RouteFactory.home}?ref=logo`}
                    rel="noopener noreferer"
                    reloadDocument
                  >
                    <img
                      src={defaultBTMobileLogo}
                      height={20}
                      width={24}
                      className="w-6"
                      alt="bt logo"
                    />
                  </Link>
                </div>
              ) : null}

              {parentCategoryData && !displayBreadcrumb ? (
                <>
                  <div className="mx-3 hidden h-[24px] w-[1px] flex-none bg-gray-175 min-[1250px]:block"></div>
                  <p className="m-0 mr-3 hidden h-9 w-auto items-center justify-center text-nowrap rounded-[25px] px-3 font-poppins text-2xs font-semibold leading-none tracking-tightest text-gray-850 duration-300 lg:hover:bg-gray-250 min-[1250px]:flex">
                    <Link
                      to={`${parentCategoryData.link}?ref=header`}
                      rel="noopener noreferer"
                      reloadDocument
                    >
                      {parentCategoryData.stickyHeaderLabel ||
                        parentCategoryData.label}
                    </Link>
                  </p>
                </>
              ) : null}
            </div>

            {article && displayArticle ? (
              <>
                <div className="absolute left-1/2 top-1/2 flex w-full flex-1 -translate-x-1/2 -translate-y-1/2 items-center px-3 min-[991px]:max-w-[643px] min-[1170px]:max-w-[784px]">
                  <div className={cx("w-[500px]")}>
                    <p className="!mb-[0.563rem] mt-[0.563rem] line-clamp-2 font-lct font-bold leading-tight text-gray-850 lg:!mb-1 lg:mt-1">
                      {article.title}
                    </p>
                  </div>
                  <div className="ml-auto min-[1000px]:ml-0 min-[1070px]:ml-auto">
                    <ArticleSocialSharingHeader
                      articleTitle={article.title}
                      shareUrl={article.shortUrl}
                    />
                  </div>
                </div>

                <div
                  className="absolute left-0 top-full h-[0.125rem] bg-gray-850 transition-all duration-150"
                  style={{ width: `${scrollW?.scrollW}%` }}
                ></div>
              </>
            ) : null}

            {isFromHome ? (
              <div
                className={cn(
                  "hidden w-auto min-w-0 items-center justify-end overflow-hidden min-[991px]:w-[100px] min-[991px]:min-w-[100px] lg:ml-auto lg:flex min-[1281px]:w-auto"
                )}
              >
                <div className="hidden lg:block">
                  <Navigation data={headerData} position="right" />
                </div>

                <div className="mx-3 hidden h-[24px] w-[1px] flex-none bg-gray-175 lg:block"></div>
              </div>
            ) : null}

            {displayBreadcrumb ? (
              <HeaderBreadcrumb
                parentCategory={parentCategory}
                childCategory={childCategory}
                categoryDataMapping={sectionNavigationItems}
                enableTopStoriesLink={enableTopStoriesLink}
              />
            ) : null}

            <div
              className={cn(
                "flex items-center",
                {
                  "hidden lg:flex": isHeaderStuck && article,
                },
                { "ml-auto": displayBreadcrumb }
              )}
            >
              <div
                className={cx("flex items-center justify-center ", {
                  "lg:mr-3": displaySubscribe,
                })}
              >
                {userDisplayName ? (
                  <UserInfo
                    rootClassName="text-2xs font-poppins font-semibold"
                    userInfo={
                      {
                        ...userInfo,
                        lastname: userDisplayName,
                        loginId: OKTAUserInfo?.loginid,
                        sub: OKTAUserInfo?.reguserstatus,
                      } as UserInfoType
                    }
                  />
                ) : (
                  <LoginTrigger title={"Login"} />
                )}
              </div>
              {displaySubscribe ? (
                <div className={cx("hidden lg:block")}>
                  <SubscribeTrigger />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </header>

      <div className={cx("block pt-1 text-center lg:hidden")}>
        {displaySubscribe ? (
          <SubscribeTrigger
            displayArrow
            title="Subscribe from S$9.90/month"
            rootClassName={
              "bg-transparent !text-orange-300 visited:!text-orange-300 font-normal"
            }
          />
        ) : null}
      </div>
    </>
  );
}

export type MastheadProps = {
  prefix?: string;
  mastheadTitle?: string;
  mastheadCustomElement?: React.ReactElement;
  userDisplayName?: boolean;
  mastheadLinks?: NavigationA;
};

export function MastheadContainer({
  mastheadTitle = "",
  mastheadCustomElement,
  mastheadLinks,
}: MastheadProps) {
  return (
    <Container rootClassName="">
      <div className="relative hidden w-full flex-none pb-0 pt-6 lg:block">
        {mastheadTitle && mastheadTitle in sectionNavigationItems ? (
          <>
            <LogoMasthead
              rootClassName="absolute"
              imgWidth={220}
              imgHeight={19}
            />
            {mastheadCustomElement ?? (
              <h2 className="text-center font-playfair text-8xl uppercase">
                <Link
                  to={`/${mastheadTitle}`}
                  rel="noopener noreferer"
                  reloadDocument
                >
                  {sectionNavigationItems?.[mastheadTitle]?.label}
                </Link>
              </h2>
            )}
          </>
        ) : (
          <>
            <div className="text-center uppercase">
              {mastheadLinks?.map((links, index) => {
                const childrenD = links?.section?.children || [];

                return (
                  <div key={`masthead-links-${index}`}>
                    <>
                      {childrenD.map((children, index) => {
                        const { link, label, key } = children;
                        return (
                          <Link
                            key={key}
                            to={`${link}?ref=masthead`}
                            rel="noopener noreferer"
                            reloadDocument
                            className={cn(
                              "border-l border-gray-175 px-[0.375rem] font-poppins text-4xs font-light text-gray-850 lg:hover:underline",
                              { "border-none": index === 0 }
                            )}
                          >
                            {label}
                          </Link>
                        );
                      })}
                    </>
                  </div>
                );
              })}
            </div>
            <LogoMasthead rootClassName={"flex justify-center"} />
          </>
        )}
      </div>
    </Container>
  );
}

export type ModalProps = {
  isOpen: boolean;
  setIsClose: () => void;
  headerData: NavigationA;
};

export const Modal = ({ isOpen, setIsClose, headerData }: ModalProps) => {
  const handleOnClose = () => {
    setIsClose();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[210000000]"
        onClose={handleOnClose}
        aria-label="side-menu-dialog"
        data-testid={"side-menu-dialog"}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0  bg-black/25" />
        </TransitionChild>

        <div
          className="fixed inset-0 overflow-y-auto"
          data-testid={"side-menu-dialog-container"}
        >
          <TransitionChild
            as={Fragment}
            enter="transition ease duration-500 transform"
            enterFrom="opacity-0 -translate-x-12"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-12"
          >
            <DialogPanel className="w-full transform transition-all lg:max-w-[260px]">
              <SideMenu
                data={headerData}
                isSideMenuOpen={isOpen}
                handleOnClose={handleOnClose}
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};
