import { ReactElement } from "react";
import type { TooltipTriggerComponentProps as AriaTooltipTriggerComponentProps } from "react-aria-components";
import { TooltipTrigger as AriaTooltipTrigger } from "react-aria-components";

/**
 *
 * @param props AriaTooltipTriggerComponentProps
 * @see https://react-spectrum.adobe.com/react-spectrum/Tooltip.html#tooltiptrigger-props
 * @returns ReactElement<AriaTooltipTriggerComponentProps>
 */
export default function TooltipTrigger(
  props: AriaTooltipTriggerComponentProps
): ReactElement<AriaTooltipTriggerComponentProps> {
  const { children, ...rest } = props;

  return <AriaTooltipTrigger {...rest}>{children}</AriaTooltipTrigger>;
}
