import useScript from "@hooks/useScript";

/**
 *  Hook that loads Ceros scripts.
 */
export default function useCerosScript() {
  useScript({
    id: "ceros-origin-domains",
    async: true,
    src: "https://view.ceros.com/scroll-proxy.min.js",
    type: "text/javascript",
  });
}
