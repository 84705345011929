import { useState } from "react";
import Icon from "@components/Icon/Icon";
import Modal from "@components/Modal/Modal";
import Spinner from "@components/Spinner/Spinner";
import {
  getPercentageChangeInfo,
  getTableData,
  useStockHistoricalData,
} from "@components/StockPickerWidget/helpers";
import StockChart from "@components/StockPickerWidget/StockChart";
import type {
  StockPickerWidgetProps,
  TimeFrame,
} from "@components/StockPickerWidget/types";
import { stockWidgetTimestampFormat } from "@util/constant";
import { cn, getFormattedTime } from "@util/helpers";
import cx from "classnames";

export default function StockPickerWidget({
  code,
  stockData,
  isModalOpen,
  setIsModalOpen,
}: StockPickerWidgetProps) {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame>("Days");
  const stockHistoricalData = useStockHistoricalData(code);
  const tableData = getTableData(stockData);
  const {
    percentageChange,
    percentageChangeColor,
    percentageChangeSign,
    percentageChangeIcon,
  } = getPercentageChangeInfo(stockData.CHANGES, stockData.PREVIOUS);
  const timeFrames: TimeFrame[] = ["Days", "Months", "Years"];
  const tableWrapperStyle = "flex border-b py-[5px]";
  const tableTitleStyle = "font-bold text-sm leading-5 text-gray-900 w-[70%]";
  const tableValueStyle = "text-sm leading-5 text-gray-900 w-[30%]";
  const priceChangeStyle = `font-medium tracking-wide ${percentageChangeColor}`;

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      dialogPanelClassNames={cn(
        "!w-[1000px] h-screen p-[20px] md:p-[40px] !rounded-[4px]",
        "md:h-[700px]"
      )}
    >
      <div data-testid={`stock-picker-widget-${code}`}>
        <div className="flex justify-between border-b border-black py-[10px]">
          <span
            data-testid={`stock-picker-widget-close-${code}`}
            className="absolute right-[20px] top-[20px] h-[30px] w-[30px] cursor-pointer rounded-full  bg-black/[0.75] p-1"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <Icon
              rootClassName="stroke-white stroke-[4px]"
              viewbox={22}
              type="xMark"
            />
          </span>

          <div className={cx("flex flex-col gap-2 text-left", "md:flex-row")}>
            <div className="text-3xl">
              <span className="font-bold uppercase">
                {stockData.STOCK_NAME}&nbsp;
              </span>
              ({stockData.STOCK_CODE})
            </div>
            <div className="flex items-center">
              <div className="flex items-end">
                <div className="mx-[10px]">
                  <span className="text-3xl">{stockData.LAST}</span>{" "}
                  {stockData.CURRENCY}
                </div>
              </div>

              {stockData.CHANGES !== 0 ? (
                <>
                  <div className="mx-[10px] flex items-center space-x-1">
                    <img
                      src={percentageChangeIcon}
                      width="14"
                      height="22"
                      alt="Percentage Change Icon"
                    />
                    <span className={priceChangeStyle}>
                      {percentageChangeSign}
                      {percentageChange}%
                    </span>
                  </div>
                  <div className="ml-3 pl-3">
                    <span className={priceChangeStyle}>
                      {percentageChangeSign}
                      {stockData.CHANGES}
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>

        <div className="my-[10px] text-right text-sm font-normal not-italic leading-normal text-gray-400">
          As at{" "}
          <span className="uppercase">
            {getFormattedTime(stockData.TIMESTAMP, stockWidgetTimestampFormat)}
          </span>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="w-[100%] md:w-[60%]">
            <div className="mb-5 text-left">
              {timeFrames.map((timeFrame) => (
                <span
                  onClick={() => {
                    setSelectedTimeFrame(timeFrame);
                  }}
                  key={timeFrame}
                  className={cx(
                    "text-md mx-[5px] cursor-pointer border border-[#007bad] px-[10px] py-[4px] text-[16px] font-normal",
                    "hover:bg-[#007bad] hover:text-white",
                    timeFrame === selectedTimeFrame
                      ? "bg-[#007bad] text-white"
                      : "text-[#007bad]"
                  )}
                >
                  {timeFrame}
                </span>
              ))}
            </div>

            {stockHistoricalData ? (
              <StockChart
                stockHistoricalData={stockHistoricalData}
                timeFrame={selectedTimeFrame}
              />
            ) : (
              <div className="mb-4 flex h-full w-full items-center justify-center bg-gray-200 p-4">
                <Spinner />
              </div>
            )}
          </div>

          <div className="mt-8 w-[100%] text-left md:w-[40%] md:pl-[20px]">
            {tableData.map((row) => (
              <div key={row.title} className={tableWrapperStyle}>
                <span className={tableTitleStyle}>{row.title}</span>
                <span className={tableValueStyle}>{row.value || "null"}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
