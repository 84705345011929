import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import type { ArticleDataObject, Section } from "@app/types/Cue";

export type BreakingNewsProps = {
  data?: ArticleDataObject[];
  sections?: Section[];
};

export default function ArticlesFromSameSection({
  data = [],
  sections = [],
}: BreakingNewsProps): React.ReactElement {
  const firstSection = sections.length > 0 ? sections[0] : null;
  const firstSectionName = firstSection ? firstSection.name : "";
  const firstSectionUniqueName = firstSection ? firstSection.uniqueName : "";

  return (
    <>
      {data && data.length > 0 ? (
        <section className="my-5">
          <h2 className="mb-4 font-poppins text-3xl font-normal uppercase tracking-[1px]">
            <Link
              className="!text-black"
              to={RouteFactory.section(":name").replace(
                ":name",
                firstSectionUniqueName
              )}
            >
              {firstSectionName}
            </Link>
          </h2>

          <ul data-testid="stories-from-same-section">
            {data
              ?.slice(0, 6)
              .map(({ id, title, urlPath }: ArticleDataObject) => {
                return (
                  <li key={id}>
                    <h2 className="mb-2 mt-1 border-b border-dotted border-gray-450 pb-2 font-public-sans text-base font-semibold">
                      <Link className="!text-black" to={urlPath} reloadDocument>
                        {title}
                      </Link>
                    </h2>
                  </li>
                );
              })}
          </ul>
        </section>
      ) : null}
    </>
  );
}
