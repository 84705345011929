import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import rightArrow from "@assets/newsletter/right-arrow.png";
import { cn } from "@util/helpers";

export type AseanBusinessNewsletterOtherReadsProps = {
  link: string;
  title: string;
  description: string;
  image: string;
};

export type AseanBusinessNewsletterSampleOtherReadsProps = {
  newsletters: AseanBusinessNewsletterOtherReadsProps[];
};

export default function AseanBusinessNewsletterSampleOtherReads({
  newsletters,
}: AseanBusinessNewsletterSampleOtherReadsProps): React.ReactElement {
  const commonTextStyle =
    "text-lg  font-poppins font-semibold leading-[1.3] text-gray-850";
  const commonTextNormalStyle =
    "text-base font-public-sans pt-1 font-normal leading-[1.5] text-gray-750";

  return (
    <div className="px-5 pt-6">
      <div className="pb-6 pl-0">
        <h5 className="border-t-[5px] border-verticals-asean pt-1 font-arial text-xl font-bold leading-6 text-verticals-asean">
          OTHER GOOD READS
        </h5>
      </div>

      <div className="pl-0 pr-2.5">
        <div className="promote-newsletter w-full">
          {newsletters.map((newsletter) => (
            <div
              className="flex flex-row border-b border-gray-250 py-6"
              key={newsletter.title}
            >
              <div className="w-3/4 pb-5 pr-8 pt-0">
                <a
                  href={newsletter.link}
                  target="_blank"
                  className="text-gray-850 no-underline"
                  rel="noreferrer"
                >
                  <h5 className={cn("m-0", commonTextStyle)}>
                    {newsletter.title}
                  </h5>
                </a>

                <p className={cn("m-0", commonTextNormalStyle)}>
                  {newsletter.description}
                </p>
              </div>
              <div className="min-w-[60px]">
                <img
                  alt="newsletter icon"
                  src={newsletter.image}
                  height={60}
                  width={60}
                />
              </div>
            </div>
          ))}

          <div className="border-t border-gray-250 pb-0 pl-0 pt-0"></div>
        </div>
      </div>

      <div className="pb-6 pl-0 pr-2.5 pt-9 text-right">
        <Link
          className="pr-1 text-base font-bold uppercase leading-[1.5] text-gray-850 no-underline"
          to={RouteFactory.newsletterSignup}
          target="_blank"
          rel="noopener noreferrer"
        >
          NEWSLETTERS
          <img
            src={rightArrow}
            alt="Newsletter"
            className="mb-[2px] ml-1 inline w-[10px]"
            height={12}
            width={10}
          />
        </Link>
      </div>
    </div>
  );
}
