import nahIcon from "@assets/thrive_images/thrive-newsletter-nah.jpg";
import yeahIcon from "@assets/thrive_images/thrive-newsletter-yeah.jpg";
import Container from "@components/Container/Container";
import { linksInHomeNewsletterTopFooter } from "@pages/Thrive/constants";

export default function ThriveSampleNewsletterTopFooter(): React.ReactElement {
  return (
    <Container
      rootClassName="text-gray-850 bg-white pb-10 px-6"
      data-testid="thrive-home-sample-newsletter-top-footer-component"
    >
      <p className="thrive-past-event-title m-0 p-0 pb-4 text-center font-interBold text-2xl text-white">
        Enjoy this newsetter?
      </p>

      <div className="flex items-center justify-center gap-4 md:w-auto lg:flex-col">
        <div className="text-center">
          <a
            href={linksInHomeNewsletterTopFooter.yeahResponse.to}
            target="_blank"
            rel="noreferrer"
          >
            <img src={yeahIcon} alt="Yeah newsletter" />
          </a>

          <p>Yeah</p>
        </div>

        <div className="text-center">
          <a
            href={linksInHomeNewsletterTopFooter.nahResponse.to}
            target="_blank"
            rel="noreferrer"
          >
            <img src={nahIcon} alt="Nah newsletter" />
          </a>

          <p>Nah</p>
        </div>
      </div>

      <p className="pt-4 text-center">
        Invite your friends to join the Thrive community.
      </p>

      <p className="pt-2 text-center">
        Simply send them this link:{" "}
        <a
          href={linksInHomeNewsletterTopFooter.thriveLink.to}
          target="_blank"
          className="border-b-2 border-green-400"
          rel="noreferrer"
        >
          {"bt.sg/thrive"}
        </a>
      </p>
    </Container>
  );
}
