import { RouteFactory } from "@app/routePaths";
import GarageLogo from "@assets/newsletter/newsletter-garage-logo.png";

export default function NewsletterSampleHeader(): React.ReactElement {
  return (
    <>
      <div className="m-0 mx-auto box-border w-full max-w-4xl p-0">
        <div
          className="m-0 box-border bg-gray-850 !bg-cover bg-no-repeat px-2.5 pb-5 pt-12"
          style={{
            background:
              'url("https://static1.businesstimes.com.sg/s3fs-public/newsletter-garage-bg.png")',
          }}
        >
          <a
            href={RouteFactory.section("startups-tech/startups")}
            target="_blank"
            rel="noopener noreferrer"
            className="block text-center no-underline"
          >
            <img
              className="banner-logo relative mx-auto block h-auto max-w-5/10"
              src={GarageLogo}
              height="50"
              width="110"
              alt="Newsletter Logo"
            />
          </a>
        </div>

        <div className="m-0 box-border p-5 text-center">
          <span className="tracking-wider font-arial text-4xs font-bold leading-4 text-gray-515">
            MON, JUL 8, 2024
          </span>
        </div>
      </div>
    </>
  );
}
