import cx from "classnames";

export type RowProps = {
  rootClassName?: string;
  children?: React.ReactElement | React.ReactElement[];
};

export default function Row(props: RowProps): React.ReactElement {
  const { rootClassName, children, ...rest } = props;

  return (
    <div
      data-component="component-row"
      className={cx(rootClassName, "-mx-3 flex flex-wrap")}
      {...rest}
    >
      {children}
    </div>
  );
}
