import SearchTrigger from "@components/Header/SearchTrigger/SearchTrigger";
import IndividualSideMenuSection from "@components/Header/SideMenu/IndividualSideMenuSection";
import type { NavigationA } from "@components/Header/types";
import Icon from "@components/Icon/Icon";
import { useGetHamburgerList } from "@hooks/useGetHamburgerList";
import { OKTAUserType } from "@store/useOKTAUserStore";
import {
  mySPHOpenLogin,
  mySPHOpenLogout,
  mySPHOpenSignUp,
} from "@util/helpers";
import cx from "classnames";

interface SideMenuProps {
  data: NavigationA;
  isSideMenuOpen: boolean;
  handleOnClose: () => void;
}

export default function SideMenu({
  data,
  handleOnClose,
}: SideMenuProps): React.ReactElement {
  return (
    <SideMenuWrapper>
      <>
        <SideMenuHeader handleOnClose={handleOnClose} />
        <SideMenuNavItems headerData={data} />
      </>
    </SideMenuWrapper>
  );
}
interface SideMenuWrapperProps {
  children: React.ReactElement;
}
function SideMenuWrapper({
  children = <></>,
}: SideMenuWrapperProps): React.ReactElement | null {
  return (
    <div
      data-testid="side-menu-wrapper"
      className="min-h-screen bg-white pb-12 lg:pb-0"
    >
      {children}
    </div>
  );
}
interface SideMenuHeaderProps {
  handleOnClose: () => void;
}

function SideMenuHeader({ handleOnClose }: SideMenuHeaderProps) {
  return (
    <div className="flex items-center gap-4 bg-white px-8 py-4 lg:hidden">
      <div className="w-full flex-1 items-center rounded-[2px] border border-gray-175 bg-gray-175">
        <SearchTrigger />
      </div>
      <div
        data-testid="side-menu-header-close-icon"
        className={cx(
          "flex cursor-pointer items-center justify-center opacity-100 duration-300",
          "lg:hover:opacity-75",
          "block lg:hidden"
        )}
        onClick={handleOnClose}
      >
        <Icon type="close" fill="#2b2b2b" size={36} />
      </div>
    </div>
  );
}

interface SideMenuNavItemsProps {
  headerData?: NavigationA;
}

function SideMenuNavItems({ headerData }: SideMenuNavItemsProps) {
  const hamburgerList = useGetHamburgerList(headerData || []);
  return (
    <nav className="flex flex-col bg-white">
      {hamburgerList?.map((section, index) => {
        return (
          <IndividualSideMenuSection
            key={`side-menu-${index}`}
            menuItems={section}
          />
        );
      })}
    </nav>
  );
}
interface RegisterAccountBlockProps {
  OKTAUserInfo: OKTAUserType;
}

export function RegisterAccountBlock({
  OKTAUserInfo,
}: RegisterAccountBlockProps) {
  const displayName = OKTAUserInfo?.display_name;
  const email = OKTAUserInfo?.loginid;

  const AccountInfoDisplay = () => {
    return (
      <div data-testid="AccountInfoDisplay">
        <div className="mb-3 font-public-sans text-base font-bold text-gray-900">
          Welcome, {displayName}
        </div>
        <div className="mb-3 border-b border-gray-450 pb-3 font-public-sans text-base font-medium text-gray-900">
          {email}
        </div>
        <div
          className={cx(
            "font-poppins font-semibold text-gray-850",
            "lg:hover:cursor-pointer"
          )}
          onClick={mySPHOpenLogout}
        >
          LOG OUT
        </div>
      </div>
    );
  };

  const RegisterAccountDisplay = () => {
    return (
      <div data-testid="RegisterAccountDisplay">
        <div className="mb-6 font-poppins text-base text-gray-650">
          Create a free account with Business Times for seamless access across
          SPH Media products.
        </div>
        <div className="flex items-center font-poppins font-semibold text-black">
          <div
            className={cx(
              "rounded-full border border-gray-525 bg-gray-100 px-6 py-1.5 text-base duration-300",
              "lg:hover:cursor-pointer lg:hover:bg-gray-300"
            )}
            onClick={mySPHOpenSignUp}
          >
            REGISTER
          </div>
          <div
            className={cx("ml-1 border px-6 py-1.5", "lg:hover:cursor-pointer")}
            onClick={mySPHOpenLogin}
          >
            LOGIN
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-250 px-6 py-4">
      {displayName ? <AccountInfoDisplay /> : <RegisterAccountDisplay />}
    </div>
  );
}
