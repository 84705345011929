import { useRef } from "react";
import {
  AdvertisementTypeEnum,
  NewsletterArchiveAdKeywordsEnums,
} from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import {
  BIGMONEY_SUBSCRIBE_CTA,
  NEWSLETTER_PAGE_LINK,
} from "@components/Newsletter/constants";
import {
  Newsletter,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@hooks/useWindowSize";
import ArchiveNewsletterForm from "@pages/Article/components/ArticleNewsletter/ArchiveNewsletterForm";
import NewsletterContributor, {
  NewsletterContributorProps,
} from "@pages/Newsletter/Archive/components/NewsletterContributor";
import SocialShare from "@pages/Newsletter/Archive/components/SocialShare";
import { NewsletterSampleIssueFactory } from "@pages/Newsletter/Archive/util/helpers";
import { ENVIRONMENT } from "@util/constant";
import { cn, getEnvironmentDomain, GoogleAdsSlotFactory } from "@util/helpers";

export type NewsletterArchiveHeaderProps = {
  newsletterTitle?: string;
  newsletterDescription?: string;
  newsletterContributors?: NewsletterContributorProps[];
  signUpNL: Newsletter;
  newsletterTime?: string;
  newsletterRoute: string;
  newsletterAdKeyword: NewsletterArchiveAdKeywordsEnums;
  isBigMoney?: boolean;
  isSubscriber?: boolean;
  divRef?: React.RefObject<HTMLDivElement>;
  displayNewsletterForm?: boolean;
};
export default function NewsletterArchiveHeader({
  newsletterTitle,
  newsletterDescription,
  newsletterContributors = [],
  signUpNL,
  newsletterTime,
  isBigMoney = false,
  newsletterRoute,
  newsletterAdKeyword,
  isSubscriber = false,
  divRef,
  displayNewsletterForm,
}: NewsletterArchiveHeaderProps): React.ReactElement | null {
  const divtRef = useRef<HTMLDivElement>(null);
  const { isScreenLG } = useWindowSize();

  const scrollToElement = () => {
    if (divRef?.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const baseUrl = getEnvironmentDomain(ENVIRONMENT);
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-2/3 lg:pr-12">
          <div className="pb-6 font-medium">
            <a
              className="font-poppins text-base text-gray-850 no-underline"
              href={NEWSLETTER_PAGE_LINK}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                size="xs"
                width={15}
                height={12}
                className="pr-2"
              />
              All newsletters
            </a>
          </div>

          <div className="flex flex-row">
            <span className="mb-2 inline-block bg-gray-250 px-2 py-1 font-poppins text-xs font-light uppercase text-gray-850">
              {newsletterTime}
            </span>

            {isBigMoney ? (
              <span className="mb-2 ml-2 block rounded-sm border border-black p-1 font-public-sans text-xs font-normal lg:hidden">
                Subscriber only
              </span>
            ) : null}
          </div>

          <div className="flex flex-wrap items-center">
            <h1 className="mb-2 font-lct text-10xl font-bold !leading-[43px] text-gray-850 lg:text-[3.25rem] lg:!leading-[62px]">
              {newsletterTitle}
            </h1>

            {isBigMoney ? (
              <span className="my-1 ml-6 mt-5 hidden rounded-sm border border-black p-1 font-public-sans text-xs font-normal leading-4 lg:block">
                Subscriber only
              </span>
            ) : null}
          </div>

          <p className="mb-0 font-public-sans text-lg font-normal text-gray-850 lg:text-base">
            {newsletterDescription}
          </p>

          {isBigMoney ? (
            <div className="fixed bottom-0 left-0 right-0 z-10 border-t-4 border-gray-850 md:static md:border-none">
              {!isSubscriber ? (
                <div className="mt-0 bg-gray-150 md:mb-8 md:mt-5">
                  <p className="px-5 pb-12 pt-9 font-public-sans text-base font-normal md:px-0 md:pb-5 md:pt-4">
                    <a
                      target="_blank"
                      className="text-base font-semibold underline"
                      href={BIGMONEY_SUBSCRIBE_CTA}
                      rel="noreferrer"
                    >
                      Subscribe to BT
                    </a>
                    &nbsp; to sign up to this newsletter.
                  </p>
                </div>
              ) : signUpNL ? (
                <div className="bg-gray-150 px-5 md:mt-2 md:bg-transparent md:px-0">
                  <ArchiveNewsletterForm
                    testId="archive-newsletter-form"
                    newsletter={signUpNL}
                  />
                </div>
              ) : null}
            </div>
          ) : null}

          {signUpNL && displayNewsletterForm ? (
            <div
              className={cn({
                "fixed bottom-0 left-0 right-0 z-10 border-t-4 border-gray-850 md:static md:border-none":
                  !isScreenLG,
              })}
            >
              <div className="bg-gray-150 px-5 md:mt-2 md:bg-transparent md:px-0">
                <ArchiveNewsletterForm
                  testId="archive-newsletter-form"
                  newsletter={signUpNL}
                />
              </div>
            </div>
          ) : null}

          <div
            className={cn(
              signUpNL.type == NEWSLETTER_TYPE.GARAGE ? "pt-14" : "pt-2"
            )}
          >
            {newsletterContributors.map((contributor, index) => (
              <NewsletterContributor
                key={index}
                imageSrc={contributor.imageSrc}
                quote={contributor.quote}
                name={contributor.name}
              />
            ))}
          </div>

          <div>
            <SocialShare
              shareUrl={baseUrl + newsletterRoute}
              rootClassName="hidden lg:block border-t border-gray-250"
            />
          </div>
        </div>

        <div className="flex w-full flex-col pt-2 lg:w-1/3">
          <div className="relative hidden lg:block">
            <NewsletterSampleIssueFactory
              divtRef={divtRef}
              newsletter={signUpNL?.type}
              rootClassName="w-[300px] h-[555px] mx-auto"
            />
            <div className="absolute bottom-0 left-12 mb-2 w-7/10 bg-nlArchiveGradient pb-4 pt-20"></div>
          </div>

          <span
            onClick={scrollToElement}
            className="mb-10 block cursor-pointer font-poppins text-lg font-medium lg:hidden"
          >
            Preview sample issue
          </span>

          <Advertisement
            rootClassName="bg-stripes mt-12 py-4 mb-16"
            adUnitProps={{
              type: AdvertisementTypeEnum.IMU1,
              slot: GoogleAdsSlotFactory.imu1(newsletterAdKeyword),
            }}
          />
        </div>
      </div>
    </>
  );
}
