import { useState } from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import shareIcon from "@assets/icon-share.svg";
import Button from "@components/Button/Button";
import {
  ActionType,
  ButtonSize,
  IconName,
  SocialMediaType,
} from "@components/Button/types";
import {
  faFacebookF,
  faLinkedinIn,
  faWhatsapp,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPaperPlane,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@util/helpers";

const buttonClass =
  "shrink-0 rounded-full !bg-gray-250 w-10 h-10 hover:opacity-70";

type ArticleSocialSharing = {
  articleTitle: string;
  shareUrl?: string;
};

//TODO: Will change this when article v2 live
export default function ArticleSocialSharing({
  articleTitle,
  shareUrl = "",
}: ArticleSocialSharing): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleHover = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {shareUrl ? (
        <div
          className="relative cursor-pointer"
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          data-testid="article-social-sharing-component"
        >
          <img
            src={shareIcon}
            width={40}
            height={40}
            className="hover:opacity-70"
            alt="Share this article."
          />
          {isOpen ? (
            <div
              className={cn(
                "absolute right-0 top-full z-50 flex space-x-2 border-t border-gray-250 bg-white px-4 py-3 shadow-lg",
                "xl:right-[unset] xl:-translate-x-1/2"
              )}
            >
              <FacebookShareButton url={shareUrl} className={buttonClass}>
                <FontAwesomeIcon icon={faFacebookF} />
              </FacebookShareButton>

              <TwitterShareButton
                url={shareUrl}
                title={articleTitle}
                className={buttonClass}
              >
                <FontAwesomeIcon icon={faXTwitter} />
              </TwitterShareButton>

              <WhatsappShareButton
                url={shareUrl}
                title={articleTitle}
                className={buttonClass}
              >
                <FontAwesomeIcon icon={faWhatsapp} size="lg" />
              </WhatsappShareButton>

              <LinkedinShareButton
                url={shareUrl}
                title={articleTitle}
                className={buttonClass}
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </LinkedinShareButton>

              <TelegramShareButton
                url={shareUrl}
                title={articleTitle}
                className={buttonClass}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </TelegramShareButton>

              <EmailShareButton
                url={shareUrl}
                subject="Sharing from The Business Times"
                body={articleTitle}
                separator=" - "
                className={buttonClass}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </EmailShareButton>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
}

export function ArticleSocialSharingHeader({
  articleTitle,
  shareUrl = "",
}: ArticleSocialSharing): React.ReactElement {
  const shareOnClick = () => {
    if (typeof navigator.share === "function" && shareUrl) {
      navigator.share({
        text: articleTitle, // Current page title
        url: shareUrl, // Current page URL
      });
    }
  };

  return (
    <>
      {shareUrl ? (
        <div
          className="group relative cursor-pointer"
          data-testid="article-social-sharing-component"
        >
          <div
            className="flex h-[36px] w-[36px] items-center justify-center gap-[6px]
           rounded-full !border !border-solid !border-gray-175 hover:opacity-70 min-[1170px]:mr-3 xl:hidden "
            onClick={shareOnClick}
          >
            <FontAwesomeIcon
              icon={faShare}
              className="text-gray-850"
              color="#2b2b2b"
            />
          </div>

          <div
            className={cn(
              "invisible absolute right-0 top-full z-50 flex gap-[6px] border-t border-gray-250 bg-white px-4 py-4 shadow-lg group-hover:opacity-100 ",
              "xl:visible xl:relative xl:flex xl:border-none xl:px-0 xl:py-0 xl:shadow-none",
              { "group-hover:visible": typeof navigator.share !== "function" }
            )}
          >
            <Button
              key={`social-link-button-facebook`}
              icon={IconName.facebook}
              whichSocialMedia={SocialMediaType.facebook}
              actionType={ActionType.shareButton}
              buttonSize={ButtonSize.small}
              className="m-0"
              imgAlt={articleTitle}
              title={articleTitle}
              shareUrl={shareUrl}
            />

            <Button
              key={`social-link-button-twitter`}
              icon={IconName.twitter}
              whichSocialMedia={SocialMediaType.twitter}
              actionType={ActionType.shareButton}
              buttonSize={ButtonSize.small}
              className="m-0"
              imgAlt={articleTitle}
              title={articleTitle}
              shareUrl={shareUrl}
            />

            <Button
              key={`social-link-button-whatsapp`}
              icon={IconName.whatsapp}
              whichSocialMedia={SocialMediaType.whatsapp}
              actionType={ActionType.shareButton}
              buttonSize={ButtonSize.small}
              className="m-0"
              imgAlt={articleTitle}
              title={articleTitle}
              shareUrl={shareUrl}
            />

            <Button
              key={`social-link-button-linkedin`}
              icon={IconName.linkedin}
              whichSocialMedia={SocialMediaType.linkedin}
              actionType={ActionType.shareButton}
              buttonSize={ButtonSize.small}
              className="m-0"
              imgAlt={articleTitle}
              title={articleTitle}
              shareUrl={shareUrl}
            />

            <Button
              key={`social-link-button-telegram`}
              icon={IconName.telegram}
              whichSocialMedia={SocialMediaType.telegram}
              actionType={ActionType.shareButton}
              buttonSize={ButtonSize.small}
              className="m-0"
              imgAlt={articleTitle}
              title={articleTitle}
              shareUrl={shareUrl}
            />

            <Button
              key={`social-link-button-email`}
              icon={IconName.email}
              whichSocialMedia={SocialMediaType.email}
              actionType={ActionType.shareButton}
              buttonSize={ButtonSize.small}
              className="m-0"
              imgAlt={articleTitle}
              title={articleTitle}
              shareUrl={shareUrl}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
