import FooterBottom from "@components/Footer/FooterComponents/FooterBottom";
import FooterTop from "@components/Footer/FooterComponents/FooterTop";

export default function Footer(): React.ReactElement {
  return (
    <div
      data-testid="footer-container"
      // Dont remove this id attribute, needed by Queryly
      id="footer-container"
      className="antialiased"
    >
      <FooterTop />
      <FooterBottom />
    </div>
  );
}
