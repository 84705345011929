import React, { useState } from "react";
import { RouteFactory } from "@app/routePaths";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import { getFormattedCaaSImageUrl } from "@components/OptimisedImage/helper";
import { SanitizedPulseData } from "@pages/Pulse/utils/types";

import PulseMobileCarousel from "./components/PulseMobileCarousel/PulseMobileCarousel";

export type PulseMobilePageProps = {
  sortedPulseData: SanitizedPulseData[];
};

export default function PulseMobilePage({
  sortedPulseData,
}: PulseMobilePageProps): React.ReactElement {
  const slideId = sortedPulseData.length > 0 ? sortedPulseData[0].id : "";

  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideId, setCurrentSlideId] = useState(slideId);

  const currentSlideData = sortedPulseData.find(
    (article) => article.id === currentSlideId
  );

  const defaultFilters: CaaSImageFilters[] = [{ w: 800, dpr: 1, f: "webp" }];

  const imgSrcSets = defaultFilters.map(({ breakpoint, ...imageFilters }) => {
    return getFormattedCaaSImageUrl(currentSlideData?.media, {
      ...imageFilters,
    });
  });

  if (sortedPulseData.length === 0) return <></>;
  return (
    <div data-testid="pulse-mobile-page" className="h-full w-full">
      <MetaTags
        title={renderPageTitleV2({
          kind: "pulse",
          sectionName: "Pulse",
          title: currentSlideData ? currentSlideData.title : undefined,
        })}
        description={
          currentSlideData
            ? currentSlideData.standfirst || currentSlideData.summaries[0]
            : "The Business Times - Find latest business & financial news including analysis and opinion on top business stories, stock markets in Singapore, Asia-Pacific & global market news and more at The Business Times."
        }
        ogType="website"
        slug={RouteFactory.pulse}
        imageUrl={
          currentSlideData && currentSlideData.media ? imgSrcSets[0] : undefined
        }
      />

      <PulseMobileCarousel
        articles={sortedPulseData}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
        setCurrentSlideId={setCurrentSlideId}
      />
    </div>
  );
}
