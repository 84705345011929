import { ReactElement } from "react";
import cx from "classnames";

export type SubscriberFlagProps = {
  rootClassName?: string;
  label?: string;
};

/**
 * SubscriberFlag
 * Description: The subscriber flag of the article
 * @param {SubscriberFlagProps} props
 * @returns {ReactElement}
 *
 */
export default function SubscriberFlag({
  rootClassName,
  label,
}: SubscriberFlagProps): ReactElement {
  return (
    <div
      className={cx(
        "relative mb-1 inline-block pl-3 font-poppins text-4xs uppercase",
        "before:absolute before:left-0 before:top-1 before:mr-[5px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-orange-300",
        rootClassName
      )}
      data-testid="subscriber-flag-component"
    >
      {label || "Subscribers"}
    </div>
  );
}
