import { useState } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import LoadMore from "@components/LoadMore/LoadMore";
import LifestyleNews from "@pages/Section/components/Lifestyle/LifestyleNews";
import axios, { HttpStatusCode } from "axios";

type LifestyleLuxeArticlesProps = {
  articles: SanitizedSectionArticle[];
  articleIds: string[];
};

const PAGE_SIZE = 12;

export default function LifestyleLuxeArticles({
  articles,
  articleIds,
}: LifestyleLuxeArticlesProps): React.ReactElement {
  const [moreStories, setMoreStories] = useState<SanitizedSectionArticle[]>([]);
  const loadMore = async () => {
    const page = moreStories.length / PAGE_SIZE + 1;

    const response = await axios.post<SanitizedSectionArticle[]>(
      "/_plat/api/v1/more-stories",
      {
        category: "lifestyle_bt-luxe",
        parentCategory: "lifestyle",
        size: PAGE_SIZE,
        page,
        excludeIds: articleIds,
      }
    );

    if (response.status !== HttpStatusCode.Ok) return;

    setMoreStories(response.data);
  };

  return (
    <>
      <LifestyleNews
        data={[...articles, ...moreStories]}
        parentCategory={"lifestyle"}
        childCategory={"lifestyle_bt-luxe"}
      />

      <LoadMore loadText="MORE STORIES" onLoadMore={loadMore} />
    </>
  );
}
