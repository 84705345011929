import { Fragment } from "react";
import type {
  Element,
  EmbedCode,
  SanitizedRelatedStory,
  Section,
  seeAlso,
  Storyline,
  Tag,
} from "@app/types/Cue";
import { Type } from "@app/types/enums";
import { ExternalLink, Note } from "@blocks/Blocks";
import DynamicImu from "@components/Advertisement/DynamicImu";
import CASBlock from "@components/CASBlock";
import DelayedComponentsLocationChange from "@components/DelayedComponents/DelayedComponentsLocationChange";
import { useArticleElements } from "@hooks/useArticleElements";
import ArticlePurchaseLink from "@pages/Article/components/ArticlePurchaseLink/ArticlePurchaseLink";
import { Versions } from "@pages/Article/components/ArticlePurchaseLink/types";
import AnnotationComponentStockPicker from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentStockPicker";
import { BrightcoveVideo } from "@pages/Article/components/StoryElements/BrightcoveVideo";
import { getResolvedArticleElements } from "@pages/Article/utils/helpers";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { CDP_PAYWALL_MODULE_ID } from "@util/constant";
import { cn } from "@util/helpers";

import ArticleInlineImage from "./StoryElements/ArticleInlineImage";
import Box from "./StoryElements/Box";
import DataWrapper from "./StoryElements/DataWrapper";
import DynamicIframeWrapper from "./StoryElements/DynamicIframe/DynamicIframeWrapper";
import Embed from "./StoryElements/Embed";
import Graphic from "./StoryElements/Graphic";
import ListBulleted from "./StoryElements/ListBulleted";
import ListNumbered from "./StoryElements/ListNumbered";
import OmnyPodcast from "./StoryElements/OmnyPodcast";
import ParagraphWrapper from "./StoryElements/ParagraphWrapper";
import PodcastWrapper from "./StoryElements/PodcastWrapper";
import PullQuote from "./StoryElements/PullQuote";
import SubHeading from "./StoryElements/SubHeading";
import Table from "./StoryElements/Table";
import YouTubeVideo from "./StoryElements/YouTubeVideo";

type PurchaseLinkProps = {
  title: string;
  publishedDate: string;
  isDisplayed: boolean;
};

export type ArticleParagraphsProps = {
  className?: string;
  id: string;
  elements: Element[];
  seeAlso?: seeAlso;
  embedCodes?: EmbedCode[];
  displayRelatedLinksAndNewsletterSignupForm?: boolean;
  keywords?: Tag[];
  sections?: Section[];
  index?: number;
  gsChannels?: string;
  storyline: Storyline;
  isPremium: boolean;
  isBoxWrapped?: boolean;
  isGifted?: boolean;
  adsWrapperClass?: string;
  isBrandedContent?: boolean;
  readMoreArticle?: SanitizedRelatedStory[];
  fullScreenAds?: boolean;
  displayNewVersionNewsletter?: boolean;
  displayArticleReadMore?: boolean;
  elementsClass?: string;
  forceHeadingCss?: boolean;
  displayPurchase?: PurchaseLinkProps;
  isPreview?: boolean;
  displayStyleForDefaultLayout?: boolean;
  urlPath?: string;
};

export default function ArticleParagraphs({
  id,
  className,
  elements = [],
  seeAlso,
  displayRelatedLinksAndNewsletterSignupForm = false,
  keywords = [],
  sections = [],
  index = 0,
  gsChannels = "no_analysis",
  storyline,
  isPremium,
  isBoxWrapped = false,
  isGifted = false,
  adsWrapperClass,
  isBrandedContent = false,
  readMoreArticle = [],
  fullScreenAds = false,
  elementsClass,
  forceHeadingCss,
  displayPurchase,
  isPreview = false,
  displayStyleForDefaultLayout,
  urlPath,
}: ArticleParagraphsProps): React.ReactElement | null {
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);
  const isSubscriber = OktaUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  const fetchedElements = useArticleElements(id, elements);
  const articleElements = getResolvedArticleElements(
    storyline,
    fetchedElements,
    isPremium,
    isSubscriber,
    isGifted,
    isPreview
  );

  let paragraphCount = 1;

  const mainSection = sections?.[0];
  const totalParagraphs = articleElements.filter(
    (element) => element.type === Type.Paragraph
  ).length;

  return (
    <div className={className} data-testid="article-paragraphs-component">
      <div
        className={cn(
          "relative",
          {
            "[&>*:last-child]:relative [&>*:last-child]:after:absolute [&>*:last-child]:after:inset-0 [&>*:last-child]:after:bg-gradient-to-t [&>*:last-child]:after:from-white [&>*:last-child]:after:to-transparent":
              isPremium && !isSubscriber && !isGifted && !isPreview,
          },
          elementsClass
        )}
      >
        {articleElements.map((element) => {
          switch (element.type) {
            case Type.SubHead:
              return (
                <SubHeading
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                  forceHeadingCss={forceHeadingCss}
                />
              );

            case Type.Paragraph:
              return (
                <ParagraphWrapper
                  key={element.id}
                  {...{
                    element,
                    paragraphCount: paragraphCount++,
                    index,
                    id,
                    keywords,
                    gsChannels,
                    displayRelatedLinksAndNewsletterSignupForm,
                    seeAlso,
                    sections,
                    isBoxWrapped,
                    totalParagraphs,
                    adsWrapperClass,
                    isBrandedContent,
                    readMoreArticle,
                    fullScreenAds,
                    elementsClass,
                    displayStyleForDefaultLayout,
                  }}
                />
              );

            case Type.PodcastWrapper:
              return (
                <PodcastWrapper
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Image:
              return (
                <Fragment key={element.id}>
                  {element.relation ? (
                    <ArticleInlineImage
                      fields={element.fields}
                      relation={element.relation}
                      className={cn("mb-6", elementsClass)}
                    />
                  ) : null}
                </Fragment>
              );

            case Type.BrightcoveVideo:
              return (
                <BrightcoveVideo
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.DataWrapper:
              return (
                <DataWrapper
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.DynamicIframeWrapper:
              return (
                <DynamicIframeWrapper
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Graphic:
              return (
                <Graphic
                  attachment={element.relation}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Note:
              return (
                <Note
                  key={element.id}
                  value={element?.fields?.map((f) => f.value).join("\n")}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.GroupedRelation:
              return (
                <ExternalLink
                  key={element.id}
                  // @ts-expect-error incomplete typedef
                  src={element.children.map((group: unknown) => {
                    const rel = articleElements?.find?.(
                      (el) => el.id === group
                    );

                    return rel?.relation?.id;
                  })}
                />
              );

            case Type.Embed:
              return (
                <Embed
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.ListBulleted:
              return (
                <ListBulleted
                  key={element.id}
                  elements={element.reference || []}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.ListNumbered: {
              return (
                <ListNumbered
                  key={element.id}
                  elements={element.reference || []}
                  className={cn("mb-6", elementsClass)}
                />
              );
            }

            case Type.PullQuote:
              return (
                <PullQuote
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Table:
              return (
                <Table
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.HorizontalRow:
              return (
                <hr
                  className={cn("mb-6", elementsClass)}
                  data-story-element={Type.HorizontalRow}
                />
              );

            case Type.YoutubeVideo:
              return (
                <YouTubeVideo
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.OmnyPodcast:
              return (
                <OmnyPodcast
                  key={element.id}
                  fields={element.fields}
                  className={cn("mb-6", elementsClass)}
                />
              );

            case Type.Box:
              return (
                <Fragment key={element.id}>
                  {element.reference && element.children ? (
                    <Box
                      elements={elements}
                      storyline={element.children}
                      className={cn("mb-6", elementsClass)}
                    />
                  ) : null}
                </Fragment>
              );

            case Type.BoxTitle:
              return (
                <p key={element.id} className="mb-6 font-bold">
                  {element.fields?.[0]?.value || ""}
                </p>
              );

            case Type.StockPicker:
              return (
                <AnnotationComponentStockPicker
                  element={element}
                  className="mb-6 inline-block"
                >
                  {element.fields?.[0]?.value || ""}
                </AnnotationComponentStockPicker>
              );

            default:
              return null;
          }
        })}
      </div>

      {!isBoxWrapped ? (
        <DelayedComponentsLocationChange
          waitBeforeShow={1000}
          urlPath={urlPath}
        >
          <CASBlock
            cdpId={CDP_PAYWALL_MODULE_ID}
            index={index}
            testId={`article${index}`}
          />
        </DelayedComponentsLocationChange>
      ) : null}

      <>
        {displayPurchase?.publishedDate ? (
          <div className="mb-6 border-t border-gray-175">
            <ArticlePurchaseLink
              title={displayPurchase.title}
              publishedDate={displayPurchase.publishedDate}
              versions={Versions.version2}
              displayPurchase={displayPurchase.isDisplayed}
              urlPath={urlPath}
            />
          </div>
        ) : null}
      </>

      {mainSection ? (
        <DynamicImu
          {...{
            cueId: id,
            gsChannels,
            index,
            keywords,
            section: mainSection,
            totalParagraphs,
            adsWrapperClass,
            fullScreenAds,
          }}
        />
      ) : null}
    </div>
  );
}
