import { TestIdClassNameProps } from "@app/types/Common";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type ArticleNewsletterFormSuccessProps = TestIdClassNameProps & {
  children?: React.ReactNode;
};

export const ArticleNewsletterFormSuccess = ({
  testId,
  children,
}: ArticleNewsletterFormSuccessProps) => {
  return (
    <div className="py-10 text-center text-gray-850" data-testid={testId}>
      <FontAwesomeIcon
        size="sm"
        icon={faCheck}
        className="mb-2 aspect-square rounded-full border border-gray-850 p-3"
      />

      <div role="alert" className="text-lg font-semibold last:mb-0">
        {" Thank you! You're now signed up."}
      </div>

      {children}
    </div>
  );
};
