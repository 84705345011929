/**
 * Company: SPHMedia
 * Description: [Brief description of the component]
 */

import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { getFormattedRedirectionUrl } from "@util/helpers";
import cx from "classnames";

export type ReadMoreProps = {
  isAnimated?: boolean;
  articleId: string;
  rootClassName?: string;
  slug?: string | null;
};

/**
 * ReadMore
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function ReadMore({
  isAnimated = false,
  articleId = "",
  rootClassName = "",
  slug,
}: ReadMoreProps): ReactElement {
  // Add component logic and state here

  return (
    <>
      <Link
        className={cx(
          "border-x-none relative inline-block w-[135px] whitespace-nowrap border-y-2 border-verticals-lifestyle px-3 py-1 text-center text-3xs font-bold leading-none tracking-[3px] text-verticals-lifestyle transition-[letter-spacing]",
          rootClassName,
          { "hover:border-x-2 hover:tracking-[0px]": isAnimated }
        )}
        to={
          slug
            ? getFormattedRedirectionUrl(slug)
            : RouteFactory.article(articleId)
        }
        target="_blank"
        rel="noopener"
      >
        READ MORE
      </Link>
    </>
  );
}
