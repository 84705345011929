import { Author, ContributorProfile } from "@app/types/Cue";

import Byline from "./Byline";
import { getAllProfiles } from "./helpers";

export type BylineForCardsProps = {
  rootClassName?: string;
  authors?: Author[];
  contributors?: ContributorProfile[];
  imageWidth?: number;
  imageHeight?: number;
  size?: string;
};

export default function BylineForCards({
  rootClassName,
  authors,
  contributors,
  imageWidth,
  imageHeight,
  size,
}: BylineForCardsProps): React.ReactElement {
  const profiles = getAllProfiles(authors, contributors);

  return (
    <div className={rootClassName} data-testid="byline-for-cards-component">
      <Byline
        profiles={profiles}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        bylineNameRootClassName={size}
      />
    </div>
  );
}
