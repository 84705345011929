/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HttpStatusCode,
} from "axios";

type AxiosHttpResponseStatusCode = `${Extract<
  HttpStatusCode,
  number
>}` extends `${infer N extends number}`
  ? N
  : never;

// Default HTTP headers
const DEFAULT_HEADERS: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
};
// AWS Gateway API endpoint URL to query Opensearch via the "opensearch-search" Lambda.
const OPENSEARCH_SEARCH_API_URL = import.meta.env.VITE_OS_ENDPOINT as string;
// The key-value pair for the AWS Gateway API endpoint token.
const OPENSEARCH_SEARCH_API_TOKEN_KVPAIR: Record<string, string> = {
  "x-api-key": import.meta.env.VITE_OS_API_TOKEN as string,
};

/**
 *
 * @class Http
 * @description Class representing an Axios HTTP client.
 */
class Http {
  private instance: AxiosInstance;

  /**
   *
   * @constructor
   * @description Creates an Axios HTTP client. If no configurations are provided,
   *  the default configurations for our AWS Gateway API endpoint to query Opensearch
   *  via the "opensearch-search" Lambda will be used. Otherwise, the passed-in Axios configurations
   *  will be passed down to the initHttp() function to initialise an Axios HTTP client
   *  with the desired configurations.
   * @param {AxiosRequestConfig} config - The Axios configuration object.
   */
  constructor(config?: AxiosRequestConfig) {
    this.instance = this.initHttp(config);
  }

  /**
   *
   * @function http
   * @public
   * @description Gets the Axios instance.
   * @return {AxiosInstance} The Axios instance.
   */
  public get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  /**
   *
   * @function initHttp
   * @description This function helps to create and initialise an Axios instance with
   *  the desired configurations. If no configurations are provided,
   *  the default configurations for our AWS Gateway API endpoint to query Opensearch
   *  via the "opensearch-search" Lambda will be used. Otherwise, an Axios HTTP client
   *  will be created with the passed-in configurations.
   * @param {AxiosRequestConfig} config - The Axios configuration object.
   * @returns An Axios client instance.
   */
  initHttp(config?: AxiosRequestConfig): AxiosInstance {
    if (!config) {
      config = {
        baseURL: OPENSEARCH_SEARCH_API_URL,
        headers: {
          ...DEFAULT_HEADERS,
          ...OPENSEARCH_SEARCH_API_TOKEN_KVPAIR,
          ...this.getJWT(),
        },
      };
    }

    const http = axios.create(config);

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        return this.handleError(error);
      }
    );

    return http;
  }

  getJWT(): { Authorization: string } {
    if (typeof window === "undefined") return { Authorization: "" };

    const oktaAuth = window.localStorage.getItem(
      "okta-token-storage"
    ) as string;

    const jwt = JSON.parse(oktaAuth)?.accessToken?.accessToken ?? "";

    return { Authorization: jwt };
  }

  request<T = unknown, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  private handleError(error: AxiosError) {
    if (error.response?.status) {
      const { status } = error;

      switch (status as AxiosHttpResponseStatusCode) {
        case HttpStatusCode.InternalServerError: {
          // Add custom logic to handle InternalServerError error if required.
          break;
        }
        case HttpStatusCode.Forbidden: {
          // Add custom logic to handle Forbidden error if required.
          break;
        }
        case HttpStatusCode.Unauthorized: {
          // Add custom logic to handle Unauthorized error if required.
          break;
        }
        case HttpStatusCode.TooManyRequests: {
          // Add custom logic to handle TooManyRequests error if required.
          break;
        }
        default: {
          // Add custom logic to handle other error(s) if required.
          break;
        }
      }
    }

    return Promise.reject(error);
  }
}

// To expose an Axios HTTP client with the default configurations for OS Lambda.
export const http = new Http();
// To expose an Axios HTTP client with the default configurations for OS Lambda.
export const httpWithS3 = new Http({
  baseURL: import.meta.env.VITE_S3_ENDPOINT,
  headers: DEFAULT_HEADERS,
});
// To expose an Axios HTTP client with custom configurations.
export const createHttpInstance = (config: AxiosRequestConfig) => {
  return new Http(config);
};
