import { useCallback, useEffect } from "react";
import { AdTargetingType } from "@components/Advertisement/AdUnit";
import useLoadedTags from "@hooks/useLoadedTags";
import useScript from "@hooks/useScript";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import {
  DEFAULT_GOOGLE_SCRIPT,
  DEFAULT_GOOGLE_SCRIPT_ID,
} from "@util/constant";

type GlobalAdSettingProps = {
  targeting?: AdTargetingType[] | AdTargetingType;
};

export default function useGlobalAdSetting({
  targeting,
}: GlobalAdSettingProps = {}) {
  const { isScriptReady: isGPTScriptReady } = useScript({
    id: DEFAULT_GOOGLE_SCRIPT_ID,
    src: DEFAULT_GOOGLE_SCRIPT,
    checkScriptIsLoaded: () => {
      return typeof window.googletag !== "undefined" && !!googletag.apiReady;
    },
  });

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  const {
    isPrestitialEnabled,
    topOverlayImpressions,
    topOverlayValidity,
    isOutbrainEnabled,
  } = useLoadedTags();

  console.log("before handleGlobalAdsetting called"); // eslint-disable-line no-console
  const handleGlobalAdsetting = useCallback(() => {
    // Make sure that googletag.cmd exists.
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.googletag.slots = window.googletag.slots || {};
    // Correct: Queueing the callback on the command queue.
    googletag.cmd.push(function () {
      console.log("inside function", "check usertype", OKTAUserInfo?.usertype); // eslint-disable-line no-console
      switch (OKTAUserInfo?.usertype) {
        case OKTAUserTypeEnum.SUBSCRIBER:
          googletag.pubads().setTargeting("subscriber", "Y");
          break;

        case OKTAUserTypeEnum.REGISTERED:
          googletag.pubads().setTargeting("subscriber", "R");
          break;

        default:
          googletag.pubads().setTargeting("subscriber", "N");
          break;
      }

      [targeting].flat().forEach((target) => {
        target && googletag.pubads().setTargeting(target.key, target.value);
      });

      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
    });
  }, [targeting, OKTAUserInfo]);

  useEffect(() => {
    console.log("check isGPTScriptReady", isGPTScriptReady); // eslint-disable-line no-console
    if (typeof window !== "undefined" && isGPTScriptReady) {
      handleGlobalAdsetting();
    }
  }, [handleGlobalAdsetting, isGPTScriptReady]);

  console.log("topOverlayImpressions", topOverlayImpressions); // eslint-disable-line no-console
  console.log("isPrestitialEnabled", isPrestitialEnabled); // eslint-disable-line no-console

  return {
    isPrestitialEnabled,
    topOverlayImpressions,
    topOverlayValidity,
    isOutbrainEnabled,
  };
}
