import { Section, Tag } from "@app/types/Cue";
import {
  getNewsletterBySections,
  getNewsletterByTags,
} from "@components/Newsletter/helper";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import GenericNewsletter from "@pages/Article/components/ArticleNewsletter/GenericNewsletter";
import SpecificNewsletter from "@pages/Article/components/ArticleNewsletter/SpecificNewsletter";

export const NewsletterFactory = ({
  sections,
  tags,
  displayNewVersionNewsletter,
}: {
  sections: Section[];
  tags: Tag[];
  displayNewVersionNewsletter?: boolean;
}) => {
  // Get the associated newsletter based on section.
  const newsletterBySection = getNewsletterBySections(sections)?.[0];
  if (newsletterBySection)
    return (
      <SpecificNewsletter
        displayNewVersionNewsletter={displayNewVersionNewsletter}
        newsletter={newsletterBySection}
      />
    );

  // Get the associated newsletter based on tags.
  const newsletterByTag = getNewsletterByTags(tags)?.[0];
  if (newsletterByTag)
    return (
      <SpecificNewsletter
        displayNewVersionNewsletter={displayNewVersionNewsletter}
        newsletter={newsletterByTag}
      />
    );

  // Get the generic newsletters, morning and evening newsletters.
  const genericNewsletters = BT_NEWSLETTERS.filter(
    ({ type }) =>
      type === NEWSLETTER_TYPE.MORNING || type === NEWSLETTER_TYPE.EVENING
  );
  return (
    <GenericNewsletter
      newsletters={genericNewsletters}
      displayNewVersionNewsletter={displayNewVersionNewsletter}
    />
  );
};
