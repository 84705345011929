import { useEffect, useState } from "react";
import { Element } from "@app/types/Cue";
import axios from "axios";

export const useArticleElements = (id: string, elements: Element[]) => {
  const [fetchedElements, setFetchedElements] = useState<Element[]>(elements);

  useEffect(() => {
    const fetchArticleElements = async () => {
      const { data } = await axios.post<Element[]>(
        "/_plat/api/v1/article-elements",
        { id }
      );

      if (data.length === 0) return;

      setFetchedElements(data);
    };

    fetchArticleElements();
  }, [id]);

  return fetchedElements;
};
