import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ChartOptions,
  ChartType,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  TooltipItem,
} from "chart.js/auto";
import dayjs from "dayjs";

import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";

import type { StockHistoricalData, TimeFrame } from "./types";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  TimeSeriesScale,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

const options: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      intersect: false,
      mode: "index",
      callbacks: {
        label: function (tooltipItem: TooltipItem<ChartType>) {
          return "$ " + parseFloat(tooltipItem.formattedValue).toFixed(2);
        },
      },
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: "Closing Price ($)",
      },
      border: {
        display: false,
      },
    },
    x: {
      type: "timeseries",
      offset: true,
      ticks: {
        autoSkip: true,
        autoSkipPadding: 75,
        font: {
          weight: "bold",
        },
        major: {
          enabled: true,
        },
        maxRotation: 0,
        source: "data",
        sampleSize: 500,
      },
    },
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      borderWidth: 2,
    },
  },
};

export default function StockChart({
  stockHistoricalData,
  timeFrame,
}: {
  stockHistoricalData: StockHistoricalData;
  timeFrame: TimeFrame;
}) {
  const stockDataToDisplay = filterStockData(stockHistoricalData, timeFrame);

  const chartData = {
    datasets: [
      {
        data: stockDataToDisplay,
        borderColor: "#027BAD",
        backgroundColor: "#027BAD",
      },
    ],
  };

  return <Chart type="line" options={options} data={chartData} />;
}

type ChartCoord = {
  x: string;
  y: number;
};

const filterStockData = function (
  data: StockHistoricalData, // Endpoint returns data in decreasing order i.e. most recent date first
  unit: TimeFrame = "Days"
) {
  const coordsOnChart: ChartCoord[] = [];
  const keys = Object.keys(data);

  if (unit == "Years") {
    for (let i = 0; i < keys.length; i++) {
      const currentItem = data[i];
      const nextItem = data[i + 1];

      if (nextItem && currentItem) {
        const currentDate = dayjs(currentItem["t"]);
        const nextDate = dayjs(nextItem["t"]);
        const isDateInJanuary = currentDate.month() == 0;
        const isFirstDayOfMonth = currentDate.date() < nextDate.date();

        if (isDateInJanuary && isFirstDayOfMonth) {
          coordsOnChart.push({
            x: currentDate.format("MMM DD, YYYY, h:mm:ss a"),
            y: currentItem["y"],
          });
        }
      }
    }
  } else if (unit == "Months") {
    for (let i = 0; i < keys.length; i++) {
      const currentItem = data[i];
      const nextItem = data[i + 1];

      if (nextItem && currentItem) {
        const currentDate = dayjs(currentItem["t"]);
        const nextDate = dayjs(nextItem["t"]);
        const isNextMonthDifferent = currentDate.month() != nextDate.month();

        if (isNextMonthDifferent) {
          coordsOnChart.push({
            x: currentDate.format("MMM DD, YYYY, h:mm:ss a"),
            y: currentItem["y"],
          });
        }
      }
    }
    coordsOnChart.length = Math.min(coordsOnChart.length, 300);
  } else if (unit == "Days") {
    for (let i = 0; i < Math.min(keys.length, 150); i++) {
      const current = data[i];

      if (current) {
        const currDate = dayjs(current["t"]);

        coordsOnChart.push({
          x: currDate.format("MMM DD, YYYY, h:mm:ss a"),
          y: current["y"],
        });
      }
    }
  }

  return coordsOnChart.reverse();
};
