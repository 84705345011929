import { StockPicker } from "@app/blocks/Blocks";
import { Name } from "@app/types/enums";
import { AnnotationComponentProps } from "@pages/Article/components/StoryElements/Annotation/utils/types";
import { getFieldValue } from "@util/helpers";

export default function AnnotationComponentStockPicker({
  className,
  children,
  element,
}: AnnotationComponentProps): React.ReactElement {
  const stockCode = getFieldValue(element?.fields || [], Name.StockCode);

  return (
    <>
      {typeof stockCode === "string" ? (
        <StockPicker code={stockCode} className={className}>
          {children}
        </StockPicker>
      ) : null}
    </>
  );
}
