import { ArticleDataObject } from "@app/types/Cue";
import chevronLeft from "@assets/icon-chevron-left-gray.svg";
import chevronRight from "@assets/icon-chevron-right-gray.svg";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { cn } from "@util/helpers";
import { truncate } from "lodash-es";

import "@greglaisph/react-splide/css";

export interface PodcastsTopFeaturedCarouselProps {
  data: ArticleDataObject[];
}

export default function PodcastsTopFeaturedCarousel({
  data,
}: PodcastsTopFeaturedCarouselProps): React.ReactElement {
  const cardVariant: CardVariations = {
    image: {
      width: "w-full lg:w-7/12",
      position: "left",
      filters: [
        { breakpoint: "(min-width: 992px)", w: 900, h: 600, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 696, h: 464, dpr: 1, f: "webp" },
        { w: 401, h: 267, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: "w-full lg:w-5/12",
      extraClass: "flex-grow-0 bg-gray-850 p-6 pb-16 lg:pb-8 text-white",
      blurb: {
        extraClass: "mt-3 !leading-normal !block lg:text-base",
      },
    },
    title: {
      size: "text-xl xl:text-3xl",
      color: "text-white",
      extraClass: "font-lct !leading-tight !my-0",
    },
    subscriberLabel: {
      extraClass: "mt-3",
    },
    kicker: {
      color: "text-gray-515",
      extraClass: "uppercase mb-3",
    },
  };

  const options: Options = {
    perPage: 1,
    perMove: 1,
    pagination: false,
    arrows: true,
    snap: true,
    type: "loop",
    autoHeight: true,
  };

  return (
    <div
      className="relative"
      data-testid="podcasts-top-feature-carousel-component"
    >
      <Splide options={options} hasTrack={false}>
        <div
          className={cn(
            "splide__arrows absolute bottom-8 right-[calc(50%_-_2.25rem)] w-full lg:right-0"
          )}
        >
          <button
            className={cn(
              "splide__arrow splide__arrow--prev left-[calc(100%_-_5rem)] bg-transparent lg:left-[calc(100%_-_6rem)]"
            )}
          >
            <div
              className={cn(
                "flex h-full w-full items-center justify-center rounded-full border border-[#B2B2B2]"
              )}
            >
              <img src={chevronLeft} width={18} height={18} />
            </div>
          </button>

          <button
            className={cn(
              "splide__arrow splide__arrow--next right-0 bg-transparent lg:right-6"
            )}
          >
            <div
              className={cn(
                "flex h-full w-full items-center justify-center rounded-full border border-[#B2B2B2]"
              )}
            >
              <img src={chevronRight} width={18} height={18} />
            </div>
          </button>
        </div>

        <SplideTrack>
          {data.map(
            (
              { id, title, media, kicker, sections, paywall, blurb, urlPath },
              index
            ) => {
              return (
                <SplideSlide className="w-full bg-gray-850" key={id}>
                  <BasicCard
                    id={id}
                    title={title}
                    slug={`${urlPath}?card_name=featured&position=${index + 1}`}
                    paywall={paywall?.contentAccess === "1"}
                    kicker={kicker?.fields?.[0]?.value}
                    blurb={truncate(blurb, { length: 60 })}
                    media={media}
                    variations={cardVariant}
                    defaultImage={{
                      directoryName: sections?.[0]?.uniqueName || "",
                    }}
                    rootClassName="h-full flex-col lg:flex-row"
                  />
                </SplideSlide>
              );
            }
          )}
        </SplideTrack>
      </Splide>
    </div>
  );
}
