import { ArticleDataObject } from "@src/app/types/Cue";

export const extractMagazineData = (articleData: ArticleDataObject) => {
  let magazineLink = "";
  let magazineTitle = "";
  const storyThread = articleData?.others?.storyThread;

  if (storyThread) {
    const storyThreadJson = JSON.parse(storyThread)[0];

    // TODO: Replace this line once we have the confirmed format from CUE
    magazineLink = storyThreadJson.id.split(":")[2].replace("-1", "");
    magazineTitle = storyThreadJson.value;
  }

  // TODO: Replace this once we have the confirmed format and source
  const magazineHighlights = [
    {
      kickerValue: "Spotlight",
      title: "Navigating 2023: Key themes",
      articleId: "123",
    },
    {
      kickerValue: "Roundtable",
      title: "Asia ex-Japan equities beckon",
      articleId: "123",
    },
  ];

  return { magazineLink, magazineTitle, magazineHighlights };
};
