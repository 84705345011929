import { RouteFactory } from "@src/app/routePaths";

export const PR_PAGE_CTA =
  "https://forms.zohopublic.com/maria47/form/BusinessTimesPressReleasePublicationApplication/formperma/rWVKlwT54X-ZaVCrybR-RkGT7rTGm3DMdj5QFB5JgGk";
export const PR_PAGE_FORM_URL = new URL(PR_PAGE_CTA);
export const EMBED_FLOURISH_SCRIPT =
  "https://public.flourish.studio/resources/embed.js";
export const IFRAME_URL = "https://advertise.techinasia.com/bt-advertise";
export const IFRAME_URL_SRC = new URL(IFRAME_URL);
export const ADVERTISE_PAGE_METATAGS = {
  title: "Advertise with Us",
  author: "The Business Times",
  description:
    "Leveraging four decades of data, insight, and industry relationships, we create tailored, data-driven strategies to meet your goals. Partner with The Business Times for bespoke marketing solutions that elevate your brand and captivate your audience.",
  slug: "/advertise",
  image: {
    url: "https://i.imgur.com/wNwIu33.jpeg",
  },
  ogTitle: "Business Times - Advertise with Us",
};
export const PR_PAGE_METATAGS = {
  title: "Paid Press Releases | The Business Times",
  author: "The Business Times",
  description:
    "You can publish your own press release on The Business Times. Simply fill in a form and our team will get back to you.",
  slug: "/paid-press-release",
  image: {
    url: "https://i.imgur.com/whrAhar.png",
  },
  ogTitle: "Paid Press Releases",
};

//UTM Default values.
export const DEFAULT_UTM_SOURCE_PR = "pr-landing-page";
export const DEFAULT_UTM_MEDIUM_PR = "direct";
export const DEFAULT_UTM_CAMPAIGN_PR = "pre-amble";

//Metatags for Faq page
export const FAQ_PAGE_METATAGS = {
  title: "Paid Press Release Publication Guidelines - The Business Times",
  author: "The Business Times",
  description:
    "To ensure a smooth and quick publication process, please adhere to the following guidelines.",
  slug: RouteFactory.faq,
  image: {
    url: "https://www.businesstimes.com.sg/assets-web2/logo-masthead-PNobukBu.svg",
  },
};

//Metatags for rss feed page
export const RSS_FEEDS_PAGE_METATAGS = {
  title: "RSS Feeds Landing Page, - THE BUSINESS TIMES",
  author: "The Business Times",
  description: "Read more at The Business Times.",
  slug: RouteFactory.rssFeeds,
  image: {
    url: "/src/app/assets/logo-masthead.svg",
  },
};

//Links inside faq page
export const ANNOUNCEMENT_URL =
  "https://www.techinasia.com/press-release-tech-asia-redacts-content-spotlight-asias-gender-pay-gap";
export const CUSTOM_OPTION_URL =
  "https://www.businesstimes.com.sg/advertise?utm_source=googledoc&utm_medium=referral&utm_campaign=dg-bt-pr-faq-doc";
export const DEDICATED_SECTION_URL = "https://www.businesstimes.com.sg/pr";
export const PR_PAGE_URL = "https://www.businesstimes.com.sg/pr";
export const TIA_PR_PAGE_URL =
  "http://techinasia.com/pr?utm_source=pr-faq-doc&utm_medium=referral&utm_campaign=pr-faq-doc";
export const MAIL_TO_TIA_FAQ = "btdigital@sph.com.sg";
export const RSSFEEDS_DATA = {
  news: {
    title: "News",
    items: [
      {
        title: "Singapore",
        url: "/rss/singapore",
        subItems: [{ title: "Economy & Policy", url: "/rss/economy-policy" }],
      },
      {
        title: "International",
        url: "/rss/international",
        subItems: [
          { title: "ASEAN Business", url: "/rss/asean-business" },
          { title: "Global Enterprise", url: "/rss/global-enterprise" },
        ],
      },
      {
        title: "Opinion & Features",
        url: "/rss/opinion-features",
        subItems: [
          { title: "Columns", url: "/rss/columns" },
          { title: "Features", url: "/rss/features" },
        ],
      },
      {
        title: "Companies & Markets",
        url: "/rss/companies-markets",
        subItems: [
          { title: "Banking & Finance", url: "/rss/banking-finance" },
          { title: "Reits & Property", url: "/rss/reits-property" },
          { title: "Energy & Commodities", url: "/rss/energy-commodities" },
          { title: "Telcos, Media & Tech", url: "/rss/telcos-media-tech" },
          { title: "Transport & Logistics", url: "/rss/transport-logistics" },
          { title: "Consumer & Healthcare", url: "/rss/consumer-healthcare" },
          {
            title: "Capital Markets & Currencies",
            url: "/rss/capital-markets-currencies",
          },
        ],
      },
      { title: "Property", url: "/rss/property" },
      {
        title: "Startups & Tech",
        url: "/rss/startups-tech",
        subItems: [
          { title: "Startups", url: "/rss/startups" },
          { title: "Technology", url: "/rss/technology" },
        ],
      },
      { title: "ESG", url: "/rss/esg" },
      {
        title: "Wealth",
        url: "/rss/wealth",
        subItems: [
          { title: "Personal Finance", url: "/rss/personal-finance" },
          {
            title: "Crypto & Alternative Assets",
            url: "/rss/crypto-alternative-assets",
          },
        ],
      },
      { title: "Working Life", url: "/rss/working-life" },
      {
        title: "Lifestyle",
        url: "/rss/lifestyle",
        subItems: [{ title: "BT Luxe", url: "/rss/bt-luxe" }],
      },
      {
        title: "Events & Awards",
        url: "/rss/events-awards",
        subItems: [
          {
            title: "Singapore Business Awards",
            url: "/rss/singapore-business-awards",
          },
          {
            title: "Singapore Corporate Awards",
            url: "/rss/singapore-corporate-awards",
          },
          { title: "Enterprise 50", url: "/rss/enterprise-50" },
          { title: "Emerging Enterprise", url: "/rss/emerging-enterprise" },
        ],
      },
      { title: "Podcasts", url: "/rss/podcasts" },
    ],
  },
  weekly: {
    title: "Weekly",
    items: [
      { title: "Wealth & Investing", url: "/rss/wealth-investing" },
      { title: "SGSME", url: "/rss/sgsme" },
    ],
  },
  topStories: {
    title: "Top Stories",
    items: [{ title: "Top Stories", url: "/rss/top-stories" }],
  },
};
