import { useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { ArticleDataObject } from "@app/types/Cue";
import { ARTICLE_INIFINITE_SCROLL_LIMIT } from "@util/constant";
import { includes } from "lodash-es";

import { UseFetchNextArticle } from "./useFetchNextArticle";

export const useNextArticle = (context: ArticleDataObject) => {
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [moreStories, setMoreStories] = useState<ArticleDataObject[]>([
    context,
  ]);

  const fetchTheNextArticle = async () => {
    setIsLoading(true);
    const lastArticle = moreStories?.[moreStories.length - 1];
    const nextStoryWShortUrl = await UseFetchNextArticle(lastArticle);
    setMoreStories([...moreStories, nextStoryWShortUrl]);
    setIsLoading(false);
  };

  const { ref } = useInView({
    triggerOnce: true,
    onChange: async (inView) => {
      if (typeof window === "undefined") return;
      if (!inView) return;
      if (moreStories.length >= ARTICLE_INIFINITE_SCROLL_LIMIT) return;
      if (pathname.startsWith(RouteFactory.aseanBusiness)) return;
      if (pathname.startsWith(RouteFactory.globalEnterprise)) return;

      const userAgent = window.navigator.userAgent;
      if (includes(userAgent.toLowerCase(), "googlebot")) return;

      fetchTheNextArticle();
    },
  });

  return { moreStories, isLoading, ref };
};
