import { Link } from "react-router-dom";
import { formatTitle } from "@components/Newsletter/helper";
import HomepageCarouselNewsletterSlide from "@components/Newsletter/HomepageCarouselNewsletter/HomepageCarouselNewsletterSlide";
import { NewsletterSectionProps } from "@components/Newsletter/types/Newsletter";
import NewsletterTitle from "@pages/Newsletter/SignUp/NewsletterTitle";
import { BIGMONEY_SUBSCRIBE_CTA } from "@src/app/components/Newsletter/constants";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";

export default function NewsletterSection({
  title,
  newsletters,
  selectedNewsletters,
  handleSelectedNewsletter,
  sectionRef,
  rootClassname,
}: NewsletterSectionProps): React.ReactElement {
  const formattedTitle = formatTitle(title);
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);
  const isSubscriber = OktaUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;
  return (
    <div>
      <NewsletterTitle
        title={title}
        formattedTitle={formattedTitle}
        sectionRef={sectionRef}
        rootClassname={rootClassname}
      />
      {title == "Premium NEWSLETTERS" && !isSubscriber ? (
        <div className="mb-5 font-public-sans text-[16px] font-normal">
          <Link
            to={BIGMONEY_SUBSCRIBE_CTA}
            target="_blank"
            className="font-public-sans font-semibold underline"
          >
            Subscribe to BT
          </Link>
          {" to access these exclusive newsletters."}
        </div>
      ) : null}
      <div className="mb-11 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {newsletters.map((newsletter) => (
          <HomepageCarouselNewsletterSlide
            wrapperClassName="!mb-0"
            titleClassName="text-xl md:text-3xl"
            descriptionClassName="mb-4 font-normal text-gray-850 md:mb-10"
            testId={newsletter.type}
            key={newsletter.type}
            newsletter={newsletter}
            handleSelectedNewsletter={handleSelectedNewsletter}
            subscribed={selectedNewsletters.some(
              (selectedNewsletter) =>
                selectedNewsletter.type === newsletter.type
            )}
            premiumOnly={newsletter?.premiumOnly}
          />
        ))}
      </div>
    </div>
  );
}
