import { SanitizedSectionArticle } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { Options, Splide, SplideSlide } from "@greglaisph/react-splide";
import { cn } from "@util/helpers";

import "@greglaisph/react-splide/css";
import styles from "@pages/MBO/styles/MboSlider.module.css";

export type MboSliderCarouselProps = {
  data: SanitizedSectionArticle[];
};

export default function MboSliderCarousel({
  data,
}: MboSliderCarouselProps): React.ReactElement {
  const cardVariant: CardVariations = {
    image: {
      position: "left",
      width: styles.mboImage,
    },
    content: {
      width: styles.mboHeadline,
      extraClass: cn("bg-white text-gray-850"),
    },
    title: {
      size: styles.mboHeadlineLink,
      color: "text-gray-850",
    },
  };
  const options: Options = {
    perPage: 1,
    perMove: 1,
    pagination: false,
    arrows: false,
    snap: true,
    type: "loop",
    autoHeight: true,
    autoplay: true,
  };

  return (
    <div className="relative" data-testid="mbo-carousel-list">
      <Splide options={options}>
        {data.map(({ id, title, media, displaySetting }) => {
          return (
            <SplideSlide
              className="h-auto w-full"
              key={id}
              data-testid={`title_link_${id}`}
            >
              <BasicCard
                id={id}
                title={displaySetting?.displayHeadline || title}
                media={{
                  ...media?.[0],
                  content: { ...media?.[0]?.content, type: "picture" },
                }}
                variations={cardVariant}
                isTitleLinkRequired={false}
                rootClassName={cn({ "text-center": media.length === 0 })}
              />
            </SplideSlide>
          );
        })}

        <ul
          className={cn(
            "splide__pagination podcast-featured-carousel",
            "lg:!left-[unset] lg:right-0 lg:w-5/12",
            "xl:w-4/12"
          )}
        ></ul>
      </Splide>
    </div>
  );
}
