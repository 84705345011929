import { useState } from "react";
import { Link } from "react-router-dom";
import { TestIdClassNameProps } from "@app/types/Common";
import {
  COMMON_BUTTON_CSS,
  REMOVE_FOCUS_CSS,
} from "@components/Button/constants";
import { EmarsysResponse } from "@components/Newsletter/types/Emarsys";
import { Newsletter } from "@components/Newsletter/types/Newsletter";
import { ArticleNewsletterForm } from "@pages/Article/components/ArticleNewsletter/ArticleNewsletterForm";
import { ArticleNewsletterFormSuccess } from "@pages/Article/components/ArticleNewsletter/ArticleNewsletterFormSuccess";
import { NewsletterFormTrigger } from "@pages/Article/components/ArticleNewsletter/NewsletterFormTrigger";
import cx from "classnames";

type SpecificNewsletterProps = TestIdClassNameProps & {
  newsletter: Newsletter;
  displayNewVersionNewsletter?: boolean;
};
export default function SpecificNewsletter({
  testId,
  rootClassName,
  newsletter,
  displayNewVersionNewsletter,
}: SpecificNewsletterProps): React.ReactElement {
  return (
    <div
      className={cx(rootClassName)}
      data-testid={testId}
      data-component="specific-newsletter"
    >
      {displayNewVersionNewsletter ? (
        <SpecificNewsletterVersion2 {...{ newsletter }} />
      ) : (
        <SpecificNewsletterVersion1 {...{ newsletter }} />
      )}
    </div>
  );
}

function SpecificNewsletterVersion1({
  newsletter,
}: SpecificNewsletterProps): React.ReactElement {
  const [showForm, setShowForm] = useState(false);
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();
  const { thumbnail, updateTime, label, description } = newsletter;
  return (
    <>
      {emarsysResponse?.status !== "success" ? (
        <>
          <h5 className="mb-2 pt-3 !font-poppins text-lg font-semibold tracking-[1px] md:text-3xl">
            A NEWSLETTER FOR YOU
          </h5>

          <div className="flex pb-8 pt-3">
            <img
              alt="Newsletter Img"
              className="hidden h-auto max-w-full self-start min-[321px]:block"
              width="75"
              height="75"
              src={thumbnail}
            />
            <div className="min-[321px]:pl-4 md:pl-6">
              <span className="font-public-sans text-4xs uppercase text-gray-850">
                {updateTime}
              </span>

              <h5
                role="name"
                className="font-public-sans font-bold text-gray-850 md:text-4xl"
              >
                {label}
              </h5>

              <p
                role="description"
                className="mb-3 font-public-sans text-base text-gray-850 md:text-lg"
              >
                {description}
              </p>

              <div className="relative w-full md:w-4/5 lg:w-7/10 xl:w-6/10">
                {!showForm ? (
                  <NewsletterFormTrigger
                    setShowForm={setShowForm}
                    showViewAll={false}
                  />
                ) : null}

                <ArticleNewsletterForm
                  showForm={showForm}
                  newsletters={[newsletter]}
                  emarsysResponse={emarsysResponse}
                  setEmarsysResponse={setEmarsysResponse}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}

      {emarsysResponse?.status === "success" ? (
        <ArticleNewsletterFormSuccess>
          <p className="mb-0 mt-4 text-base font-light">
            {"We have other newsletters you might enjoy. "}
            <Link
              to="/newsletter/sign-up"
              className="!text-gray-850 underline"
              target="_blank"
              reloadDocument
            >
              Take a look.
            </Link>
          </p>
        </ArticleNewsletterFormSuccess>
      ) : null}
    </>
  );
}

function SpecificNewsletterVersion2({
  newsletter,
}: SpecificNewsletterProps): React.ReactElement {
  const [showForm, setShowForm] = useState(false);
  const [emarsysResponse, setEmarsysResponse] = useState<EmarsysResponse>();
  const { thumbnail, updateTime, label, description } = newsletter;
  const commonClasses = `${REMOVE_FOCUS_CSS} ${COMMON_BUTTON_CSS}`;
  return (
    <>
      {emarsysResponse?.status !== "success" ? (
        <>
          <h5 className="mb-6 border-b border-gray-850 py-2 !font-poppins text-base font-semibold uppercase tracking-5% md:text-lg">
            A NEWSLETTER FOR YOU
          </h5>

          <div className="flex py-3 md:px-10 md:py-6">
            <img
              alt="Newsletter Img"
              className="hidden h-auto max-w-full self-start min-[321px]:block"
              width="75"
              height="75"
              src={thumbnail}
            />
            <div className="min-[321px]:pl-4 md:pl-6">
              <p className="mb-1 font-poppins text-4xs font-light uppercase tracking-[1px] text-gray-850">
                {updateTime}
              </p>

              <h5
                role="name"
                className="!font-poppins text-xl font-medium text-gray-850"
              >
                {label}
              </h5>

              <p
                role="description"
                className="mb-3 font-public-sans text-base font-light tracking-normal text-gray-850 md:text-lg "
              >
                {description}
              </p>

              <div className="relative hidden w-full md:block md:w-4/5 lg:w-7/10 xl:w-6/10">
                {!showForm ? (
                  <NewsletterFormTrigger
                    setShowForm={setShowForm}
                    showViewAll={false}
                    displayNewVersionNewsletter
                    commonButtonCss={commonClasses}
                  />
                ) : null}

                <ArticleNewsletterForm
                  showForm={showForm}
                  newsletters={[newsletter]}
                  emarsysResponse={emarsysResponse}
                  setEmarsysResponse={setEmarsysResponse}
                />
              </div>
            </div>
          </div>
          <div className="relative block w-full md:hidden">
            {!showForm ? (
              <NewsletterFormTrigger
                setShowForm={setShowForm}
                showViewAll={false}
                displayNewVersionNewsletter
                commonButtonCss={commonClasses}
              />
            ) : null}

            <ArticleNewsletterForm
              showForm={showForm}
              newsletters={[newsletter]}
              emarsysResponse={emarsysResponse}
              setEmarsysResponse={setEmarsysResponse}
            />
          </div>
        </>
      ) : null}

      {emarsysResponse?.status === "success" ? (
        <ArticleNewsletterFormSuccess>
          <p className="mb-0 mt-4 text-base font-light">
            {"We have other newsletters you might enjoy. "}
            <Link
              to="/newsletter/sign-up"
              className="!text-gray-850 underline"
              target="_blank"
              reloadDocument
            >
              Take a look.
            </Link>
          </p>
        </ArticleNewsletterFormSuccess>
      ) : null}
    </>
  );
}
