/**
 * Company: SPHMedia
 * Description: The vertical pages' most read article
 */

import { ReactElement } from "react";
import { getRandomUniqueId } from "@components/ArticleCard/helpers/helpers";
import TitleLink from "@components/ArticleMeta/TitleLink";
import { ChartbeatArticleDataObject } from "@src/app/types/Chartbeat";
import { VERTICAL_COLOUR_VARIANTS, VerticalName } from "@src/app/util/constant";
import cx from "classnames";

export type VerticalMostReadProps = {
  id?: string;
  rootClassName?: string;
  articleData: ChartbeatArticleDataObject;
  verticalName: VerticalName;
};

/**
 * VerticalMostRead
 * @description The vertical pages' most read article
 * @param {VerticalMostReadProps} props
 * @returns {ReactElement}
 */
export default function VerticalMostRead({
  rootClassName = "",
  articleData,
  verticalName,
}: VerticalMostReadProps): ReactElement {
  const { title, article_url } = articleData;

  return (
    <div className={cx("flex flex-row gap-x-4", rootClassName)}>
      <div className="flex flex-1 flex-col">
        {title ? (
          <TitleLink
            rootClassName={cx(
              "font-poppins font-semibold text-base",
              VERTICAL_COLOUR_VARIANTS[verticalName].hoverStyle
            )}
            articleId={getRandomUniqueId(6)}
            title={title}
            slug={article_url}
          />
        ) : null}
      </div>
    </div>
  );
}
