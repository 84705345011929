import { TestIdClassNameProps } from "@app/types/Common";
import BrightcovePlayerLoader from "@brightcove/react-player-loader";
import cx from "classnames";

import styles from "./BrightcoveVideo.module.css";

export type BrightcoveVideoPlayerProps = TestIdClassNameProps & {
  videoId: string;
  accountId: string;
  playerId?: string;
};

export default function BrightcoveVideoPlayer({
  testId,
  rootClassName,
  videoId,
  accountId,
  playerId = "default",
}: BrightcoveVideoPlayerProps): React.ReactElement {
  return (
    <>
      {accountId && videoId ? (
        <div className={cx(rootClassName, "aspect-video")} data-testid={testId}>
          <BrightcovePlayerLoader
            accountId={accountId}
            videoId={videoId}
            embedType="iframe"
            playerId={playerId}
            attrs={{ className: cx("h-full", styles.brightcove) }}
          />
        </div>
      ) : null}
    </>
  );
}
