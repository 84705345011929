import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import CirculationBanner from "@assets/newsletter/circulation_banner.gif";
import DealsIcon from "@assets/newsletter/deals.png";
import EarningsIcon from "@assets/newsletter/earnings.png";
import QuestionIcon from "@assets/newsletter/questions.png";
import { renderOrangeLink } from "@pages/Newsletter/Archive/util/helpers";

const links = [
  {
    href: "https://www.businesstimes.com.sg/property/condo-resale-volumes-surge-32-2-july-ccr-posts-biggest-price-increase-2-8-srx-99-co",
    text: "flash estimates",
  },
  {
    href: "https://www.businesstimes.com.sg/opinion-features/subdued-bidding-housing-sites-will-persist-if-sales-new-condo-launches-are-tepid",
    text: "The Level Ground",
  },
  {
    href: "https://www.businesstimes.com.sg/property/tampines-executive-condominium-site-560-units-put-sale",
    text: "EC site in Tampines Street 95",
  },
  {
    href: "https://www.businesstimes.com.sg/startups-tech/startups/ceo-be-replaced-after-healthtech-unicorn-biofourmis-lays-120-staff",
    text: "ousting of its CEO",
  },
  {
    href: "https://www.businesstimes.com.sg/opinion-features/tighter-hdb-loan-limit-will-cool-resale-flat-prices-choice-units-should-still-see-firm-demand",
    text: "tightening of the loan-to-value limit on HDB loans",
  },
  {
    href: "https://www.businesstimes.com.sg/property/ok-lims-tanglin-hill-bungalow-sells-s39-2-million",
    text: "Tanglin Hill bungalow",
  },
  {
    href: "https://www.businesstimes.com.sg/property/blackrock-close-buying-299-unit-citadines-raffles-place-s300-million",
    text: "Citadines Raffles Place",
  },
  {
    href: "https://www.businesstimes.com.sg/international/global/kkr-hulic-bid-us2-billion-gics-central-tokyo-skyscraper",
    text: "sovereign wealth fund GIC",
  },
  {
    href: "https://www.businesstimes.com.sg/opinion-features/heres-what-private-equity-may-be-getting-right",
    text: "EQT could continue to cut expenses",
  },
  {
    href: "https://www.businesstimes.com.sg/companies-markets/s-reit-dpus-weighed-down-higher-interest-rates-h1-tide-turn-h2",
    text: "turn the tide for Reits",
  },
  {
    href: "https://www.businesstimes.com.sg/opinion-features/new-dawn-s-reits-fed-rate-cuts-get-underway",
    text: "underlying fundamentals",
  },
  {
    href: "https://www.businesstimes.com.sg/companies-markets/oxley-holdings-expects-net-loss-h2-fy2024-earnings",
    text: "Oxley Holdings",
  },
  {
    href: "https://www.businesstimes.com.sg/property/us-new-home-sales-rise-more-expected-july",
    text: "US single-family homes",
  },
  {
    href: "https://www.businesstimes.com.sg/property/relevance-real-estate-agent-table",
    text: "not hiring a real estate agent",
  },
  {
    href: "mailto:lyee@sph.com.sg",
    text: "lyee@sph.com.sg",
  },
];

export default function PropertyInsightNewsletterSampleContent(): React.ReactElement {
  const bannerLink = RouteFactory.newsletterSignup;

  return (
    <>
      <div className="today-highlights-wrapper px-5 pb-6">
        <h4 className="mb-2.5 font-pullQuote font-bold leading-8 text-gray-850">
          This week in Property
        </h4>

        <ul className="mb-2.5 list-disc pl-7 font-sans text-sm font-normal leading-5">
          <li className="pb-2">
            Subdued bidding for housing sites could persist if sales at new
            condo launches are tepid
          </li>
          <li className="pb-2">A serviced residences sale and other deals</li>
          <li className="pb-2">
            Hope springs for Reits as interest rate cuts beckon
          </li>
        </ul>
      </div>
      <div className="mx-5 mt-6 border-t-[3px] border-gray-250 pb-6" />
      <div className="front-copy w-full max-w-2xl px-5 text-left font-arial font-normal">
        <div className="greeting-text-wrapper ">
          <div>
            <img
              width="72"
              height="72"
              src={QuestionIcon}
              alt="READ MORE"
              className="my-6 block h-auto max-w-full"
            />
          </div>
          <h4 className="mb-2.5 font-pullQuote font-bold leading-8 text-gray-850">
            On subdued housing land bids
          </h4>
          <p className="mb-2.5 font-sans text-sm leading-6">
            Condo resale volumes in July jumped 32 per cent from the previous
            month, according to{" "}
            {renderOrangeLink("flash estimates", links[0].href)} from SRX and
            99.co. Analysts attributed the rise to a lower base in June due to
            the school holiday season.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            Might some buyers in the private homes market be drawn to resale
            units because of the large gap in price per square foot (psf)
            between new and resale units? As it stands, sales of new homes
            excluding executive condos (ECs) in H1 were 44 per cent less than in
            the corresponding period last year.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            I argue in {renderOrangeLink("The Level Ground", links[0].href)}{" "}
            that the biggest worry facing housing developers is uncertain
            demand. If sales at new condo launches hover in the 20 per cent plus
            region, this can spell trouble for developers. For example,
            developers will see financing costs rise and face being hit with
            clawback in Additional Buyer’s Stamp Duty, which was remitted
            upfront, for failing to sell all housing inventory in a project
            within the prescribed timeline.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            New condo launches have taken a hiatus during the Chinese Hungry
            Ghost month, which runs from early August to early September. All
            eyes will be trained on how a flurry of new condo launches after the
            Hungry Ghost month fare. Should sales be tepid, expect continued
            subdued bidding for private housing sites.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            A segment where housing land bids are relatively stronger is that
            for EC plots. Last week, an{" "}
            {renderOrangeLink("EC site in Tampines", links[0].href)} Street 95,
            which can yield some 560 homes, was put up for sale by the Housing
            and Development Board (HDB). Market watchers expect around four to
            eight bids for this site, with estimates ranging between S$650 and
            S$750 psf per plot ratio.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            One area of possible weakness in the new homes market could be that
            of the popular suburban condo segment, where prices have surged
            since the Covid-pandemic.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            In a commentary on the recent{" "}
            {renderOrangeLink(
              "tightening of the loan-to-value limit on HDB loans",
              links[0].href
            )}
            , I opine that some fizz will come off the high-flying HDB resale
            flat market. After all, the buying power of some HDB resale flat
            buyers will be curtailed. In turn, owners of HDB homes looking to
            sell their flats to fund the purchase of suburban condos could see
            their buying power affected too.
          </p>
        </div>
        <div className="mt-6 border-t-[3px] border-gray-250 pb-6" />

        <div className="greeting-text-wrapper ">
          <div>
            <img
              width="72"
              height="72"
              src={DealsIcon}
              alt="READ MORE"
              className="my-6 block h-auto max-w-full"
            />
          </div>
          <h4 className="mb-2.5 font-pullQuote font-bold leading-8 text-gray-850">
            A serviced residences deal brewing
          </h4>
          <p className="mb-2.5 font-sans text-sm leading-6">
            Elsewhere in the housing market, the{" "}
            {renderOrangeLink("Tanglin Hill bungalow", links[0].href)} of
            convicted Hin Leong founder Lim Oon Kuin, also known as OK Lim, has
            been sold for S$39.2 million, or S$2,507 psf on the freehold land
            area of 15,636 square feet.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            My colleague Kalpana Rashiwala reported that a major serviced
            residences deal is brewing. Asset manager BlackRock is close to
            buying the 299-unit{" "}
            {renderOrangeLink("Citadines Raffles Place", links[0].href)}, which
            is within the CapitaSpring building on Market Street, for S$300
            million or slightly over S$1 million per unit. The serviced
            residences are on levels nine to 16 of the 51-storey building, which
            sits on a site with over 56 years of land lease outstanding.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            A group led by KKR and one of Japan’s largest real estate companies
            reportedly put in a US$2 billion bid to acquire Shiodome City
            Center, a 43-storey office tower in Tokyo, Japan, owned by Singapore{" "}
            {renderOrangeLink("sovereign wealth fund GIC", links[0].href)}.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            Earlier this month, a deal was announced involving investment firm
            EQT Private Capital Asia buying New York-listed PropertyGuru Group,
            which started as an online property listings portal, for US$1.1
            billion and taking the latter private.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            My colleague Joan Ng argues that amid the loss-making PropertyGuru
            reporting improving financials,{" "}
            {renderOrangeLink(
              "EQT could continue to cut expenses",
              links[0].href
            )}
            , find efficiencies or add more businesses to PropertyGuru’s
            portfolio.
          </p>
        </div>
        <div className="mt-6 border-t-[3px] border-gray-250 pb-6" />

        <div className="greeting-text-wrapper pb-6">
          <div>
            <img
              width="72"
              height="72"
              src={EarningsIcon}
              alt="READ MORE"
              className="my-6 block h-auto max-w-full"
            />
          </div>
          <h4 className="mb-2.5 font-pullQuote font-bold leading-8 text-gray-850">
            Better days ahead for Reits
          </h4>
          <p className="mb-2.5 font-sans text-sm leading-6">
            In the real estate investment trust (Reit) space, higher financing
            costs weighed down on the distributions of trusts in H1. However,
            market watchers expect interest rate cuts, which appear to be
            increasingly likely, to{" "}
            {renderOrangeLink("turn the tide for Reits", links[0].href)} in the
            second half of this year.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            Higher borrowing costs due to rising interest rates have adversely
            impacted the distribution of many Reits and dampened investor
            sentiment in the sector. Still, investors who want to ride the
            improving picture for interest-rate sensitive Reits will need to
            delve deep into the exposure of the various trusts given performance
            across property asset types and geographies may differ.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            My colleague Ben Paul cautions that investors should not lose sight
            of the {renderOrangeLink("underlying fundamentals", links[0].href)}{" "}
            of various Reits, and the risk of more fundraising and acquisitions.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            Meanwhile, property developer{" "}
            {renderOrangeLink("Oxley Holdings", links[0].href)} said that it
            expects to post a net loss for both the second half and full year
            ended-June. The group attributed this to impairment losses, finance
            costs, and lower revenue recognised from its property development
            projects.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            Overseas, sales of new{" "}
            {renderOrangeLink("US single-family homes", links[0].href)} rose
            more than expected in July as a drop in mortgage rates boosted
            demand.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            In the US, sales commission could be around 5 per cent to 6 per cent
            of the transacted price of a home. Maybe, home sellers can pocket
            substantial savings by{" "}
            {renderOrangeLink("not hiring a real estate agent", links[0].href)}{" "}
            and lining up a lawyer instead to do the deal closing.
          </p>

          <p className="mb-2.5 font-sans text-sm leading-6">
            At home, could a case be made over time for the role of the agent in
            housing transactions to be done away with, especially as technology
            advances?
          </p>

          <p className="mb-2.5 font-sans text-sm font-bold italic leading-6">
            Will lower interest rates spur more bullish housing land bids? Let
            me know your thoughts at{" "}
            {renderOrangeLink("lyee@sph.com.sg", links[0].href)}
          </p>
        </div>
      </div>

      <div className="mx-auto my-6 flex max-w-[300px] justify-center py-[60px]">
        <Link
          className="no-underline"
          to={bannerLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={CirculationBanner}
            alt="Circulation Banner"
            width="300"
            height="180"
            className="block h-auto max-w-full"
          />
        </Link>
      </div>
    </>
  );
}
