import { AdvertisementTypeEnum } from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn, GoogleAdsSlotFactory } from "@util/helpers";

type BreakingNewsDynamicAdsProps = React.HTMLAttributes<HTMLDivElement> & {
  articleCounter: number;
  isRefresh?: boolean;
};

export default function BreakingNewsDynamicAds({
  className,
  articleCounter,
  isRefresh = false,
}: BreakingNewsDynamicAdsProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();
  const iterator = isScreenLG ? 10 : 5;
  const adUnitProps = isScreenLG
    ? DesktopAdsFactory(articleCounter, iterator)
    : MobileAdsFactory(articleCounter, iterator);

  return (
    <>
      {articleCounter % iterator === 0 && adUnitProps ? (
        <Advertisement
          rootClassName={cn(className)}
          adUnitProps={{ ...adUnitProps, isRefresh: isRefresh }}
        />
      ) : null}
    </>
  );
}

const DesktopAdsFactory = (index: number, iterator: number) => {
  if (index === iterator * 1) {
    return {
      type: AdvertisementTypeEnum.BN1,
      slot: GoogleAdsSlotFactory.bn1("breaking-news"),
    };
  }

  if (index === iterator * 2) {
    return {
      type: AdvertisementTypeEnum.BN2,
      slot: GoogleAdsSlotFactory.bn2("breaking-news"),
    };
  }

  if (index === iterator * 3) {
    return {
      type: AdvertisementTypeEnum.BN3,
      slot: GoogleAdsSlotFactory.bn3("breaking-news"),
    };
  }

  if (index === iterator * 4) {
    return {
      type: AdvertisementTypeEnum.BN4,
      slot: GoogleAdsSlotFactory.bn4("breaking-news"),
    };
  }
};

const MobileAdsFactory = (index: number, iterator: number) => {
  if (index === iterator * 1) {
    return {
      type: AdvertisementTypeEnum.BN1,
      slot: GoogleAdsSlotFactory.bn1("breaking-news"),
    };
  }

  if (index === iterator * 2) {
    return {
      type: AdvertisementTypeEnum.IMU1,
      slot: GoogleAdsSlotFactory.imu1("breaking-news"),
    };
  }

  if (index === iterator * 3) {
    return {
      type: AdvertisementTypeEnum.BN2,
      slot: GoogleAdsSlotFactory.bn2("breaking-news"),
    };
  }

  if (index === iterator * 4) {
    return {
      type: AdvertisementTypeEnum.IMU2,
      slot: GoogleAdsSlotFactory.imu2("breaking-news"),
    };
  }

  if (index === iterator * 5) {
    return {
      type: AdvertisementTypeEnum.BN3,
      slot: GoogleAdsSlotFactory.bn3("breaking-news"),
    };
  }

  if (index === iterator * 6) {
    return {
      type: AdvertisementTypeEnum.IMU3,
      slot: GoogleAdsSlotFactory.imu3("breaking-news"),
    };
  }

  if (index === iterator * 7) {
    return {
      type: AdvertisementTypeEnum.BN4,
      slot: GoogleAdsSlotFactory.bn4("breaking-news"),
    };
  }
};
