import { Field } from "@app/types/Cue";
import { Name, Type } from "@app/types/enums";
import { getFieldValue } from "@util/helpers";
import { stringify } from "querystringify";

export type YouTubeVideoProps = {
  fields: Field[];
  className?: string;
};
export default function YouTubeVideo({
  className,
  fields,
}: YouTubeVideoProps): React.ReactElement {
  const title = getFieldValue(fields, Name.Title)?.toString();
  const id = getFieldValue(fields, Name.YoutubeId);
  const autoPlay = getFieldValue(fields, Name.AutoPlay);
  const showCaption = getFieldValue(fields, Name.ClosedCaption);
  const allowFullScreen = getFieldValue(fields, Name.AllowFullScreen);

  const params = {
    ...(autoPlay && { autoplay: 1 }),
    ...(showCaption && { cc_load_policy: 1 }),
    ...(!allowFullScreen && { fs: 0 }),
  };

  return (
    <>
      {id ? (
        <div
          className={className}
          data-story-element={Type.YoutubeVideo}
          data-testid="youtube-video-component"
        >
          <iframe
            className="aspect-video w-full"
            src={
              Object.keys(params).length > 0
                ? `https://www.youtube.com/embed/${id}?${stringify(params)}`
                : `https://www.youtube.com/embed/${id}`
            }
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            title={title}
          ></iframe>
        </div>
      ) : null}
    </>
  );
}
