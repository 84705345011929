import { profileURIMapping } from "./constants";

/**
 * Helper function to get the alternative path for the profiles.
 * @param uri The uri of the profile.
 * @returns {string|undefined}
 */
export const getProfilesAlternativePath = (
  uri?: string
): string | undefined => {
  const profileMatch = profileURIMapping.find((profile) => profile.uri === uri);

  return profileMatch?.urlPath;
};

/**
 * Helper function to check if the profile path is reserved.
 * @param urlPath The url path of the profile.
 * @returns {boolean}
 */
export const isProfilePathReserved = (urlPath: string): boolean => {
  return profileURIMapping.some((profile) => profile.urlPath === urlPath);
};
