import { Link } from "react-router-dom";
import Button from "@components/AccessibleComponents/Button";
import { getKeywordLink } from "@components/FollowKeyword/helper";
import { cn } from "@util/helpers";

import {
  IndividualKeywordType,
  KeywordType,
  KeywordTypeFactoryProps,
} from "./types";

const commonClasses =
  "line-clamp-1 place-self-center break-all px-3 text-center font-poppins text-base font-semibold capitalize";

const KeywordWithTag = ({
  tag,
  disableBorder,
  tagClassName,
  tagDisplayName,
}: IndividualKeywordType) => {
  return (
    <Link
      to={getKeywordLink(tag)}
      className={cn(
        commonClasses,
        {
          "mr-3 border-r border-gray-450": !disableBorder,
        },
        tagClassName
      )}
      reloadDocument
    >
      {tagDisplayName}
    </Link>
  );
};

const KeywordListing = ({
  tagDisplayName,
  tagClassName,
}: IndividualKeywordType) => {
  return <h1 className={cn(commonClasses, tagClassName)}>{tagDisplayName}</h1>;
};

const KeywordNoTag = ({
  tagDisplayName,
  tagClassName,
  handleClick,
  disableBorder,
}: IndividualKeywordType) => {
  return (
    <Button
      onPress={handleClick}
      className={cn(
        commonClasses,
        {
          "mr-3 border-r border-gray-450": !disableBorder,
        },
        tagClassName
      )}
    >
      {tagDisplayName}
    </Button>
  );
};

export const KeywordFilterFactory = ({
  keywordType = KeywordType.default,
  tagDisplayName = "",
  handleClick,
  disableBorder,
  tagClassName,
  tag,
}: KeywordTypeFactoryProps) => {
  const props = {
    tagDisplayName,
    disableBorder,
    tagClassName,
    tag,
  };

  switch (keywordType) {
    case KeywordType.withTag:
      return <KeywordWithTag {...props} />;

    case KeywordType.listing:
      return <KeywordListing {...props} />;

    case KeywordType.default:
    default:
      return <KeywordNoTag handleClick={handleClick} {...props} />;
  }
};
