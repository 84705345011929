import { ArticlePurchaseLinkFactory } from "./ArticlePurchaseLinkFactory";
import { ArticlePurchaseLinkProps } from "./types";

export default function ArticlePurchaseLink({
  ...props
}: ArticlePurchaseLinkProps): React.ReactElement {
  return (
    <section
      className="mx-auto my-4 w-full"
      data-testid="article-purchase-link-component"
    >
      <ArticlePurchaseLinkFactory {...props} />
    </section>
  );
}
