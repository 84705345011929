import type { TermContext } from "@app/types/Page";

export type SectionDefaultProps = {
  context: TermContext;
  parentCategory?: string;
  childCategory?: string;
};

export enum TagType {
  StoryThread = "storyThread",
  Keyword = "keyword",
}
