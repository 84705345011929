import { Link } from "react-router-dom";
import Container from "@components/Container/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  linksInHomeNewsletterBottomFooter,
  socialLinks,
} from "@pages/Thrive/constants";

export default function ThriveSampleNewsletterBottomFooter(): React.ReactElement {
  return (
    <Container
      rootClassName="px-6 text-gray-850 bg-gray-850 pt-20 pb-40"
      data-testid="thrive-home-sample-newsletter-bottom-footer-component"
    >
      <div className="flex flex-wrap items-center justify-center gap-4 px-5 pb-8 text-center">
        {socialLinks.map((links) => {
          return (
            <Link
              key={links.to}
              to={links.to}
              reloadDocument
              className="flex w-full items-center justify-center gap-2 border border-orange-400 bg-white p-3 text-center md:w-auto lg:w-full"
            >
              <FontAwesomeIcon icon={links.icon} />
              <span className="font-semibold uppercase">{links.label}</span>
            </Link>
          );
        })}
      </div>

      <p className="text-center text-4xs text-white">
        Everything in this newsletter is for your general information only.
        Consult a qualified financial professional or expert for independent
        advice and verification.
      </p>

      <p className="text-center text-4xs text-white">
        {"Was this email forwarded to you? "}
        <a
          href={linksInHomeNewsletterBottomFooter.newsletterSignUp.to}
          target="_blank"
          className="underline"
          rel="noreferrer"
        >
          Sign up here.
        </a>
      </p>

      <p className="text-center text-4xs text-white">
        <a
          href={linksInHomeNewsletterBottomFooter.unsubscribe.to}
          target="_blank"
          className="underline"
          rel="noreferrer"
        >
          Unsubscribe
        </a>{" "}
        from Thrive publications.
      </p>

      <p className="m-0 p-0 text-center font-interBold text-white">
        THRIVE by THE BUSINESS TIMES.
      </p>

      <p className="text-center text-4xs text-white">
        Copyright © 2023 SPH Media Limited. All Rights Reserved.
      </p>
    </Container>
  );
}
