import ThriveLogo from "@assets/thrive_images/thrive_newsletter_logo.png";

export default function NewsletterSampleHeader(): React.ReactElement {
  return (
    <>
      <div
        data-testid="businesstimes-nl-time"
        className="flex justify-center bg-verticals-thrive pb-6"
      >
        <span className="text-center font-inter text-2xs leading-relaxed text-gray-575">
          Fri, Sep 6, 2024
        </span>
      </div>
      <div
        data-testid="businesstimes-logo"
        className="bg-th flex  justify-center"
      >
        <a
          href="https://www.businesstimes.com.sg"
          target="_blank"
          className="block text-center"
          rel="noreferrer"
        >
          <img width="335" height="83" src={ThriveLogo}></img>
        </a>
      </div>
      <div className="m-0 mb-1 box-border border-b-4 border-green-400 p-0 pt-8"></div>
    </>
  );
}
