import { useEffect, useState } from "react";
import dayjs from "dayjs";

export default function PageGeneratedAtTag(): React.ReactElement {
  const [generatedAt, setGeneratedAt] = useState("");

  useEffect(() => {
    setGeneratedAt(dayjs().format("YYYY-MM-DD hh:mm:ss A"));
  }, []);

  return (
    <span data-testid="page-generated-at-tag" className="hidden">
      {generatedAt}
    </span>
  );
}
