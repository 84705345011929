import { useEffect, useState } from "react";

/**
 * @returns {number} The current window size state.
 */
function useWindowInnerWidth() {
  const [windowWidth, setWindowWidth] = useState({
    width: 0,
  });

  useEffect(() => {
    // useEffect is only called client-side.
    // To prevent SSR issues, we check if window is defined.
    const handleResize = () => {
      const width = window.visualViewport
        ? window.visualViewport?.width
        : window.innerWidth;
      setWindowWidth({
        width,
      });
    };

    if (typeof window === "undefined") return;

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
}

export { useWindowInnerWidth };
