import { SanitizedSectionArticle, ValueOf } from "@app/types/Cue";
import podcastIcon from "@assets/icon-podcast.svg";
import TitleLink from "@components/ArticleMeta/TitleLink";
import { PodcastKeywordData } from "@pages/Section/layouts/PodcastsPage/util/constants";
import { articleDateOnlyTimestampFormat } from "@util/constant";
import { getFormattedTime } from "@util/helpers";

export type PodcastsKeywordsStoriesProps = {
  article: SanitizedSectionArticle;
  cardName: ValueOf<Pick<PodcastKeywordData, "id">>;
  index: number;
};

export default function PodcastsKeywordsStories({
  article,
  cardName,
  index,
}: PodcastsKeywordsStoriesProps): React.ReactElement {
  const { id, urlPath, title, updated, kicker } = article;
  return (
    <div
      className="flex items-start border-b border-gray-175 py-3 text-black"
      data-testid="podcasts-keywords-story-component"
    >
      <img
        src={podcastIcon}
        width={15}
        height={15}
        className="my-2 mr-2 h-full"
        alt="podcast icon"
      />

      <div className="mr-6 flex w-full flex-row flex-wrap items-center">
        <TitleLink
          title={title}
          articleId={id}
          slug={`${urlPath}?card_name=${cardName}&position=${index}`}
          isTitleLinkRequired
          rootClassName="font-semibold text-base md:text-lg mr-6"
        />

        {kicker?.fields?.[0]?.value ? (
          <p
            className="font-poppins text-4xs text-gray-515"
            data-testid="kicker-component"
          >
            {kicker?.fields?.[0]?.value}
          </p>
        ) : null}
      </div>

      <p
        className="ml-auto hidden flex-none font-public-sans text-xs text-gray-550 lg:block"
        data-testid="update-time-component"
      >
        {getFormattedTime(updated, articleDateOnlyTimestampFormat)}
      </p>
    </div>
  );
}
