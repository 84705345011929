import { useState } from "react";
import { Link } from "react-router-dom";
import type { IndividualNavigationSection } from "@app/types/Navigation";
import btLogo from "@assets/bt-logo-white-mobile.svg";
import Accordion from "@components/Accordion/Accordion";
import Button from "@components/Button/Button";
import { ActionType, ButtonSize } from "@components/Button/types";
import { NavigationItem, SocialLinkConfig } from "@components/Footer/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@hooks/useWindowSize";
import { RouteFactory } from "@src/app/routePaths";
import { cn } from "@util/helpers";

import {
  appDownloadLinks,
  footerBtRecommends,
  footerFollowUsSocialLinks,
  footerSectionLabel,
  footerSocialLinks,
} from "../constant";

export function FollowBT() {
  return (
    <div className="mx-auto flex max-w-[300px] flex-wrap justify-center gap-3 sm:max-w-full lg:gap-2">
      {footerSocialLinks.map((socialLink?: SocialLinkConfig) => {
        return socialLink ? (
          <Button
            key={`social-link-button-${socialLink.selfIcon}`}
            icon={socialLink.selfIcon}
            shareUrl={socialLink.link}
            actionType={ActionType.linkButton}
            className="!h-[60px] lg:!h-[36px]"
            imgAlt={socialLink.alt}
            buttonSize={ButtonSize.small}
          />
        ) : null;
      })}
    </div>
  );
}

export function FollowUsBT() {
  return (
    <div className="lg:ml-[50%] lg:mt-5">
      <div className="fw-700 pb-3 font-lato text-xl text-gray-675">
        FOLLOW US
      </div>
      <div className="flex flex-wrap gap-x-4 lg:grid lg:grid-cols-2">
        {footerFollowUsSocialLinks.map((socialLink?: SocialLinkConfig) => {
          return socialLink ? (
            <Link
              className="inline-block pb-2"
              to={socialLink.link}
              key={socialLink.link}
              target="_blank"
              rel="noreferrer"
              referrerPolicy="strict-origin-when-cross-origin"
              title={socialLink.alt}
            >
              <FontAwesomeIcon icon={socialLink.icon} size="1x" />
            </Link>
          ) : null;
        })}
      </div>
    </div>
  );
}

type FooterNavigationChildrenProps = {
  navItems: NavigationItem[];
};

function FooterNavigationChildren({
  navItems,
}: FooterNavigationChildrenProps): React.ReactElement {
  return (
    <>
      {navItems.map((items, index) => {
        return (
          <Link
            data-testid={`footer-children-${items.key?.replaceAll("/", "-")}`}
            className="block py-3 font-poppins text-xs font-medium leading-tight hover:underline md:pb-0 md:pt-3"
            key={`children-${index}`}
            to={`${items.link}?ref=footer`}
            reloadDocument
          >
            {items.label}
          </Link>
        );
      })}
    </>
  );
}

type FooterSectionTitleProps = {
  panelIndex?: string;
  title?: string;
  isExpanded?: boolean;
  handleDrawer?: () => void;
};

function FooterSectionTitle({
  title,
  handleDrawer,
  isExpanded,
}: FooterSectionTitleProps): React.ReactElement {
  return (
    <div
      data-testid="footer-title-button"
      className="pt-4 lg:pt-0"
      onClick={handleDrawer}
    >
      <div className="flex items-center justify-between font-poppins text-xs font-semibold uppercase leading-tight tracking-[0.0875rem] text-black lg:cursor-default lg:leading-loose lg:text-gray-350">
        <span>{title}</span>
        <div className="flex h-[42px] w-[42px] items-center justify-center rounded-full border border-gray-175 hover:opacity-70 lg:hidden">
          <svg
            className={cn(
              "block h-[5px] w-[10px] transition-all duration-300 group-hover:fill-gray-850 lg:hidden",
              { "-rotate-180 ": isExpanded }
            )}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 8"
            fill="none"
          >
            <path
              d="M1 1.33398C5 5.33398 6 6.33398 6 6.33398L11 1.33398"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

type IndividualFooterSectionProps = {
  sections: IndividualNavigationSection;
};

function IndividualFooterSection({
  sections,
}: IndividualFooterSectionProps): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const { isScreenLG } = useWindowSize();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Accordion
      rootClassName={"text-gray-850"}
      isExpanded={isScreenLG ? true : isOpen}
      rootChildrenClassName={"!bg-transparent !px-0 before:!h-0 after:!h-0"}
      id={sections.section.key}
      renderHeader={() => {
        return (
          <FooterSectionTitle
            title={sections.section.label || ""}
            panelIndex={sections.section.key}
            handleDrawer={handleClick}
            isExpanded={isScreenLG ? true : isOpen}
          />
        );
      }}
    >
      <FooterNavigationChildren navItems={sections.section.children || []} />
    </Accordion>
  );
}

export function SectionsNavigationItems() {
  return (
    <div
      data-testid="footer-navigation-items"
      className="flex flex-col lg:flex-row lg:gap-6"
    >
      <div className="flex flex-col lg:flex-row lg:gap-6">
        {footerSectionLabel.map((sections) => {
          return (
            <div
              data-testid={`footer-navigation-${sections.section.key}`}
              className="border-b border-gray-175 pb-4 lg:flex-1 lg:border-0 lg:pb-0"
              key={sections.section.key}
            >
              <IndividualFooterSection sections={sections} />
            </div>
          );
        })}
      </div>
      <div className="border-gray-175 lg:border-l lg:pl-6">
        {footerBtRecommends.map((sections) => {
          return (
            <div
              data-testid={`footer-navigation-${sections.section.key}`}
              className="flex-1"
              key={sections.section.key}
            >
              <IndividualFooterSection sections={sections} />
            </div>
          );
        })}

        <div className="mt-6 border-t border-gray-175 pt-8 lg:pt-6">
          <div className="mx-auto block pb-10 text-center lg:hidden">
            <FollowBT />
          </div>
          <div className="hidden lg:block">
            <FooterSectionTitle title={"Mobile"} isExpanded={true} />
          </div>
          <div className="pt-3">
            <FooterDownloadLinks />
          </div>
        </div>
      </div>
    </div>
  );
}

export function FooterDownloadLinks() {
  return (
    <div className={cn("flex flex-row justify-center gap-1 lg:flex-col")}>
      <Link
        className={cn("mr-5 lg:mr-1")}
        to={appDownloadLinks.ios.link}
        target="_blank"
        rel=" noopener noreferrer"
      >
        <img
          width={135}
          height={40}
          src={appDownloadLinks.ios.src}
          alt={appDownloadLinks.ios.alt}
        />
      </Link>
      <Link
        to={appDownloadLinks.google.link}
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          width={135}
          height={40}
          src={appDownloadLinks.google.src}
          alt={appDownloadLinks.google.alt}
        />
      </Link>
    </div>
  );
}

export function FooterBTLogo() {
  return (
    <div className={cn("flex justify-center", "lg:ml-4 lg:justify-start")}>
      <Link to={RouteFactory.home} reloadDocument>
        <img width={370} height={38} src={btLogo} alt="The Business Times" />
      </Link>
    </div>
  );
}
