const betteradsScript = `
// For Testing Purpose only
//prestitial_enabled = 1; // this value will get override in prebid.js
//postitial_enabled = 1; // this value will get override in prebid.js
//catfish_enabled = 1; // this value will get override in prebid.js';

var cname = "mySPHUserType";
var user_sub = 0;
match = document.cookie.match(new RegExp(cname + "=([^;]+)"));
var cval;
if (match) {
  cval = match[1];
}
if (cval == "y-sub" || cval == "sub") {
  user_sub = 1;
}

if (
  user_sub == 1 &&
  dfp_preview_ids != undefined &&
  dfp_preview_ids.length == 0
) {
  postitial_enabled = 0;
  prestitial_enabled = 0;
}
`;

export default betteradsScript;
