import { ContributorProfile, Profile } from "@app/types/Cue";
import cx from "classnames";

import BylineImage from "./BylineImage";
import BylineName from "./BylineName";
import { getSeparator } from "./helpers";

export type BylineProps = {
  profiles: (Profile | ContributorProfile)[];
  rootClassName?: string;
  bylineNameRootClassName?: string;
  imageWidth?: number;
  imageHeight?: number;
  timeStamp?: React.ReactElement;
  alwaysShowDefaultPhoto?: boolean;
};

export default function Byline({
  profiles = [],
  imageWidth = 50,
  imageHeight = 50,
  rootClassName,
  bylineNameRootClassName,
  timeStamp,
  alwaysShowDefaultPhoto = false,
}: BylineProps): React.ReactElement {
  const profilesCount = profiles.length || 0;

  return (
    <>
      {profilesCount > 0 ? (
        <div
          className={cx(
            "flex flex-row items-start justify-start gap-2",
            rootClassName
          )}
          data-testid="byline-component"
        >
          {profilesCount >= 1 && profilesCount <= 3 ? (
            <div className="flex flex-shrink-0 flex-row items-center justify-start">
              {profiles?.map((profile, index) => {
                return (
                  <BylineImage
                    key={profile.content.id}
                    rootClassName={cx({ "-ml-2": index !== 0 })}
                    profile={profile}
                    width={imageWidth}
                    height={imageHeight}
                  />
                );
              })}
            </div>
          ) : null}

          {profilesCount >= 4 ? (
            <BylineImage
              width={imageWidth}
              height={imageHeight}
              rootClassName="flex-shrink-0"
            />
          ) : null}

          <div className="flex flex-col flex-wrap pl-2">
            <div className="flex flex-wrap gap-x-1.5">
              {profiles?.map((profile, index) => {
                return (
                  <BylineName
                    key={profile.content.id}
                    profile={profile}
                    separator={getSeparator(index, profilesCount)}
                    rootClassName={cx(
                      "align-middle font-poppins font-semibold text-gray-850 leading-6 word-break text-base"
                    )}
                    linkClassName={bylineNameRootClassName}
                  />
                );
              })}
            </div>
            {timeStamp}
          </div>
        </div>
      ) : (
        <div className="flex items-center">
          {alwaysShowDefaultPhoto ? (
            <BylineImage
              width={imageWidth}
              height={imageHeight}
              rootClassName="flex-shrink-0"
            />
          ) : null}

          <div className="flex flex-col flex-wrap pl-2">{timeStamp}</div>
        </div>
      )}
    </>
  );
}
