import { WebCoreSecretsManager } from "@sphtech/web2-secrets";
import aws4 from "aws4";
import axios from "axios";

import { AlacrityRequestHeaders, AlacrityRequestPayload } from "./types";

const makeAlacrityApiCall = async (
  apiEndpoint: string,
  requestHeaders: AlacrityRequestHeaders,
  requestPayload: AlacrityRequestPayload
) => {
  const alacrityBaseDomain = (
    await WebCoreSecretsManager.getWeb2Secret("ALACRITY_DOMAIN")
  ).value;

  const fullUrl = alacrityBaseDomain + apiEndpoint;

  // Hardcode to test in local.
  // const AWS_ACCESS_KEY_ID = "";
  // const AWS_SECRET_ACCESS_KEY = "";
  // const AWS_SESSION_TOKEN = "";

  const AWS_ACCESS_KEY_ID = process.env.AWS_ACCESS_KEY_ID;
  const AWS_SECRET_ACCESS_KEY = process.env.AWS_SECRET_ACCESS_KEY;
  const AWS_SESSION_TOKEN = process.env.AWS_SESSION_TOKEN;

  const awsConfig = {
    secretAccessKey: AWS_SECRET_ACCESS_KEY,
    accessKeyId: AWS_ACCESS_KEY_ID,
    sessionToken: AWS_SESSION_TOKEN,
    service: "execute-api",
    region: "ap-southeast-1",
  };

  // Parse the apiEndpoint to extract protocol, hostname, and path
  const url = new URL(fullUrl);

  const headers = {
    "content-type": "application/json",
  };

  // Check if requestHeaders is not empty
  if (Object.keys(requestHeaders).length > 0) {
    // Merge requestHeaders into headers
    Object.assign(headers, requestHeaders);
  }

  const request = {
    host: url.hostname,
    method: "POST",
    url: fullUrl,
    data: requestPayload,
    body: JSON.stringify(requestPayload),
    path: url.pathname,
    headers: headers,
  };
  const signedRequest: object = aws4.sign(request, awsConfig);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let response: any = {};
  try {
    response = await axios(signedRequest);

    return response.data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(
      "Error Response: In makeAlacrityApiCall Got error response from Alacrity API call ",
      JSON.stringify(response)
    );
    // eslint-disable-next-line no-console
    console.error(
      "Err in catch bloclk in makeAlacrityApiCall(): ",
      JSON.stringify(err)
    );
  }

  // eslint-disable-next-line no-console
  console.timeLog(
    `Received response from Alacrity API: ${JSON.stringify(response)}`
  );
  return response;
};

export default makeAlacrityApiCall;
