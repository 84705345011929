import { useEffect } from "react";
import sha256 from "crypto-js/sha256";
import Cookies from "js-cookie";

import { LIVE_RAMP_PID } from "../util/constant";

export const COOKIE_SPH_LR = "mysph_lr";
export const COOKIE_SPH_TYPE = "mySPHUserType";
export const LOCAL_STORAGE_LR_ENVELOPE = "_lr_env";
export const LOCAL_STORAGE_LR_ENVELOPE_EXPIRY = "_lr_env_exp";
export const LOCAL_STORAGE_LR_PAIR_ID = "_lr_pairId";
export const LOCAL_STORAGE_LR_PAIR_ID_INIT = "_lr_pairId_init";
export const LOCAL_STORAGE_LR_PAIR_ID_EXPIRY = "_lr_pairId_exp";

export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export const LIVE_RAMP_URL = "https://api.rlcdn.com/api/identity/v2/envelope";
enum Mode {
  FETCH,
  REFRESH,
}
const MODE_URLS = {
  [Mode.FETCH]: LIVE_RAMP_URL,
  [Mode.REFRESH]: LIVE_RAMP_URL + "/refresh",
};

const isUserLoggedIn = () => {
  return Cookies.get(COOKIE_SPH_LR) !== undefined;
};
const isUserAnonymous = () => {
  return Cookies.get(COOKIE_SPH_TYPE) === "y-anoy";
};

const isLRAvailableInStorage = (): boolean => {
  return localStorage.getItem(LOCAL_STORAGE_LR_ENVELOPE) !== null;
};

const isLRLocalStorageExpired = () => {
  const expiryTimestamp = localStorage.getItem(
    LOCAL_STORAGE_LR_ENVELOPE_EXPIRY
  );
  if (!expiryTimestamp) return true; // If the item doesn't exist, consider it expired
  return new Date().getTime() >= Number(expiryTimestamp);
};

interface UseLiveRampProps {
  fetchEnvelope: (url: string) => Promise<string | null>;
}

export const useLiveRamp = async ({ fetchEnvelope }: UseLiveRampProps) => {
  useEffect(() => {
    async function callFetch() {
      if (!isUserLoggedIn()) {
        return;
      }

      if (isLRAvailableInStorage() || isLRLocalStorageExpired()) {
        if (isUserAnonymous()) {
          await callEnvelope(Mode.REFRESH);
          return;
        } else {
          await callEnvelope(Mode.FETCH);
          return;
        }
      }

      if (!isUserAnonymous()) {
        await callEnvelope(Mode.FETCH);
        return;
      }
    }
    callFetch();
  }, [fetchEnvelope]);
};

async function callEnvelope(mode: Mode): Promise<void> {
  const url = constructLiveRampURL(mode);

  if (url == null) return;

  const values = await fetchEnvelope(url);

  if (values == null) return;

  setLiveRampLocalStorage(values);
}

export const fetchEnvelope = async (url: string): Promise<string | null> => {
  try {
    const response = await fetch(url);
    const json = await response.json();

    return json;
  } catch (error) {
    return null;
  }
};

export const constructLiveRampURL = (mode: Mode): string | null => {
  const sphCookie = Cookies.get(COOKIE_SPH_LR);

  const localStorageValue = getLocalStorage(LOCAL_STORAGE_LR_ENVELOPE);
  let envelopeValue;
  if (localStorageValue !== null) {
    envelopeValue = base64Decode(decodeURIComponent(localStorageValue));
  }

  const it = mode === Mode.REFRESH ? "19" : "4";

  let iv;

  if (mode == Mode.REFRESH) {
    if (!envelopeValue) return null;
    iv = JSON.parse(envelopeValue).envelope;
  } else {
    if (!sphCookie) return null;
    iv = sha256(sphCookie).toString();
  }

  const url = new URL(MODE_URLS[mode]);

  url.searchParams.set("pid", LIVE_RAMP_PID);
  url.searchParams.set("it", it);
  url.searchParams.set("iv", iv);

  return url.toString();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setLiveRampLocalStorage = (response: any) => {
  if (!response || !response.envelopes || response.envelopes.length === 0)
    return;

  if (response.envelopes[0].value) {
    const lrEnv = {
      envelope: response.envelopes[0].value,
    };

    setLocalStorage(
      LOCAL_STORAGE_LR_ENVELOPE,
      base64Encode(JSON.stringify(lrEnv))
    );
    setLocalStorage(
      LOCAL_STORAGE_LR_ENVELOPE_EXPIRY,
      Date.now() + 3600 * 1000 * 24
    );
  }

  if (response.envelopes.length > 1 && response.envelopes[1].value) {
    const lrPairIdEnv = {
      envelope: JSON.parse(base64Decode(response.envelopes[1].value)),
    };
    setLocalStorage(
      LOCAL_STORAGE_LR_PAIR_ID,
      base64Encode(JSON.stringify(lrPairIdEnv))
    );
    setLocalStorage(LOCAL_STORAGE_LR_PAIR_ID_INIT, Date.now());

    if (isExpired(getLocalStorage(LOCAL_STORAGE_LR_PAIR_ID_EXPIRY))) {
      setLocalStorage(LOCAL_STORAGE_LR_PAIR_ID_EXPIRY, setExpiry());
    }
  }
};

// Encode data to Base64
export function base64Encode(str: string): string {
  if (typeof window !== "undefined" && typeof window.btoa === "function") {
    // Browser environment
    return btoa(str);
  }

  return str;
}

// Decode Base64 to string
function base64Decode(str: string): string {
  if (typeof window !== "undefined" && typeof window.atob === "function") {
    // Browser environment
    return atob(str);
  }

  return str;
}

function setExpiry(): number {
  const current: Date = new Date();
  const hour = current.getHours();

  if (hour < 5) {
    // Before 5AM
    // Set Expiry to 10:59 AM
    return current.setHours(10, 59);
  } else if (hour > 4 && hour < 10) {
    // 11AM
    // Set Expiry to 4:59 PM
    return current.setHours(16, 59);
  } else if (hour > 9 && hour < 17) {
    // 5PM
    // Set Expiry to 10:59 PM
    return current.setHours(22, 59);
  } else if (hour > 16 && hour < 23) {
    // 11PM
    // Set Expiry to 4:59 AM
    return current.setHours(4, 59);
  } else {
    return current.setHours(current.getHours() + 1); // default to setting the expiry to +1 hour
  }
}

function setLocalStorage(key: string, value: string | number | boolean) {
  localStorage.setItem(key, value.toString());
}

/**
 * Retrieves the value stored in local storage associated with the provided key.
 * @param key The key used to retrieve the value from local storage.
 * @returns The value associated with the key in local storage, or null if the key does not exist.
 */
function getLocalStorage(key: string): string | null {
  return localStorage.getItem(key);
}

/**
 * Checks if the provided timestamp is expired by comparing it with the current time.
 * @param current The timestamp to compare against the current time.
 * @returns True if the provided timestamp is expired, otherwise false.
 */
function isExpired(current: Date | number | string | null): boolean {
  if (current === null) {
    return false;
  }
  // Convert the current timestamp to a Date object if it's not already one
  const currentTimestamp = new Date(current);

  // Compare the current time with the provided timestamp
  return new Date() > currentTimestamp;
}
