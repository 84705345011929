/**
 * Company: SPHMedia
 * Description: Section link of the article
 */

import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { getMappedSectionName } from "@util/helpers";
import cx from "classnames";

export type SectionLinkProps = {
  rootClassName?: string;
  sectionName?: string;
  sectionUniqueName?: string;
  isLinkRequired?: boolean;
};

export default function SectionLink({
  rootClassName = "",
  sectionName = "",
  sectionUniqueName = "",
  isLinkRequired = true,
}: SectionLinkProps): ReactElement {
  const cls = cx(
    "uppercase hover:underline font-poppins font-light text-4xs tracking-[1px]",
    rootClassName
  );

  return (
    <>
      {isLinkRequired ? (
        <Link
          className={cls}
          to={
            sectionUniqueName
              ? RouteFactory.section(sectionUniqueName.replace("_", "/"))
              : RouteFactory.home
          }
          target="_blank"
          rel="noreferrer"
          referrerPolicy="strict-origin-when-cross-origin"
          title={sectionName}
          data-testid="section-link-component"
        >
          {getMappedSectionName(sectionUniqueName, sectionName)}
        </Link>
      ) : (
        <div className={cls} data-testid="section-link-component">
          {sectionName}
        </div>
      )}
    </>
  );
}
