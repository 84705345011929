import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoMyBT from "@assets/logo-mybt-light.svg";
import Button from "@components/AccessibleComponents/Button";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { MYBT_ESHOP_SUBSCRIBE_CTA } from "@util/constant";
import { myBTLoginTracker } from "@util/helpers";

interface KeywordLimitModalProps {
  onClose: () => void;
}

export default function KeywordLimitModal({
  onClose,
}: KeywordLimitModalProps): React.ReactElement {
  const closeModal = () => {
    onClose();
  };
  const [isUserType, setIsUserType] = useState(OKTAUserTypeEnum.ANONYMOUS);

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  const logged_user =
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER ||
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.REGISTERED;

  const trackMyBtLogin = () => {
    closeModal();
    myBTLoginTracker(logged_user, "edit my keywords");
  };

  useEffect(() => {
    OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER
      ? setIsUserType(OKTAUserTypeEnum.SUBSCRIBER)
      : setIsUserType(OKTAUserTypeEnum.REGISTERED);
  }, [OKTAUserInfo?.usertype]);

  return (
    <>
      <div className="fixed bottom-0 left-0 z-50 md:!left-1/4 md:bottom-auto md:mt-1/10 lg:!left-7/20 lg:mt-1/10">
        <div className="modal-container mx-auto w-full rounded-md bg-white ring-1 ring-gray-400 md:w-[500px]">
          <div className="relative">
            <div className="flex items-center justify-between rounded-t-md p-1">
              {/* <!-- Modal title --> */}
              <div className="text-neutral-800 dark:text-neutral-200 mx-auto text-xl font-medium lg:w-1/3"></div>
              {/* <!-- Close button --> */}
              <Button
                type="button"
                className="invisible box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none md:visible"
                onPress={closeModal}
              >
                <div className="fa-stack relative">
                  <FontAwesomeIcon icon={faXmark} className="fa-stack-2x" />
                </div>
              </Button>
            </div>
            <div className="px-3 pb-3">
              <div className="item-center mx-auto flex place-content-center content-center">
                <img
                  alt="logo-mybt-light.svg"
                  src={logoMyBT}
                  width={106}
                  height={40}
                />
              </div>
            </div>
            {isUserType === OKTAUserTypeEnum.REGISTERED ? (
              <div className="px-3 md:pb-3">
                <h2 className="my-3 text-center text-3xl font-semibold">
                  Enjoying myBT so far?
                </h2>
                <p className="px-2 text-center text-gray-850 text-opacity-80">
                  Now that you have experienced myBT with just a few keywords,
                  subscribe to BT Premium to get full-access.
                </p>
                <Link
                  to={MYBT_ESHOP_SUBSCRIBE_CTA}
                  className="mx-auto block w-[45%] items-center rounded-full border-orange-300 bg-orange-300 px-4 py-2 text-center text-base font-bold !text-white md:w-7/20"
                >
                  SUBSCRIBE NOW
                </Link>
              </div>
            ) : (
              <div className="px-3 md:pb-3">
                <h2 className="my-3 text-center text-3xl font-semibold">
                  We are glad that you enjoy myBT
                </h2>
                <p className="px-2 text-center text-gray-850 text-opacity-80">
                  You have reached your limit of 30 keywords. subscribe to BT To
                  follow new keywords, edit your myBT settings..
                </p>
                <Link
                  to={"/mybt/onboard"}
                  className="mx-auto block w-[55%] rounded-full px-5 py-2 text-center text-base font-normal ring-1 ring-black md:w-[45%] md:px-7"
                  onClick={trackMyBtLogin}
                  reloadDocument
                >
                  EDIT MY KEYWORDS
                </Link>
              </div>
            )}
            <div className="container cursor-pointer md:hidden">
              <Button
                type="button"
                className="text-md w-full px-6 pb-2 pt-2.5 font-normal uppercase text-black focus:outline-none"
                onPress={closeModal}
              >
                MAYBE LATER
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
