import { useEffect, useRef, useState } from "react";
import { AdvertisementTypeEnum } from "@src/app/types/enums";

export function useAdvertLoaded(type: AdvertisementTypeEnum, id?: string) {
  const [advertLoaded, setAdvertLoaded] = useState(false);
  const targetNodeRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const targetNodeId = id || generateAdvertElementId(type);
    const callBack = () => {
      if (
        targetNodeRef?.current?.children[0]?.firstElementChild?.tagName ===
        "IFRAME"
      ) {
        setAdvertLoaded(true);
      }
    };

    targetNodeRef.current = document.getElementById(targetNodeId);
    const observer = new MutationObserver(callBack);

    if (targetNodeRef.current) {
      observer.observe(targetNodeRef.current, {
        childList: true,
        subtree: true,
      });
    }
  }, [id, type, setAdvertLoaded]);

  return advertLoaded;
}

export const generateAdvertElementId = (type: AdvertisementTypeEnum) => {
  return `dfp-ad-${type}`;
};
