import { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";

const fetchBrightcovePlayerId = async (): Promise<string> => {
  try {
    const response = await axios.post<string>(
      "/_plat/api/v1/brightcove-player-id"
    );
    return response.data || "default";
  } catch (e) {
    return "default";
  }
};

export default function useBrightcovePlayerId(): string | undefined {
  const [playerId, setPlayerId] = useState<string | undefined>();

  useEffect(() => {
    const callFetch = async () => {
      setPlayerId(await fetchBrightcovePlayerId());
    };

    try {
      callFetch();
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return playerId;
}
