import { Link } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import ImuBanner from "@assets/newsletter/nl-banner-imu.gif";
import skewedDiverse from "@assets/newsletter/skewed-diverse.png";
import { renderGreenLink } from "@pages/Newsletter/Archive/util/helpers";

const links = [
  {
    href: "https://www.businesstimes.com.sg/opinion-features/are-we-track-gender-parity-boards-singapore",
    text: "opinion",
  },
  {
    href: "https://www.businesstimes.com.sg/companies-markets/women-directorships-highest-record-across-three-sectors-2023",
    text: "Data",
  },
  {
    href: "https://www.tandfonline.com/doi/abs/10.1080/00091383.2010.489022",
    text: "study",
  },
  {
    href: "https://www.businesstimes.com.sg/esg/uk-watchdog-gives-funds-anti-greenwashing-rule-extension",
    text: "extend",
  },
  {
    href: "https://www.businesstimes.com.sg/esg/worlds-most-stringent-esg-rules-draw-backlash-eu-business",
    text: "moving",
  },
  {
    href: "https://www.businesstimes.com.sg/property/ok-lims-tanglin-hill-bungalow-sells-s39-2-million",
    text: "Tanglin Hill bungalow",
  },
  {
    href: "https://www.businesstimes.com.sg/property/blackrock-close-buying-299-unit-citadines-raffles-place-s300-million",
    text: "Citadines Raffles Place",
  },
  {
    href: "https://www.businesstimes.com.sg/international/global/kkr-hulic-bid-us2-billion-gics-central-tokyo-skyscraper",
    text: "sovereign wealth fund GIC",
  },
  {
    href: "https://www.businesstimes.com.sg/opinion-features/heres-what-private-equity-may-be-getting-right",
    text: "EQT could continue to cut expenses",
  },
  {
    href: "https://www.businesstimes.com.sg/companies-markets/s-reit-dpus-weighed-down-higher-interest-rates-h1-tide-turn-h2",
    text: "turn the tide for Reits",
  },
  {
    href: "https://www.businesstimes.com.sg/opinion-features/new-dawn-s-reits-fed-rate-cuts-get-underway",
    text: "underlying fundamentals",
  },
  {
    href: "https://www.businesstimes.com.sg/companies-markets/oxley-holdings-expects-net-loss-h2-fy2024-earnings",
    text: "Oxley Holdings",
  },
  {
    href: "https://www.businesstimes.com.sg/property/us-new-home-sales-rise-more-expected-july",
    text: "US single-family homes",
  },
  {
    href: "https://www.businesstimes.com.sg/property/relevance-real-estate-agent-table",
    text: "not hiring a real estate agent",
  },
  {
    href: "mailto:lyee@sph.com.sg",
    text: "lyee@sph.com.sg",
  },
];

export default function EsgInsightsNewsletterSampleContent(): React.ReactElement {
  const bannerLink = RouteFactory.newsletterSignup;

  return (
    <>
      <div className="today-highlights-wrapper px-5 pb-6">
        <h4 className="mb-2.5 font-pullQuote font-bold leading-8 text-gray-850">
          This week in ESG
        </h4>

        <ul className="mb-2.5 list-disc pl-7 font-arial text-sm font-normal leading-5">
          <li className="pb-2">
            Paying attention to the process of board appointments
          </li>
          <li className="pb-2">Supporting policy with credible strategies</li>
        </ul>
      </div>
      <div className="mx-5 border-t-[3px] border-gray-250 pb-6" />
      <div className="front-copy w-full max-w-2xl px-5 text-left font-arial font-normal">
        <div className="greeting-text-wrapper ">
          <span className="font-sm font-pullQuote font-bold text-verticals-esg">
            Board diversity
          </span>
          <h4 className="mb-2.5 font-pullQuote font-bold leading-8 text-gray-850">
            How you get there matters
          </h4>
          <div>
            <img
              height="72"
              src={skewedDiverse}
              alt="READ MORE"
              className="my-6 block h-auto max-w-full"
            />
          </div>
          <p className="mb-2.5 font-arial text-sm leading-6">
            The elusive quest for gender parity among the boards of
            Singapore-listed companies has drawn a call for a new strategy:
            Search better.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            In an {renderGreenLink("opinion", links[0].href)} piece, Seah Gek
            Choo, Deloitte Southeast Asia and Singapore boardroom programme
            leader, and Terence Lam, director for advocacy and professional
            standards at the Institute of Singapore Chartered Accountants, put
            most of the blame on companies “not holistically considering their
            board candidates from a broader talent pool”.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            Companies need to move past their preference for candidates with
            chief executive officer experience to include other C-suite
            expertise, they said. Looking beyond gender diversity, companies
            should also consider a diverse mix of skills, backgrounds and
            capabilities.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            The focus on search processes could be a powerful shift in the
            market’s approach towards board diversity and gender parity, but the
            authors can perhaps go further than just looking at skill sets.
            Instead of aiming diversity targets at board composition
            percentages, companies might make better progress if more targets
            and processes were applied to how candidates are found and assessed
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            A point of pride among Singapore’s board gender diversity movement
            has been the progress made without a national quota. Data by the
            Council for Board Diversity (CBD) showed that women held 23.7 per
            cent of board seats among the 100 largest Singapore-listed companies
            as at December 2023, significantly better than the 7.5 per cent
            participation rate 10 years earlier
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            One of the driving forces behind the progress has been a regulatory
            requirement to disclose diversity policies, including targets. But
            despite that requirement, diversity outside of the largest companies
            has been slow. As at end-2023, women held only 16.1 per cent of
            board seats among all Singapore-listed companies. Furthermore, 43
            per cent of boards outside of the top 100 listed companies have no
            female directors
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            It might be time to consider shifting the focus away from the
            eventual board composition and towards how search and assessment are
            carried out.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            An important reason to do so is to avoid the trap of reaching the
            numerical target but getting there the wrong way. If companies
            appoint female directors just to meet a target, that raises the risk
            of underqualified token appointments and nepotism. As CBD points out
            on its website, “meeting quotas does not guarantee that benefits
            follow”.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            What might a process-centric diversity policy include?
          </p>

          <ul className="mb-2.5 list-disc pl-7 font-arial text-sm font-normal leading-6">
            <li className="pb-2">
              <strong>Diversity targets for candidate slates.</strong> One of
              the most cited reasons for the under-representation of women on
              boards is that nomination committees are drawing from a small pool
              of potential candidates. This leads to the mistaken perception
              that there are not enough women directors. To address that,
              leading search firms in Singapore have developed a “Statement of
              Good Practice” that states “search firms should ensure that 20 to
              25 per cent of the candidates are women” during executive
              searches. That percentage should perhaps be higher, given that
              research suggests 30 per cent is the critical level at which
              members of under-represented groups are judged more fairly.
              Nominating committees should also commit to that threshold.
            </li>
            <li className="pb-2">
              <strong>Diversity training for directors.</strong> Even a diverse
              pool of candidates can be defeated if the assessors cannot
              overcome their biases. Ensuring that nominating committee members
              – possibly all board members – receive adequate training about
              diversity, equity and inclusion can help to overcome those
              prejudices.
            </li>
            <li>
              <strong>Pro-active and continuous searches.</strong> A US study
              into higher education diversity hiring practices found that hiring
              pools were improved and more diverse when the search wasn’t only
              handled by a search committee when a need arose. The suggestion is
              to get every board member – even those not on the nominating
              committee – to see it as part of their responsibility to be on the
              lookout for potential candidates all the time, and to add those
              candidates to a constantly-refreshed list. This not only maintains
              a wider pool that can be tapped in times of need, it also ensures
              that the search pool overcomes limitations and biases of the
              nominating committee.
            </li>
          </ul>

          <p className="!mb-14 font-arial text-sm leading-6">
            A diversity policy focused on processes can still include board
            composition targets to ensure that progress is sufficiently
            ambitious. But because the processes are more robust, companies and
            stakeholders will have greater confidence that when they do reach
            their goals, they would have gotten there the right way.
          </p>
        </div>
        <div className="mt-6 border-t-[3px] border-gray-250 pb-6" />

        <div className="mx-auto flex max-w-[300px] justify-center py-6">
          <Link
            className="no-underline"
            to={bannerLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={ImuBanner}
              alt="Imu Banner"
              width="300"
              height="180"
              className="block h-auto max-w-full"
            />
          </Link>
        </div>

        <div className="greeting-text-wrapper ">
          <div className="mt-6 border-t-[3px] border-gray-250 pb-6" />

          <span className="font-sm font-pullQuote font-bold text-verticals-esg">
            ESG policy
          </span>
          <h4 className="mb-2.5 font-pullQuote font-bold leading-8 text-gray-850">
            Supporting the stretch
          </h4>
          <p className="mb-2.5 font-arial text-sm leading-6">
            The most beautiful rule in the world is meaningless if nobody
            follows it.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            That’s the principle that some policymakers are having to confront
            on environmental, social and governance (ESG) matters as
            well-intended rules written in the past come up against
            implementation challenges.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            In the United Kingdom, the Financial Conduct Authority has had to{" "}
            {renderGreenLink("extend", links[0].href)} the October deadline for
            funds to meet a requirement on naming and marketing rules aimed at
            reducing greenwashing.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            European businesses are {renderGreenLink("moving", links[0].href)}{" "}
            investments and operations away from the European Union – where they
            grumble that stringent ESG rules are hurting competitiveness and
            depressing valuations and bottom lines – to less demanding
            jurisdictions in the latest version of sustainability regulatory
            arbitrage.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            It’s important that South-east Asian policymakers learn the right
            lessons.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            The easy reaction is to dial back on ambition. For example, pushing
            too hard for costly decarbonisation can alienate voters, hurt
            incumbent industrial sectors and deplete national coffers. Planetary
            boundaries need to be balanced against social ones, and it’s
            reasonable for transition pathways to be informed by what’s
            realistically achievable given existing constraints and resources.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            But it’s also true that the capacity for change is not set in stone.
            Instead of lowering ambitions, policymakers could potentially
            achieve better outcomes if they paired stretch targets with credible
            strategies to reach them.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            That’s why capacity-building and ecosystem development projects like
            Singapore’s Project Greenprint, which aims to build a national ESG
            database, or the Asean-Interconnected Sustainability Ecosystem, an
            ESG data collaboration between Bursa Malaysia, Indonesia Stock
            Exchange, the Stock Exchange of Thailand and Singapore Exchange, are
            important aspects of policy implementation.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            Sustainability reporting will test the region’s ability to aim high
            and build a strong bow. As large customers increasingly green their
            supply chains to address expanded emissions reporting requirements
            and meet carbon pricing regulations in their home markets, the
            region’s small and medium-sized enterprises will need to better
            account for their carbon footprint or large swathes of the economy
            could be disadvantaged in international trade.
          </p>

          <p className="mb-2.5 font-arial text-sm leading-6">
            Policymakers will need to set standards that keep the companies
            competitive globally, while also providing the support to help
            companies comply.
          </p>
        </div>
        <div className="mt-6 border-t-[3px] border-gray-250 pb-6" />
      </div>
    </>
  );
}
