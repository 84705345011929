import { useEffect, useState } from "react";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import axios from "axios";

export const useHomepageVideos = () => {
  const [videos, setVideos] = useState<BrightcoveVideo[]>([]);

  useEffect(() => {
    const fetchVideos = async () => {
      const response = await axios.post<BrightcoveVideo[]>(
        "/_plat/api/v1/homepage-videos"
      );

      const fetchedVideos = response.data || [];

      setVideos(fetchedVideos);
    };

    fetchVideos();
  }, []);

  return { videos };
};
