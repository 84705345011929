import { Link } from "react-router-dom";
import { Relation } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import { cn, getMimeTypeIcon } from "@app/util/helpers";

export type GraphicProps = {
  className?: string;
  attachment?: Relation;
};

export default function Graphic({
  className,
  attachment,
}: GraphicProps): React.ReactElement {
  const iconPath = getMimeTypeIcon(attachment?.href || "");
  const attachmentLink = attachment?.["attachmentLink-caas"];

  if (!attachmentLink) return <></>;

  return (
    <>
      {attachment ? (
        <div
          data-testid="graphic-element-component"
          data-story-element={Type.Graphic}
        >
          <div className={cn(className)}>
            {iconPath ? (
              <span
                className="inline-block h-4 w-4 bg-center bg-no-repeat align-middle"
                style={{ backgroundImage: `url(${iconPath})` }}
              ></span>
            ) : null}

            <Link
              className="file pl-2 font-poppins text-base font-normal hover:!text-gray-850 hover:underline"
              to={attachmentLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {attachment.title}
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
}
