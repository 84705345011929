/**
 * Helper function to get active section path.
 * @param {string} pathname Current page path.
 * @param {string} childCategory To check if child category present.
 * @returns {string}
 */
export const getActiveSectionPath = (
  pathname: string,
  childCategory?: string
): string => {
  const slashOccurrence = childCategory ? 3 : 2;
  const activeSectionPath = pathname.substring(
    0,
    pathname.split("/", slashOccurrence).join("/").length
  );

  return activeSectionPath;
};
