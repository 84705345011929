import { Link } from "react-router-dom";
import { sideNavigationItems } from "@pages/Thrive/constants";
import cx from "classnames";

import NavigationItem from "./NavigationItem";

export default function ThriveSideNavigation(): React.ReactElement {
  const navItemStyle =
    "px-2 py-3 thrive-past-event-title text-white flex justify-center text-center font-interBold text-11xl text-shadow-thrive-2";

  return (
    <nav
      aria-label="header-nav"
      className="thrive-side-nav fixed inset-0 h-full w-full bg-orange-400"
    >
      <ul className="space-y-11 px-5 pt-5">
        {sideNavigationItems.map(({ to, label, icon }, index) =>
          index === 0 ? (
            <li key={to}>
              <Link to={to} className="inline-block" reloadDocument>
                <img src={icon} width="150" height="56" alt="Thrive" />
              </Link>
            </li>
          ) : (
            <NavigationItem
              key={to}
              to={to}
              className={cx(navItemStyle, icon && "flex items-center")}
            >
              {label}
              {icon && (
                <img src={icon} width="50" height="30" className="pl-5" />
              )}
            </NavigationItem>
          )
        )}
      </ul>
    </nav>
  );
}
