import type { ButtonProps as AriaButtonProps } from "react-aria-components";
import Button from "@components/AccessibleComponents/Button";

export type StartReadingProps = AriaButtonProps & {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function StartReading(
  props: StartReadingProps
): React.ReactElement {
  const { setIsModalOpen } = props;

  const handleClick = () => {
    if (typeof setIsModalOpen === "undefined") return;

    setIsModalOpen(false);
  };

  return (
    <Button
      {...props}
      onPress={handleClick}
      className="w-full rounded-full bg-black px-9 py-3 font-poppins text-base font-semibold text-white outline-none"
    >
      START READING
    </Button>
  );
}
