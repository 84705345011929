import { useEffect, useState } from "react";

type ExpireProps = {
  delay?: number;
  children?: React.ReactNode;
};

export default function Expire({
  delay = 5000,
  children,
}: ExpireProps): React.ReactElement {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  return <>{visible ? children : null}</>;
}
