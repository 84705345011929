import Container from "@components/Container/Container";

export default function ThriveSampleNewsletterPollResult(): React.ReactElement {
  return (
    <Container
      rootClassName="px-0"
      data-testid="thrive-home-sample-newsletter-poll-result-component"
    >
      <p className="m-0 mb-2 p-0 font-interBold text-2xl">
        {"Last week's Poll"}
      </p>

      <p className="m-0 mb-2 p-0">
        Is AI your friend or enemy when it comes to work?
      </p>

      <p className="m-0 mb-2 p-0 font-interBold">
        {"It's going to take away my job 😫 ! – 50%"}
      </p>

      <p className="m-0 mb-2 p-0 font-interBold">
        {"I think I'll figure out how to roll with this…– 50%"}
      </p>
    </Container>
  );
}
