import { useEffect } from "react";
import { sectionRequests } from "@pages/Section/Section.server";
import useMyBtStore from "@store/useMyBtStore";
import { OKTAUserTypeEnum } from "@store/useOKTAUserStore";

/**
 * Hook function to get followed keywords by a user.
 * @param userType User type.
 * @param aovisitorid User visitor unique id.
 * @returns {void}
 */
export default function useMyBtFollowedKeywordsInfo(
  userType: OKTAUserTypeEnum = OKTAUserTypeEnum.ANONYMOUS,
  aovisitorid = ""
): void {
  const setTags = useMyBtStore((myBtStore) => myBtStore.setTags);

  useEffect(() => {
    const fetchFollowedKeywords = async () => {
      await sectionRequests
        .fetchMyBtFollowedKeywords(userType, aovisitorid)
        .then((response) => {
          setTags(response ?? []);
        });
    };

    if (userType !== OKTAUserTypeEnum.ANONYMOUS || aovisitorid !== "") {
      fetchFollowedKeywords();
    }
  }, [userType, aovisitorid, setTags]);
}
