import iconGift from "@assets/icon-gift-v2.svg";
import { SUCCESS_RESPONSE_GIFT_ICON } from "@pages/Article/components/ArticleSubshare/constants";
import { LambdaResponsePayload } from "@pages/Article/types/Alacrity";

export type IconProps = LambdaResponsePayload;

export default function Icon({
  data: { responseType },
}: IconProps): React.ReactElement {
  return (
    <>
      {SUCCESS_RESPONSE_GIFT_ICON.includes(responseType) ? (
        <img
          src={iconGift}
          width={40}
          height={40}
          alt="Gift a story"
          className="mb-4"
        />
      ) : null}
    </>
  );
}
