import ThriveEnjoyNLIcon from "@assets/newsletter/Thrive/banner-enjoy-this-newsletter.png";
import ThriveDisLikeIcon from "@assets/thrive_images/thrive-newsletter-nah.jpg";
import ThriveLikeIcon from "@assets/thrive_images/thrive-newsletter-yeah.jpg";

export default function NewsletterSamplePoll(): React.ReactElement {
  return (
    <div className="m-0 mx-auto mb-12 box-border w-full p-0">
      <div className="w-full border-collapse">
        <div className="py-2 text-center">
          <div className="border-t-[6px] border-double border-[#e8e8e8] pb-8"></div>
          <img
            src={ThriveEnjoyNLIcon}
            alt="Banner"
            width="185"
            height="18"
            className="max-w-17/20 relative mx-auto h-auto"
          />
        </div>
        <div className="py-2 text-center">
          <div className="mr-4 inline-block">
            <a
              href="https://forms.gle/8jaVYCGeQkDvaCce8"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-4 inline-block rounded-full p-4 font-normal text-gray-850 no-underline"
            >
              <img
                src={ThriveLikeIcon}
                alt="Like"
                className="relative"
                height="70"
                width="70"
              />
            </a>
            <p className="mb-2 mr-4 text-base font-normal leading-7 text-gray-850">
              Yeah
            </p>
          </div>
          <div className="mr-4 inline-block">
            <a
              href="https://forms.gle/D8a6cUyTLcMj3deT6"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-4 inline-block rounded-full p-4 font-normal text-gray-850 no-underline"
            >
              <img
                src={ThriveDisLikeIcon}
                alt="Dislike"
                className="relative"
                height="70"
                width="70"
              />
            </a>
            <p className="mb-2 mr-4 text-base font-normal leading-7 text-gray-850">
              Nah
            </p>
          </div>
        </div>
        <div className="py-2 text-center">
          <p className="mb-2 text-base leading-7 text-gray-850">
            Invite your friends to join the Thrive community.
          </p>
        </div>
        <div className="py-2 text-center">
          <p className="mb-2 text-base leading-7 text-gray-850">
            Simply send them this link:{" "}
            <a
              href="http://bt.sg/thrive"
              target="_blank"
              rel="noopener noreferrer"
              className="border-b-2 border-green-400 text-gray-850 no-underline"
            >
              bt.sg/thrive
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
