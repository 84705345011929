import type { ArticleDataObject } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import OpinionsFeaturesStories from "@pages/Home/OpinionFeatures/OpinionFeaturesStories";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import cx from "classnames";

export type OpinionFeaturesProps = {
  testId?: string | null;
  rootClassName?: string;
  data?: ArticleDataObject[];
};

const section = sectionNavigationItems["opinion-features"];

export default function OpinionFeatures({
  testId = null,
  rootClassName,
  data = [],
}: OpinionFeaturesProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  return (
    <section className={rootClassName} data-testid={testId}>
      <BlockTitle
        link={section?.link}
        text={section?.label?.toUpperCase() ?? ""}
      />

      <div
        className={cx(
          "stories lg:grid lg:grid-flow-col lg:grid-cols-3 lg:grid-rows-1"
        )}
      >
        {data?.map((article: ArticleDataObject, index: number) => {
          return (
            <OpinionsFeaturesStories
              key={index}
              article={article}
              index={index}
              disablePaywall={
                OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
              }
            />
          );
        })}
      </div>
    </section>
  );
}
