import { Link } from "react-router-dom";
import cx from "classnames";

export interface SectionMastheadProps {
  rootClassName?: string;
  link?: string;
  masthead?: {
    src: string;
    width: number;
    height: number;
    alt: string;
  };
}

export default function SectionMasthead({
  rootClassName = "",
  link,
  masthead,
}: SectionMastheadProps): React.ReactElement {
  return (
    <div className={cx(rootClassName)} data-testid="section-masthead">
      {link && masthead && masthead.src ? (
        <Link to={link}>
          <img
            src={masthead.src}
            width={masthead.width}
            height={masthead.height}
            alt={masthead.alt}
          />
        </Link>
      ) : null}
    </div>
  );
}
