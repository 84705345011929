import { Media } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import { DefaultImage } from "@components/ArticleCard/types";
import { getDefaultImage } from "@util/helpers";

/**
 * Helper function to get thumbnail image.
 * @param {string} title The article title.
 * @param {Media | Media[]} media The article image.
 * @param {DefaultImage} defaultImage The default image.
 * @returns
 */
export const getCardThumbnail = (
  title: string,
  media?: Media | Media[],
  defaultImage?: DefaultImage
) => {
  const _media = media && "length" in media ? media?.[0] : media;

  const mediaContent =
    _media &&
    (_media.content?.type === "picture" ||
      _media.content?.type === Type.ArticleBrightcoveVideo ||
      _media.content?.type === Type.ArticleYoutubeVideo)
      ? _media.content
      : undefined;
  const thumbnail =
    _media && mediaContent
      ? mediaContent
        ? { ...mediaContent, alt: title }
        : { ..._media, alt: title }
      : undefined;

  const defaultThumbnail = defaultImage
    ? {
        url: getDefaultImage(defaultImage?.directoryName),
        alt: title,
      }
    : undefined;

  return thumbnail || defaultThumbnail;
};

/**
 * Generates a random unique identifier of the specified length.
 *
 * @param length - The desired length of the unique identifier.
 *                 Note that the length should be an even number
 *                 since each byte is represented by two hexadecimal characters.
 * @returns A string representing the unique identifier. If the function is
 *          executed in a non-browser environment, it returns an empty string.
 */
export const getRandomUniqueId = (length: number) => {
  if (typeof window === "undefined") return "";

  const array = new Uint8Array(16);
  window.crypto.getRandomValues(array);
  const uniqueId = Array.from(array, (byte) =>
    byte.toString(16).padStart(2, "0")
  )
    .slice(0, length / 2)
    .join("");

  return uniqueId;
};
