import { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { TagType } from "@pages/Section/types";
import { isArticleUpdated } from "@util/helpers";
import cx from "classnames";

export default function BreakingNewsStories({
  article,
  index,
  disablePaywall = false,
}: {
  article: ArticleDataObject;
  index: number;
  disablePaywall?: boolean;
}): React.ReactElement {
  const variant: CardVariations = {
    kicker: {
      color: "text-gray-515",
    },
    title: {
      color: "text-gray-850",
      size: "text-base",
    },
    content: {
      width: "w-full",
      extraClass: "h-full",
    },
  };

  return (
    <div
      className={cx("story border-b border-gray-175 py-3 lg:col-span-1", {
        "lg:order-1": index < 2,
        "lg:order-3": index >= 2,
        "lg:mr-3": index === 1 || index === 3,
        "lg:border-none": index === 6 || index === 7,
        "border-none lg:border-b": index === 7,
      })}
    >
      <BasicCard
        id={article.id}
        slug={article.slug}
        title={article.displaySetting?.displayHeadline || article.title}
        kicker={article.kicker?.fields?.[0]?.value}
        section={article?.sections?.[0]}
        storyThread={article.tags?.find(
          ({ type }) => type === TagType.StoryThread
        )}
        paywall={
          !disablePaywall ? article.paywall?.contentAccess === "1" : false
        }
        variations={variant}
        rootClassName={cx("h-full border-gray-175", {
          "lg:border-r lg:pr-3": [0, 2, 4, 6].includes(index),
          "lg:pl-3": [1, 3, 5, 7].includes(index),
          "lg:pr-3": [1, 3].includes(index),
        })}
        edited={
          isArticleUpdated(article.updated, article.edited)
            ? article.edited
            : undefined
        }
        updated={article.updated}
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
      />
    </div>
  );
}
