import { BreakingNewsGroup } from "@app/types/BreakingNews";
import { ArticleDataObject } from "@app/types/Cue";
import dayjs from "dayjs";

export function getGroupTitle(group: BreakingNewsGroup) {
  switch (group) {
    case "today":
    case "yesterday":
      return group;
    case "thedaybefore":
      return dayjs().subtract(2, "day").format("MMM DD");
  }
}

export function getFilteredArticles(
  articles: ArticleDataObject[],
  filter: string
) {
  if (!filter) return articles;

  return articles?.filter((article) => {
    const sections = article.sections?.map((section) => section.uniqueName);
    return sections?.includes(filter.replaceAll("/", "_"));
  });
}
