import { useLocation } from "react-router-dom";
import { navigationItems } from "@pages/Thrive/constants";
import cx from "classnames";

import NavigationItem from "./NavigationItem";

export default function ThriveNavigation(): React.ReactElement {
  const navItemClass =
    "relative w-fit block py-1 after:block after:-bottom-0.5 after:absolute after:h-[2px] after:bg-green-400 after:w-full";
  const navItemPseudoClass =
    "after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center";
  const pageLocation = useLocation();

  return (
    <nav aria-label="header-nav" className="hidden md:block">
      <ul className="flex space-x-12">
        {navigationItems.map(({ to, label }, index) => (
          <NavigationItem
            key={index}
            to={to}
            className={cx(
              navItemClass,
              pageLocation.pathname !== to && navItemPseudoClass
            )}
          >
            {label}
          </NavigationItem>
        ))}
      </ul>
    </nav>
  );
}
