import { Await } from "react-router-dom";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import { HubContext } from "./utils/types";
import HubPageContent from "./HubPageContent";

export default function HubPage(): React.ReactElement {
  const { context } = useRouteContext<TRouteWithRedirect<HubContext, string>>();

  // StatusCode is not 200
  if (context && context.statusCode !== HttpStatusCode.Ok) {
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {(context: HubContext) => {
        const { kind, data } = context;

        // Load article page content.
        if (kind === "section") {
          return <HubPageContent data={data} />;
        }

        // Default error page.
        return <CustomError statusCode={HttpStatusCode.NotFound} />;
      }}
    </Await>
  );
}
