import { GARAGE_OTHER_READS } from "@components/Newsletter/constants";
import NewsletterSampleFooter from "@pages/Newsletter/Archive/components/NewsletterSampleFooter";
import NewsletterSampleAuthor from "@pages/Newsletter/Archive/Garage/SampleIssues/components/NewsletterSampleAuthor";
import NewsletterSampleContent from "@pages/Newsletter/Archive/Garage/SampleIssues/components/NewsletterSampleContent";
import NewsletterSampleFiveReads from "@pages/Newsletter/Archive/Garage/SampleIssues/components/NewsletterSampleFiveReads";
import NewsletterSampleHeader from "@pages/Newsletter/Archive/Garage/SampleIssues/components/NewsletterSampleHeader";
import NewsletterSampleOtherReads from "@pages/Newsletter/Archive/Garage/SampleIssues/components/NewsletterSampleOtherReads";
import { cn } from "@util/helpers";

export type GarageSampleIssueProps = {
  divRef?: React.RefObject<HTMLDivElement>;
  rootClassName?: string;
  firstChildClassName?: string;
};

export default function GarageSampleIssue({
  divRef,
  rootClassName = "",
  firstChildClassName = "",
}: GarageSampleIssueProps): React.ReactElement {
  return (
    <>
      <h2 className="mb-3 text-center font-poppins text-base font-medium">
        Sample issue
      </h2>

      <div
        data-testid="garage-iframe"
        ref={divRef}
        className={cn(
          "rounded-2xl border border-gray-850 bg-gray-250",
          rootClassName
        )}
      >
        <div
          className={cn(
            "my-3 h-[525px] overflow-y-scroll px-2",
            firstChildClassName
          )}
        >
          <div className="mx-auto mb-9 w-full max-w-[600px] border-collapse bg-white p-2">
            <NewsletterSampleHeader />

            <NewsletterSampleAuthor />

            <NewsletterSampleContent />

            <NewsletterSampleFiveReads />

            <NewsletterSampleOtherReads newsletters={GARAGE_OTHER_READS} />

            <NewsletterSampleFooter editionId={"63311"} />
          </div>
        </div>
      </div>
    </>
  );
}
