import { Link } from "react-router-dom";
import { Attachment } from "@app/types/Cue";
import { getMimeTypeIcon } from "@app/util/helpers";
import cx from "classnames";

type ArticleAttachmentsProps = {
  rootClassName?: string;
  attachments?: Attachment[];
};

export default function ArticleAttachments({
  rootClassName,
  attachments,
}: ArticleAttachmentsProps): React.ReactElement {
  return (
    <>
      {attachments?.length ? (
        <div data-testid="article-attachments-component">
          {attachments.map((attachment, index) => {
            const iconPath = getMimeTypeIcon(attachment.content.href);
            const attachmentLink = attachment.content["attachmentLink-caas"];

            if (!attachmentLink) return null;

            return (
              <div
                key={index}
                className={cx(
                  rootClassName,
                  "my-2 border-b border-gray-250 py-2"
                )}
              >
                {iconPath ? (
                  <span
                    className="inline-block h-4 w-4 bg-center bg-no-repeat align-middle"
                    style={{ backgroundImage: `url(${iconPath})` }}
                  ></span>
                ) : null}

                <Link
                  className="file pl-2 font-poppins text-base font-normal hover:!text-gray-850 hover:underline"
                  to={attachmentLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {attachment.content.title}
                </Link>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
}
