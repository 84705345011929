import { EmbedProps } from "@app/types/Embed";
import { Name, Type } from "@app/types/enums";
import cx from "classnames";
import IframeResizer from "iframe-resizer-react";

import { SocialEmbedFactory } from "./helpers";

export default function Embed({
  className,
  fields,
}: EmbedProps): React.ReactElement {
  const src = decodeURIComponent(
    fields.find((field) => field.name === Name.Uri)?.value || ""
  );
  // @TODO Check with Kevin (CUE TEAM) where is this used, do we need it?.
  // const htmlAnchor = fields.find((field) => field.name === Type.HTMLAnchor)?.value;
  const cssClass = fields.find((field) => field.name === Type.CSSClass)?.value;
  const dataAttributes = fields.find(
    (field) => field.name === Type.DataAttributes
  )?.value;
  const attr = dataAttributes
    ?.split(";")
    .map((data) => {
      return data.split("=");
    })
    .reduce((prev, cur) => {
      return { ...prev, [cur?.[0]]: cur?.[1] };
    }, {});

  const iframeAttributes = {
    className: cx("box-content w-full overflow-auto border-none", cssClass, {
      "aspect-video": !src.includes("omny.fm"),
    }),
    src: src,
    width: "100%",
  };

  const SocialEmbed = SocialEmbedFactory(src);

  return (
    <div
      className={className}
      data-story-element={Type.Embed}
      data-testid="embed-component"
    >
      {SocialEmbed ? (
        <SocialEmbed url={src} className={cssClass} />
      ) : (
        <IframeResizer {...iframeAttributes} {...attr} />
      )}
    </div>
  );
}
