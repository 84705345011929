import { useEffect } from "react";

type Props = {
  id: string;
  value: string;
};

export default function useScriptFunction({ id, value }: Props) {
  useEffect(() => {
    if (document.getElementById(id)) {
      return;
    }

    const script = document.createElement("script");

    script.type = "text/javascript";
    script.innerHTML = value;
    script.id = id;

    document.head.appendChild(script);
  }, [id, value]);
}
