import AnnotationWrapper from "@pages/Article/components/StoryElements/Annotation/AnnotationWrapper";
import {
  Annotation,
  AnnotationType,
} from "@pages/Article/components/StoryElements/Annotation/utils/types";

const annotationOrder = [
  AnnotationType.ExternalLink.valueOf(),
  AnnotationType.InternalLink.valueOf(),
  AnnotationType.StockPicker.valueOf(),
];

export const useTextRangesWithAnnotations = (
  fieldValue: string,
  annotations: Annotation[]
) => {
  // support multibyte characters correctly
  let characterArray: string[];
  if (typeof Intl !== "undefined" && typeof Intl.Segmenter === "function") {
    // works for modifier like skin tones
    const segmenter = new Intl.Segmenter(); // default to granularity grapheme
    characterArray = [...segmenter.segment(fieldValue)].map((s) => s.segment);
  } else {
    // fallback, will not work for modifier chars
    characterArray = [...fieldValue];
  }

  const orderedAnnotations = [...annotations].sort(
    (a, b) =>
      // sort to enusure extenal_link and internal_link are applied first
      annotationOrder.indexOf(a.name.toString()) -
        annotationOrder.indexOf(b.name.toString()) ||
      // stable order for all types
      (a.name > b.name ? -1 : a.name < b.name ? 1 : 0)
  );

  const annotationsByIndex = orderedAnnotations.reduce<Annotation[][]>(
    (byIndex, annotation) => {
      const maxIndex = annotation.index + annotation.length;

      for (let i = annotation.index; i < maxIndex; i += 1) {
        if (!byIndex[i]) {
          byIndex[i] = [];
        }
        byIndex[i].push(annotation);
      }

      return byIndex;
    },
    []
  );

  const isEqualArray = (a: unknown[], b: unknown[]) =>
    a.length === b.length && a.every((d, index) => b.indexOf(d) === index);

  const textRangesWithAnnotations = annotationsByIndex.reduce<
    {
      start: number;
      end: number;
      annotations: Annotation[];
    }[]
  >((ranges, annotations, index) => {
    const lastRange = ranges[ranges.length - 1];

    if (ranges.length && lastRange.end === index) {
      // last range ended at current index
      if (isEqualArray(lastRange.annotations, annotations)) {
        // extend last range by one char
        lastRange.end += 1;
      } else {
        // start new range with different annotations
        ranges.push({
          start: index,
          end: index + 1,
          annotations,
        });
      }
    } else {
      if (ranges.length) {
        // add text from last range until this index
        ranges.push({
          start: lastRange.end,
          end: index,
          annotations: [],
        });
      } else if (index > 0) {
        // add text from begining until first annotation index
        ranges.push({
          start: 0,
          end: index,
          annotations: [],
        });
      }
      // start new range with annotations
      ranges.push({
        start: index,
        end: index + 1,
        annotations,
      });
    }
    if (
      index === annotationsByIndex.length - 1 &&
      index + 1 < characterArray.length
    ) {
      // text from last annotation until end of plain text
      ranges.push({
        start: index + 1,
        end: characterArray.length,
        annotations: [],
      });
    }

    return ranges;
  }, []);

  const ComponentWithOutAnnotation = <>{fieldValue}</>;
  const ComponentWithAnnotation = (
    <>
      {textRangesWithAnnotations.map((range, index) => {
        const text = characterArray.slice(range.start, range.end).join("");

        return (
          <AnnotationWrapper key={index} annotations={range.annotations}>
            {text}
          </AnnotationWrapper>
        );
      })}
    </>
  );

  const componentToRender =
    annotations.length === 0
      ? ComponentWithOutAnnotation
      : ComponentWithAnnotation;

  return { componentToRender };
};
