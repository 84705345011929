import { getHeaderText } from "@pages/Article/components/ArticleSubshare/helpers";
import { LambdaResponsePayload } from "@pages/Article/types/Alacrity";

export type HeaderProps = LambdaResponsePayload;

export default function Header({
  data: { responseType },
}: HeaderProps): React.ReactElement {
  return (
    <h1 className="mb-3 font-poppins text-4xl font-semibold text-black">
      {getHeaderText(responseType)}
    </h1>
  );
}
