import { useState } from "react";
import { ArticleDataObject, SanitizedSectionArticle } from "@app/types/Cue";

export const useLoadMoreArticles = (
  initialList: SanitizedSectionArticle[],
  size: number,
  fetchArticles: () => Promise<SanitizedSectionArticle[]>
) => {
  const [articleList, setArticleList] =
    useState<ArticleDataObject[]>(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length === size
  );

  const handleLoadMore = async () => {
    if (initialList.length === 0) return;

    const newMoreArticles = await fetchArticles();

    if (newMoreArticles.length < size) setHasMoreArticles(false);

    setArticleList((prev) => [...prev, ...newMoreArticles]);
  };

  return { articleList, handleLoadMore, hasMoreArticles };
};
