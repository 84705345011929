import { SanitizedSectionArticle } from "@app/types/Cue";
import axios from "axios";

export const fetchMoreBrandedContentData = async (
  sort: number[]
): Promise<SanitizedSectionArticle[]> => {
  try {
    const response = await axios.post("/_plat/api/v1/more-stories-by-kicker", {
      kicker: "Branded Content",
      sort: sort,
      size: 10,
    });

    return response.data || [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error in fetchMoreBrandedContentData function", error);
    return [];
  }
};
