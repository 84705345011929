import { ArticleDataObject } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems, shortTimestampFormat } from "@util/constant";
import { getFormattedTime } from "@util/helpers";
import cx from "classnames";

export type BreakingNewsProps = {
  rootClassName?: string;
  showHeading?: boolean;
  showBorder?: boolean;
  data?: ArticleDataObject[];
  blockTitle?: string;
  blockLink?: string;
};

const section = sectionNavigationItems["breaking-news"];

export default function BreakingNews({
  rootClassName,
  showHeading = true,
  showBorder = true,
  data = [],
  blockTitle,
  blockLink,
}: BreakingNewsProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const isSubscriber = OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  return (
    <>
      {data.length > 0 ? (
        <section className={rootClassName} data-testid="breakingnews-component">
          {showHeading ? (
            <BlockTitle
              link={blockLink ?? section?.link}
              text={blockTitle ?? section?.label?.toUpperCase()}
              rootLinkClassName="!text-black"
            />
          ) : null}

          <div
            className={cx("stories", {
              "divide divide-y": !!showBorder,
            })}
          >
            {data?.map((article: ArticleDataObject) => {
              return (
                <div
                  className={cx("story flex flex-row items-start", {
                    "py-4": !!showBorder,
                    "py-2": !showBorder,
                  })}
                  key={article.id}
                >
                  <article className={cx("flex h-full flex-row")}>
                    <div className="mr-4 mt-1.5 w-[60px] flex-shrink-0 whitespace-nowrap font-poppins text-3xs font-medium text-gray-550">
                      {getFormattedTime(article.edited, shortTimestampFormat)}
                    </div>

                    <BasicCard
                      title={
                        article.displaySetting?.displayHeadline || article.title
                      }
                      id={article.id}
                      paywall={
                        isSubscriber
                          ? article.paywall?.contentAccess === "1"
                          : false
                      }
                      slug={article.slug}
                      variations={{
                        title: {
                          size: "text-base",
                          color: "!text-black",
                        },
                      }}
                    />
                  </article>
                </div>
              );
            })}
          </div>
        </section>
      ) : null}
    </>
  );
}
