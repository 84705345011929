import { useEffect, useState } from "react";

type LoadScriptArguments = {
  /**
   * The script source, e.g. //www.google.com/recaptcha/api.js
   * Will be loaded via `<script src={src} />`
   */
  src: string;
  /**
   * The script id, e.g. recaptcha, google-analytics, etc.
   * This will be used to check if the script is already loaded, so make sure it's unique
   * Will be loaded via `<script id={id} />`
   */
  id: string;
  /**
   * The script async attribute, default to true
   * Currently we only support async script
   */
  async?: boolean;
};
export type UseScriptProps = LoadScriptArguments & {
  /**
   * A function to allow custom logic to dertermine if the script is already loaded and is ready to use
   * To avoid loading the script multiple times and as final greenlight to render the ads
   * Because a `script` tag loaded in DOM does not mean the content is ready
   * e.g. `() => typeof window !== "undefined" && typeof window.grecaptcha !== "undefined"`
   * @returns boolean
   */
  checkScriptIsLoaded?: () => boolean;
  /**
   * A callback function to run when the script is loaded
   * @returns void
   */
  onLoad?: () => void;
  type?: string;
};

export const loadScript = ({ src, id, async = true }: LoadScriptArguments) =>
  new Promise<void>((resolve) => {
    const script = document.createElement("script");

    script.id = id;
    script.dataset.testid = id;
    script.src = src;
    script.async = async;
    script.onload = () => {
      resolve();
    };
    document.head.appendChild(script);
  });

/**
 *  A custom hook to load any script on CSR and run callback function on load
 */
export default function useScript({
  src,
  id,
  onLoad,
  async = true,
  checkScriptIsLoaded = () => false,
}: UseScriptProps) {
  const [isScriptReady, setIsScriptReady] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (checkScriptIsLoaded()) {
        setIsScriptReady(true);
        return;
      }

      if (document.getElementById(id)) {
        return;
      }

      loadScript({
        src,
        id,
        async,
      }).then(() => {
        setIsScriptReady(true);
        if (typeof onLoad == "function") {
          onLoad();
        }
      });
    }
  }, [src, id, async, onLoad, setIsScriptReady, checkScriptIsLoaded]);

  return { isScriptReady };
}
