import { Element } from "@app/types/Cue";
import { Type } from "@app/types/enums";
import cx from "classnames";

import Paragraph from "./Paragraph";

type ListBulletedProps = {
  className?: string;
  elements: Element[];
};

export default function ListBulleted({
  className,
  elements,
}: ListBulletedProps): React.ReactElement {
  return (
    <ul
      className={cx("list-outside list-disc pl-8", className)}
      data-story-element={Type.ListBulleted}
      data-testid="list-bulleted-component"
    >
      {elements.map((element) => {
        switch (element.type) {
          case Type.Paragraph:
            return (
              <li key={element.id}>
                <Paragraph element={element} />
              </li>
            );

          default:
            return null;
        }
      })}
    </ul>
  );
}
