import { BIGMONEY_OTHER_READS } from "@components/Newsletter/constants";
import NewsletterSampleAuthor from "@pages/Newsletter/Archive/BigMoney/SampleIssues/components/NewsletterSampleAuthor";
import NewsletterSampleBigReads from "@pages/Newsletter/Archive/BigMoney/SampleIssues/components/NewsletterSampleBigReads";
import NewsletterSampleContent from "@pages/Newsletter/Archive/BigMoney/SampleIssues/components/NewsletterSampleContent";
import NewsletterSampleHeader from "@pages/Newsletter/Archive/BigMoney/SampleIssues/components/NewsletterSampleHeader";
import NewsletterSampleOtherReads from "@pages/Newsletter/Archive/BigMoney/SampleIssues/components/NewsletterSampleOtherReads";
import NewsletterSampleFooter from "@pages/Newsletter/Archive/components/NewsletterSampleFooter";
import { cn } from "@util/helpers";

export type BigMoneySampleIssueProps = {
  divRef?: React.RefObject<HTMLDivElement>;
  rootClassName?: string;
};

export default function BigMoneySampleIssue({
  divRef,
  rootClassName = "",
}: BigMoneySampleIssueProps): React.ReactElement {
  return (
    <>
      <h2 className="mb-3 text-center font-poppins text-base font-medium">
        Sample issue
      </h2>

      <div
        data-testid="bigmoney-iframe"
        ref={divRef}
        className={cn(rootClassName, "rounded-2xl border border-gray-850")}
      >
        <div className="my-3 h-[525px] overflow-y-scroll px-4">
          <div className="mx-auto w-full max-w-[600px] border-collapse pt-5">
            <NewsletterSampleHeader />

            <NewsletterSampleAuthor />

            <NewsletterSampleContent />

            <NewsletterSampleBigReads />

            <NewsletterSampleOtherReads newsletters={BIGMONEY_OTHER_READS} />

            <NewsletterSampleFooter editionId={"63311"} />
          </div>
        </div>
      </div>
    </>
  );
}
