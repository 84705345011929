import useScript from "@hooks/useScript";
import { getFlourishDataSrc } from "@pages/Article/components/StoryElements/helpers";
import { cn } from "@util/helpers";

import { FLOURISH_REMOVE_CREDIT_TEXT_ID } from "./constants";

type FlourishEmbedProps = {
  url: string;
  className?: string;
};

export default function FlourishEmbedCustom({
  url,
  className,
}: FlourishEmbedProps) {
  useScript({
    id: "flourish-embed",
    src: "https://public.flourish.studio/resources/embed.js",
    type: "text/javascript",
  });

  const srcUrl = getFlourishDataSrc(url);

  return (
    <>
      {typeof srcUrl !== undefined ? (
        <div
          className={cn("flourish-embed", className)}
          data-src={`${srcUrl}?${FLOURISH_REMOVE_CREDIT_TEXT_ID}`}
        ></div>
      ) : null}
    </>
  );
}
