import { SanitizedRelatedStory } from "@app/types/Cue";
import TitleLink from "@components/ArticleMeta/TitleLink";

export type RelatedArticlesProps = {
  articles?: SanitizedRelatedStory[];
};

export default function RelatedArticles({
  articles,
}: RelatedArticlesProps): React.ReactElement {
  return (
    <>
      {articles && articles.length > 0 ? (
        <section className="my-4 font-poppins">
          <div className="font-bold">READ MORE</div>

          <ul
            role="list"
            data-testid="section-related-stories"
            className="list-disc ps-5"
          >
            {articles.map((relatedArticle: SanitizedRelatedStory) => {
              return relatedArticle.id && relatedArticle.title ? (
                <li key={relatedArticle.id}>
                  <TitleLink
                    rootClassName="text-lg font-poppins font-medium my-1"
                    linkRootClassName="hover:!text-gray-850 !inline"
                    articleId={relatedArticle.id}
                    slug={relatedArticle.urlPath}
                    title={relatedArticle.title}
                  />
                </li>
              ) : null;
            })}
          </ul>
        </section>
      ) : null}
    </>
  );
}
