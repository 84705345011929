import type { ArticleDataObject } from "@app/types/Cue";
import { BlockTitle } from "@blocks/Blocks";
import PodcastsStories from "@pages/Home/Podcasts/PodcastStories";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import cx from "classnames";

export type PodcastsProps = {
  testId?: string | null;
  rootClassName?: string;
  data?: ArticleDataObject[];
};

const section = sectionNavigationItems.podcasts;

export default function Podcasts({
  testId = "",
  rootClassName,
  data = [],
}: PodcastsProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  return (
    <section className={rootClassName} data-testid={testId}>
      <BlockTitle link={section?.link} text={section?.label?.toUpperCase()} />

      <div className={cx("stories flex flex-wrap")}>
        {data?.map((article: ArticleDataObject, index: number) => {
          return (
            <PodcastsStories
              key={index}
              article={article}
              index={index}
              disablePaywall={
                OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
              }
            />
          );
        })}
      </div>
    </section>
  );
}
