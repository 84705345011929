import { Tag } from "@app/types/Cue";
import { myBTDataTracker } from "@util/helpers";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface MyBtStore {
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
  followTag: (tag: Tag) => void;
  unfollowTag: (tag: Tag) => void;
  isLimitModalShown: boolean;
  setIsLimitModalShown: (isLimitModalShown: boolean) => void;
}

const useMyBtStore = create(
  persist<MyBtStore>(
    (set) => ({
      tags: [],
      setTags: (tags: Tag[]) => {
        set({ tags });
        myBTDataTracker(tags);
      },
      followTag: (tag: Tag) => {
        set((store) => {
          const updatedTags = [tag, ...store.tags];
          localStorage.setItem(
            "mybt-keywords",
            JSON.stringify({ tags: updatedTags })
          );
          myBTDataTracker(updatedTags); // Track data after updating the tags
          return { tags: updatedTags };
        });
      },
      unfollowTag: (tag: Tag) => {
        set((store) => {
          const updatedTags = store.tags.filter(
            (_tag) => _tag.name.toLowerCase() !== tag.name.toLowerCase()
          );
          localStorage.setItem(
            "mybt-keywords",
            JSON.stringify({ tags: updatedTags })
          );
          myBTDataTracker(updatedTags); // Track data after updating the tags
          return { tags: updatedTags };
        });
      },
      isLimitModalShown: false,
      setIsLimitModalShown: (isLimitModalShown: boolean) => {
        set({ isLimitModalShown });
      },
    }),
    {
      name: "mybt-keywords", // unique name
      getStorage: () => localStorage, // Specify localStorage as the storage option
    }
  )
);

export default useMyBtStore;
