import { useInView } from "react-intersection-observer";
import Button from "@components/Button/Button";
import { ActionType, ButtonSize } from "@components/Button/types";
import { gaEventTracker } from "@src/app/util/helpers";

import { contactFormUrl, newspostUrl, nonSphText, sphText } from "./constants";
import { ArticlePurchaseLinkProps } from "./types";

export default function ArticlePurchaseLinkVersion2({
  title,
  publishedDate,
  displayPurchase,
  urlPath,
}: ArticlePurchaseLinkProps): React.ReactElement {
  const date = encodeURIComponent(publishedDate);
  const encodedTitle = encodeURIComponent(title);

  const { ref: ArticlePurchaseLinkRef } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        const url = urlPath || "";
        displayPurchase
          ? gaEventTracker("copyright", "visible", url)
          : gaEventTracker("feedback", "visible", url);
      }
    },
  });

  const onClickTriggerGa = (eventLabel: string) => {
    const eventCategory = displayPurchase ? "copyright" : "feedback";
    gaEventTracker(eventCategory, "click", eventLabel);
  };

  return (
    <section
      ref={ArticlePurchaseLinkRef}
      className="mx-auto flex w-full flex-wrap items-center justify-between gap-4 lg:gap-0 lg:px-3"
      data-testid="article-purchase-link-version-2-component"
    >
      <p className="mb-0 font-public-sans text-xs tracking-normal">
        {displayPurchase ? sphText : nonSphText}
      </p>

      <div className="flex flex-wrap">
        {displayPurchase ? (
          <Button
            actionType={ActionType.linkButton}
            buttonSize={ButtonSize.medium}
            text="Purchase Article"
            shareUrl={`${newspostUrl}?publication=BT&title=${encodedTitle}&date=${date}`}
            className="w-fit-content mb-4 mr-4 px-6 !text-gray-850 !no-underline xs:mb-0"
            onClick={() => {
              onClickTriggerGa("purchase article");
            }}
          />
        ) : null}

        <Button
          actionType={ActionType.linkButton}
          buttonSize={ButtonSize.medium}
          text="Contact Us"
          shareUrl={contactFormUrl}
          className="w-fit-content px-6 !text-gray-850 !no-underline"
          onClick={() => {
            onClickTriggerGa("contact us");
          }}
        />
      </div>
    </section>
  );
}
