import { Fragment } from "react";
import { BreakingNewsStoriesProps } from "@app/types/BreakingNews";
import { BasicCardProps } from "@components/ArticleCard/types";
import { useHeaderState } from "@components/Header/helpers";
import { getGroupTitle } from "@pages/BreakingNews/helper";
import cx from "classnames";
import { pick } from "lodash-es";

import BreakingNewsCard from "./BreakingNewsCard";
import BreakingNewsDynamicAds from "./BreakingNewsDynamicAds";
import WhatsappQRBreakingNews from "./WhatsappQRBreakingNews";

export default function BreakingNewsStories({
  testId,
  rootClassName,
  articles,
  group,
  disablePaywall = false,
  articlesBefore = 0,
  isRefresh = false,
}: BreakingNewsStoriesProps): React.ReactElement {
  const groupTitle = getGroupTitle(group);
  const { showLowerNavBar } = useHeaderState();

  return (
    <>
      {articles.length > 0 ? (
        <section className={rootClassName} data-testid={testId}>
          <h4
            className={cx(
              "sticky z-10 bg-white py-3 font-poppins text-lg font-medium tracking-[1px] duration-300 lg:static",
              showLowerNavBar ? "top-[50px]" : "top-[50px]"
            )}
          >
            {groupTitle.toUpperCase()}
          </h4>

          <div
            className={cx(
              "stories mt-3 space-y-4",
              "relative pl-20 before:absolute before:left-10 before:top-0 before:h-full before:w-[1px] before:bg-gray-175",
              "lg:pl-0 lg:before:hidden"
            )}
          >
            {articles.map((article, index) => {
              const props: BasicCardProps = {
                //Using pick to extract the same fields from ArticleDataObject to BasicCardProps
                ...pick(article, "id", "slug", "edited", "updated"),
                section: article?.sections?.[0],
                paywall: !disablePaywall
                  ? article.paywall?.contentAccess === "1"
                  : false,
                hasUpdatedTimestampDisplay:
                  article?.displaySetting?.hasUpdatedTimeDisplayed,
                title:
                  article?.displaySetting?.displayHeadline || article.title,
              };
              const totalArticlesIndex = index + articlesBefore;

              return (
                <Fragment key={props.id}>
                  {group === "today" && index === 2 ? (
                    <WhatsappQRBreakingNews />
                  ) : null}

                  {totalArticlesIndex > 0 ? (
                    <BreakingNewsDynamicAds
                      articleCounter={index + articlesBefore}
                      isRefresh={isRefresh}
                      className="w-[calc(100%+5rem)] -translate-x-20 overflow-hidden bg-white lg:w-auto lg:translate-x-0"
                    />
                  ) : null}

                  <BreakingNewsCard
                    basicCardProps={props}
                    isLast={index === articles.length - 1}
                  />
                </Fragment>
              );
            })}
          </div>
        </section>
      ) : null}
    </>
  );
}
