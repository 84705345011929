import { Link } from "react-router-dom";
import { TestIdClassNameProps } from "@app/types/Common";
import iconCheck from "@assets/icon-check.svg";
import iconPlus from "@assets/icon-plus-white.svg";
import Button from "@components/AccessibleComponents/Button";
import NewsletterSubscriberOnly from "@components/Newsletter/HomepageCarouselNewsletter/NewsletterSubscriberOnly";
import { Newsletter } from "@components/Newsletter/types/Newsletter";
import { useWindowSize } from "@src/app/hooks/useWindowSize";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import cx from "classnames";

export type HomepageCarouselNewsletterSlideProps = TestIdClassNameProps & {
  newsletter: Newsletter;
  titleClassName?: string;
  descriptionClassName?: string;
  wrapperClassName?: string;
  handleSelectedNewsletter: (
    action: "add" | "remove",
    newsletters: Newsletter
  ) => void;
  subscribed?: boolean;
  premiumOnly?: boolean;
};

export default function HomepageCarouselNewsletterSlide({
  testId,
  rootClassName,
  titleClassName,
  descriptionClassName,
  wrapperClassName,
  newsletter,
  handleSelectedNewsletter,
  subscribed = false,
  premiumOnly = false,
}: HomepageCarouselNewsletterSlideProps): React.ReactElement {
  const { isScreenMD } = useWindowSize();
  const { thumbnail, label, updateTime, description, link } = newsletter;
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);
  const isSubscriber = OktaUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;
  const NewsletterDescription = () => (
    <>
      <p
        className={cx(descriptionClassName, "mb-10 font-public-sans text-base")}
      >
        {description}
      </p>
      <div className="relative mt-auto flex min-h-[40px]">
        {!premiumOnly || (premiumOnly && isSubscriber) ? (
          <Button
            data-testid={testId ? `${testId}-button` : undefined}
            className={cx(
              "float-left flex h-[40px] w-[40px] items-center justify-center rounded-full border border-black text-white",
              subscribed ? "bg-white" : "bg-black"
            )}
            onPress={() => {
              handleSelectedNewsletter(
                subscribed ? "remove" : "add",
                newsletter
              );
            }}
            aria-label={subscribed ? "Subscribe" : "Unsubscribe"}
          >
            <img
              src={subscribed ? iconCheck : iconPlus}
              alt={subscribed ? "Subscribe" : "Unsubscribe"}
              width={15}
              height={15}
              className="relative -left-[1px] aspect-square"
            />
          </Button>
        ) : null}
        {isScreenMD && premiumOnly ? <NewsletterSubscriberOnly /> : null}
      </div>
    </>
  );

  return (
    <div
      className={cx(
        rootClassName,
        "h-full border-b border-gray-250 pb-4 last:border-none last:pb-0 md:border-none md:pb-0"
      )}
      data-testid={testId}
    >
      <div className="!flex h-full flex-col md:border md:border-black md:p-4">
        <div className={cx(wrapperClassName, "flex md:mb-6 md:pb-6")}>
          <img
            src={thumbnail}
            alt={label}
            width={75}
            height={75}
            className="aspect-square self-start object-contain pr-3"
          />
          <div>
            {!isScreenMD && premiumOnly ? (
              <div className="mb-1 inline-block border border-black px-2 py-1 font-public-sans text-[14px] font-normal">
                Subscriber Only
              </div>
            ) : null}
            <div className="font-poppins text-4xs font-light tracking-[1px] text-gray-850">
              {updateTime.toUpperCase()}
            </div>
            <h3
              className={cx(
                titleClassName,
                "font-poppins text-3xl font-semibold text-gray-850"
              )}
            >
              {link ? (
                <Link to={link} className="hover:underline" reloadDocument>
                  {label}
                </Link>
              ) : (
                label
              )}
            </h3>
            {!isScreenMD ? <NewsletterDescription /> : null}
          </div>
        </div>

        {isScreenMD ? <NewsletterDescription /> : null}
      </div>
    </div>
  );
}
