import { OS_INDEX_ALIAS } from "@app/util/constant";
import { sortByStickyAndRecentDate } from "@caas/queries/constant";
import { CaasQuery } from "@caas/types";

export const queryByKeywords = (
  keywordUrlPath: string,
  size: number,
  page: number,
  source?: string[],
  sort?: object[]
): CaasQuery => {
  const from = size * (page - 1);

  return {
    index: OS_INDEX_ALIAS,
    search: {
      query: {
        bool: {
          should: [
            {
              nested: {
                path: "data.context.tags",
                query: {
                  term: {
                    "data.context.tags.urlPath.keyword": keywordUrlPath,
                  },
                },
              },
            },
            {
              nested: {
                path: "data.context.tags",
                query: {
                  term: {
                    "data.context.tags.urlPathHistory.keyword": keywordUrlPath,
                  },
                },
              },
            },
          ],
          minimum_should_match: 1,
          must: [
            {
              term: {
                "data.context.displaySetting.hideFromSectionListing": false,
              },
            },
          ],
        },
      },
      size: size,
      from: from > 0 ? from : 0,
      sort: sort || sortByStickyAndRecentDate,
      ...(source ? { _source: source } : undefined),
    },
  };
};
