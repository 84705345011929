import { ValueOf } from "@app/types/Cue";
import AnnotationComponentBold from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentBold";
import AnnotationComponentCapitals from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentCapitals";
import AnnotationComponentDefault from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentDefault";
import AnnotationComponentExternalLink from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentExternalLink";
import AnnotationComponentInternalLink from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentInternalLink";
import AnnotationComponentItalics from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentItalics";
import AnnotationComponentStockPicker from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentStockPicker";
import AnnotationComponentStrikethrough from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentStrikethrough";
import AnnotationComponentSubscript from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentSubscript";
import AnnotationComponentSuperscript from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentSuperscript";
import AnnotationComponentUnderline from "@pages/Article/components/StoryElements/Annotation/components/AnnotationComponentUnderline";

import { AnnotationType, RelAttributeKeys } from "./types";

export const AnnotationComponentFactory = (name: ValueOf<AnnotationType>) => {
  switch (name) {
    case AnnotationType.Bold:
      return AnnotationComponentBold;

    case AnnotationType.Italic:
    case AnnotationType.SubHeadItalic:
      return AnnotationComponentItalics;

    case AnnotationType.Capitals:
      return AnnotationComponentCapitals;

    case AnnotationType.ExternalLink:
      return AnnotationComponentExternalLink;

    case AnnotationType.InternalLink:
      return AnnotationComponentInternalLink;

    case AnnotationType.Strikethrough:
      return AnnotationComponentStrikethrough;

    case AnnotationType.Subscript:
      return AnnotationComponentSubscript;

    case AnnotationType.Superscript:
      return AnnotationComponentSuperscript;

    case AnnotationType.Underline:
      return AnnotationComponentUnderline;

    case AnnotationType.StockPicker:
      return AnnotationComponentStockPicker;

    case AnnotationType.MarkOut:
    case AnnotationType.Note:
    case AnnotationType.AmazonDynamicPricing:
    default:
      return AnnotationComponentDefault;
  }
};

/**
 * Helper function to get the rel attribute for an anchor tag.
 * @param isNewWindow To open the link in a new window.
 * @param isNoFollow To add the nofollow attribute.
 * @returns {string|undefined} The rel attribute.
 */
export const getRelAttribute = (
  isNewWindow: boolean,
  isNoFollow: boolean
): string | undefined => {
  const attributeValues = [
    { key: RelAttributeKeys.ISNEWWINDOW, value: isNewWindow },
    { key: RelAttributeKeys.ISNOWFOLLOW, value: isNoFollow },
  ]
    .map(({ key, value }) => {
      if (key === RelAttributeKeys.ISNEWWINDOW && value)
        return <string>"noopener noreferrer";

      if (key === RelAttributeKeys.ISNOWFOLLOW && value)
        return <string>"nofollow";
    })
    .filter((item): item is string => !!item);

  return attributeValues.join(" ") || undefined;
};

/**
 * Helper function to get the target attribute for an anchor tag.
 * @param isNewWindow To open the link in a new window.
 * @returns {string|undefined} The target attribute.
 */
export const getTargetAttribute = (
  isNewWindow: boolean
): string | undefined => {
  return isNewWindow ? "_blank" : undefined;
};
