/**
 * Company: SPHMedia
 * Description: International Global Layout
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import { VerticalProps } from "@app/types/Verticals";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import KeywordStoriesRow from "@pages/Section/components/VerticalMeta/KeywordStoriesRow";
import LatestStoriesRow from "@pages/Section/components/VerticalMeta/LatestStoriesRow";
import TopStoriesRow from "@pages/Section/components/VerticalMeta/TopStoriesRow";
import GlobalEnterpriseBanner from "@pages/Section/layouts/Verticals/GlobalEnterprise/components/GlobalEnterpriseBanner";
import { GoogleAdsSlotFactory } from "@util/helpers";

import GlobalEnterpriseNewsletterDescription from "./components/GlobalEnterpriseNewsletterDescription";

export default function GlobalEnterpriseVertical({
  context,
  mostReadArticles,
}: VerticalProps): ReactElement {
  const {
    data: { title, topStories, additional },
  } = context;
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });
  const geRouteWithoutLeadingSlash = RouteFactory.globalEnterprise.replace(
    "/",
    ""
  );

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description="THE BUSINESS TIMES Global Enterprise - Find Global Enterprise News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="article"
        slug={RouteFactory.globalEnterprise}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(geRouteWithoutLeadingSlash)}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header />

      <Container rootClassName="pt-6">
        <GlobalEnterpriseBanner />
      </Container>

      <Container rootClassName="mt-6 px-3">
        <TopStoriesRow
          parentCategory="international/global"
          articles={topStories}
          verticalName="ge"
        />

        <LatestStoriesRow
          articles={topStories.slice(4, 9)}
          seeMoreLink="/international/global/latest"
          verticalName="ge"
          mostReadStories={mostReadArticles}
        />

        <KeywordStoriesRow
          seeMoreLink="/keywords/asia-pacific"
          data={additional?.[0]}
          verticalName="ge"
          verticalBlockTitle="ASIA-PACIFIC"
        />
      </Container>

      <NewsletterSignUpPanel
        rootClassName="mb-3"
        descriptionComponent={<GlobalEnterpriseNewsletterDescription />}
        newsletter={BT_NEWSLETTERS.filter(
          (newsletter) =>
            newsletter.type === NEWSLETTER_TYPE.MORNING ||
            newsletter.type === NEWSLETTER_TYPE.EVENING
        )}
        btnColors="bg-verticals-ge text-white hover:bg-white hover:text-verticals-ge"
        linkColors="text-verticals-ge"
      />
    </div>
  );
}
