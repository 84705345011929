import { HTMLAttributes } from "react";
import { cn } from "@util/helpers";

import SplideNextArrow from "./SplideNextArrow";
import SplidePrevArrow from "./SplidePrevArrow";

export default function SplideArrows({
  className,
}: Pick<HTMLAttributes<HTMLDivElement>, "className">): React.ReactElement {
  return (
    <div className={cn("splide__arrows", className)}>
      <SplidePrevArrow />
      <SplideNextArrow />
    </div>
  );
}
