import { REMOVE_FOCUS_CSS } from "@components/Button/constants";
import { FilteringSort } from "@pages/Search/contants";
import { SearchFilteringSortProps } from "@pages/Search/types";
import { cn } from "@util/helpers";

export default function SearchSortFiltering({
  sortBy,
  setSortBy,
}: SearchFilteringSortProps): React.ReactElement {
  return (
    <div
      data-testid="search-filtering-sort-component"
      className="flex items-center gap-4 "
    >
      {FilteringSort.map((sort) => {
        return (
          <button
            key={sort.key}
            onClick={() => {
              setSortBy(sort.key);
            }}
            className={cn(REMOVE_FOCUS_CSS)}
          >
            <p
              title={sort.value}
              className={cn(
                "leading-[3] text-gray-550 hover:font-semibold hover:text-gray-850 hover:underline hover:decoration-2 hover:underline-offset-[1rem]",
                "before:invisible before:block before:h-0 before:overflow-hidden before:font-semibold before:[content:attr(title)]",
                {
                  "font-semibold text-gray-850 underline decoration-2 underline-offset-[1rem]":
                    sortBy === sort.key,
                }
              )}
            >
              {sort.value}
            </p>
          </button>
        );
      })}
    </div>
  );
}
