import { RouteFactory } from "@app/routePaths";
import instaIcon from "@assets/footer/icon-insta.svg";
import tiktokIcon from "@assets/footer/icon-tiktok.svg";
import thriveEventSliderImage from "@assets/thrive-events-slide1.png";
import thriveIcon from "@assets/thrive-icon.svg";
import thriveOutIcon from "@assets/thrive-out-icon.svg";
import thriveEventSliderImage2 from "@assets/thrive-slide2.png";
import thriveEventSliderImage3 from "@assets/thrive-slide3.png";
import thriveEventSliderImage4 from "@assets/thrive-slide4.png";
import thriveWhiteLogo from "@assets/thrive-white.svg";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

export const slidesData = [
  {
    image: thriveEventSliderImage,
    label1: "MEET THE",
    label2: "COMMUNITY",
  },
  {
    image: thriveEventSliderImage2,
    label1: "Talk to",
    label2: "Experts",
  },
  {
    image: thriveEventSliderImage3,
    label1: "Learn And",
    label2: "Discover",
  },
  {
    image: thriveEventSliderImage4,
    label1: "Navigate",
    label2: "Life",
  },
];

export const socialMediaLinks = [
  { to: "/thrive", icon: thriveIcon, alt: "Thrive" },
  {
    to: "https://www.tiktok.com/@thrivebt",
    icon: tiktokIcon,
    alt: "Thrive Tiktok",
  },
  {
    to: "https://www.instagram.com/thrive_bt",
    icon: instaIcon,
    alt: "Thrive Instagram",
  },
];

export const footerLinks = [
  {
    to: "https://www.sph.com.sg/legal/sph_privacy/?_gl=1*owaov8*_gcl_au*MTg1MzkzNDM0Ni4xNjk1NjExMzU0",
    label: "Privacy Policy",
  },
  {
    to: "https://www.sph.com.sg/legal/website_tnc/?_gl=1*m600nc*_gcl_au*MTg1MzkzNDM0Ni4xNjk1NjExMzU0",
    label: "Terms & Conditions",
  },
  {
    to: "https://www.sph.com.sg/legal/cookie-policy/?_gl=1*1mvmsna*_gcl_au*MTg1MzkzNDM0Ni4xNjk1NjExMzU0",
    label: "Cookie Policy",
  },
  {
    to: "https://www.sph.com.sg/legal/sph_privacy/",
    label: "Data Protection Policy",
  },
];

export const navigationItems = [
  { to: "/thrive/past-issues", label: "Past Issues" },
  { to: "https://www.instagram.com/thrive_bt/", label: "Instagram" },
  { to: "https://www.tiktok.com/@thrivebt", label: "TikTok" },
  {
    to: RouteFactory.thriveEvents,
    label: "Events",
    reloadDocument: true,
  },
  { to: "/thrive/about-us", label: "About us" },
];

export const sideNavigationItems = [
  { to: "/thrive", label: "Thrive", icon: thriveWhiteLogo },
  { to: "/thrive/past-issues", label: "Past Issues" },
  {
    to: "https://www.instagram.com/thrive_bt/",
    label: "Instagram",
    icon: thriveOutIcon,
  },
  {
    to: "https://www.tiktok.com/@thrivebt",
    label: "TikTok",
    icon: thriveOutIcon,
  },
  { to: RouteFactory.thriveEvents, label: "Events" },
  { to: "/thrive/about-us", label: "About us" },
];

export const events = [
  {
    title: "PA - FutureYOUth Conversation",
    date: "11.05.2024",
    link: "https://www.instagram.com/p/C7WG2-tORE-/",
  },
  {
    title: "Singapore Polytechnic - 70th Anniversary Music Festival",
    date: "08.03.2024",
    link: "https://www.instagram.com/p/C4Xq0cXsGil",
  },
  {
    title: "NAFA Career Runway",
    date: "05 - 06.03.2024",
    link: "https://www.instagram.com/p/C4NXp98u_Oc",
  },
  {
    title: "Thrive x Tzu Chi HYC - Thriving (not just surviving!)",
    date: "28.10.2023",
    link: "https://www.instagram.com/p/CzBLz2yRgsb/",
  },
  {
    title: "Thrive x SMU - Career and Financial Resilience",
    date: "27-28.09.2023",
  },
  {
    title: "Thrive x SGX - Investing for Gen Zs",
    date: "17.08.2023",
    link: "https://www.instagram.com/p/CwExyLtBxI2/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
  },
  {
    title: "Thrive x TAC - World X",
    date: "22.07.2023",
    link: "https://www.instagram.com/reel/CvE4QQ1BmgS/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
  },
  {
    title: "Thrive launch party",
    date: "16.03.2023",
    link: "https://www.instagram.com/p/Cp4GgBussLY/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==",
  },
];

export const socialLinks = [
  {
    to: "https://www.tiktok.com/@thrivebt",
    label: "We're here",
    icon: faTiktok,
  },
  {
    to: "https://www.instagram.com/thrive_bt/",
    label: "and here",
    icon: faInstagram,
  },
];

export const linksInHomeAboutUs = {
  aboutUs: {
    to: "/thrive/about-us",
    label: "Meet the team",
  },
};

export const linksInHomeNewsletterPoll = {
  pollLink: {
    to: "https://docs.google.com/forms/d/e/1FAIpQLSf86N3PsJmeLG3Dmqw2YYIKsgSmXdNvIO4H9wxUHPGQAdRZ6Q/viewform",
  },
};

export const linksInHomeNewsletterBottomFooter = {
  newsletterSignUp: {
    to: "https://www.businesstimes.com.sg/thrive",
  },
  unsubscribe: {
    to: "https://www.businesstimes.com.sg/bt_newsletter/unsubscribe?edition=60845",
  },
};

export const linksInHomeNewsletterIntroduction = {
  instagram: {
    to: "https://www.instagram.com/thrive_bt/",
  },
  authorImg: {
    to: "https://static1.businesstimes.com.sg/s3fs-public/styles/profile_photo/public/author/2024/07/04/daryl20choo20headshot_0.jpg?VersionId=xI..uUlbkW0jobYNuJYJFdm4nkPBrTql",
  },
};

export const linksInHomeNewsletterOther = {
  economyPortCongestion: {
    to: "https://www.instagram.com/p/C8rL69GtJuY/?img_index=10",
  },
  economy90Percent: {
    to: "https://www.businesstimes.com.sg/companies-markets/transport-logistics/about-90-boxships-arriving-singapore-are-schedule-transport-minister",
  },
  moneyScoot: {
    to: "https://www.straitstimes.com/life/travel/looking-for-a-budget-friendly-holiday-scoot-airasia-offering-discounted-tickets-till-july-7",
  },
  moneyProfit: {
    to: "https://www.businesstimes.com.sg/companies-markets/banking-finance/tech-in-asia-1/no-profit-no-problem-singapores-digital-banks-six-charts",
  },
  moneyHospitalisation: {
    to: "https://www.straitstimes.com/singapore/integrated-shield-plan-lifetime-premiums-vary-widely-across-insurers-moh-comparison-shows",
  },
  moneyShieldPlans: {
    to: "https://www.instagram.com/p/C8_JJC2S71z/?img_index=1",
  },
  stocksNotTheSolution: {
    to: "https://www.businesstimes.com.sg/companies-markets/investing-gic-funds-not-solution-make-singapores-equity-market-more-attractive-chee-hong-tat",
  },
  stockSTI: {
    to: "https://www.businesstimes.com.sg/companies-markets/dbs-ocbc-uob-swell-almost-half-st-index-after-recent-run",
  },
  stocksETF: {
    to: "https://www.bloomberg.com/news/articles/2024-07-01/blackrock-enters-booming-market-for-stock-etfs-with-a-100-hedge?cmpid=BBD070124_MONEYSTUFF&utm_medium=email&utm_source=newsletter&utm_term=240701&utm_campaign=moneystuff",
  },
  careerNinjaVan: {
    to: "https://www.businesstimes.com.sg/startups-tech/startups/ninja-van-slashes-more-tech-roles-second-round-layoffs",
  },
  careerDataCentre: {
    to: "https://www.businesstimes.com.sg/companies-markets/telcos-media-tech/asias-data-centre-rush-making-some-unique-skills-highly-desirable",
  },
  lifeLanguageBarrier: {
    to: "https://www.channelnewsasia.com/commentary/singapore-chinese-dialects-teochew-hokkien-cantonese-language-thai-movie-millions-grandma-4439751",
  },
  propertyRise: {
    to: "https://www.businesstimes.com.sg/property/hdb-resale-prices-2-1-q2-marking-fastest-quarterly-rise-2022",
  },
  politicsPm: {
    to: "https://www.businesstimes.com.sg/singapore/why-are-mps-allowed-have-full-time-jobs-pm-wong-answers-questions-youth-dialogue",
  },
};

export const linksInHomeNewsletterStory = {
  storyImgWokeSalaryMan: {
    source: "THE WOKE SALARYMAN",
    src: "https://static1.businesstimes.com.sg/s3fs-public/image/2024/07/04/80-1.png?VersionId=ifhAb1QR_6RZRczUVm.T1oyOWWVWTtZO",
  },
  storyImgTikTok: {
    source: "@camerondonnelly78/TIKTOK",
    src: "https://static1.businesstimes.com.sg/s3fs-public/image/2024/07/04/80-2_1.gif?VersionId=WlsmzRMe8NgR7Mt8uifWJBRdk.fiTdJr",
  },
  storyEmergercyFunds: {
    to: "https://www.businesstimes.com.sg/companies-markets/woman-plan",
  },
  storyInterestRate: {
    to: "https://www.businesstimes.com.sg/companies-markets/woman-plan",
  },
  storyImgFederal: {
    source: "FEDERAL RESERVE BANK OF ST. LOUIS",
    src: "https://static1.businesstimes.com.sg/s3fs-public/image/2024/07/04/80-3.png?VersionId=lIFhb_tCTZqY2ncg1b6pSgI3NmzEHUby",
  },
  storySavingRules: {
    to: "https://www.businesstimes.com.sg/companies-markets/woman-plan",
  },
};

export const linksInHomeNewsletterTopFooter = {
  yeahResponse: {
    to: "https://docs.google.com/forms/d/e/1FAIpQLScqPmOEJL_71i-mV6-YhvMVYFykfIOdTZun4TJrfhFKCEruXw/viewform",
  },
  nahResponse: {
    to: "https://docs.google.com/forms/d/e/1FAIpQLScqPmOEJL_71i-mV6-YhvMVYFykfIOdTZun4TJrfhFKCEruXw/viewform",
  },
  thriveLink: {
    to: "https://bt.sg/thrive",
  },
};
