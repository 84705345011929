import { useEffect, useState } from "react";
import { GrapeshotResponse } from "@app/types/Cue";
import axios from "axios";

const fetchGrapeshotData = async (urlPath: string): Promise<string> => {
  try {
    const gsChannelsCombined = await axios
      .post<GrapeshotResponse>("/_plat/api/v1/grapeshot", { urlPath: urlPath })
      .then((item) => item.data?.channels.map((i) => i.name).join(","));

    return gsChannelsCombined || "no_analysis";
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `fetchGrapeshotData error for urlPath: ${urlPath}. Details: ${(error as Error).message}`
    );
    return "no_analysis";
  }
};

export const useGrapeshotData = (urlPath: string, index: number) => {
  const [gsChannels, setGsChannels] = useState<string>("");

  useEffect(() => {
    const callFetch = async () => {
      if (index === 0) return;

      setGsChannels(await fetchGrapeshotData(urlPath));
    };

    callFetch();
  }, [urlPath, index]);

  return { gsChannels };
};
