/**
 * Company: SPHMedia
 * Description: International Global Layout
 */

import { ReactElement } from "react";
import { RouteFactory } from "@app/routePaths";
import { VerticalProps } from "@app/types/Verticals";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import NewsletterSignUpPanel from "@components/Newsletter/Verticals/NewsletterSignUp";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import KeywordStoriesRow from "@pages/Section/components/VerticalMeta/KeywordStoriesRow";
import LatestStoriesRow from "@pages/Section/components/VerticalMeta/LatestStoriesRow";
import TopStoriesRow from "@pages/Section/components/VerticalMeta/TopStoriesRow";
import { GoogleAdsSlotFactory } from "@util/helpers";

import AseanBanner from "./components/AseanBanner";
import AseanNewsletterDescription from "./components/AseanNewsletterDescription";

export default function AseanBusinessVertical({
  context,
  mostReadArticles,
}: VerticalProps): ReactElement {
  const {
    data: { topStories, additional, title },
  } = context;

  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });

  const aseanRouteWithoutLeadingSlash = RouteFactory.aseanBusiness.replace(
    "/",
    ""
  );

  return (
    <div className="w-full">
      <MetaTags
        title={title}
        description="THE BUSINESS TIMES ASEAN Business - Find ASEAN Business News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
        ogType="article"
        slug={RouteFactory.aseanBusiness}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(aseanRouteWithoutLeadingSlash)}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header />

      <Container rootClassName="pt-6">
        <AseanBanner />
      </Container>

      <Container rootClassName="mt-6 px-3">
        <TopStoriesRow
          parentCategory="international/asean"
          articles={topStories}
          verticalName="asean"
        />

        <LatestStoriesRow
          articles={topStories.slice(4, 9)}
          seeMoreLink="/international/asean/latest"
          verticalName="asean"
          mostReadStories={mostReadArticles}
        />

        <KeywordStoriesRow
          seeMoreLink="/keywords/asean"
          data={additional?.[0]}
          verticalName="asean"
          verticalBlockTitle="ASEAN"
        />
      </Container>

      <NewsletterSignUpPanel
        rootClassName="mb-3"
        descriptionComponent={<AseanNewsletterDescription />}
        newsletter={BT_NEWSLETTERS.filter(
          (newsletter) => newsletter.type === NEWSLETTER_TYPE.ASEAN
        )}
        btnColors="bg-verticals-asean text-white hover:bg-white hover:text-verticals-asean"
        linkColors="text-verticals-asean"
      />
    </div>
  );
}
