import type { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@util/helpers";

export default function PodcastsListingStories({
  id,
  displaySetting,
  title,
  sections,
  kicker,
  media,
  blurb,
  urlPath,
  paywall,
  edited,
}: ArticleDataObject): React.ReactElement {
  const { isScreenLG } = useWindowSize();

  const variant: CardVariations = {
    kicker: {
      color: "text-gray-515",
    },
    image: {
      width: "w-1/4",
      position: "right",
      extraClass: "order-1 lg:order-2",
      filters: [
        { breakpoint: "(min-width: 992px)", w: 900, h: 600, dpr: 1, f: "webp" },
        { breakpoint: "(min-width: 768px)", w: 600, h: 400, dpr: 1, f: "webp" },
        { w: 300, h: 200, dpr: 1, f: "webp" },
      ],
    },
    content: {
      width: cn("w-3/4"),
      extraClass: "pl-3 lg:pl-0 lg:pr-3 pr-0",
      blurb: {
        extraClass: "text-gray-850 text-base",
      },
    },
    title: {
      size: "text-base lg:text-lg font-semibold",
      color: "text-gray-850",
    },
  };

  return (
    <div className="story mb-4 border-gray-175 pt-4 [&:not(:first-child)]:border-t">
      <BasicCard
        id={id}
        title={displaySetting?.displayHeadline || title}
        kicker={kicker?.fields?.[0]?.value}
        media={media?.[0]}
        blurb={isScreenLG ? blurb : undefined}
        slug={urlPath}
        paywall={paywall?.contentAccess === "1"}
        variations={variant}
        edited={edited}
        hasUpdatedTimestampDisplay={displaySetting?.hasUpdatedTimeDisplayed}
        defaultImage={{
          directoryName: sections?.[0]?.uniqueName || "",
        }}
      />
    </div>
  );
}
