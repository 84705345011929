import { useEffect, useState } from "react";

/**
 * Used in article display example ArticleDisplayMainNewsDefault
 * To get the scroll value to calculate the progress bar
 */
export const useGetScrollValue = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [scrollDown, setScrollDown] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      setScrollDown(!(prevScrollPos > currentScrollPos));
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return { prevScrollPos, scrollDown };
};
