import { useRef, useState } from "react";
import { RouteFactory } from "@app/routePaths";
import {
  AdvertisementTypeEnum,
  NewsletterArchiveAdKeywordsEnums,
  NewsletterArchiveKeywordsEnums,
} from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Header from "@components/Header/Header";
import MetaTags from "@components/MetaTags/MetaTags";
import {
  THRIVE_CONTRIBUTORS,
  THRIVE_DESC,
  THRIVE_TITLE,
} from "@components/Newsletter/constants";
import HomepageCarouselNewsletter from "@components/Newsletter/HomepageCarouselNewsletter";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import NewsletterArchiveArticlesList from "@pages/Newsletter/Archive/components/NewsletterArchiveArticlesList";
import NewsletterArchiveHeader from "@pages/Newsletter/Archive/components/NewsletterArchiveHeader";
import SocialShare from "@pages/Newsletter/Archive/components/SocialShare";
import ThriveSampleIssue from "@pages/Newsletter/Archive/Thrive/SampleIssues/ThriveSampleIssue";
import { NewsletterArchiveContext } from "@src/app/types/Page";
import { ENVIRONMENT } from "@util/constant";
import { cn, getEnvironmentDomain, GoogleAdsSlotFactory } from "@util/helpers";

type ThriveArchiveContentProps = Pick<NewsletterArchiveContext, "data">;

export default function ThriveArchivePageContent({
  data: { title, overview },
}: ThriveArchiveContentProps): React.ReactElement {
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting();
  const divRef = useRef<HTMLDivElement>(null);
  const [isKeepReadingVisible, setIsKeepReadingVisible] = useState(true);

  const thriveNL = BT_NEWSLETTERS.filter(
    ({ type }) => type === NEWSLETTER_TYPE.THRIVE
  );

  const handleButtonClick = () => {
    setIsKeepReadingVisible(false);
  };

  const dynamicClasses = !isKeepReadingVisible ? "overflow-visible h-full" : "";

  const baseUrl = getEnvironmentDomain(ENVIRONMENT);
  const newsletterThriveLink = RouteFactory.newsletterThriveArchivePage.replace(
    "/",
    ""
  );

  return (
    <>
      <MetaTags
        title={title}
        description="Read more at The Business Times."
        slug={RouteFactory.newsletterThriveArchivePage}
      />

      <GAData title="thrive" level2="newsletter" />

      <Advertisement
        rootClassName="py-6"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(newsletterThriveLink),
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(newsletterThriveLink)}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
        topOverlayValidity={topOverlayValidity}
      />

      <Header />

      <Container rootClassName="pt-5 !px-5">
        <div
          className="border-b-2 border-black"
          data-testid="thrive-archive-page-content"
        >
          <NewsletterArchiveHeader
            newsletterTitle={THRIVE_TITLE}
            newsletterDescription={THRIVE_DESC}
            newsletterContributors={THRIVE_CONTRIBUTORS}
            newsletterRoute={RouteFactory.newsletterThriveArchivePage}
            newsletterAdKeyword={NewsletterArchiveAdKeywordsEnums.Thrive}
            newsletterTime={"Friday, 3 pm"}
            signUpNL={thriveNL[0]}
            divRef={divRef}
            displayNewsletterForm={true}
          />
        </div>

        <NewsletterArchiveArticlesList
          newsletterKeyword={NewsletterArchiveKeywordsEnums.Thrive}
          articles={overview}
        />

        <div className="relative flex flex-col lg:hidden">
          <ThriveSampleIssue
            divRef={divRef}
            firstChildClassName={dynamicClasses}
            className={cn("h-[600px]", dynamicClasses)}
          />

          {isKeepReadingVisible ? (
            <div className="absolute bottom-0 mb-[1px] ml-4 w-11/12 bg-nlArchiveGradient px-4 pb-4 pt-20">
              <button
                className="flex w-full flex-row
                items-start justify-center rounded-3xl border border-gray-850 py-3 lg:hidden"
                onClick={handleButtonClick}
              >
                KEEP READING
              </button>
            </div>
          ) : null}
        </div>

        <SocialShare
          shareUrl={baseUrl + RouteFactory.newsletterThriveArchivePage}
          rootClassName={"pt-12 block lg:hidden pb-20"}
        />

        <HomepageCarouselNewsletter
          newsletterToSkip={NEWSLETTER_TYPE.THRIVE}
          showSeeMore={false}
          blockTitle="YOU MAY LIKE "
          rootClassName="border-t border-black pt-3 mb-16"
        />
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </>
  );
}
