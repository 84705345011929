import { AnnotationComponentFactory } from "./utils/helpers";
import { Annotation } from "./utils/types";

export type AnnotationWrapperProps = {
  children: React.ReactNode;
  annotations: Annotation[];
};

export default function AnnotationWrapper({
  children,
  annotations,
}: AnnotationWrapperProps) {
  return annotations.reduce((children, annotation) => {
    const AnnotationComponent = AnnotationComponentFactory(annotation.name);

    return (
      <AnnotationComponent element={annotation.reference}>
        {children}
      </AnnotationComponent>
    );
  }, children);
}
