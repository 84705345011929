import { useState } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import axios from "axios";

export const useLoadMoreArticlesByStoryThread = (
  urlPath: string,
  initialList: SanitizedSectionArticle[],
  pageSize: number
) => {
  const [articleList, setMoreArticlesList] = useState(initialList);
  const [hasMoreArticles, setHasMoreArticles] = useState(
    initialList.length >= pageSize
  );

  const handleLoadMore = async () => {
    const page = articleList.length / pageSize + 1;

    if (articleList.length === 0) return;
    if (typeof page === "undefined") return;

    const newMoreArticles = await fetchStories(urlPath, pageSize, page);

    if (newMoreArticles.length < pageSize) setHasMoreArticles(false);

    setMoreArticlesList((prev) => [...prev, ...newMoreArticles]);
  };

  return { articleList, handleLoadMore, hasMoreArticles };
};

const fetchStories = async (urlPath: string, size: number, page: number) => {
  try {
    const response = await axios.post<SanitizedSectionArticle[]>(
      "/_plat/api/v1/more-story-thread-stories",
      { urlPath, size, page }
    );

    return response?.data || [];
  } catch (error) {
    console.log("error on fetchStories"); // eslint-disable-line no-console
    return [];
  }
};
