import useScript from "@hooks/useScript";
import { OUTBRAIN_SCRIPT } from "@pages/Article/utils/constants";

/**
 *  Hook that loads the outBrain script.
 */
export default function useOutBrainScript() {
  useScript({
    id: "outbrain-script",
    async: true,
    src: OUTBRAIN_SCRIPT,
    type: "text/javascript",
  });
}
