import React, { useState } from "react";
import { RouteFactory } from "@app/routePaths";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import { getFormattedCaaSImageUrl } from "@components/OptimisedImage/helper";
import { SanitizedPulseData } from "@pages/Pulse/utils/types";

import PulseDesktopCarousel from "./components/PulseDesktopCarousel/PulseDesktopCarousel";

export type PulseDesktopPageProps = {
  sortedPulseData: SanitizedPulseData[];
};

export default function PulseDesktopPage({
  sortedPulseData,
}: PulseDesktopPageProps): React.ReactElement {
  const [activeSlide, setActiveSlide] = useState(0);
  const currentSlideData = sortedPulseData[activeSlide];
  const defaultFilters: CaaSImageFilters[] = [{ w: 800, dpr: 1, f: "webp" }];

  const imgSrcSets = defaultFilters.map(({ breakpoint, ...imageFilters }) => {
    return getFormattedCaaSImageUrl(currentSlideData?.media, {
      ...imageFilters,
    });
  });

  if (sortedPulseData.length === 0) return <></>;

  return (
    <div
      data-testid="pulse-desktop-page"
      className="w-svh flex h-svh items-center justify-center overflow-x-hidden"
    >
      <MetaTags
        title={renderPageTitleV2({
          kind: "pulse",
          sectionName: "Pulse",
          title: currentSlideData ? currentSlideData.title : undefined,
        })}
        description={
          currentSlideData
            ? currentSlideData.standfirst || currentSlideData.summaries[0]
            : "The Business Times - Find latest business & financial news including analysis and opinion on top business stories, stock markets in Singapore, Asia-Pacific & global market news and more at The Business Times."
        }
        ogType="website"
        slug={RouteFactory.pulse}
        imageUrl={currentSlideData ? imgSrcSets[0] : undefined}
      />

      <PulseDesktopCarousel
        articles={sortedPulseData}
        activeSlide={activeSlide}
        setActiveSlide={setActiveSlide}
      />
    </div>
  );
}
