import { Link } from "react-router-dom";
import { AdvertisementTypeEnum } from "@app/types/enums";
import logoE50 from "@assets/events-awards/logo-e50.png";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import Container from "@components/Container/Container";
import GAData from "@components/GAData/GAData";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useBreadCrumbState } from "@components/Header/helpers";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import {
  EVENTS_AWARDS_E50_LABEL,
  EVENTS_AWARDS_E50_PATH,
  EVENTS_AWARDS_E50_SLOT,
  EVENTS_AWARDS_SLOT,
} from "@pages/Section/layouts/EventsAwardsPage/utils/constants";
import { EventsAwardsLandingPageContextData } from "@pages/Section/layouts/EventsAwardsPage/utils/types";
import { sectionNavigationItems } from "@util/constant";
import { GoogleAdsSlotFactory } from "@util/helpers";

export default function EventsAwardsE50PageContent({
  title,
  // article,
}: EventsAwardsLandingPageContextData): React.ReactElement {
  const { isShowBreadCrumb } = useBreadCrumbState();
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({ targeting: { key: "page", value: "listing" } });

  return (
    <div
      className="w-full"
      data-testid="events-awards-e50-page-content-component"
    >
      <GAData title="Enterprise_50_Index" level2="events awards" />

      <MetaTags
        title={title}
        description={`THE BUSINESS TIMES ${EVENTS_AWARDS_E50_LABEL} - Find ${EVENTS_AWARDS_E50_LABEL} News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times.`}
        ogType="website"
        slug={EVENTS_AWARDS_E50_PATH}
      />

      <Advertisement
        rootClassName="min-h-[50px] lg:min-h-[250px] bg-gray-125 py-1 md:py-3 border-b border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(EVENTS_AWARDS_E50_SLOT),
          adsClassName:
            "min-h-[50px] lg:min-h-[250px] flex flex-col justify-center items-center",
        }}
      />

      <PrestitialAdWrapper
        slot={GoogleAdsSlotFactory.prestitial(EVENTS_AWARDS_E50_SLOT)}
        {...{ isPrestitialEnabled, topOverlayImpressions, topOverlayValidity }}
      />

      <Header
        displayBreadcrumb={isShowBreadCrumb}
        parentCategory={EVENTS_AWARDS_SLOT}
        categoryDataMapping={sectionNavigationItems}
        enableTopStoriesLink={false}
      />

      <Container>
        <Row rootClassName="justify-center">
          <Column rootClassName="w-full lg:w-8/12 text-center mt-8">
            <Link to={EVENTS_AWARDS_E50_PATH}>
              <img
                src={logoE50}
                alt="Enterprise 50 logo"
                width={120}
                height={109}
                className="mx-auto"
              />
            </Link>
          </Column>
        </Row>
      </Container>
    </div>
  );
}
