import thriveMeetTheThrivers from "@assets/thrive_images/thrive-meet-the-thrivers.png";
import Container from "@components/Container/Container";
import { cn } from "@util/helpers";

export default function ThriveAboutUsDescription(): React.ReactElement {
  return (
    <div
      className="font-inter text-gray-850"
      data-testid="thrive-about-us-description-component"
    >
      <Container rootClassName="px-7 py-10 lg:py-20 font-inter">
        <div className={cn("items-center justify-center gap-20 lg:flex")}>
          <div className="lg:w-2/5">
            <img
              src={thriveMeetTheThrivers}
              alt="meet the thrivers"
              width={450}
              height={397}
            />
          </div>

          <div className="lg:w-1/2">
            <h1 className="thrive-past-event-title stroke-gray-850 stroke-1 py-4 font-interBold text-11xl font-bold leading-[4.5rem] text-white text-shadow-thrive-2 lg:py-0 lg:pb-3 lg:text-13xl">
              Meet the Thrivers
            </h1>

            <p className="text-lg">
              {
                "Hello there! We are a bunch of (relatively) young people creating content for other young people on career, money and life. We aim to build a community where we can discuss issues that really matter. Adulting's tough, but you don't have to do it alone. Thrive is owned by The Business Times, part of SPH Media."
              }
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}
