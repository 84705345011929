import type { ArticleDataObject } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { BlockTitle } from "@blocks/Blocks";
import Advertisement from "@components/Advertisement/Advertisement";
import BreakingNewsStories from "@pages/Home/BreakingNews/BreakingNewsStories";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { GoogleAdsSlotFactory } from "@util/helpers";

export type BreakingNewsProps = {
  testId?: string | null;
  rootClassName?: string;
  data?: ArticleDataObject[];
};

const section = sectionNavigationItems["breaking-news"];

export default function BreakingNews({
  testId = null,
  rootClassName,
  data = [],
}: BreakingNewsProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  return (
    <section
      className={rootClassName}
      data-testid={testId}
      id="homepage-breakingnews-section"
    >
      <BlockTitle link={section?.link} text={section?.label?.toUpperCase()} />

      <div className="stories lg:grid lg:grid-cols-3 lg:gap-4">
        <div className="lg:col-span-2 lg:grid lg:grid-cols-2">
          {data?.map((article: ArticleDataObject, index: number) =>
            article ? (
              <BreakingNewsStories
                article={article}
                index={index}
                key={index}
                disablePaywall={
                  OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
                }
              />
            ) : null
          )}
        </div>

        <div className="bg-gray-125 lg:col-start-3 lg:h-auto">
          <div className="sticky top-[40px]">
            <Advertisement
              rootClassName="bg-gray-125 py-6 border-t border-gray-175 text-center -mx-3 md:mx-0"
              adUnitProps={{
                type: AdvertisementTypeEnum.IMU2,
                slot: GoogleAdsSlotFactory.imu2("homepage"),
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
