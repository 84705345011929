import BenjaminCher from "@assets/newsletter/benjamin20cher.png";
import MailIcon from "@assets/newsletter/nl-byline-mail-icon.png";

export default function NewsletterSampleAuthor(): React.ReactElement {
  return (
    <>
      <div className="m-0 mx-auto box-border w-full max-w-4xl p-0">
        <div className="flex flex-wrap items-center justify-center">
          <div className="flex-none pb-2 pl-6 pr-7 pt-2">
            <img
              className="user_profile_img relative h-auto max-w-full rounded-full border-4 border-green"
              src={BenjaminCher}
              width="80"
              height="80"
            />
          </div>
          <div className="items-center pt-2">
            <span className="font-helvetica block text-base font-bold leading-5 text-green">
              Benjamin Cher
            </span>
            <span className="font-helvetica block text-base font-normal leading-5 text-black">
              Garage Correspondent
            </span>
            <a
              className="byline-link font-helvetica text-base font-normal leading-5 text-black no-underline"
              href="mailto:benjamincher@sph.com.sg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="email-img inline h-5 w-5 align-middle"
                alt="author e-mail"
                src={MailIcon}
                width="20"
                height="20"
              />
              <div className="inline-block pl-1 align-middle">BenjaminCher</div>
            </a>
          </div>
        </div>
      </div>
      <div className="mt-9 border-t-[10px] border-gray-250 pb-5" />
    </>
  );
}
