const lotameInitScript = `
! function() {
    // Lotame config
    var lotameClientId = '12374';
    var lotameTagInput = {
      data: {},
      config: {
        clientId: Number(lotameClientId),
      }
    };

    // Lotame initialization
    var lotameConfig = lotameTagInput.config || {};
    var namespace = window['lotame_' + lotameConfig.clientId] = {};
    namespace.config = lotameConfig;
    namespace.data = lotameTagInput.data || {};
    namespace.cmd = namespace.cmd || [];
  } ();`;

export default lotameInitScript;
