import { useState } from "react";
import Button from "@components/AccessibleComponents/Button";
import Tooltip from "@components/AccessibleComponents/Tooltip";
import TooltipTrigger from "@components/AccessibleComponents/TooltipTrigger";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import cx from "classnames";

export default function NewsletterSubscriberOnly(): React.ReactElement {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const OktaUserInfo = useOKTAUserStore((store) => store.userInfo);
  const isSubscriber = OktaUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  return (
    <>
      <TooltipTrigger
        delay={0}
        closeDelay={0}
        key="test"
        isOpen={isTooltipVisible}
      >
        <div
          className={cx("flex flex-row flex-wrap gap-x-3")}
          onMouseEnter={() => {
            setIsTooltipVisible(true);
          }}
          onMouseLeave={() => {
            setIsTooltipVisible(false);
          }}
        >
          <Button
            className={cx(
              "absolute bottom-0 border border-black px-2 py-1 font-public-sans text-[14px] font-normal",
              isSubscriber ? "right-0" : "left-0"
            )}
          >
            Subscriber Only
          </Button>
        </div>

        <Tooltip className="rounded bg-gray-850 px-4 py-2 font-poppins text-xs font-normal text-white">
          Subscribe to BT to sign up to this newsletter.
        </Tooltip>
      </TooltipTrigger>
    </>
  );
}
