export enum Name {
  Headline = "headline",
  Paragraph = "paragraph",
  PodcastWrapperSource = "podcastWrapperSource",
  Uri = "uri",
  Kicker = "kicker",
  QuoteLabel = "quoteLabel",
  Quote = "quote",
  Quotee = "quotee",
  QuoteCredit = "quoteCredit",
  QuoteRule = "quoteRule",
  CropSelection = "cropSelection",
  Caption = "caption",
  Credit = "credit",
  Alt = "alttext",
  Byline = "byline",
  TitleColor = "titleColor",
  Alignment = "alignment",
  DisplayCaption = "displayCaption",
  DisplayFullWidth = "displayFullWidth",
  ConvertToParallax = "convertToParallax",
  DataWrapperSrc = "dataWrapperSource",
  DynamicIframe = "dynamicIframe",
  Title = "title",
  YoutubeId = "youtubeId",
  AutoPlay = "autoplay",
  ClosedCaption = "closedcaption",
  AllowFullScreen = "allowfullscreen",
  TableEditor = "tableeditor",
  RowHeader = "rowheader",
  ColumnHeader = "columnheader",
  Description = "description",
  BrightcoveId = "brightcoveId",
  BrightcovePreview = "brightcovePreview",
  Display = "display",
  EmbedUrl = "embedUrl",
  Standfirst = "standfirst",
  NewWindow = "newWindow",
  NoFollow = "noFollow",
  StockCode = "stockCode",
  CssClass = "cssClass",
  Link = "link",
  Meta = "meta",
}

export enum Type {
  Headline = "headline",
  Paragraph = "paragraph",
  PodcastWrapper = "podcast_wrapper",
  DataWrapper = "data_wrapper",
  DynamicIframeWrapper = "dynamic_iframe_wrapper",
  Kicker = "kicker",
  Standfirst = "standfirst",
  Image = "image",
  Embed = "embed",
  BrightcoveVideo = "brightcove_video",
  SubHead = "sub_head",
  SubHeadField = "subhead",
  Headings = "headings",
  ExternalLink = "external_link",
  InternalLink = "internal_link",
  Graphic = "graphic",
  Relation = "relation",
  GroupedRelation = "grouped_relation",
  Note = "note",
  HTMLAnchor = "htmlAnchor",
  CSSClass = "cssClass",
  DataAttributes = "dataAttribute",
  ListBulleted = "list_bulleted",
  ListNumbered = "list_numbered",
  PullQuote = "pull_quote",
  Table = "table",
  HorizontalRow = "horizontal_row",
  YoutubeVideo = "youtube_video",
  OmnyPodcast = "omny_podcast",
  Box = "box",
  Slug = "slug",
  Map = "map",
  Endnote = "endnote",
  StockPicker = "stock_picker",
  BoxTitle = "box_title",
  ArticleBrightcoveVideo = "brightcoveVideo",
  ArticleYoutubeVideo = "youtubeVideo",
  SkyboxWidget = "skybox_widget",
}

export enum ArticleDisplayType {
  MainNewsDefault = "mainNewsDefault",
  VerticalsNewsDefault = "verticalsNewsDefault",
  Lifestyle = "lifestyle",
  Infographics = "infographics",
  Compact = "compact",
}

export enum HeroVariant {
  Main = "main",
  Secondary = "secondary",
}

export enum TagsVariant {
  Inside,
  Outside,
}

export enum AdvertisementTypeEnum {
  IMU1 = "imu1",
  IMU2 = "imu2",
  IMU3 = "imu3",
  LB1 = "lb1",
  LB2 = "lb2",
  CATFISH = "catfish",
  MIDARTICLE = "midarticlespecial",
  PRESTITIAL = "prestitial",
  TOPOVERLAY = "topoverlay",
  ABM = "abm",
  BN1 = "bn1",
  BN2 = "bn2",
  BN3 = "bn3",
  BN4 = "bn4",
}

/**
 * A parameter used for making requests to OpenSearch
 */
export enum OS_CATEGORY_PARAM {
  Title = "title",
  Author = "author",
  Tags = "tags",
  Sections = "sections",
  ArticleIdForPrerender = "queryArticleByIdForPrerender",
  SectionUniqueNameForPrerender = "querySectionsByUniqueNameForPrerender",
}

export enum CaasSchema {
  Cue = "cue-article",
}
export enum CaasEvent {
  Create = "create",
  Delete = "delete",
}
export enum CueEventState {
  Published = "published",
  Unpublished = "unpublished",
}

export enum AccessFlag {
  Premium = "2",
  Free = "0",
}

export enum Vertical {
  Sme = "sme",
}

export enum NavigationKeysEnums {
  navRight = "navigation-right",
  navLeft = "navigation-left",
  hamburgerTop = "hamburger-top",
  hamburgerSection = "hamburger-sections",
  hamburgerBrands = "hamburger-brands",
  hamburgerRecommends = "hamburger-recommends",
  hamburgerAds = "hamburger-ads",
}

export enum userInfoEnums {
  unverified = "unverified",
}

export enum KickerEnums {
  BRANDED_CONTENT = "BRANDEDCONTENT",
}

export enum NewsletterType {
  PremiumNewsletterTitle = "Premium NEWSLETTERS",
  DailyNewsletters = "daily-newsletters",
  WeeklyNewsletter = "weekly-newsletters",
  PremiumNewsletter = "premium-newsletters",
}

export enum EMARSYS_CUSTOM_FIELDS {
  OPT_IN = "31",
}

export enum EMARSYS_FIELD_VALUES {
  OPT_IN_YES = "1",
  OPT_IN_NO = "2",
}

export enum EmarsysStatusEnums {
  Success = "success",
  Error = "error",
}

export enum EmarsysCodesEnums {
  Success = 1,
  EmailExist = 2,
  Error = 3,
  EmailIsRequired = 4,
  InvaildEmail = 5,
}

export enum NewsletterArchiveKeywordsEnums {
  BigMoney = "big-money-newsletter",
  Garage = "garage-newsletter",
  Thrive = "thrive-newsletter",
  PropertyInsight = "property-insights",
  AseanBusiness = "asean-business-newsletter",
  EsgInsight = "esg-insights",
}

export enum NewsletterArchiveAdKeywordsEnums {
  BigMoney = "newsletter-bigmoney",
  Garage = "newsletter-garage",
  Thrive = "newsletter-thrive",
  PropertyInsight = "newsletter-property-insights",
  AseanBusiness = "newsletter-asean-business",
  EsgInsight = "newsletter-esg-insights",
}
