import KennethLim from "@assets/newsletter/kenneth_lim.png";
import LinkedinIcon from "@assets/newsletter/nl-byline-linkedin-icon.png";

export default function EsgInsightsNewsletterSampleAuthor(): React.ReactElement {
  return (
    <>
      <div className="m-0 mx-auto my-3 box-border w-full max-w-4xl p-0 py-2.5">
        <div className="flex flex-wrap items-center justify-center">
          <div className="pr-2">
            <img
              className="user_profile_img relative h-auto max-w-full"
              src={KennethLim}
              alt="Kenneth Lim"
              width="80"
              height="80"
            />
          </div>
          <div className="items-center pt-2">
            <span className="font-helvetica block text-sm font-bold leading-5 text-black">
              Kenneth Lim
            </span>
            <span className="font-helvetica block text-sm font-normal leading-5 text-black">
              Columnist
            </span>
            <a
              className="byline-link font-helvetica text-sm font-normal leading-5 text-black no-underline"
              href="https://www.linkedin.com/in/kenneth-lim-72b3451a/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="email-img border-vertical-asean inline h-5 w-5 border-2 align-middle"
                alt="author mail"
                src={LinkedinIcon}
                width="20"
                height="20"
              />
              <div className="inline-block pl-1 align-middle">Kenneth Lim</div>
            </a>
          </div>
        </div>
      </div>
      <div className="mx-5 mt-6 border-t-[3px] border-gray-250 pb-6" />
    </>
  );
}
