/* eslint-disable no-console */
import { DataFetcher, WebFetcher } from "@app/data";
import { RouteFactory, routesPodcastsKeywords } from "@app/routePaths";
import type { ArticleDataObject, Section, Tag } from "@app/types/Cue";
import type { PodcastKeywordContext, TermContext } from "@app/types/Page";
import { genSectionKeywordQuery } from "@caas/queries/genSectionKeywordQuery";
import { genSectionQuery } from "@caas/queries/genSectionQuery";
import { queryByKeywords } from "@caas/queries/queryByKeywords";
import { queryCaasOpenSearch } from "@caas/queryCaasOpenSearch";
import { TrimmedBreakingNewsArticle } from "@caas/types";
import { renderPageTitleV2 } from "@components/MetaTags/helpers";
import { MyBtConfigResponse, MyBtUpdateResponse } from "@pages/MyBT/types";
import { ResponseType, TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { textToSlug } from "@util/helpers";
import { santizeArticleDataObjects } from "@util/sanitization/sanitizeArticleDataObjects";
import axios, { HttpStatusCode } from "axios";
import { startCase } from "lodash-es";

import { defaultSourceForListing } from "./constants";
import { getSectionObject, getTagObject } from "./helpers";

export const sectionRequests = {
  fetchTrimmedBreakingNews: async (): Promise<TrimmedBreakingNewsArticle[]> => {
    try {
      const response = await axios.get("/_plat/api/v1/trimmed-breaking-news");
      return response.data;
    } catch (error) {
      return [];
    }
  },

  fetchArticlesByKeywords: async (tags: Tag[], size = 10) => {
    const urlPaths = tags.map((tag) => tag.urlPath);
    const response = await axios.post("/_plat/api/v1/mybt", { urlPaths, size });
    return response;
  },

  fetchMyBtFollowedKeywords: async (
    userType: OKTAUserTypeEnum,
    aovisitorid: string
  ) => {
    const response = await axios.get<MyBtConfigResponse>(
      "/_plat/api/v1/mybt/get-config",
      { headers: { usertype: userType, aovisitorid: aovisitorid } }
    );

    return <Tag[] | null>response.data.tags.map(({ name, id }) => {
      return { name: name, urlPath: `/keywords/${textToSlug(name)}`, id: id };
    });
  },

  updateMyBtFollowedKeywords: async (
    userType: OKTAUserTypeEnum = OKTAUserTypeEnum.ANONYMOUS,
    aovisitorid: string,
    tags: Tag[]
  ) => {
    if (userType == OKTAUserTypeEnum.ANONYMOUS)
      return <MyBtUpdateResponse>{
        code: 403,
        message: "Permission denied. User is anonymous.",
      };
    try {
      const response = await axios.post<MyBtUpdateResponse[]>(
        "/_plat/api/v1/mybt/update",
        { tags: tags },
        { headers: { usertype: userType, aovisitorid: aovisitorid } }
      );

      return response.data[0];
    } catch (error) {
      return <MyBtUpdateResponse>{
        code: 500,
        message: "Internal Server error.",
        errors: error,
      };
    }
  },

  deleteMyBtFollowedKeywords: async (
    userType: OKTAUserTypeEnum = OKTAUserTypeEnum.ANONYMOUS,
    aovisitorid: string,
    tags: Tag[]
  ) => {
    if (userType == OKTAUserTypeEnum.ANONYMOUS)
      return <MyBtUpdateResponse>{
        code: 403,
        message: "Permission denied. User is anonymous.",
      };
    try {
      const response = await axios.post<MyBtUpdateResponse[]>(
        "/_plat/api/v1/mybt/delete",
        { tags: tags },
        { headers: { usertype: userType, aovisitorid: aovisitorid } }
      );
      return response.data[0];
    } catch (error) {
      return <MyBtUpdateResponse>{
        code: 500,
        message: "Internal Server error.",
        errors: error,
      };
    }
  },
};

export const fetchSectionDataV2: DataFetcher<TermContext> = async function (
  this: { sectionUniqueName: string },
  params: Record<string, unknown> & Array<unknown>
) {
  const [parentCategory, childCategory] = (
    this.sectionUniqueName ?? (params[0] as string).replace("/", "")
  ).split("/");

  let matchedCategory = parentCategory;

  // TODO: Fix the issue of retrieving data for bt-luxe
  if (
    childCategory &&
    parentCategory !== "lifestyle" &&
    childCategory !== "bt-luxe"
  ) {
    matchedCategory += `_${childCategory}`;
  }

  const query = genSectionQuery(matchedCategory, 10);
  const response = await queryCaasOpenSearch(query);

  const overview = response.payload?.hits.hits?.map((article) => {
    const articleContext = article._source.data.context;
    return {
      ...articleContext,
      sort: article.sort,
      slug: articleContext.urlPath,
    };
  });

  const sanitizedOverview = santizeArticleDataObjects(overview);
  const sectionObectFromArticle = getSectionObject(
    overview?.[0]?.sections,
    matchedCategory
  );

  // Feel like this should return an error and dont process anymore if sectionobject is not found.

  const sectionObjectFromPath: Section = {
    parent: {
      uniqueName: "ece_frontpage",
      name: "Home",
      directoryName: "frontpage",
    },
    uniqueName: matchedCategory,
    name: startCase(matchedCategory.replaceAll("-", " ").toLowerCase()),
    href: `/${matchedCategory}/`,
    parameters: [],
    directoryName: matchedCategory,
  };

  return {
    type: ResponseType.SUCCESS,
    statusCode: 200,
    kind: "section",
    data: {
      entity: sectionObectFromArticle || sectionObjectFromPath,
      title: renderPageTitleV2({
        kind: "section",
        termName:
          sectionNavigationItems[matchedCategory.replace("_", "/")].label,
      }),
      overview: sanitizedOverview,
    },
  };
};

export const fetchMoreStoriesData = async function (
  category: string,
  parentCategory: string,
  page: number
): Promise<ArticleDataObject[]> {
  try {
    const response = await axios.post("/_plat/api/v1/more-stories", {
      category, // "companies-markets_banking-finance" || "food-drink"
      parentCategory, // "companies-markets" || "keywords"
      size: 10,
      page,
    });

    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchMoreBrandedContentData = async function (
  article: ArticleDataObject
): Promise<ArticleDataObject[]> {
  try {
    const response = await axios.post("/_plat/api/v1/more-stories-by-kicker", {
      kicker: "Branded Content",
      sort: article.sort,
      size: 10,
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const getPageSize = (path: string): number => {
  if (routesPodcastsKeywords.includes(path)) return 5;
  return 10;
};

export const fetchPodcastKeywordData: WebFetcher<
  TRouteWithRedirect<PodcastKeywordContext, string>
> = async ({ urlComponent }) => {
  const keywordUri =
    urlComponent && urlComponent.path
      ? urlComponent.path.replace("/", "").split("/")[1]
      : "";

  const query = genSectionKeywordQuery(
    RouteFactory.podcasts.replace("/", ""), // "podcasts"
    `/keywords/${keywordUri}`, // ie /keywords/money-hacks
    5 // pageSize,
  );
  const response = await queryCaasOpenSearch(query);

  const overview = response.payload?.hits.hits?.map((article) => {
    const articleContext = article._source.data.context;
    return { ...articleContext, sort: article.sort };
  });

  const sanitizedOverview = santizeArticleDataObjects(overview);
  const tag = getTagObject(overview?.[0]?.tags, `/keywords/${keywordUri}`);

  return {
    type: ResponseType.SUCCESS,
    statusCode: HttpStatusCode.Ok,
    payload: {
      kind: "keyword",
      data: {
        entity: tag,
        title: renderPageTitleV2({
          kind: "keyword",
          termName: tag.name,
        }),
        overview: sanitizedOverview,
      },
    },
  };
};

export const fetchKeywordDataV2: DataFetcher<TermContext> =
  async function (props: {
    urlComponent: {
      path: string;
      index: number;
      params: object;
    };
    variant: string;
  }) {
    const path = props.urlComponent.path;
    const keyword = path.replace("/", "").split("/")[1];
    const pageSize = getPageSize(path);
    const query = queryByKeywords(
      `/keywords/${keyword}`,
      pageSize,
      0,
      defaultSourceForListing
    );
    const response = await queryCaasOpenSearch(query);

    const overview = response.payload?.hits.hits?.map((article) => {
      const articleContext = article._source.data.context;
      return {
        ...articleContext,
        sort: article.sort,
        slug: articleContext.urlPath,
      };
    });

    const sanitizedOverview = santizeArticleDataObjects(overview);
    const tag = getTagObject(overview[0]?.tags, `/keywords/${keyword}`);

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      kind: "keyword",
      data: {
        entity: tag,
        title: renderPageTitleV2({
          kind: "keyword",
          termName: tag.name,
        }),
        overview: sanitizedOverview,
      },
    };
  };
