import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";
import { ArticleDataObject } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import type { SectionContext } from "@app/types/Page";
import Advertisement from "@components/Advertisement/Advertisement";
import sampleArticle from "@components/ArticleCard/__tests__/fixtures/article.json";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import SingleBreadcrumb from "@components/Breadcrumb/SingleBreadcrumb";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import Header from "@components/Header/Header";
import { useCategoriesFromPath } from "@components/Header/helpers";
import { useWindowSize } from "@hooks/useWindowSize";
import BreakingNews from "@pages/Section/components/BreakingNews/BreakingNews";
import MostPopular from "@pages/Section/components/MostPopular/MostPopular";
import { GoogleAdsSlotFactory } from "@util/helpers";
import cx from "classnames";

type SectionProps = {
  context: SectionContext;
};

// expects values like `wealth-may-2022` or `wealth-jan-2023`
const getMagazineName = (url: string | undefined) => {
  if (url === undefined) return "";
  const month = url?.split("-")[1];
  const year = url?.split("-")[2];
  const capitalizedMonth =
    month?.charAt(0).toUpperCase() + (month?.slice(1) ?? "");

  return `Wealth (${capitalizedMonth} ${year})`;
};

export default function WealthMagazineSole({
  context: {
    // eslint-disable-next-line
    data: { title, breakingNews, mostPopular },
  },
}: SectionProps): ReactElement {
  const { parentCategory } = useCategoriesFromPath();

  const magazineName = getMagazineName(parentCategory);

  // TODO: Remove mock data
  const mockArticles = new Array(8).fill(sampleArticle);

  return (
    <div className="w-full">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Advertisement
        rootClassName="min-h-[50px] lg:min-h-[250px] bg-gray-125 py-1 md:py-3 border-b border-gray-175"
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(parentCategory),
          adsClassName:
            "min-h-[50px] lg:min-h-[250px] flex flex-col justify-center items-center",
        }}
      />

      <Header />

      {magazineName ? (
        <div className="flex-none bg-gray-250">
          <SingleBreadcrumb title={magazineName} />
        </div>
      ) : null}

      <Container rootClassName="pt-6">
        <Row>
          <Column rootClassName="w-full lg:w-8/12">
            {mockArticles.map((article: ArticleDataObject, index: number) => {
              return (
                <IndividualMagazineStories
                  key={index}
                  index={index}
                  article={article}
                />
              );
            })}
          </Column>

          <Column rootClassName="w-full lg:w-4/12">
            <Advertisement
              rootClassName="bg-stripes mb-5 py-5"
              adUnitProps={{
                type: AdvertisementTypeEnum.IMU2,
                slot: GoogleAdsSlotFactory.imu2(parentCategory ?? ""),
              }}
            />

            <BreakingNews data={breakingNews} />

            <MostPopular data={mostPopular} />
          </Column>
        </Row>
      </Container>

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.CATFISH,
          slot: GoogleAdsSlotFactory.catfish(parentCategory),
        }}
      />

      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.ABM,
          slot: GoogleAdsSlotFactory.abm(),
        }}
      />
    </div>
  );
}

function IndividualMagazineStories({
  article,
  index,
}: {
  article: ArticleDataObject;
  index: number;
}): React.ReactElement {
  const { isScreenLG, isScreenMD } = useWindowSize();
  const isLeadArticle = index === 0;
  const variant = {
    image: {
      width: cx("w-1/4", {
        "md:w-1/2": isLeadArticle,
      }),
      extraClass: cx({
        "lg:order-2": !isLeadArticle,
      }),
    },
    content: {
      width: cx("w-3/4", {
        "md:w-1/2": isLeadArticle,
      }),
      extraClass: cx({
        "p-4 md:pt-0 md:pb-0 md:pr-0 items-center md:items-start text-center md:text-left bg-gray-850 md:bg-transparent max-sm:text-white":
          isLeadArticle,
        "lg:order-1": !isLeadArticle,
        "pl-4 lg:pl-0 lg:pr-4": !isLeadArticle,
      }),
    },
    title: {
      size: isLeadArticle ? "text-4xl lg:text-6xl" : "",
    },
  } as CardVariations;
  const media = isLeadArticle
    ? isScreenMD
      ? article.media?.[0]
      : undefined
    : article.media?.[0];

  return (
    <div
      key={index}
      className={cx("story border-b border-gray-175 lg:col-span-1", {
        "py-3": !isLeadArticle,
        "pb-3": isLeadArticle,
      })}
    >
      <BasicCard
        id={article.id}
        slug={article.slug}
        title={article.title}
        kicker={article.kicker?.fields?.[0]?.value}
        media={media}
        blurb={!isScreenLG ? article.storyContent?.blurb : undefined}
        paywall={article.paywall?.contentAccess === "1"}
        variations={variant}
        created={article.created}
        edited={article.edited}
        updated={article.updated}
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
      />
    </div>
  );
}
