import { useEffect, useState } from "react";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import Cookies from "js-cookie";

export default function useShowGiftNofitifcationUpdate(isPremium: boolean) {
  const OKTAUserInfo = useOKTAUserStore((store) => store.userInfo);
  const [showGiftNotification, setShowGiftNotification] = useState(false);

  const isSubscriber = OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  useEffect(() => {
    const giftAnArticleNotified = Cookies.get("giftAnArticleNotified");

    if (typeof giftAnArticleNotified !== "undefined") return;
    if (!isSubscriber) return;
    if (!isPremium) return;

    setShowGiftNotification(true);
  }, [setShowGiftNotification, isSubscriber, isPremium]);

  return { showGiftNotification, setShowGiftNotification };
}
