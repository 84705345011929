import { HTMLAttributes, ImgHTMLAttributes } from "react";
import { SanitizedSectionArticle } from "@app/types/Cue";
import { BlockTitle, BlockTitleProps } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@util/helpers";

export type EventsAwardsPageRowProps = {
  logoAttributes: ImgHTMLAttributes<HTMLImageElement>;
  article: SanitizedSectionArticle;
  blockTitleProps: BlockTitleProps;
  description: string;
} & Pick<HTMLAttributes<HTMLDivElement>, "className">;

export default function EventsAwardsPageRow({
  logoAttributes,
  article,
  blockTitleProps,
  description,
  className,
}: EventsAwardsPageRowProps): React.ReactElement {
  const { isScreenLG } = useWindowSize();

  const { id, title, kicker, media, paywall, sections } = article;

  return (
    <Row rootClassName={cn("py-6", className)}>
      <Column rootClassName="w-full mb-5 lg:w-6/12 lg:order-2 xl:w-4/12">
        <BlockTitle {...blockTitleProps} />

        <p className="font-public-sans text-lg leading-6">{description}</p>
      </Column>

      <Column rootClassName="w-full lg:w-3/12 lg:order-1 xl:w-4/12">
        <img {...logoAttributes} className="mx-auto" />
      </Column>

      <>
        {isScreenLG ? (
          <Column rootClassName="w-full lg:w-3/12 lg:order-3 xl:w-4/12">
            <BasicCard
              id={id}
              title={title}
              kicker={kicker?.fields.at(0)?.value}
              paywall={paywall.contentAccess === "1"}
              media={media.at(0)}
              defaultImage={{
                directoryName: sections?.at(0)?.uniqueName || "",
              }}
              variations={{ content: { width: "w-full pt-2" } }}
            />
          </Column>
        ) : null}
      </>
    </Row>
  );
}
