import * as React from "react";
import cx from "classnames";

import styles from "./Breadcrumb.module.scss";

export default function SingleBreadcrumb({
  title,
}: {
  title: string;
}): React.ReactElement {
  return (
    <div
      className={cx(
        styles.componentBreadcrumb,
        "container hidden h-[45px]",
        "lg:flex"
      )}
    >
      <h3 className={cx(styles.active, "relative mr-8 pr-4 text-gray-875")}>
        {title}
      </h3>
    </div>
  );
}
