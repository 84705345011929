import { RouteFactory } from "@app/routePaths";
import AseanBusinessLogo from "@assets/newsletter/asean-header.png";
import btLogo from "@assets/newsletter/big-money-bt-logo-with-white-bg.png";

export default function AseanBusinessNewsletterSampleHeader(): React.ReactElement {
  return (
    <div
      className="m-0 mx-auto box-border w-full max-w-4xl p-0 px-5"
      data-testid="asean-business-newsletter-sample-header-component"
    >
      <div className="m-0 box-border">
        <a
          href={RouteFactory.section("startups-tech/startups")}
          target="_blank"
          rel="noopener noreferrer"
          className="block border-t-2 border-verticals-asean text-center no-underline"
        >
          <img
            className="banner-logo relative mx-auto block h-auto"
            src={btLogo}
            height="30"
            width="120"
            alt="BT Logo"
          />
          <img
            className="banner-logo relative mx-auto block h-auto pb-6 pt-2"
            src={AseanBusinessLogo}
            height="78"
            width="190"
            alt="Newsletter Logo"
          />
        </a>
      </div>
    </div>
  );
}
