import { ChartbeatArticleDataObject } from "@app/types/Chartbeat";
import { BlockTitle } from "@blocks/Blocks";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import cx from "classnames";

import styles from "./MostPopular.module.scss";

export type MostPopularProps = {
  rootClassName?: string;
  data?: ChartbeatArticleDataObject[];
};

export default function MostPopular({
  rootClassName,
  data,
}: MostPopularProps): React.ReactElement {
  const variant: CardVariations = {
    title: {
      color: "!text-gray-850",
      size: "text-base",
    },
  };

  return (
    <>
      {data && data.length > 0 ? (
        <section className={rootClassName} data-testid="most-popular-component">
          <BlockTitle text="MOST POPULAR" rootClassName="mb-3" />

          <div className={cx(styles.articleList, "stories")}>
            {data?.map(
              (
                { title, article_url }: ChartbeatArticleDataObject,
                index: number
              ) => {
                return (
                  <div
                    className={cx(styles.articleCard, "story flex flex-row")}
                    key={`most_popular_${index}`}
                  >
                    <div
                      className={cx(
                        styles.articleIndex,
                        "mr-4 mt-1.5 w-[20px] flex-shrink-0 py-4 text-gray-850"
                      )}
                    >
                      {index + 1}
                    </div>

                    <div className="flex w-full flex-col border-b py-4">
                      <BasicCard
                        rootClassName="my-0"
                        id={`most_popular_${index}`}
                        title={title}
                        slug={article_url}
                        variations={variant}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </section>
      ) : null}
    </>
  );
}
