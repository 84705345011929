import { Paywall } from "@app/types/Cue";
import CreateTime from "@components/ArticleMeta/CreateTime";
import { cn } from "@util/helpers";

export type NewsletterPostProps = {
  title?: string;
  urlPath?: string;
  updated?: string;
  rootClassName?: string;
  paywall?: Paywall;
};

export default function NewsletterPost({
  title,
  urlPath,
  updated,
  rootClassName,
  paywall,
}: NewsletterPostProps): React.ReactElement {
  const isPremium = paywall?.contentAccess === "1";
  return (
    <>
      <div
        data-testid="newsletter-post"
        className={cn(rootClassName, "pb-3 lg:pt-3")}
      >
        <div className="flex flex-row justify-between">
          <div className="flex w-full flex-col lg:w-2/3 lg:flex-row">
            <div>
              <div className="block font-poppins text-xs font-normal text-gray-550 lg:hidden">
                {updated ? (
                  <CreateTime
                    rootClassName="capitalize block font-poppins text-xs font-normal text-gray-550 lg:hidden"
                    dateFormat="MMMM DD, YYYY"
                    created={updated}
                  />
                ) : null}
              </div>

              <p
                className={cn("mb-0 pr-2 font-lct text-xl font-bold", {
                  "pb-4": !isPremium,
                })}
              >
                <a
                  href={urlPath}
                  target="_blank"
                  className="hover:underline"
                  rel="noreferrer"
                >
                  {title}
                </a>

                {isPremium ? (
                  <span className="relative hidden pl-4 text-base leading-[.3] text-orange-300 before:content-['\25CF'] lg:inline lg:pl-4">
                    <span className="font-public-sans text-4xs font-normal uppercase text-gray-900">
                      Subscribers
                    </span>
                  </span>
                ) : null}
              </p>

              {isPremium ? (
                <span className="relative block pb-4 pt-3 text-xs leading-[.3] text-orange-300 before:content-['\25CF'] lg:hidden">
                  <span className="font-poppins text-4xs font-normal uppercase text-gray-900">
                    Subscribers
                  </span>
                </span>
              ) : null}
            </div>
          </div>

          {updated ? (
            <CreateTime
              rootClassName="capitalize hidden font-poppins text-xs font-normal text-gray-550 lg:block"
              dateFormat="MMMM DD, YYYY"
              created={updated}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
