import { BlockTitle } from "@app/blocks/Blocks";
import type { ArticleDataObject } from "@app/types/Cue";
import CompaniesMarketsStories from "@pages/Home/CompaniesMarkets/CompaniesMarketsStories";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import cx from "classnames";

export type CompaniesMarketsProps = {
  testId?: string | null;
  rootClassName?: string;
  data?: ArticleDataObject[];
};

const section = sectionNavigationItems["companies-markets"];

export default function CompaniesMarkets({
  testId = null,
  rootClassName,
  data = [],
}: CompaniesMarketsProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);

  return (
    <section
      className={rootClassName}
      data-testid={testId}
      id="homepage-companiesmarkets-section"
    >
      <BlockTitle
        link={section?.link}
        text={section?.label?.toUpperCase() ?? ""}
      />

      <div
        className={cx(
          "stories lg:grid lg:grid-flow-col lg:grid-cols-2 lg:grid-rows-3"
        )}
      >
        {data?.map((article: ArticleDataObject, index: number) => {
          return (
            <CompaniesMarketsStories
              key={index}
              index={index}
              article={article}
              disablePaywall={
                OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER
              }
            />
          );
        })}
      </div>
    </section>
  );
}
