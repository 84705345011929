import { SanitizedPulseData } from "./types";

export const getActiveArticleId = (pathname: string): string => {
  const activeArticleId = pathname.split("/");

  return activeArticleId[activeArticleId.length - 1];
};

export const filteredPulseArticles = (
  articleId: string,
  pulseArticles: SanitizedPulseData[]
) => {
  const filterActiveArticleId = pulseArticles.filter(
    (p: SanitizedPulseData) => p.id === articleId
  );

  if (filterActiveArticleId.length <= 0) return pulseArticles;

  // remove the item from the array
  const filteredPulseArticles = pulseArticles.filter(
    (p: SanitizedPulseData) => p.id !== articleId
  );

  // add it at the beginning
  filteredPulseArticles.unshift({ ...filterActiveArticleId[0] });
  return filteredPulseArticles;
};
