export enum Versions {
  version1 = "version1",
  version2 = "version2",
}

export type ArticlePurchaseLinkProps = {
  title: string;
  publishedDate: string;
  rootTextClassName?: string;
  versions?: Versions;
  displayPurchase?: boolean;
  urlPath?: string;
};
