import { useEffect, useState } from "react";
import { ChartbeatArticleDataObject } from "@app/types/Chartbeat";
import axios from "axios";

export type MostReadResponse = {
  total: number;
  results: ChartbeatArticleDataObject[];
};

const fetchMostReadArticles = async (): Promise<
  ChartbeatArticleDataObject[]
> => {
  try {
    const response = await axios.get<MostReadResponse>(
      "/_plat/api/v1/most-popular"
    );
    return response.data.results ?? [];
  } catch (e) {
    return [];
  }
};

/**
 * Description: It returns most popular article list from Chartbeat API
 * @returns data - {ChartbeatArticleDataObject[]}
 */
export const useMostReadArticleList = (): ChartbeatArticleDataObject[] => {
  const [data, setData] = useState<ChartbeatArticleDataObject[]>([]);
  useEffect(() => {
    const callFetch = async () => {
      setData(await fetchMostReadArticles());
    };
    callFetch();
  }, []);

  return data;
};
