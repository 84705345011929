import { ReactElement, useEffect, useState } from "react";
import { getFormattedTime } from "@src/app/util/helpers";
import cx from "classnames";

import styles from "./ArticleMeta.module.scss";

export type CreateTimeProps = {
  rootClassName?: string;
  created: string;
  dateFormat?: string;
};

/**
 * CreateTime
 * Description: The create time of the article
 * @param {CreateTimeProps} props
 * @returns {ReactElement}
 */
export default function CreateTime({
  rootClassName,
  created,
  dateFormat,
}: CreateTimeProps): ReactElement {
  const [publishedDate, setPublishedDate] = useState("");

  useEffect(() => {
    setPublishedDate(getFormattedTime(created, dateFormat));
  }, [created, dateFormat]);

  return (
    <div
      className={cx(styles.createTime, "truncate uppercase", rootClassName)}
      data-testid="create-time-component"
    >
      {publishedDate}
    </div>
  );
}
