import { useEffect, useMemo, useState } from "react";
import { filteredPulseArticles } from "@pages/Pulse/utils/helpers";
import { PulseArticles, SanitizedPulseData } from "@pages/Pulse/utils/types";
import axios from "axios";

export const getPulseData = (data: PulseArticles[]) => {
  const santitizedData = data.map((article) => {
    return {
      ...article,
      summaries: article.summary.split(" | "),
    };
  });

  return santitizedData;
};

export const useGetBTPulseData = () => {
  const [pulseData, setPulseData] = useState<SanitizedPulseData[]>([]);
  const [sortedPulseData, setSortedPulseData] = useState<SanitizedPulseData[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<PulseArticles[]>(
          "/_plat/api/v1/pulse-data",
          {
            timeout: 100000,
          }
        );

        const santitizedData = getPulseData(response.data);
        setPulseData(santitizedData);
        setSortedPulseData(santitizedData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching pulse data list", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSortedPulseData = (id: string) => {
    const data = filteredPulseArticles(id, pulseData);
    setSortedPulseData(data);
  };

  const memorisedPulseData = useMemo(() => pulseData, [pulseData]);

  return {
    pulseData: memorisedPulseData,
    sortedPulseData,
    isLoading,
    handleSortedPulseData,
  };
};
