/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
 * Company: SPHMedia
 * Description: Default Section Layout
 */

import { Await } from "react-router-dom";
import { WealthContext } from "@app/types/Page";
import CustomError from "@components/Error/CustomError";
import { TRouteWithRedirect, useRouteContext } from "@sphtech/web2-core/ssr";
import { HttpStatusCode } from "axios";

import WealthPageContent from "./WealthPageContent";

export default function WealthPage(): React.ReactElement {
  const { context } =
    useRouteContext<TRouteWithRedirect<WealthContext, string>>();

  if (context && context.statusCode !== HttpStatusCode.Ok) {
    // StatusCode is not 200
    return <CustomError statusCode={context.statusCode} />;
  }

  return (
    <Await resolve={context.payload} errorElement={<CustomError />}>
      {({ kind, data }: WealthContext) => {
        if (kind !== "section") {
          // Error or kind is not "newsletter"
          return <CustomError statusCode={context.statusCode} />;
        }

        // StatusCode is 200 and kind is "newsletter"
        return <WealthPageContent data={data} />;
      }}
    </Await>
  );
}
