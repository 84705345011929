import thriveNewsletterArrow from "@assets/thrive_images/thrive-newsletter-arrow.svg";
import thriveNewsletterBg from "@assets/thrive_images/thrive-newsletter-bg.svg";
import thriveNewsletterBgLine from "@assets/thrive_images/thrive-newsletter-bg-line.svg";
import thriveNewsletterLatestArrow from "@assets/thrive_images/thrive-newsletter-latest-arrow.svg";
import Container from "@components/Container/Container";
import Column from "@components/Grid/Column";
import Row from "@components/Grid/Row";
import ThriveSubscriptionForm from "@pages/Thrive/components/ThriveSubscriptionForm";
import ThriveSampleNewsletter from "@pages/Thrive/Home/components/ThriveSampleNewsletter/ThriveSampleNewsletter";
import { cn } from "@util/helpers";

export default function ThriveHomeNewsletterSignUp(): React.ReactElement {
  return (
    <Container
      rootClassName="px-5 font-inter md:px-8 pt-20"
      data-testid="thrive-home-top-newsletter-component"
    >
      <Row>
        <Column rootClassName="w-full lg:w-1/2 flex items-center">
          <div className="text-gray-850">
            <h1 className="thrive-past-event-title stroke-gray-850 stroke-1 font-interBold text-11xl font-bold leading-[2.5rem] text-white text-shadow-thrive-2 md:text-13xl md:!leading-[4.5rem]">
              {"Let's talk career, money and life."}
            </h1>

            <p className="m-0 p-0">
              5-minute weekly newsletter to stay ahead of the curve.
            </p>

            <div>
              <ThriveSubscriptionForm
                className={cn(
                  "mt-4 w-fit border border-black bg-white shadow-thriveButton transition-all duration-150 hover:shadow-thriveNewsletterHover"
                )}
                formRootClass={cn("lg:max-w-[400px]")}
              />
            </div>

            <p className="my-2 p-0 md:text-lg">No spam, we promise.</p>
          </div>
        </Column>

        <Column rootClassName="lg:w-1/2 relative w-full pt-24 lg:p-0">
          <div
            className="group relative flex w-full justify-center"
            data-testid="thrive-home-sample-newsletter-component"
          >
            <div className="absolute top-20 hidden lg:block">
              <img
                src={thriveNewsletterBg}
                className="w-full"
                alt="background"
              />
            </div>

            <div className="absolute top-40 hidden transition-all duration-300 group-hover:scale-110 lg:block">
              <img src={thriveNewsletterBgLine} alt="line" />
            </div>

            <div className="relative h-[30rem] w-full lg:h-[40rem] lg:w-80">
              <img
                className="absolute hidden lg:-top-8 lg:left-full lg:block"
                src={thriveNewsletterLatestArrow}
                alt="arrow"
              />

              <div className="absolute -top-10 right-0 flex w-full justify-end text-lg uppercase text-gray-850 lg:left-full lg:top-0 lg:w-auto lg:-rotate-180 lg:[writing-mode:tb-rl]">
                <div className="pr-3 font-interBold">
                  Sample Newsletter <span className="text-green-400">.</span>
                </div>

                <img
                  className="block rotate-45 scale-x-[-1] lg:hidden"
                  src={thriveNewsletterLatestArrow}
                  alt="arrow"
                />
              </div>

              <div className="relative h-full overflow-auto rounded-[5px] border-2 border-black bg-verticals-thrive shadow-thriveButton group-hover:shadow-thriveNewsletterHover lg:z-10 lg:w-full">
                <ThriveSampleNewsletter />
              </div>

              <div className="absolute bottom-[1px] z-10 mt-10 hidden items-end justify-center gap-2 rounded-b-[5px] border-x-2 border-b-2 border-gray-850 bg-gradient-to-t from-white from-85% to-100% py-3 lg:flex lg:w-80">
                <p className="mb-0 text-lg">scroll to preview</p>
                <img src={thriveNewsletterArrow} alt="arrow" />
              </div>
            </div>
          </div>

          <div className="absolute -bottom-5 -left-[0.2rem] mt-10 flex h-28 w-full items-end justify-center  gap-2 bg-gradient-to-t from-[#FFFCF2] from-30% to-80% py-3 lg:hidden">
            <p className="mx-5 w-full border-2 border-orange-400 bg-white px-4 py-3 text-center font-semibold uppercase text-gray-850">
              Keep reading
            </p>
          </div>
        </Column>
      </Row>
    </Container>
  );
}
