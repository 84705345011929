import assertEnum from "./assertEnum";

/**
 * All Deployment Environments including Local which is
 * not considered live
 */
enum DeploymentEnvironment {
  DEV = "development",
  STAGING = "staging",
  PROD = "production",
  LOCAL = "local",
  NONE = "none",
}

/**
 * Environments which are deployed are considered live
 */
const LIVE_ENVS = new Set([
  DeploymentEnvironment.STAGING,
  DeploymentEnvironment.PROD,
]);

/**
 * Returns whether the passed in environment is considered live or not
 */
export default function isLiveEnvironment(s: string): boolean {
  const enumValue = assertEnum(DeploymentEnvironment, s);

  if (!enumValue) {
    return false;
  }

  return LIVE_ENVS.has(enumValue);
}
