import { ReactElement } from "react";
import { IS_PLAYWRIGHT } from "@util/constant";
import cx from "classnames";

import { AdUnit, type AdUnitProps } from "./AdUnit";
import { ADS_TO_DISABLE_IN_PLAYWRIGHT } from "./constants";

export type AdvertisementProps = {
  rootClassName?: string;
  adUnitProps: AdUnitProps;
  setAdsHeight?: React.Dispatch<React.SetStateAction<number>>;
};

/**
 * This component renders an ad.
 * @param rootClassName - The root class name.
 * @param type - The type of the ad.
 * @param adUnitProps - The props for the ad.
 * @see https://www.imsph.sg/advertising-solutions/digital/imu/
 * @returns
 */
export default function Advertisement({
  rootClassName = "",
  adUnitProps,
  setAdsHeight,
}: AdvertisementProps): ReactElement {
  if (IS_PLAYWRIGHT && ADS_TO_DISABLE_IN_PLAYWRIGHT.includes(adUnitProps.type))
    return <></>;

  return (
    <div
      ref={(e) => {
        if (setAdsHeight && e) {
          setAdsHeight(e.scrollHeight);
        }
      }}
      className={cx("text-center", rootClassName)}
      data-testid="advertisement-element"
    >
      <AdUnit {...adUnitProps} />
    </div>
  );
}
