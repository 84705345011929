import {
  Options,
  Splide,
  SplideSlide,
  SplideTrack,
} from "@greglaisph/react-splide";
import { FilteringAllfaceted } from "@pages/Search/contants";
import {
  CommonSearchAdvanceFilteringProps,
  FilteringfacetedObjectEnum,
} from "@pages/Search/types";

import SearchIndividualAdvanceFiltering from "./SearchIndividualAdvanceFiltering";

export default function SearchAdvanceFiltering({
  facetedSearchAllData,
  setSelectedFacetedObj,
  selectedFacetedAllData,
}: CommonSearchAdvanceFilteringProps): React.ReactElement {
  const options: Options = {
    pagination: false,
    arrows: false,
    autoHeight: true,
    autoWidth: true,
  };

  const handleSelectedFaceted = (
    facetedKey: FilteringfacetedObjectEnum,
    facetedValue: string
  ) => {
    setSelectedFacetedObj(facetedKey, facetedValue);
  };

  return (
    <Splide options={options} hasTrack={false}>
      <SplideTrack className="[&>ul>li:first-child>div]:ml-0 [&>ul>li:last-child>div]:mr-0">
        {FilteringAllfaceted.map((faceted) => {
          const data = facetedSearchAllData?.[faceted.key] || [];

          const getFacetedId =
            selectedFacetedAllData?.findIndex(
              (selectedFacetedData) =>
                selectedFacetedData.facetedKey === faceted.key
            ) || 0;

          const selectedFacetedData = selectedFacetedAllData?.[getFacetedId];

          return (
            <SplideSlide key={faceted.key}>
              <SearchIndividualAdvanceFiltering
                advanceFilteringDisplayObj={faceted}
                facetedSearchData={data}
                selectedFacetedData={selectedFacetedData}
                setSelectedFacetedObj={(
                  facetedKey: FilteringfacetedObjectEnum,
                  facetedValue: string
                ) => {
                  handleSelectedFaceted(facetedKey, facetedValue);
                }}
              />
            </SplideSlide>
          );
        })}
      </SplideTrack>
    </Splide>
  );
}
